import Exception from "~lib/Exception/Exception";
import {ConnectionSettings, DbEnvironment} from "~lib/indexeddb/IndexedDb";

type ConstructorType = {
    message:  string;
    env:      DbEnvironment;
    settings: ConnectionSettings;
};

export default class DbConnectionException extends Exception implements ConstructorType {

    public readonly env:      DbEnvironment;
    public readonly settings: ConnectionSettings;

    constructor(payload: ConstructorType) {
        super({
            type:    'Exception',
            name:    'DbConnectionException',
            label:   'INDEXED-DB',
            message: payload.message,
        });

        this.env      = payload.env;
        this.settings = payload.settings;
    }
}