<template>
  <li
      :class="buttonIconClass"
      class="status">
    <button
        @click="clickHandler($event)"
        class="status__button status-button">
      <svg-elem
          key="notification-highlight-pin"
          sprite="cleared"
          :to="buttonIcon"
          class="status-button__icon" />
    </button>
    <transition name="header-status-tooltip">
      <ul
          v-if="!$notifications.isEmpty && isShown"
          :class="'status-tooltip--position-' + position"
          class="status__tooltip status-tooltip"
      >
        <li
            v-for="notification in latestNotifications"
            class="status-tooltip__item"
        >
          <div class="status-tooltip__notification status-notification">
            <svg-elem
                sprite="cleared"
                :to="notification.icon"
                :class="{'status-notification__icon--is-alert': false}"
                size="size-s"
                class="status-notification__icon"
            />
            <div class="status-notification__content">
              <span class="status-notification__title">{{notification.title}}</span>
              <p class="status-notification__text">{{notification.description}}</p>
              <span class="status-notification__date">{{notification.time}}</span>
            </div>
            <button
                @click="$notifications.remove(notification.id)"
                class="status-notification__close status-notification-close"
            >
              <svg-elem
                  sprite="cleared"
                  to="close"
                  size="size-xs"
                  class="status-notification-close__icon"
              />
            </button>
          </div>
        </li>
      </ul>
      <ul
          v-else-if="isShown"
          :class="'status-tooltip--position-' + position"
          class="status__tooltip status-tooltip"
      >
        <li class="status-tooltip__item">
          <div class="status-tooltip__notification status-notification">
            <div class="status-notification__content">
              <span class="status-notification__title">Уведомлений пока нет</span>
              <p class="status-notification__text">Вы можете закрыть данное окно</p>
              <Button
                  @click="isShown = false"
                  theme-style="outlined"
                  size="size-s"
                  :theme="'outlined-primary'"
                  text="remove"
                  class="status-notification__button"
              />
            </div>
          </div>
        </li>
      </ul>
    </transition>
  </li>
</template>

<script lang="ts">
const CMP_NAME = 'Notifications';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
  computed,
} from "@vue/runtime-core";

import NotificationsState     from "~app/Storage/Notifications.state";
import UseStandaloneNotification from "~tpl-desktop/workspace/TopBar/useNotifications";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
    from "~tpl-global/svg/SvgElem.vue";
import Button
    from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import {SvgList}
    from "~tpl-global/svg/SvgList";

/** COMPONENT LOGIC: */
const $notifications = NotificationsState();
const {
  isShown,
  position,
  clickHandler
} = UseStandaloneNotification();

/**
 * Определяем класс цвета кнопки в топ меню на основании наличия уведомлений
 */
const buttonIconClass = computed<string>(() => {
  return $notifications.isEmpty ? 'status--is-normal' : 'status--has-notifications';
});

/**
 * Определяем иконку кнопки в топ меню на основании наличия уведомлений
 */
const buttonIcon = computed<SvgList>(() => {
  return $notifications.isEmpty ? 'notification' : 'notification--new';
});

// список последних N уведомлений:
const latestNotifications = computed(() => {
  return $notifications.list.slice(0, 6);
});
</script>

<style lang="scss" scoped>
  @import '~scss-desktop/common/components/header-statuses/_header-statuses';
</style>