import {defineStore}
    from 'pinia'
import {ref, computed, triggerRef}
    from "@vue/runtime-core";

export const SettingsModuleKey: symbol = Symbol.for('SettingsModule');

const SettingsState = defineStore(SettingsModuleKey.toString(), () =>
{
    /**
     * Количество секунд, в течение которых содержимое
     * какого-либо кеша LIVE фида считается актуальным.
     */
    const _liveFeedCacheStoreSec = ref<number>(30);

    /**
     * Максимальное количество групп событий LIVE фида, которое
     * может одновременно быть сохранено в фиде.
     */
    const _liveFeedGroupsCacheMaxAmount = ref<number>(20);

    /**
     * Список настроек кеширования для функционала беттинга.
     */
    const bettingCacheSettings = computed(
        () => {
            return {
                liveFeedCacheStoreSec:        _liveFeedCacheStoreSec.value,
                liveFeedGroupsCacheMaxAmount: _liveFeedGroupsCacheMaxAmount.value
            };
        }
    );

    return {
        bettingCacheSettings,
    };
});

export default SettingsState;