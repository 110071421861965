/**
 * Коды событий, которые отдает "API" веб касс
 * при возникновении особых событий.
 */
export enum ApiStatusCode {
    /**
     * Запрос был отклонен т.к. в цепочке участвует сторонний сервис,
     * и срок жизни токена доступа кассира к нему истек, либо токен был отозван.
     */
    CASHIER_AUTH_EXPIRED = 42501700,

    /**
     * Запрос был отклонен, т.к. совершенно множество однотипных запросов
     * одного и того же значения id или телефона игрока
     */
    INTERNAL_SERVICE_THROTTLING = 52401011,
}