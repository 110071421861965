import {defineStore}
    from 'pinia';
import {ref, computed, triggerRef}
    from "@vue/runtime-core";
import Exception
    from "~lib/Exception/Exception";

import {OnAppInitTask, ProcessedInitTaskData}
    from "~app/Epos/Contracts/Epos.types";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";

export const SyncDataStateKey: symbol = Symbol
    .for('PiniaSyncDataState');

/**
 * Хранилище предназначено для обработки и отображения в интерфейсе
 * шагов инициализации и синхронизации приложения.
 *
 * Все задания выполняются по порядку, согласно очередности.
 *
 * Текущее активное задание рассчитывается автоматически,
 * на основе поля "isDone". Т.е. текущим активным заданием
 * считается первый элемент в списке, у которого "isDone === false".
 */
const SyncDataState = defineStore(SyncDataStateKey.toString(), () => {

    /**
     * Список метаданных для заданий, которые должны быть успешно выполнены
     * для инициализации интерфейса.
     */
    const _queue = ref<ProcessedInitTaskData[]|null>(null);

    /**
     * Исключение, которое привело к остановке обработки очереди.
     */
    const _occurredException = ref<Exception|null>(null);

    /**
     * Индекс задания, находящегося в обработке
     * в настоящий момент.
     *
     * Если равно "NULL", то либо очередь пуста, либо
     * все задачи из очереди выполнены успешно.
     */
    const _handledTaskIndex = computed(
        (): null|number => {

            if (null === _queue.value) {
                return null;
            }

            for (const index of _queue.value.keys()) {

                const task = _queue.value[index];

                if (task.isDone) {
                    continue;
                }

                return index;
            }

            return null;
        });

    /**
     * Возвращает "TRUE" если в очередь не загружено хотя бы одно задание.
     */
    const isQueueEmpty = computed<boolean>(
        () => null === _queue.value || _queue.value.length < 1
    );

    /**
     * Возвращает "TRUE", если очередь заданий была прервана
     * из-за возникшего исключения.
     */
    const isProcessingFailed = computed<boolean>(
        () => null !== _occurredException.value
    );

    /**
     * Позиция в списке для текущего
     * обрабатываемого задания.
     */
    const handledTaskSequenceNumber = computed(
        (): number|null => {
            return (null === _handledTaskIndex.value || null === _queue.value)
                ? null
                : _queue.value.slice(0, _handledTaskIndex.value).length +1;
        });

    /**
     * @see OnAppInitTask.id
     */
    const handledTaskId = computed(
        () => {
            return (null === _handledTaskIndex.value || null === _queue.value)
                ? null
                : _queue.value[_handledTaskIndex.value].id
        });

    /**
     * Описание текущего шага синхронизации, подходящего
     * для отображения в интерфейсе.
     *
     * Если очередь была прервана из-за возникшей не фатальной ошибки,
     * то будет содержать подходящее для отображения сообщение об ошибке.
     */
    const handledTaskDescription = computed(
        (): string[]|null => {

            if (null === _handledTaskIndex.value || null === _queue.value) {
                return null;
            }

            const {
                $T
            } = useInterfaceTexts();

            if (isProcessingFailed.value) {
                return $T.composite<string[]>('interface-init-stage-failed-msg', null, {
                    'description': $T.text(_queue.value[_handledTaskIndex.value].description ?? '')
                })
            }

            return $T.composite<string[]>('interface-init-stage-description', null,{
                'step-counter': `${(handledTaskSequenceNumber.value ?? -1)}/${_queue.value.length}`,
                'description':  $T.text(_queue.value[_handledTaskIndex.value].description ?? '')
            });
        });

    /**
     * Загрузка списка заданий на исполнение.
     */
    function loadInitQueue(
        payload: OnAppInitTask[]
    ): void
    {
        const list: ProcessedInitTaskData[] = [];

        for (const task of payload) {
            list.push({
                id:             task.id,
                description:    task.description,
                attempts:       task.attempts,
                isDone:         false,
            });
        }

        _queue.value = list;
    }

    /**
     * Очистка списка исполняемых заданий, и
     * всех сопутствующих данных.
     */
    function flushInitQueue(): void
    {
        _queue.value = null;
    }

    /**
     * Запрос на пометку текущего задания как выполненного,
     * и переключение очереди на следующее задание.
     *
     * Необходимо передать идентификатор выполненного задания,
     * причем он должен совпадать с текущим обрабатываемым.
     *
     * @param id - Идентификатор отмечаемого задания.
     */
    function commitCurrentTaskAsCompleted(
        id: string
    ): boolean
    {
        if (
            handledTaskId.value !== id       ||
            null === _handledTaskIndex.value ||
            null === _queue.value
        ) {
            return false;
        }

        _queue.value[
            _handledTaskIndex.value
            ].isDone = true;

        triggerRef(
            _queue
        );

        return true;
    }

    /**
     * Загрузка или очистка исключения, приведшего
     * к остановке обработки списка заданий.
     */
    function submitOrClearException(
        payload: Exception|null
    ): void
    {
        _occurredException
            .value = payload;

        triggerRef(
            _occurredException
        );
    }

    return {
        isQueueEmpty,
        handledTaskId,
        handledTaskDescription,
        handledTaskSequenceNumber,
        isProcessingFailed,

        loadInitQueue,
        flushInitQueue,
        commitCurrentTaskAsCompleted,
        submitOrClearException,
    };
});

export default SyncDataState;