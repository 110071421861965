import {cloneDeep}
    from "lodash";
import {defineStore}
    from 'pinia'
import {computed, ref, toRaw, triggerRef}
    from "@vue/runtime-core";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import * as FeedTypes
    from "~app/Betting/Contracts/Feed.types";
import * as BettingTypes
    from "~app/Betting/Contracts/Betting.types";
import {BetPaymentType}
    from "~app/Betting/Emum/BetPaymentType";
import {CouponOddsChangeAction}
    from "~app/Betting/Emum/CouponOddsChangeAction";
import {BetSettingsStep}
    from "~app/Betting/Emum/BetSettingsStep";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {BettingErrors}
    from "~app/Betting/Betting.errors";

export const BettingStateKey: symbol = Symbol.for('BettingStateModule');

const BettingState = defineStore(BettingStateKey.toString(), () => {

    /**
     * @see activeEvent
     */
    const _activeEvent = ref<FeedTypes.Event|null>(null);

    /**
     * Событие, для которого в интерфейс будет
     * загружен список маркетов.
     *
     * Если для отображения выбрано вложенное событие, то
     * маркеты будут загружены именно для него,
     * а не для основного события.
     *
     * Переменная, определяющая идентификатор
     * загружаемого маркета:
     * @see requestedMarketId
     *
     * Переменная, определяющая структуру события,
     * добавляемого в купон:
     * @see eventForRequestedMarket
     */
    const activeEvent = computed<FeedTypes.Event|null>(
        () => {
            return  _activeEvent.value
        });

    /**
     * Идентификатор выбранного в интерфейсе события, для которого
     * необходимо запросить и отобразить список маркетов.
     */
    const activeEventId = computed<number|null>(() =>
    {
        return (null === _activeEvent.value)
            ? null
            : _activeEvent.value.id
    });

    /**
     * Событие (или вложенное событие), которое используется
     * для запроса списка маркетов, либо же для добавления в купон.
     */
    const eventForRequestedMarket = computed<FeedTypes.Event|null>(
        () => {
            if (null === _activeEvent.value) {
                return null;
            }

            const {
                $feed,
            } = usePiniaState();

            // Проверка выбора для отображения не основного события,
            // а одного из его вложенных событий:
            const selectedSubId: number|null = $feed.selectedSubEventsMapping[
                _activeEvent.value.id
                ] ?? null;

            if (null === selectedSubId) {
                // Вложенное событие не было выбрано, потому
                // должно быть отображено основное событие:
                return _activeEvent.value;
            }

            let subEvent: FeedTypes.Event|null = null;

            // Поиск среди вложенных событий того,
            // с которым должен взаимодействовать интерфейс
            // согласно маппингу:
            for (const iteratedSubEvent of Object.values(
                _activeEvent.value.subEvents ?? [])
                )
            {
                if (iteratedSubEvent.id !== selectedSubId) {
                    continue;
                }

                subEvent = iteratedSubEvent;
            }

            if (null === subEvent) {
                throw new RuntimeException({
                    code:    BettingErrors.MARKETS_UNEXPECTED_ERROR,
                    isFatal: true,
                    label:   'BETTING-INTERFACE',
                    message: `Interface must display SubEvent with id: '${selectedSubId}'` +
                             ` for MainEvent id '${_activeEvent.value.id}', but` +
                             ` requested SubEvent is not found in available list.`
                });
            }

            return subEvent;
        });

    /**
     * Идентификатор события или вложенного события, для которого
     * необходимо загрузить и отрисовать маркет.
     */
    const requestedMarketId = computed<number|null>(
        () => eventForRequestedMarket.value?.id ?? null);

    /**
     * Возвращает "TRUE" если в настоящий момент в интерфейс загружено
     * событие, для которого требуется отрисовать список маркетов.
     */
    const isEventSelected = computed<boolean>(() => {
        return null !== activeEventId.value;
    });

    /**
     * @see availableMarkets
     */
    const _availableMarkets = ref<{[key: string]: FeedTypes.Outcome}|null>(null);

    /**
     * Список маркетов, доступных для текущего,
     * загруженного в интерфейс события.
     */
    const availableMarkets = computed(() => _availableMarkets.value);

    /**
     * @see betSlip
     */
    const _betSlip = ref<{[key: string]: FeedTypes.OutcomeInBetSlip}>({});

    /**
     * Список набранных в ставку событий.
     */
    const betSlip = computed<{[key: string]: FeedTypes.OutcomeInBetSlip}>(
        () => _betSlip.value
    );

    /**
     * Возвращает "TRUE" если в настоящий момент купон пуст.
     */
    const isBetSlipEmpty = computed<boolean>(
        (): boolean => Object.keys(_betSlip.value).length > 0
    );

    /**
     * @see settingsStep
     */
    const _settingsStep = ref<BetSettingsStep>(
        BetSettingsStep.StandByMode
    );

    /**
     * Шаг с настройками проведения ставки.
     *
     * Состояние "BetSettingsStep.StandByMode" означает,
     * что интерфейс находится в режиме выбора списка исходов событий.
     */
    const settingsStep = computed<BetSettingsStep>(
        () => _settingsStep.value
    );

    /**
     * Флаг, переводящий раздел беттинга
     * в состояние настройки параметров ставки.
     */
    const isBetSettingsActive = computed<boolean>(
        () => _settingsStep.value > BetSettingsStep.StandByMode
    );

    /**
     * @see betPaymentType
     */
    const _betPaymentType = ref<BetPaymentType>(
        BetPaymentType.Cash
    );

    /**
     * Выбранный способ оплаты проводимой ставки.
     */
    const betPaymentType = computed<BetPaymentType>(
        () => _betPaymentType.value
    );

    /**
     * @see minStakeAmount
     */
    const _minStakeAmount = ref<number>(0);

    /**
     * Минимальная допустимая сумма ставки.
     *
     * Если "0", то не ограничено.
     */
    const minStakeAmount = computed<number>(
        () => _minStakeAmount.value
    );

    /**
     * @see minStakeAmount
     */
    const _maxStakeAmount = ref<number>(0);

    /**
     * Максимальная допустимая сумма ставки.
     *
     * Если "0", то не ограничено.
     */
    const maxStakeAmount = computed<number>(
        () => _maxStakeAmount.value
    );

    /**
     * @see onCouponOddsChangeAction
     */
    const _onCouponOddsChangeAction = ref<CouponOddsChangeAction>(
        CouponOddsChangeAction.AcceptOnlyIfIncrease
    );

    /**
     * Выбранное действие логики, при изменении
     * коэффициентов ставки.
     *
     * Полный список опций: "couponOddsChangeActionsList".
     *
     * По умолчанию выбирается элемент с нулевым индексом
     * из списка доступных действий.
     */
    const onCouponOddsChangeAction = computed(
        () => _onCouponOddsChangeAction.value
    );

    /**
     * Возвращает "TRUE", если интерфейс должен отрисовать и
     * подготовить к печати компонент купона.
     */
    const isCouponPrintElemMustBeRendered = computed<boolean>(
        (): boolean => false,
    );

    /**
     * @see couponsPerShift
     */
    const _couponsPerShift = ref<BettingTypes.CouponShort[]>([]);

    /**
     * Список купонов, принятых в течение текущей смены кассира.
     */
    const couponsPerShift = computed(
        () => _couponsPerShift.value
    );

    /**
     * Возвращает "TRUE" если в течение текущей смены кассира
     * был принят хотя бы один купон.
     */
    const isCouponPerShiftListEmpty = computed<boolean>(
        () => _couponsPerShift.value.length < 1
    );

    /**
     * Обновление хранящегося в памяти приложения списка купонов,
     * принятых в течение текущей активной смены.
     *
     * Для обнуления списка необходимо передать пустой массив.
     */
    async function updateCouponsPerShiftList(
        payload: BettingTypes.CouponShort[]
    ): Promise<void>
    {
        _couponsPerShift.value = payload;
    }

    /**
     * Задает поведение кассы при изменении коэффициентов
     * в момент проведения ставки.
     */
    async function setOnCouponOddsChangeAction(
        payload: CouponOddsChangeAction
    ): Promise<void>
    {
        _onCouponOddsChangeAction.value = payload;
    }

    /**
     * Добавление в купон ставки исхода переданного события.
     */
    async function addOutcomeToSlip(
        payload: FeedTypes.OutcomeInBetSlip
    ): Promise<void>
    {
        // Добавление или замена исхода события в купон ставки:
        _betSlip.value[payload.key] = payload;

        // Страховочное обновление
        // реактивности:
        triggerRef(
            _betSlip
        );
    }

    /**
     * Удаление из купона исхода
     * переданного события.
     *
     * Если событие было найдено и удалено,
     * то вернется "TRUE".
     *
     * @param payload - Ключ исхода в купоне или структура исхода события.
     */
    async function removeOutcomeFromSlip(
        payload: FeedTypes.OutcomeInBetSlip|string
    ): Promise<boolean>
    {
        if ('object' !== typeof (_betSlip.value['string' === typeof payload
            ? payload
            : payload.key]
        )) {
            return false;
        }

        // Удаление исхода события из купона ставки:
        delete _betSlip.value['string' === typeof payload
            ? payload
            : payload.key];

        // Страховочное обновление
        // реактивности:
        triggerRef(
            _betSlip
        );

        return true;
    }

    /**
     * Очистка всех исходов событий,
     * набранных в купон.
     */
    async function flushSlip(): Promise<void>
    {
        _betSlip.value = {};

        // Страховочное обновление
        // реактивности:
        triggerRef(
            _betSlip
        );
    }

    /**
     * Поиск по уникальному ключу исхода,
     * добавленного в купон.
     *
     * Если исход не найден, то вернется "NULL".
     */
    async function getOutcomeFromSlipByKey(
        key: string
    ): Promise<FeedTypes.OutcomeInBetSlip|null>
    {
        return _betSlip.value[key] ?? null;
    }

    /**
     * Проверка существования в купоне того же,
     * либо связанного с переданным, исхода события.
     */
    async function isOutcomeExistsInSlip(
        payload: FeedTypes.OutcomeInBetSlip
    ): Promise<FeedTypes.OutcomeInBetSlip|false>
    {
        let existedOutComeInSlip: FeedTypes.OutcomeInBetSlip|null = null;

        for (const search of Object.values(betSlip.value)) {
            if (
                // Если уже добавлено основное событие,
                // и пытаются заменить на то же основное:
                (search.event.id !== null && search.event.id === payload.event.id) ||
                // Если уже добавлено основное событие,
                // и пытаются заменить на связанное вложенное:
                (search.event.id !== null && search.event.id === payload.event.mainEventId) ||
                // Если уже добавлено вложенное событие, а теперь
                // пытаются добавить другое вложенное событие:
                (search.event.mainEventId !== null && search.event.mainEventId === payload.event.mainEventId) ||
                // Если уже добавлено то же вложенное, а теперь
                // пытаются заменить на основное:
                (search.event.mainEventId !== null && search.event.mainEventId === payload.event.id)
            )
            {
                // Тот же, или связанный с переданным,
                // исход события уже находится в купоне:
                existedOutComeInSlip = cloneDeep(toRaw(search));
                break;
            }
        }

        return existedOutComeInSlip
            ?? false;
    }

    /**
     * Задает событие для которого необходимо
     * отрисовать список маркетов.
     *
     * ВНИМАНИЕ! Если требуется отображение вложенного события, то
     * загружать нужно непосредственно его.
     */
    async function loadEventIntoMarket(
        payload: FeedTypes.Event
    ): Promise<void>
    {
        _activeEvent.value = payload;

        triggerRef(
            _activeEvent
        );
    }

    /**
     * Отменяет выбор текущего активного события, для которого
     * интерфейс должен отображать список маркетов.
     */
    async function closeActiveMarket(): Promise<number|null>
    {
        if (null === _activeEvent.value) {
            return null;
        }

        const id: number = _activeEvent.value.id;

        _activeEvent.value = null;

        triggerRef(
            _activeEvent
        );

        await flushEventMarkets();

        return id;
    }

    /**
     * Загрузка в интерфейс списка исходов, доступных
     * для загруженного в маркет события.
     */
    async function loadEventMarkets(
        payload: {[key: string]: FeedTypes.Outcome}
    ): Promise<void>
    {
        _availableMarkets.value = payload;

        triggerRef(
            _availableMarkets
        );
    }

    /**
     * Очистка списка доступных исходов.
     */
    async function flushEventMarkets(): Promise<void>
    {
        _availableMarkets.value = null;

        triggerRef(
            _availableMarkets
        );
    }

    /**
     * Устанавливает текущий шаг настроек проводимой ставки.
     *
     * (Метод не должен содержать никакой логики, а только
     * устанавливать одно из переданных корректных значений)
     */
    async function setActualBettingStep(
        step: BetSettingsStep
    ): Promise<void>
    {
        _settingsStep.value = step;
    }

    /**
     * Устанавливает способ оплаты
     * проводимой ставки.
     */
    async function setBetPaymentType(
        type: BetPaymentType
    ): Promise<void>
    {
        _betPaymentType.value = type;
    }

    /**
     * Применение к текущему активному купону списка
     * переданных ограничений.
     *
     * Для исходов, присутствующих в купоне, но отсутствующих
     * в переданном списка, флаг блокировки будет снят, и
     * сброшено значение предыдущего коэффициента.
     */
    async function applyRestrictionsToActiveCoupon(
        payload: {[key: number]: BettingTypes.RestrictedOutcome}
    ): Promise<void>
    {
        if (Object.keys(_betSlip.value).length < 1) {
            return;
        }

        for (const OISKey of Object.keys(_betSlip.value)) {
            // Событие из купона:
            const OIS = _betSlip.value[OISKey];

            // Наложенное на событие ограничение:
            const restriction = payload[
                OIS.event.id
                ];

            if ('object' !== typeof restriction || 'object' !== typeof OIS) {
                // Снятие с события купона информации о блокировке,
                // и подсветки разницы между коэффициентами:
                OIS.outcome.isLocked = false;
                OIS.outcome.prevOdds = null;

                _betSlip.value[OISKey] = OIS;
                // Пропуск исхода т.к. для него нет ограничений,
                // либо не получилось найти само событие
                // в купоне по ключу:
                continue;
            }

            // Обновление флага блокировки исхода:
            OIS.outcome.isLocked = restriction.isLocked;

            // Обновление текущего и предыдущих коэффициентов
            // исхода события:
            if (null !== restriction.allowedOdds) {
                OIS.outcome.prevOdds = OIS.outcome.odds;
                OIS.outcome.odds     = restriction.allowedOdds;
            }

            _betSlip.value[OISKey] = OIS;
        }

        // Страховочное обновление
        // реактивности:
        triggerRef(
            _betSlip
        );
    }

    return {
        activeEventId,
        isEventSelected,
        activeEvent,
        availableMarkets,
        betSlip,
        isBetSettingsActive,
        settingsStep,
        betPaymentType,
        minStakeAmount,
        maxStakeAmount,
        onCouponOddsChangeAction,
        isBetSlipEmpty,
        isCouponPrintElemMustBeRendered,
        couponsPerShift,
        isCouponPerShiftListEmpty,
        eventForRequestedMarket,
        requestedMarketId,

        loadEventIntoMarket,
        closeActiveMarket,
        loadEventMarkets,
        flushEventMarkets,
        addOutcomeToSlip,
        removeOutcomeFromSlip,
        isOutcomeExistsInSlip,
        getOutcomeFromSlipByKey,
        setActualBettingStep,
        setBetPaymentType,
        flushSlip,
        setOnCouponOddsChangeAction,
        applyRestrictionsToActiveCoupon,
        updateCouponsPerShiftList,
    };
});

export default BettingState;