import {defineStore}
    from 'pinia'
import {ref, computed, triggerRef}
    from "@vue/runtime-core";
import LocalStorage
    from "~lib/LocalStorage";
import Date
    from "~lib/helpers/Date";
import Helpers
    from "~lib/helpers";

import Application
    from "~app/Epos/Models/Application";
import {ApplicationSystemParameters}
    from "~app/Epos/Contracts/Epos.types";

export const ApplicationStateKey: symbol = Symbol
    .for('PiniaApplicationState');

const ApplicationState = defineStore(ApplicationStateKey.toString(), () => {

    /**
     * Системные параметры приложения.
     */
    const _parameters = ref<Application>(
        new Application()
    );

    /**
     * Прокси доступ к системным параметрам приложения.
     *
     * ВНИМАНИЕ!
     * Не рекомендуется использовать для "реактивных" проверок.
     */
    const parameters = computed(
        () => {
            return <T extends keyof ApplicationSystemParameters>(
                field: T
            ): ApplicationSystemParameters[T]|null => {
                return _parameters.value?.get(field) ?? null
            };
        });

    /**
     * Модификация значения системного параметра.
     *
     * @param field   - Параметр.
     * @param payload - Новое значение.
     */
    function changeParameter<T extends keyof ApplicationSystemParameters>(
        field:   T,
        payload: ApplicationSystemParameters[T]
    ): void
    {
        if (null === _parameters.value) {
            return;
        }

        _parameters.value.set(
            field,
            payload
        );

        triggerRef(
            _parameters
        );
    }

    /**
     * Если "TRUE", то веб сервер вернул ошибку инициализации,
     * и нормальная работа приложения невозможна.
     */
    const isAppStartFailed = computed<boolean>(
        () => 200 !== parameters.value('initHttpCode')
    );

    /**
     * Если "TRUE", то функционал веб касс находится на техобслуживании.
     */
    const isMaintenanceActive = computed<boolean>(
        () => true === parameters.value('isMaintenanceActive')
    );

    /**
     * Если "TRUE", то приложение закончило подготовку к запуску, и
     * выполнение всех заданных сервис провайдеров.
     */
    const isAppBootstrapFinished = computed<boolean>(
        () => true === parameters.value('isAppBootstrapFinished')
    );

    /**
     * При переключении маркера в "TRUE" приложение
     * должно аварийно завершить работу.
     *
     * Возврат маркера в обратное состояние не допускается.
     */
    const isAppCrashed = computed<boolean>(
        () => true === parameters.value('isAppCrashed'),
    );

    /**
     * При переключении маркера в "TRUE" приложение должно
     * корректно завершать свою работу.
     *
     * Повторное возвращение флага в состояние "FALSE" не допускается.
     */
    const isAppHasDuplicatedInstance = computed(
        () => true === parameters.value('isDuplicatedInstanceDetected')
    );

    /**
     * Флаг отвечающий за глобальную загруженность интерфейса,
     * какой-либо задачей.
     *
     * При активации флага ограничивается часть взаимодействия
     * пользователя с интерфейсом, а так же некоторые фоновые задачи.
     */
    const isAppBusy = computed<boolean>(
        () => true === parameters.value('isAppBusy') || isAppCrashed.value
    );

    /**
     * TODO: перенести в отдельное хранилище
     *
     * Идентификатор активного в настоящий момент
     * окна интерфейса.
     */
    const _windowId = ref<string|null>(null);

    /**
     * Часы кассы, в формате
     * day:  XXX
     * time: --:--
     * date:
     */
    const clock = ref<{day: string, time: string, date: string}|null>(null);

    /**
     * Возвращает "TRUE" если интерфейс поддерживает возможность
     * переключения в ночной режим
     */
    const isNightModeAvailableForPallet = computed<boolean>(
        () => {
            return true === parameters.value('hasNightMode');
        });

    /**
     * Возвращает "TRUE" если в настоящий момент в интерфейсе должен
     * использоваться ночной режим.
     */
    const isNightModeActive = computed<boolean>(
        () => {
            return isNightModeAvailableForPallet.value &&
                true === parameters.value('isNightModeActive');
        });

    /**
     * TODO: переделать
     *
     * @see _windowId
     */
    const windowId = computed<string|null>(() => _windowId.value);

    /**
     * TODO: убедиться в актуальности использования параметра
     */
    const isTextHighlightDisabled = computed<boolean>(
        () => false
    );

    /**
     * Идентификатор используемой интерфейсом языковой локали.
     */
    const locale = computed<string>(
        () => parameters.value('locale') ?? ''
    );

    /**
     * Первые для символа языковой локали.
     */
    const lang = computed<string>(() => {
        return locale.value.substring(0, 2);
    });

    /**
     * Возвращает "TRUE" если текст локали
     * должен отображаться справа-налево.
     */
    const isLocaleRTL = computed<boolean>(
        () => {
            // Список локалей, для которых интерфейс
            // должен отображаться справа-налево:
            const RTL: string[] = [
                'ar_AE'
            ];

            return RTL.includes(locale.value);
        });

    /**
     * TODO: пересмотреть
     *
     * Переключатель активных окон интерфейса.
     */
    function changeActiveWindowId(
        id: string | null
    ): void
    {
        if (_windowId.value === id)
            return;

        _windowId.value = id;

        triggerRef(
            _windowId
        );
    }

    /**
     * Переключает флаг глобальной занятости приложения.
     *
     * @param payload - состояние флага, на которое необходимо переключить
     * @param waitMs  - (опционально) задержка перед переключением флага
     */
    async function changeAppBusyStatus(
        payload: boolean,
        waitMs:  number = 0
    ): Promise<void>
    {
        if (waitMs > 0) {
            await Helpers.sleep(waitMs);
        }

        changeParameter(
            'isAppBusy',
            payload
        );
    }

    /**
     * TODO: перенести в workspace?
     *
     * Возвращает временной штамп
     * на клиенте пользователя.
     *
     * Используется для расчета отображаемых
     * на кассе времени и дат.
     */
    function getWorkspaceTimestamp(): number
    {
        // TODO: учитывать возможности установки кривого времени
        // TODO: возможно стоит перенести в workspace
        // на системе пользователя
        return Date.clientTimestamp();
    }

    /**
     * Обновляет данные в часах, предназначенных
     * только для отображения в интерфейсе.
     */
    function updateClock(
        payload: {
            day:  string,
            time: string,
            date: string
        }|null
    ): void
    {
        clock.value = payload;
    }

    /**
     * Проверяет соответствие между UUID идентификатором,
     * хранящимся в приложении, и тем, что установлен
     * в локальное хранилище.
     *
     * В случае если идентификаторы не совпадают - вернется "FALSE",
     * и считается что приложение должно немедленно остановить свою работу.
     *
     * Если UUID идентификатор еще не был задан, то вернется значение "NULL".
     */
    function checkAppUUID(): boolean|null
    {
        const uuid: string|null = parameters
            .value('instanceUUID');

        if (null === uuid) {
            return null;
        }

        return LocalStorage
            .getValue('app-uuid') === uuid;
    }

    /**
     * Переключатель интерфейса, адаптированного к помещению
     * со слабой освещенностью.
     */
    function switchInterfaceNightMode(
        isNightModeActive: boolean
    ): void
    {
        changeParameter(
            'isNightModeActive',
            isNightModeActive
        );

        window
            .document
            .getElementsByTagName("html")[0]
            .setAttribute(
                'theme',
                (isNightModeActive)
                    ? 'dark'
                    : 'light'
            );

        LocalStorage.setValue(
            'night-mode',
            (isNightModeActive)
                ? '1'
                : '0'
        );
    }

    return {
        _parameters,
        parameters,
        isAppBusy,
        locale,
        lang,
        isTextHighlightDisabled,
        isAppBootstrapFinished,
        isAppCrashed,
        isMaintenanceActive,
        isAppHasDuplicatedInstance,
        windowId,
        clock,
        isNightModeActive,
        isNightModeAvailableForPallet,
        isLocaleRTL,
        isAppStartFailed,

        changeParameter,
        changeActiveWindowId,
        changeAppBusyStatus,
        getWorkspaceTimestamp,
        updateClock,
        checkAppUUID,
        switchInterfaceNightMode,
    };
});

export default ApplicationState;