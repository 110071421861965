import Exception
    from "~lib/Exception/Exception";

export type ServiceProviderConstructor = {
    provider:  string;
    code:      number;
    message:   string|null;
    previous?: any;
};

/**
 * Исключение, возникающее при обработке
 * логики сервис провайдеров.
 *
 * Всегда приводит к аварийному завершению
 * работы приложения.
 */
export default class ServiceProviderException
    extends    Exception
    implements ServiceProviderConstructor {

    /**
     * Название провайдера, в котором произошла ошибка.
     */
    public readonly provider: string;

    constructor(
        payload: ServiceProviderConstructor
    )
    {
        super({
            isFatal:  true,
            code:     payload.code,
            type:     'Exception',
            name:     'ServiceProviderException',
            label:    'SERVICE-PROVIDER',
            message:  payload.message,
            previous: payload.previous
        });

        this.provider = payload.provider;
    }
}