/**
 * Технический класс, для обеспечения правильной работы
 * некоторого функционала "eloquent model".
 */
export default class FieldMap {

    public static readonly MODEL_SEGMENT        = 'Model';
    public static readonly INCORRECT_ROOT_FIELD = 'RootFieldError';

    /**
     * Массив с сегментами запроса.
     */
    public readonly segments: string[];

    /**
     * Название корневого поля.
     */
    public readonly rootField: string;

    /**
     * Оставшаяся часть "хвоста" запроса,
     * исключая корневое поле.
     */
    public readonly tailString: string;

    /**
     * Количество сегментов в запрашиваемом поле.
     */
    public readonly length: number;

    /**
     * Длина сегмента запроса, исключая корневое поле.
     */
    public readonly tailLength: number;

    /**
     * Массив строк, содержащий сегменты "хвоста".
     */
    public readonly tail: string[];

    /**
     * @param field - запрошенное поле, любой вложенности
     */
    constructor(field: string) {
        this.segments   = field.split('.');
        this.tail       = this.segments.splice(1);
        this.length     = this.segments.length;
        this.tailLength = this.tail.length;

        this.rootField  = this.segments[0];
        this.tailString = (this.hasTail())
            ? this.tail.join('.')
            : '';
    }

    /**
     * Содержит ли запрашиваемое "поле" модели
     * вложенные поля.
     */
    public hasTail(): boolean
    {
        return this.tailLength >= 1;
    }

    /**
     * Возвращает строку поля, использующаяся
     * при создании объекта.
     */
    public getField(): string
    {
        return (this.hasTail())
            ? `${this.rootField}.${this.tailString}`
            : this.rootField;
    }
}