import { decode } from "@msgpack/msgpack";

export default class MsgpackDecoder {

    /**
     * TODO: описание, асинхронное декодирование
     *
     * @param payload
     */
    public static decode<R = any>(payload: ArrayBuffer|ArrayLike<number>|BufferSource): R
    {
        return decode(payload) as unknown as R;
    }
}