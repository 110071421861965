import { Container } from "inversify";
/**
 * Обертка для регистрации сервисов в контейнер,
 * предоставляемый расширением "InversifyJS".
 * @see https://inversify.io/
 *
 * Injecting a constant or dynamic value:
 * @see https://github.com/inversify/InversifyJS/blob/master/wiki/value_injection.md
 *
 * InversifyJS необходим заданный "тип", который будет использоваться
 * в качестве идентификатора, во время работы приложения.
 *
 * По-умолчанию лучше использовать 'Symbol.for("type-name")',
 * но так же допускается использовать классы или строковые литералы.
 *
 * ----------------------------------------------------------------
 *
 * Пример:
 *
 * const
 *   type    = Symbol.for("type-name"),
 *   payload = 12345;
 *
 * регистрация в контейнере:
 * container.bind<number>(type).toConstantValue(payload);
 *
 * извлечение из контейнера:
 * container.get<number>(type);
 */
export default abstract class ServiceProvider
{
    /**
     * Имя инициализируемого сервис провайдера.
     */
    abstract name(): symbol;

    /**
     * Описание логики регистрации сервис провайдера
     * в приложении.
     *
     * @param app
     */
    abstract register(
        app: Container
    ): void|Promise<void>;
};
