<template>
  <div class="empty-state">
    <!-- Иконка: -->
    <svg-elem
        :to="ico"
        sprite="cleared"
        :size="icoSize"
        class="empty-state__icon empty-state__icon-flicker"
        :class="{'empty-state__icon-flicker': icoFlicker}"
    />
    <!-- Прогресс бар -->
    <progress-bar
        v-if="'undefined' !== typeof barPercent"
        :percentage="barPercent"
        class="empty-state__progressbar"
    />
    <!-- Заголовок: -->
    <h3 class="empty-state__title">{{ title }}</h3>
    <!-- Дополнительное описание: -->
    <p v-if="'undefined' !== typeof desc" class="empty-state__subtitle no-select">{{ desc }}</p>
    <Button
        v-if="'undefined' !== typeof btn"
        :text="btn.text"
        @click="btn.callable()"
        class="empty-state__button no-select"
        label-style="uppercase"
    />
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'EmptyWindowContent';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {defineProps, withDefaults}
  from "@vue/runtime-core";

/** IMPORTED VUE TEMPLATES: */
import {SvgList}
  from "~tpl-global/svg/SvgList";
import SvgElem
  from "~tpl-global/svg/SvgElem.vue";
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import ProgressBar
  from "~tpl-global/ProgressBar/ProgressBar.vue";
import {SvgSize}
  from "~tpl-global/svg/SvgSize";

/** COMPONENT LOGIC: */
type ComponentProps = {
  ico: SvgList;
  icoSize?: SvgSize;
  icoFlicker?: boolean | undefined;
  title: string;
  desc?: string | undefined;
  barPercent?: number | undefined;
  btn?: {
    text: string,
    callable: () => void | Promise<void>
  } | undefined;
}

// server-settings
const props = withDefaults(defineProps<ComponentProps>(), {
  icoFlicker: false,
  icoSize: 'size-custom-80'
});
</script>

<style lang="scss" scoped>
@import '~scss-mobile/components/_empty-state';
</style>