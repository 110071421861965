<template>
  <div class="workplace-info">
    <h3 class="workplace-info__title">{{ $T.text('not-auth-epos-info-header') }}:</h3>
    <ul class="workplace-info__list workplace-info-list">
      <EposInfoLine
          ico="home--front"
          :title="$workspace.workspaceName ?? '-'"
          :description="$T.text('epos-info-note-name')"
      />
      <EposInfoLine
          ico="time--pictogram"
          :title="$T.text('epos-info-working-hours-around-the-clock')"
          :description="$T.text('epos-info-note-working-hours')"
          no-mirrored
      />
      <EposInfoLine
          ico="endpoint--protection"
          :title="$workspace.workspaceCode ?? '-'"
          :description="$T.text('epos-info-note-identifier')"
      />
    </ul>
    <p class="workplace-info__note">{{ $T.text('epos-info-note-support') }}</p>
    <div class="workplace-info__date is-no-mirrored">
      <span class="workplace-info__time">{{clock}}</span>
      <span class="workplace-info__day">{{date}}</span>
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'WorkspaceInfo';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {computed}
    from "@vue/runtime-core";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import EposInfoLine
    from "~tpl-desktop/guest/shared/EposInfoLine.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
   $app,
   $workspace
} = usePiniaState();

const clock = computed<string>(() => {
  return (null === $app.clock || 'undefined' === typeof $app.clock?.time)
      ? '--:--'
      : $app.clock?.time;
});

const date = computed<string>(() => {
  return (null === $app.clock || 'undefined' === typeof $app.clock?.date)
      ? '--.---.----'
      : $app.clock?.date.toUpperCase();
});
</script>