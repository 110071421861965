<template>
<h1>Window error</h1>
</template>

<script lang="ts">
import VueComponentType
    from "~lib/render/VueComponent";

const CMP_NAME = 'WindowSwapError';
export default {
    name: CMP_NAME,
} as VueComponentType
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
// -

/** IMPORTED VUE TEMPLATES: */
// -

/** COMPONENT LOGIC: */
// -
</script>