// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../storage/.yarn/__virtual__/css-loader-virtual-d175a59b3a/0/cache/css-loader-npm-6.10.0-5561e0c62e-1abd52e24a.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../storage/.yarn/__virtual__/css-loader-virtual-d175a59b3a/0/cache/css-loader-npm-6.10.0-5561e0c62e-1abd52e24a.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.expand-enter-active[data-v-ff819634],
  .expand-leave-active[data-v-ff819634] {
    transition: height .5s ease-in-out;
    overflow: hidden;
}
.expand-enter[data-v-ff819634],
  .expand-leave-to[data-v-ff819634] {
    height: 0;
}
`, "",{"version":3,"sources":["webpack://./ts/shared/templates/TransitionExpand.vue"],"names":[],"mappings":";AA6CE;;IAEE,kCAAkC;IAClC,gBAAgB;AAClB;AAEA;;IAEE,SAAS;AACX","sourcesContent":["<template>\n  <transition\n      @enter=\"enter\"\n      @after-enter=\"afterEnter\"\n      @leave=\"leave\"\n      name=\"expand\">\n    <slot/>\n  </transition>\n</template>\n\n<script lang=\"ts\">\nconst CMP_NAME = 'TransitionExpand';\nexport default {\n  name: CMP_NAME,\n}\n</script>\n\n<script setup lang=\"ts\">\n/** IMPORTED SCRIPTS: */\n// -\n\n/** IMPORTED VUE TEMPLATES: */\n// -\n\n/** COMPONENT LOGIC: */\nconst enter = (element: any) => {\n  const height = getComputedStyle(element).height;\n\n  element.style.height = 'auto';\n\n  element.style.height = 0;\n\n  setTimeout(() => element.style.height = height);\n}\n\nconst afterEnter = (element: any) => element.style.height = 'auto';\nconst leave = (element: any) => {\n  element.style.height = getComputedStyle(element).height;\n\n  setTimeout(() => element.style.height = 0);\n};\n\n</script>\n\n<style scoped>\n  .expand-enter-active,\n  .expand-leave-active {\n    transition: height .5s ease-in-out;\n    overflow: hidden;\n  }\n\n  .expand-enter,\n  .expand-leave-to {\n    height: 0;\n  }\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
