import {Model}
    from "~lib/eloquent/Model";
import {EnumToUnion}
    from "~lib/helpers/Types";

import {ApplicationSystemParameters}
    from "~app/Epos/Contracts/Epos.types";
import {AppInterfaceType}
    from "~app/Epos/Enum/AppInterfaceType";
import {AppLocale}
    from "~app/Epos/Enum/AppLocale";

export const ModelName = Symbol
    .for('WorkspaceModel');

/**
 * @see WorkspaceSystemParameters
 */
export default class Application
    extends Model<ApplicationSystemParameters> {

    protected isDuplicatedInstanceDetected: boolean = false;
    protected isMaintenanceActive:          boolean = false;
    protected isAppBootstrapFinished:       boolean = false;
    protected isAppCrashed:                 boolean = false;
    protected isAppBusy:                    boolean = false;

    protected initHttpCode: 200|550|number|null = null;
    protected initAppCode: number|null          = null;

    protected appVersion:   string                                    = '';
    protected locale:       EnumToUnion<typeof AppLocale>|null        = null;
    protected interface:    EnumToUnion<typeof AppInterfaceType>|null = null;
    protected pallet:       string|null                               = null;
    protected hasNightMode: boolean                                   = false;
    protected instanceUUID: string                                    = '';

    protected isTextHighlightDisabled: boolean = false;
    protected isNightModeActive:       boolean = false;

    /**
     * @see Model.init
     *
     * @param feed
     */
    constructor(
        feed: Partial<ApplicationSystemParameters>|null = null
    )
    {
        super();

        this.init(
            feed
        );
    }

    /**
     * @inheritDoc
     */
    public modelName(): symbol
    {
        return ModelName;
    }
}