import Exception
    from "~lib/Exception/Exception";

import {AppCoreErrors}
    from "~lib/Exception/Enum/AppCore.errors";

export type NotHandledRejectionConstructor = {
    message:  string|null;
    previous: any;
}

/**
 * Исключение создается и обрабатывается когда в глобальную область
 * попадает не обработанный и отклоненный "Promise".
 *
 * В поле "previous" будет полная структура события.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
 */
export default class NotHandledRejectionException
    extends    Exception
    implements NotHandledRejectionConstructor {

    constructor(
        payload: NotHandledRejectionConstructor
    )
    {
        super({
            isFatal:  true,
            code:     AppCoreErrors.GLOBAL_JS_ERROR,
            type:     'Exception',
            name:     'NotHandledRejectionException',
            label:    'GLOBAL-ERROR',
            message:  payload.message,
            previous: payload.previous
        });
    }
}