import {Container}
    from "inversify";
import WorkspaceModule
    from "~app/Epos/Workspace.module";

export default function (): {
    $workspaceModule: WorkspaceModule
} {
    return {
        /**
         * @var $workspaceModule - Извлекает из IoC контейнера приложения модуль
         *                         отвечающий за локальный функционал рабочего места.
         */
        $workspaceModule: (window as unknown as {$container: Container})
            .$container
            .get<WorkspaceModule>(WorkspaceModule.SONAR)
    };
}