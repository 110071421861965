<template>
  <div class="progressbar">
    <div :style="`width: ${percentage}%`" class="progressbar__value"></div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'ProgressBar';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {defineProps, withDefaults}
  from "@vue/runtime-core";

/** IMPORTED VUE TEMPLATES: */
// -

/** COMPONENT LOGIC: */
type ComponentProps = {
  percentage: number;
}

const props = withDefaults(defineProps<ComponentProps>(), {
  percentage: 0,
});
</script>

<style lang="scss">
  @import '~scss-shared/components/progressbar';
</style>