<template>
  <div class="page__main main page__main--not-auth">
    <div class="main__workplace workplace">
      <div class="workplace__main workplace-main">
        <div class="workplace-main__header">
          <span class="workplace-main__version">{{ $T.text('version-info-short') }}: {{ $app.parameters('appVersion') }}</span>
        </div>
        <div class="workplace-main__inner">
          <guest-window-ico
              ico="warning-round"
              sprite="cleared"
              size="size-custom-95"
          />
          <h1 class="workplace-main__title">{{ $T.text('win-duplicated-workspace-instance-header') }}</h1>
          <div v-for="text in $T.composite('win-workspace-has-duplicate-description')" class="workplace-main__description">
            <p class="workplace-main__text">{{ text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'ApplicationCrash';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";
/*import Button
    from "~tpl-global/Buttons/ButtonDefault/Button.vue";*/

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();
const {
    $app
} = usePiniaState();

/**
 * Необходимо взаимодействия пользователя с элементом разметки
 * для закрытия окна через API браузера.
 */
function closeBrowserWindow(): void
{
    window.close();
}
</script>