<template>
  <div class="user">
    <div class="user__current">
      <svg-elem sprite="cleared" to="user--avatar" class="user__icon" />
      <div class="user__name">{{ $workspace.cashier('login') ?? '--------' }}</div>
      <svg-elem sprite="cleared" to="caret--down" size="size-s" class="user__arrow" />
    </div>
    <ul class="user__options user-options">
      <li class="user-options__item">
        <div class="user-current">
          <svg-elem sprite="cleared" to="user--avatar" class="user-current__icon" />
          <div class="user-current__name">{{ $workspace.cashier('login') ?? '--------' }}</div>
        </div>
      </li>
      <li v-for="option in menu" :key="option.id" class="user-options__item">
        <button
            @click="(option.isLocked) ? null : option.handler()"
            class="user-options__option user-option"
            :class="{'user-option--is-locked': option.isLocked}"
        >
          <svg-elem
              sprite="cleared"
              :to="option.ico"
              size="size-s"
              is-mirrored
              class="user-option__icon" />
          <span class="user-option__label">{{ option.name }}</span>
        </button>
      </li>
      <!-- Элемент управления ночным режимом приложения: -->
      <li
        v-if="$app.isNightModeAvailableForPallet"
        class="user-options__item user__theme user-theme"
      >
        <span class="user-theme__label">{{ $T.text('dark-theme-switch') }}</span>
        <switcher
          class="user-theme__switcher"
          :is-active="$app.isNightModeActive"
          @radio-switcher-triggered="$app.switchInterfaceNightMode(!$app.isNightModeActive)"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
  @import '~scss-desktop/common/components/_header-user';
</style>

<script lang="ts">
const CMP_NAME = 'CashierTopMenu';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
  from "~app/IoC/useInterfaceTexts";
import {watch}
  from "@vue/runtime-core";
import useCashierMenu
  from "~interaction/Workspace/TopBar/useCashierMenu";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
  from "~tpl-global/svg/SvgElem.vue";
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import Switcher
    from "~tpl-desktop/shared/Switcher.vue";

/** COMPONENT LOGIC: */
const {$T}
    = useInterfaceTexts();

const {
    $workspace,
    $shift,
    $app
} = usePiniaState();

const {
  menu,

  cashierLogOut,
} = useCashierMenu();

// временное решение, иначе объект не будет обновляться
// при изменении значения в хранилище:
watch(() => $shift.isActive, (current, prev) =>
{
  menu['log-out'].isLocked = current;
});
</script>