import Exception from "~lib/Exception/Exception";

type ConstructorType = {
    windowKey: string,
    isFatal:   boolean,
    label:     string;
    message:   string;
    previous?: Error;
};

export default class WindowManagerException extends Exception
    implements
        ConstructorType {

    public readonly windowKey: string;

    constructor(payload: ConstructorType) {
        super({
            type:     'Exception',
            name:     'WindowManagerException',
            isFatal:  payload.isFatal  ?? true,
            label:    payload.label,
            message:  payload.message,
            previous: payload.previous ?? null,
        });

        this.windowKey = payload.windowKey;
    }
}