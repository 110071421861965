import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";
import Obj
    from "~lib/helpers/Obj";
import {isAsync}
    from "~ioc/contracts/AsyncProvider.contract";

import {RuntimeExceptionHandlerFactory}
    from "~lib/Exception/Contracts/RuntimeExceptionHandlerFactory";
import {RuntimeExceptionHandlerSonar}
    from "~lib/render/Render";
import {AppCoreErrors}
    from "~lib/Exception/Enum/AppCore.errors";
import ServiceProviderException
    from "~ioc/exceptions/ServiceProvider.exception";
import ExceptionHandler, {ExceptionHandlerFactory}
    from "~lib/Exception/ExceptionHandler";
import Exception from "~lib/Exception/Exception";

export type ServiceProviderRegistrationType =
    ServiceProvider              |
    ServiceProvider[]            |
    (new() => ServiceProvider)   |
    (new() => ServiceProvider)[];

/**
 * Описание типа, с которым должен регистрироваться сервис по обработке ошибок,
 * возникающих при регистрации сервисов.
 */
export type ServiceProviderSetupExceptionHandlerFactory = ExceptionHandlerFactory<
    ServiceProviderException,
    [
        provider:   ServiceProvider,
        code:       number,
        throwable:  unknown,
        defaultMsg: string,
    ],
    ExceptionHandler<ServiceProviderException>
    >;

export default class ServiceProviderSetup {

    /**
     * Контейнер, используемый для
     * регистрации провайдерами полезной нагрузки.
     */
    private readonly container: Container;

    /**
     * Список зарегистрированных сервис провайдеров.
     */
    private registered: {[index: symbol]: boolean} = {};

    constructor(
        container: Container
    )
    {
        this.container = container;
    }

    /**
     * Регистрация сервис провайдеров в контейнер приложения.
     *
     * ВНИМАНИЕ!
     * Ошибки, возникшие в асинхронном и параллельном провайдере, не влияют
     * на регистрацию идущих далее по списку провайдеров. В остальных случаях
     * регистрация будет прервана.
     *
     * @param payload - Провайдер, или список провайдеров, для
     *                  регистрации в приложении.
     */
    public async register(
        payload: ServiceProviderRegistrationType
    ): Promise<void>
    {
        for await (const providerPayload of (Array.isArray(payload))
            ? payload
            : [payload]
            )
        {
            // В случае проблемы при создании экземпляра сервис провайдера
            // не требуется общий обработчик исключений.
            const provider: ServiceProvider = ServiceProviderSetup
                .createProvider(providerPayload);

            if (!provider) {
                break;
            }

            // Запуск обработки логики каждого из зарегистрированных сервис провайдеров,
            // и обработка исключений, возникших во время выполнения:
            const reg: boolean = await this.runRegistration(provider)
                .then((): boolean => true)
                .catch((throwable): boolean =>
                {
                    this.handleProviderExecException(
                        throwable
                    );

                    return false;
                });

            if (!reg) {
                break;
            }
        }
    }

    /**
     * Запуск регистрации конкретного сервис провайдера.
     *
     * Провайдеры могут быть:
     * - синхронными
     * - асинхронными блокирующими
     * - асинхронными не блокирующими (параллельными)
     *
     * Асинхронные параллельные сервис провайдеры могут продолжать выполнять свою логику
     * не блокируя регистрацию провайдеров, следующих в списке вслед за ним.
     *
     * @param payload
     *
     * @throws ServiceProviderException
     */
    private async runRegistration(
        payload: ServiceProvider
    ): Promise<void>
    {
        if (this.registered.hasOwnProperty(payload.name())) {
            throw new ServiceProviderException({
                provider: payload.name.toString(),
                code:     AppCoreErrors.DUPLICATE_PROVIDER_REGISTRATION,
                message:  `Provider '${payload.name().toString()}' is already registered.` +
                          ` Duplicate registration are not allowed and make no sense.`
            });
        }

        // Маркировка текущего провайдера как зарегистрированного,
        // и проставление флага "false" означающего, что система
        // дожидается успешного выполнения логики провайдера:
        this.registered[payload.name()] = false;

        // Результат запуска провайдера для
        // определения является ли он асинхронным:
        let runAttempt: void|Promise<void>;

        try
        {
            // Запуск обработки провайдера:
            runAttempt = payload
                .register(this.container);

            if (!Obj.isPromise(runAttempt)) {
                // Логика синхронного провайдера успешно выполнена:
                this.registered[payload.name()] = true;
                return;
            }

            if (!isAsync(payload)) {
                throw new ServiceProviderException({
                    provider: payload.name().toString(),
                    code:     AppCoreErrors.INCORRECT_ASYNC_PROVIDER,
                    message:  `An error detected for provider '${payload.name().toString()}'.` +
                              ` Provider has shape for asynchronous logic,` +
                              ` but does not implements "AsyncProvider" interface.`,
                });
            }

            // Если текущий провайдер асинхронный, и при том не параллельный:
            if (!(payload as unknown as {isParallel: () => boolean}).isParallel()) {
                // Очередь выполнения блокируется до завершения работы
                // асинхронного провайдера:
                await runAttempt;
                this.registered[payload.name()] = true;
                return;
            }

            // Т.к. текущий провайдер является асинхронным и параллельным, то
            // очередь не блокируется и переходит к следующему провайдеру:
            (runAttempt as Promise<void>)
                .then((): void =>
                {
                    this.registered[payload.name()] = true;
                })
        }

        catch (throwable)
        {
            if (throwable instanceof ServiceProviderException) {
                await this.handleProviderExecException(
                    throwable
                );

                return;
            }

            // Основная часть сообщения об ошибке в провайдере:
            let message: string = `An error occurred while trying to handle`;

            // Дополнение, в зависимости от того, является ли провайдер
            // синхронным или асинхронным:
            if (isAsync(payload)) {
                message += ((payload as unknown as {isParallel: () => boolean}).isParallel())
                    ? ` asynchronous parallel provider '${payload.name().toString()}'.`
                    : ` asynchronous provider '${payload.name().toString()}'.`;
            }

            else  {
                message += ` provider '${payload.name().toString()}'.`;
            }


            // Попытка дополнить деталями основное сообщение об ошибке,
            // в некоторых ситуациях:
            if ('string' === typeof throwable) {
                message += ` Details: '${throwable}'.`;
            }

            else if (throwable instanceof Exception) {
                message += ` Details: '${throwable.message}'.`;
            }

            else if (throwable instanceof Error) {
                message += ` Details: '${throwable.message}'.`;
            }

            await this.handleProviderExecException(
                new ServiceProviderException({
                    provider: payload.name().toString(),
                    code:     AppCoreErrors.ASYNC_PROVIDER_HANDLE_ERROR,
                    message:  message,
                    previous: throwable,
                })
            );
        }
    }

    /**
     * Обработка и логирование ошибок, возникших
     * при обработке сервис провайдеров.
     */
    private async handleProviderExecException(
        throwable: ServiceProviderException
    ): Promise<void>
    {
        if (!this.container.isBound(RuntimeExceptionHandlerSonar)) {
            // Невозможно обработать ошибку т.к. в контейнере
            // не зарегистрирован обработчик ошибок:
            console.warn(
                `WARNING: Runtime exception handler is not defined.` +
                ` Error handler must be bound into container` +
                ` for key '${RuntimeExceptionHandlerSonar.toString()}'.`
            );

            // Вывод ошибки в консоль "как есть":
            console
                .error(throwable);

            return;
        }

        const exceptionHandler: ExceptionHandler<Exception> = this.container
            .get<RuntimeExceptionHandlerFactory>(
                RuntimeExceptionHandlerSonar
            )(throwable);

        // Запуск обработки и логгирования:
        exceptionHandler.handle();
        exceptionHandler.log();
    }

    /**
     * Метод-нормализатор, необходим т.к. при регистрации можно
     * как передать экземпляр провайдера, так и его конструктор.
     *
     * @param payload
     */
    private static createProvider(
        payload: ServiceProvider | (new({}) => ServiceProvider)
    ): ServiceProvider
    {
        if (!(payload instanceof ServiceProvider))
        {
            try
            {
                return  new payload({}) as ServiceProvider;
            }

            catch (exception)
            {
                let message: string = `Failed to create service provider constructor.` +
                    ` It should be shape of '${ServiceProvider}'.`;

                if ((exception instanceof Error) || 'string' === typeof exception) {
                    message += ('string' === typeof exception)
                        ? ` Details: '${exception}'.`
                        : ` Details: '${exception.message}'.`;
                }

                throw new ServiceProviderException({
                    provider: 'Incorrect provider shape',
                    code:     AppCoreErrors.INCORRECT_PROVIDER_SHAPE,
                    message:  message,
                });
            }
        }

        return payload;
    }
};
