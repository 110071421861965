<template>
  <div :class="inputCssStyles">
    <div class="input__container">
      <div v-if="currency" class="input__currency input-currency no-select">
        <span class="input-currency__label">{{ currency }}</span>
      </div>
      <div v-if="prependIco" class="input__icon">
        <SvgElem
            sprite="cleared"
            :to="prependIco.svg"
            :size="prependIco.size"
            :is-mirrored="isMirroredPrependIcon"
        />
      </div>
      <input
          :value="modelValueProxy"
          @input="inputHandler($event)"

          :id="inputId"
          :name="inputName"
          :type="type"
          :placeholder="placeholder"
          class="input__field"

          :autocomplete="autocomplete"

          @focus="isFocused = true"
          @blur="isFocused = false"
          @mouseenter="isHovered = true"
          @mouseleave="isHovered = false"
      >
      <button
          v-if="appendIcoList"
          v-for="ico in appendIcoList"
          class="input__button input-button"
      >
        <SvgElem
            sprite="cleared"
            :to="ico.svg"
            :size="ico.size"
            @click="('function' === typeof ico.callable ? ico.callable($event) : null)"
        />
      </button>
<!--      <Dropdown
        v-if="dropdownOptions?.length > 0"
        :options="dropdownOptions ?? []"
        :is-hidden="isDropdownHidden"
      />-->
    </div>
    <div
      v-if="null !== description"
      :class="descriptionCssStyles"
    >
      <p v-for="descElem in description" class="input-message__text">{{ descElem }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '~scss-shared/components/input';
</style>

<script lang="ts">
const CMP_NAME = 'Input';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
    defineProps,
    defineEmits,
    computed,
} from "@vue/runtime-core";
import useShellStyles
    from "~interaction/useShellStyles";
import useInput, {ComponentProps}
    from "~interaction/forms/useInput";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
    from "~tpl-global/svg/SvgElem.vue";
import Dropdown
    from "~tpl-global/Dropdown/Dropdown.vue";

/** COMPONENT LOGIC: */
const {
    shellCssConstruct
} = useShellStyles();

const props = withDefaults(
    defineProps<ComponentProps>(), {
        type:       'text',
        isError:    false,
        isDisabled: false,

        size:              'size-m',
        shellStyles:       () => [],
        descriptionStyles: () => [],
        hasBorder:         true,

        prependIco:    undefined,
        appendIcoList: undefined,
        currency:      null,

        id:              '',
        name:            '',
        label:           '',
        placeholder:     '',
        description:     '',
        dropdownOptions: () => [],

        modelValue: () => (''),
    });

const emits = defineEmits<{
  (e: 'update:modelValue', id: string | number): void
}>();

const {
    isFocused,
    isHovered,
    isDropdownHidden,

    inputId,
    inputName,
    autocomplete,

    modelValueProxy,
    inputHandler,

} = useInput(props, emits);

// стили для общей оболочки поля ввода:
const inputCssStyles = computed<string[]>(() => {
    const settings: {[key: string]: boolean} = {
        'input':             true,
        'input--has-border': props.hasBorder,
        'input--no-border':  !props.hasBorder,
        'input--is-locked':  props.isDisabled,
        'input--has-error':  props.isError,
        'input--is-focused': true === isFocused.value,
        'input--is-hovered': true === isHovered.value,
    };

    settings[`input--${props.size}`] = true;

    return shellCssConstruct(
        settings,
        props.shellStyles
    );
});

// стили для сообщения под полем ввода:
const descriptionCssStyles = computed<string[]>(() => {
    const settings: {[key: string]: boolean} = {
        'input__message':        true,
        'input-message':         true,
        'input__message--error': props.isError,
    };

    return shellCssConstruct(
        settings,
        props.descriptionStyles
    );
});

/**
 * Список текстовых описаний, заданных полю ввода
 * для отображения под ним.
 */
const description = computed<string[]|null>(
    () => {
        if ('undefined' === typeof props.description) {
            return null;
        }

        const raw: string[] = ('string' === typeof props.description)
            ? [props.description]
            : props.description;

        const list: string[] = [];

        for (const elem of raw) {
            if (elem.length < 1) {
                continue;
            }

            list.push(elem);
        }

        return list.length > 0
            ? list
            : null;
    });
</script>