import {Model}
    from "~lib/eloquent/Model";
import {AuthorizedCashier}
    from "~app/Epos/Contracts/Epos.types";

export const ModelName = Symbol
    .for('CashierModel');

/**
 * @see AuthorizedCashier
 */
export default class Cashier
    extends Model<AuthorizedCashier> {

    protected startedAt:    number      = 0;
    protected id:           number      = 0;
    protected login:        string      = '';
    protected name:         string|null = null;
    protected surname:      string|null = null;
    protected middleName:   string|null = null;

    /**
     * @see Model.init
     *
     * @param feed
     */
    constructor(
        feed: Partial<AuthorizedCashier>|null = null
    )
    {
        super();

        this.init(
            feed
        );
    }

    /**
     * @inheritDoc
     */
    public modelName(): symbol
    {
        return ModelName;
    }
}