export const isAsync = (item: object): boolean =>
    'function' === typeof (item as {[index: string]: any})['isParallel'];

/**
 * Сервис провайдеру разрешается возвращать Promise<void> при регистрации,
 * если он реализует данный интерфейс.
 */
export default interface AsyncProvider {

    /**
     * Если метод вернет "false", то регистрация последующий провайдеров
     * будет заблокирована до разрешения промиса текущего провайдера.
     *
     * Иначе провайдер продолжит регистрацию в фоне.
     */
    isParallel(): boolean;
}