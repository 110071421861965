import VueComponentType
    from "~lib/render/VueComponent";
import Library
    from "~lib/translate/Library";
import {WinListDefinition, WM_TAG_CUSTOMER, WM_TAG_LEFT}
    from "~app/Providers/Windows/WindowsManager.provider";

import WindowLoad
    from "~tpl-desktop/workspace/Windows/WindowLoad.vue";
import WindowLoadError
    from "~tpl-desktop/workspace/Windows/WindowLoadError.vue";

//TODO: добавить проверку доступа к модулям при отрисовке меню
export default function ($T: Library): WinListDefinition {
    return {
        loadingCmp: WindowLoad,
        errorCmp:   WindowLoadError,
        list:       {
            'shift': {
                tag:  WM_TAG_LEFT,
                data: {
                    ico: 'dashboard',
                    name: $T.text('main-menu-shift-overview'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-desktop/workspace/Shift/Shift.vue') as unknown as Promise<VueComponentType>;
                }
            },
            'customer-deposit': {
                tag: WM_TAG_LEFT,
                data: {
                    ico:  'bank--slot-alt',
                    name: $T.text('main-menu-customer-deposit'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-desktop/workspace/Customer/Deposit.vue') as unknown as Promise<VueComponentType>;
                }
            },
            'customer-withdrawal': {
                tag: WM_TAG_LEFT,
                data: {
                    ico:  'bank-alt',
                    name: $T.text('main-menu-customer-withdrawal'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-desktop/workspace/Customer/Withdrawal.vue') as unknown as Promise<VueComponentType>;
                }
            },
            'shift-history': {
                tag:  WM_TAG_LEFT,
                data: {
                    ico: 'transactions',
                    name: $T.text('shift-overview-table-title'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-desktop/workspace/Shift/History.vue') as unknown as Promise<VueComponentType>;
                }
            },
            'betting': {
                tag: WM_TAG_LEFT,
                data: {
                    ico:  'ticket',
                    name: $T.text('main-menu-betting'),
                    showInHotMenu: true
                },
                vue: function () {
                    return import('~tpl-desktop/workspace/Betting/Betting.vue') as unknown as Promise<VueComponentType>;
                },
            },
            'coupons-per-shift': {
                tag: WM_TAG_LEFT,
                data: {
                    ico:  'receipt',
                    name: $T.text('main-menu-coupons-per-shift'),
                    showInHotMenu: true
                },
                vue: function () {
                    return import('~tpl-desktop/workspace/Coupon/CouponsPerShift/CouponsPerShift.vue') as unknown as Promise<VueComponentType>;
                },
            },
            /*couponPayout: {
                tag: WM_TAG_LEFT,
                data: {
                    ico:  'currency',
                    name: $T.text('main-menu-coupon-operations'),
                    showInHotMenu: false
                },
                vue: function() {
                    return import('~tpl-desktop/workspace/Coupon/CouponPayout.vue') as unknown as Promise<VueComponentType>;
                }
            },*/
        }
    };
};