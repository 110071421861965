<template>
  <div class="workplace-main__inner">
    <guest-window-ico
      sprite="cleared"
      size="size-custom-95"
      ico="login"
    />
    <h1 class="workplace-main__title">{{ $T.text('win-cashier-auth-header') }}</h1>
    <div
      v-if="isWorkspaceInSessionRestoreMode"
      v-for="text in $T.composite('win-cashier-auth-broken-description', null, {'cashier-login': $workspace.cashier<string>('login')})"
      class="workplace-main__description"
    >
      <p class="workplace-main__text">{{ text }}</p>
    </div>
    <div class="workplace-main__form workplace-main-form">
      <Input
        v-if="!isWorkspaceInSessionRestoreMode"
        v-model="login"
        :placeholder="$T.text('win-cashier-login-placeholder')"
        :shell-styles="['workplace-main-form__field']"
        size="size-l"
        :is-disabled="$app.isAppBusy"
        :is-error="errorMsg.length > 1"
        :i-mask="loginMask"
      />
      <Input
        v-model="password"
        :placeholder="$T.text('win-cashier-password-placeholder')"
        :shell-styles="['workplace-main-form__field']"
        size="size-l"
        :is-disabled="$app.isAppBusy"
        :is-error="errorMsg.length > 1"
        :description="errorMsg"
        :i-mask="passwordMask"
        type="password"
      />
      <Button
        @click="tryCashierAuth()"
        :is-disabled="$app.isAppBusy || isFormInvalid"
        :is-processing="$app.isAppBusy"
        :text="$T.text('win-cashier-auth-apply-btn')"
        size="size-l"
        label-style="uppercase"
        class="workplace-main__button"
      />
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'CashierAuth.window';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import useCashierAuthWindow
  from "~interaction/guest/useCashierAuthWindow";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";
import Input
  from "~tpl-global/Input/Input.vue";
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import useInputMasks
    from "~interaction/useInputMasks";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $app,
    $workspace,
} = usePiniaState();

const {
    isWorkspaceInSessionRestoreMode,
    isFormInvalid,
    errorMsg,
    login,
    password,

    tryCashierAuth,
} = useCashierAuthWindow();

const {
    loginMask,
    passwordMask,
} = useInputMasks();
</script>