<template>
  <div class="workplace-main__inner">
    <guest-window-ico :ico="ico" size="size-custom-95" sprite="cleared" />
    <h1
      v-html="title.join('<br/>')"
      class="workplace-main__title"
    ></h1>
    <div class="workplace-main__description">
      <p v-for="text in description" class="workplace-main__text">{{ text }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>

<script lang="ts">
const CMP_NAME = 'InfoOnly.window';
export default {
    name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {defineProps, withDefaults}
    from "@vue/runtime-core";
import {SvgList}
    from "~svg/cleared/pictograms/SvgList";

/** IMPORTED VUE TEMPLATES: */
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";

/** COMPONENT LOGIC: */
type ComponentProps = {
    ico:         SvgList;
    title:       string[];
    description: string[];
}

const props = withDefaults(
    defineProps<ComponentProps>(),
    {}
);
</script>