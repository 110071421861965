/**
 * Маппинг для исправления проблемы форматирования чисел
 * через функционал "Numbers.numFormat".
 *
 * Ключом является локаль приложения, а значением - локаль,
 * под которую должны форматироваться числа.
 */
export const LocalesFormatException: {[key: string]: string} =
    {
        'uz_UZ': 'en_GB',
        'ar_AE': 'en_GB',
        'bn_BD': 'en_GB',
        'so_SO': 'en_GB',
    };