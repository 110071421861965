import {inject, injectable}
    from 'inversify';
import HttpClient
    from "~lib/client/HttpClient";
import HttpResponse
    from "~lib/client/Response";

import RoutesPool
    from "~app/Epos/RoutesPool";
import * as SrvHelper
    from "~app/Other/Api/ApiService.helpers";
import {CouponShort}
    from "~app/Betting/Contracts/Betting.types";
import * as CommitBetRoute
    from "~app/Betting/Routes/CommitBet.route";
import * as BindCouponRoute
    from "~app/Betting/Routes/BindCoupon.route"
import * as CouponsPerShift
    from "~app/Betting/Routes/CouponsPerShift.route"

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {BettingErrors}
    from "~app/Betting/Betting.errors";

/**
 * Список методов, для запросов к API веб касс,
 * связанных с функционалом беттинга.
 */
@injectable()
export default class BettingService {

    public static readonly SONAR: symbol = Symbol
        .for('BettingService');

    private readonly client: HttpClient;
    private readonly routes: RoutesPool;

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
        @inject(HttpClient.SONAR) client: HttpClient,
        @inject(RoutesPool.SONAR) routes: RoutesPool,
    )
    {
        this.client = client;
        this.routes = routes;
    }

    /**
     * Запрос к АПИ на проведение ставки.
     *
     * В ответе вернется статус запроса, сообщение от апи
     * и набор данных, определяющий результат проведения ставки.
     */
    public async makeBetRequest(
        payload: CommitBetRoute.Request
    ): Promise<HttpResponse<CommitBetRoute.Response>>
    {
        const request = await this.client
            .post<CommitBetRoute.Response, CommitBetRoute.Request>(
                this.routes.route('commit-bet'),
                payload,
                {
                    // На всякий случай увеличено
                    // время ожидания ответа:
                    timeout: 16,
                }
            );

        SrvHelper
            .checkApiResponse(request);

        if (null === request.response) {
            throw new RuntimeException({
                code:    BettingErrors.COMMIT_BET_UNEXPECTED_ERROR,
                isFatal: false,
                label:   'BETTING-API',
                message: 'Bet commit failed. Unexpected empty response' +
                         ' returned from API.',
            });
        }

        return request;
    }

    /**
     * Запрос к АПИ на привязку купона к аккаунту клиента.
     *
     * В ответе вернется статус запроса, сообщение от апи
     * и набор данных, определяющий результат привязки купона.
     */
    public async bindCouponRequest(
        payload: BindCouponRoute.Request
    ): Promise<HttpResponse<BindCouponRoute.Response>>
    {
        const request = await this.client
            .post<BindCouponRoute.Response, BindCouponRoute.Request>(
                this.routes.route('bind-coupon'),
                payload,
                {
                    // На всякий случай увеличено
                    // время ожидания ответа:
                    timeout: 12,
                }
            );

        SrvHelper
            .checkApiResponse(request);

        if (null === request.response) {
            throw new RuntimeException({
                code:    BettingErrors.BIND_BET_UNEXPECTED_ERROR,
                isFatal: false,
                label:   'BETTING-API',
                message: 'Bet bind failed. Unexpected empty response returned from API.',
            });
        }

        return request;
    }

    /**
     * Запрос у API списка купонов, принятых
     * в течение текущей смены кассира.
     */
    public async fetchCouponsPerShift(): Promise<CouponShort[]>
    {
        const request = await this.client
            .post<CouponsPerShift.Response, CouponsPerShift.Request>(
                this.routes.route('coupons-per-shift'),
                {},
                {
                    // На всякий случай увеличено
                    // время ожидания ответа:
                    timeout: 16,
                }
            );

        SrvHelper
            .checkApiResponse(request);

        if (!request.isSuccess) {
            throw new RuntimeException({
                code:    BettingErrors.COUPON_PER_SHIFT_FETCH_ERROR,
                isFatal: false,
                label:   'COUPON-API',
                message: 'Unexpected API fetch error.',
            });
        }

        if (
            null === request.response ||
            null === (request.response?.list ?? null) ||
            Object.keys(request.response.list).length < 1
        ) {
            return [];
        }

        return Object.values(
            request.response.list
        );
    }

    /*public async searchCouponById(
        couponId: number
    ): Promise<unknown>
    {
        const request = await this.client
            .post(
                this.routes.route('search-coupon'),
                {
                    couponId: couponId
                }
            );

        SrvHelper
            .checkResponseForCashierAuthLost(request);
    }*/
}