import { Container }
    from "inversify";

/**
 * Загрузка доступа к API контейнера приложения.
 */
export default function () {
    return {
        $container: (window as unknown as {$container: Container}).$container
    }
}