export type ResponseConstructor <
    ResponseType = any,
    PayloadType  = any
> = {
    url:                  string,
    method:               string,
    params:               {[index: string]: string}|null,
    payload:              PayloadType,
    expectedPayloadType:  string,
    expectedResponseType: string,
    statusCode:           number,
    statusText:           string,
    timeout:              number,
    duration:             number,
    isSuccess:            boolean,
    code:                 number|null,
    message:              string|null,
    response:             ResponseType|null,
};

export default class HttpResponse <
    ResponseType = any,
    PayloadType  = any
> {

    /**
     * URL запроса.
     */
    public readonly url: string;

    /**
     * Метод запроса.
     */
    public readonly method: string;

    /**
     * URL параметры запроса.
     */
    public readonly params: {[index: string]: string}|null;

    /**
     * Содержимое запроса, передаваемое серверу.
     */
    public readonly payload: PayloadType;

    /**
     * Тип содержимого, переданного в теле запроса.
     */
    public readonly expectedPayloadType: string;

    /**
     * Ожидаемое содержимое ответа от сервера.
     */
    public readonly expectedResponseType: string;

    /**
     * HTTP статус код ответа от сервера.
     */
    public readonly statusCode: number;

    /**
     * Текстовое описание статуса ответа от сервера.
     */
    public readonly statusText: string;

    /**
     * Максимальное время ожидание ответа от сервера
     * для текущего запроса. (в секундах)
     */
    public readonly timeout: number;

    /**
     * Время на выполнение и обработку запроса.
     * (в секундах)
     */
    public readonly duration: number;

    /**
     * Является ли успешным запрос по мнению сервера.
     */
    public readonly isSuccess: boolean;

    /**
     * Уникальный код ответа на запрос.
     * (не путать с HTTP кодом)
     */
    public readonly code: number|null;

    /**
     * Сообщение от сервера, с пояснением
     * о результате запроса.
     */
    public readonly message: string|null;

    /**
     * Содержимое ответа от сервера.
     */
    public readonly response: ResponseType|null;

    /**
     * @param feed
     */
    constructor(
        feed: ResponseConstructor
    ) {
        this.url                  = feed.url;
        this.method               = feed.method;
        this.params               = feed.params;
        this.payload              = feed.payload;
        this.expectedPayloadType  = feed.expectedPayloadType;
        this.expectedResponseType = feed.expectedResponseType;
        this.statusCode           = feed.statusCode;
        this.statusText           = feed.statusText;
        this.timeout              = feed.timeout;
        this.duration             = feed.duration;
        this.isSuccess            = feed.isSuccess;
        this.code                 = feed.code;
        this.message              = feed.message;
        this.response             = feed.response;
    }
}