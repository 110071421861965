<template>
  <div class="ui-rounded-button">
    <button
      v-on:mousedown="missClickCheck($event)"
      v-on:mouseup="missClickCheck($event)"
      v-on:mouseleave="missClickCheck($event)"
      v-on:touchstart="missClickCheck($event)"
      v-on:touchend="missClickCheck($event)"
      v-on:touchcancel="missClickCheck($event)"
      v-on:touchmove="missClickCheck($event)"
      :type="type"
      :class="buttonStyles"
    >
      <span class="rounded-button__container">
        <template v-if="!$app.isAppBusy">
          <svg-elem
              v-if="ico?.length > 0"
              sprite="cleared"
              :to="ico"
              :size="size === 'size-s' ? 'size-xs' : icoSize"
              class="rounded-button__icon"
          />
          <span
              v-if="props.text.length > 0"
              v-html="('string' === typeof text) ? text : text.join('<br/>')"
              class="rounded-button__label no-select"
          >
          </span>
        </template>
        <svg-elem
            v-else
            sprite="cleared"
            to="spinner"
            class="rounded-button__icon"
            :isLoader="true"
        />
      </span>
      <!-- размер полосы загрузки кнопки для size-m from 264 to 0 -->
      <template v-if="props.size === 'size-m'">
        <svg xmlns="http://www.w3.org/2000/svg" width="5.5em" height="5.5em" class="ui-rounded-button__border">
          <circle cx="2.75em" cy="2.75em" r="2.625em" class="ui-rounded-button__circle ui-rounded-button__circle--is-under"></circle>
          <circle
            :style="`stroke-dashoffset: ${circleValue / 16}em;`"
            cx="2.75em"
            cy="2.75em"
            r="2.625em"
            class="ui-rounded-button__circle ui-rounded-button__circle--is-over"
          ></circle>
        </svg>
      </template>
      <!-- размер полосы загрузки кнопки для size-l from 302 to 0 -->
      <template v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="6.3125em" height="6.3125em" class="ui-rounded-button__border">
          <circle cx="3.15625em" cy="3.15625em" r="3em" class="ui-rounded-button__circle ui-rounded-button__circle--is-under"></circle>
          <circle
            :style="`stroke-dashoffset: ${circleValue / 16}em;`"
            cx="3.15625em"
            cy="3.15625em"
            r="3em"
            class="ui-rounded-button__circle ui-rounded-button__circle--is-over"
          ></circle>
        </svg>
      </template>
    </button>
    <span
        v-if="description?.length"
        v-html="('string' === typeof description) ? description : description.join('<br/>')"
        class="ui-rounded-button__description no-select"
    ></span>
  </div>
</template>

<style lang="scss" scoped>
  @import '~scss-shared/components/rounded-button';
</style>

<script lang="ts">
const CMP_NAME = 'OnHoldButton';
export default {
    name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
    computed,
    defineProps,
    withDefaults,
    defineEmits,
}
    from "@vue/runtime-core";
import ApplicationState
    from "~app/Storage/Application.state";
import useHoldMouseButtons
    from "~interaction/useHoldMouseButtons";
import useShellStyles
    from "~interaction/useShellStyles";
import useMessageParser
    from "~interaction/useMessageParser";

import {
  ButtonType,
  ButtonSize,
  ButtonTheme
} from "~tpl-global/Buttons/OnHoldButton/OnHoldButtonTypes";

/** IMPORTED VUE TEMPLATES: */
import {SvgList}
    from "~tpl-global/svg/SvgList";
import {SvgSize}
    from "~tpl-global/svg/SvgSize";
import SvgElem
    from "~tpl-global/svg/SvgElem.vue";

/** COMPONENT LOGIC: */

type ComponentProps = {
    styles?: string[];
    // время удержания кнопки:
    holdMs?: number;
    // интервал между циклами обновления прогресса
    // по удержанию кнопки:
    transitionMs?: number;
    isDisabled?: boolean;
    // текст кнопки:
    text?: string|string[];
    // текст под кнопкой:
    description?: string|string[];

    type?: ButtonType;
    size?: ButtonSize;
    theme?: ButtonTheme;

    ico?: SvgList;
    icoSize?: SvgSize;
    icoOnly?: boolean;
}

const props = withDefaults(defineProps<ComponentProps>(), {
    shellStyles:  () => [],
    holdMs:       2000,
    transitionMs: 20,
    isDisabled:   false,
    text:         '',
    description:  '',

    type:  'button',
    size:  'size-m',
    theme: 'green', // TODO заменить на income/outcome

    icoSize: 'size-s',
    icoOnly: false,
});

/**
 * Список событий, возникающий при проверке "зажима".
 */
const emitter = defineEmits([
    'onHoldButtonAborted',
    'onHoldButtonPassed'
]);

const $app = ApplicationState();
const {
    fillPercentage,
    missClickCheck,
} = useHoldMouseButtons(props, emitter);
const {
    shellCssConstruct
} = useShellStyles();

/**
 * Список стилей для зажимаемой кнопки.
 */
const buttonStyles = computed<string[]>(() => {
    const settings: {[key: string]: boolean} = {
        'ui-rounded-button__button': true,
        'rounded-button':            true,

        'button--icon-only':           props.icoOnly,
        'rounded-button--is-disabled': props.isDisabled,
        'rounded-button--is-loading':  $app.isAppBusy,
    };

    settings[`rounded-button--${props.size}`]        = true;
    settings[`rounded-button--theme-${props.theme}`] = true;

    return shellCssConstruct(
        settings,
        props.styles
    );
});

/**
 * Значение заполнения полосы в формате html компонента.
 */
const circleValue = computed<number>(() => {
    const zeroValue = ('size-m' === props.size)
        ? 264
        : 302;

    if (0 === fillPercentage.value) {
        return zeroValue;
    }

    const valuePerPercent = zeroValue / 100;
    const fillValue       = zeroValue - (valuePerPercent * fillPercentage.value);

    return (fillValue <= 0)
        ? 0
        : fillValue;
});
</script>