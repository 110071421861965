import SvgSprite
    from "~lib/assets/SvgSprite";

export default function () {

    /**
     * Поиск и извлечение из спрайта URL для
     * указанной SVG иконки.
     *
     * @param spriteName - Название спрайта
     * @param icoName    - Название иконки
     */
    function getSvgUrl(
        spriteName: string,
        icoName:    string,
    ): string
    {
        return (window as unknown as {$svgSprite: SvgSprite}).
        $svgSprite
            .get(spriteName, icoName);
    }

    return {
        getSvgUrl,
    };
};