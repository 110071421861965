import { Container }
    from "inversify";
import Supervisor
    from "~lib/supervisor/Supervisor";

export default function () {
    return {
        $SUPERVISOR: (window as unknown as {$container: Container}).$container
            .get<Supervisor>(Supervisor.SONAR)
    };
}