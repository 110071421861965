import Exception from "~lib/Exception/Exception";
import {integer} from "~lib/CustomTypes";

type ConstructorType = {
    model:     string,
    message:   string;
    code?:     integer;
    field?:    string;
    payload?:  any;
    previous?: ModelException|Error;
};

export default class ModelException extends Exception implements ConstructorType {

    /**
     * В модели нет ни одного поля. Существование
     * такой модели бессмысленно.
     */
    public static readonly EMPTY_MODEL_FIELDS = 0;

    /**
     * Запрошенное поле модели не существует.
     */
    public static readonly MODEL_FIELD_NOT_EXISTS = 1;

    /**
     * Пытаются засунуть в модель 'undefined' значение.
     */
    public static readonly UNDEFINED_PAYLOAD_TYPE = 2;

    /**
     * Ошибка при создании саб модели.
     */
    public static readonly SUB_MODEL_CREATION_FAILED = 3;

    /**
     * В поле модели было передано содержимое с
     * недопустимым типом.
     */
    public static readonly INCORRECT_FIELD_TYPE = 4;

    public static readonly UNEXPECTED_ERROR = 5;

    public readonly model: string;
    public readonly code: number;
    public readonly field?: string;
    public readonly payload?: any;

    constructor(payload: ConstructorType) {
        super({
            type:    'Exception',
            name:    'ModelException',
            label:   'ELOQUENT-MODEL',
            message:  payload.message,
            previous: payload.previous ?? null,
        });

        this.model    = payload.model;
        this.code     = payload.code ?? ModelException.UNEXPECTED_ERROR;
        this.field    = payload.field;
        this.payload  = payload.payload;
    }
}