import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";
import Library
    from "~lib/translate/Library";
import DOMAttr
    from "~lib/DOM/Attribute";

import {SystemSonar}
    from "~app/Epos/Enum/SystemSonar";
import {InterfaceBootstrapResponse}
    from "~app/Epos/Contracts/Epos.types";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/**
 * Используя минимальный набор текстов, пришедших в
 * инициализирующей константе, создается временная
 * библиотека текстов приложения.
 *
 * Для не авторизованного рабочего места используется только
 * временная библиотека.
 *
 * Для авторизованного рабочего места полноценная текстовая
 * библиотека загружается на этапе синхронизации.
 *
 * Выбирается направление отображения интерфейса и текста в нем (LTR или RTL).
 *
 * Задается параметр разметки, отвечающий за выбор используемых шрифтов.
 */
export default class CreateTmpLibServiceProvider
    extends ServiceProvider
{
    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('CreateTmpLibServiceProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        const appInitData: InterfaceBootstrapResponse = app
            .get<InterfaceBootstrapResponse>(SystemSonar.AppInitConstant);

        const {
            $app
        } = usePiniaState();

        // Временная библиотека с текстами интерфейса:
        app.bind<Library>(Library.SONAR)
            .toConstantValue(
                new Library(
                    '-',
                    $app.locale,
                    appInitData.bootstrapDictionary
                )
            );

        // Смена в теге HTML направления отображения интерфейса:
        DOMAttr.set<'dir'>(
            document.documentElement,
            'dir',
            ($app.isLocaleRTL)
                ? 'rtl'
                : 'ltr'
        );

        // Указание текущего языка для
        // выбора интерфейсом корректных шрифтов:
        DOMAttr.set<'lang'>(
            document.documentElement,
            'lang',
            $app.lang
        );
    }
}