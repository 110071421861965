import {
    inject, injectable
} from 'inversify';
import HttpClient
    from "~lib/client/HttpClient";
import RoutesPool
    from "~app/Epos/RoutesPool";
import * as SrvHelper
    from "~app/Other/Api/ApiService.helpers";
import HttpResponse
    from "~lib/client/Response";

import * as AccessRecoveryRoute
    from "~app/Epos/Routes/AccessRecovery.route";
import * as WorkspaceStateRoute
    from "~app/Epos/Routes/WorkspaceState.route";
import * as CashierLogInRoute
    from "~app/Epos/Routes/CashierLogIn.route";
import * as CashierLogOutRoute
    from "~app/Epos/Routes/CashierLogOut.route";
import * as ShiftOpenRoute
    from "~app/Epos/Routes/ShiftOpen.route";
import * as ShiftCloseRoute
    from "~app/Epos/Routes/ShiftClose.route";
import * as CheckTextsLibraryRoute
    from "~app/Epos/Routes/CheckTextsLibrary.route";
import * as FetchTextsLibraryRoute
    from "~app/Epos/Routes/FetchTextsLibrary.route";
import WorkspaceHeaders
    from "~app/Epos/WorkspaceHeaders";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

@injectable()
export default class EposService {

    public static readonly SONAR = Symbol
        .for('EposService');

    private readonly client: HttpClient;
    private readonly routes: RoutesPool;

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
        @inject(HttpClient.SONAR) client: HttpClient,
        @inject(RoutesPool.SONAR) routes: RoutesPool,
    )
    {
        this.client = client;
        this.routes = routes;
    }

    /**
     * Запрос к API на авторизацию текущего рабочего места.
     */
    public async requestWorkspaceAuthorization(
        payload: AccessRecoveryRoute.Request
    ): Promise<HttpResponse<AccessRecoveryRoute.Response>>
    {
        const response = await this.client
            .post<AccessRecoveryRoute.Response, AccessRecoveryRoute.Request>(
                this.routes.route('access-recovery'),
                payload
            );

        SrvHelper
            .checkApiResponse(
                response,
                {
                    responseMustBeNotEmpty: true,
                }
            );

        return response;
    }

    /**
     * Запрос к API для получения данных текущего состояния виртуальной кассы,
     * связанной с авторизованным рабочим местом кассира.
     *
     * Если у рабочего места нет связи с виртуальной кассой,
     * то будет выброшено исключение.
     *
     * В остальных случаях всегда будет успешный ответ.
     */
    public async fetchWorkspaceState(): Promise<HttpResponse<WorkspaceStateRoute.Response>>
    {
        const response = await this.client
            .post<WorkspaceStateRoute.Response, WorkspaceStateRoute.Request>(
                this.routes.route('fetch-workspace-state'),
                {
                    uuid: '', // TODO: передавать uuid экземпляра приложения
                }
            );

        SrvHelper
            .checkApiResponse(
                response,
                {
                    responseMustBeNotEmpty: true,
                    responseMustBeSuccess:  true,
                }
            );

        return response;
    }

    /**
     * Запрос на авторизацию кассира на
     * виртуальной кассе.
     *
     * Так же используется для восстановления авторизационной сессии кассира
     * в случае смены пароля в момент, когда кассир все еще авторизован на рабочем месте.
     */
    public async cashierLogIn(
        payload: CashierLogInRoute.Request
    ): Promise<HttpResponse<CashierLogInRoute.Response>>
    {
        const response = await this.client
            .post<CashierLogInRoute.Response, CashierLogInRoute.Request>(
                this.routes.route('cashier-log-in'),
                payload,
            );

        SrvHelper
            .checkApiResponse(
                response,
                {
                    responseMustBeNotEmpty: true,
                }
            );

        return response;
    }

    /**
     * Запрос на завершение на кассе сессии текущего
     * авторизованного кассира.
     */
    public async cashierLogOut(): Promise<any>
    {
        const response = await this.client
            .post<CashierLogOutRoute.Response, CashierLogOutRoute.Request>(
                this.routes.route('cashier-log-out'),
            );

        SrvHelper
            .checkApiResponse(
                response,
                {
                    responseMustBeNotEmpty: true,
                }
            );

        return response;
    }

    /**
     * Запрос к API на открытие новой
     * рабочей смены кассира.
     */
    public async openNewShift(): Promise<
        HttpResponse<ShiftOpenRoute.Response>
    >
    {
        const response = await this.client
            .post<ShiftOpenRoute.Response, ShiftOpenRoute.Request>(
                this.routes.route('shift-open'),
                {}
            );

        SrvHelper
            .checkApiResponse(
                response,
                {
                    responseMustBeNotEmpty: true,
                }
            );

        return response;
    }

    /**
     * Запрос на закрытие смены кассира.
     */
    public async closeActiveShift(): Promise<
        HttpResponse<ShiftCloseRoute.Response>
    >
    {
        const response = await this.client
            .post<ShiftCloseRoute.Response, ShiftCloseRoute.Request>(
                this.routes.route('shift-close'),
                {}
            );

        SrvHelper
            .checkApiResponse(
                response
            );

        return response;
    }

    /**
     * Проверка актуальности набора текстов интерфейса,
     * хранящегося в браузере.
     *
     * @param locale - Текстовая локаль.
     * @param id     - Идентификатор набора текстов.
     */
    public async isTextsLibraryRelevant(
        locale: string,
        id:     string
    ): Promise<boolean>
    {
        const headers: {[p: string]: string} = {};

        headers[
            WorkspaceHeaders.X_CLIENT_TEXTS_LIBRARY_UUID
            ] = id;

        headers[
            WorkspaceHeaders.X_CLIENT_TEXTS_LIBRARY_LOCALE
            ] = locale;

        const libCheckRq = await this.client
            .get<CheckTextsLibraryRoute.Response, CheckTextsLibraryRoute.Request>(
                this.routes.route('check-texts-library'),
                {},
                {
                    headers: headers
                },
            );

        if (![304, 205].includes(libCheckRq.statusCode)) {
            throw new RuntimeException({
                code:     EposCoreErrors.UNEXPECTED_API_ERROR,
                isFatal:  false,
                message: `Failed to check texts relevance.` +
                         `Unexpected response status '${libCheckRq.statusCode}' returned.`
            });
        }

        return 304 === libCheckRq.statusCode;
    }

    /**
     * Запрос полного списка текстов интерфейса
     * для запрошенной локали.
     *
     * @param locale - Запрашиваемая локаль приложения.
     */
    public async fetchTextsLibraryForLocale(
        locale: string
    ): Promise<FetchTextsLibraryRoute.Response>
    {
        const textsLibRq = await this.client
            .post<FetchTextsLibraryRoute.Response, FetchTextsLibraryRoute.Request>(
                this.routes.route('fetch-texts-library'),
                {
                    locale: locale
                }
            );

        SrvHelper
            .checkApiResponse(
                textsLibRq,
                {
                    responseMustBeNotEmpty: true,
                    responseMustBeSuccess:  true,
                }
            );

        return textsLibRq.response as unknown as
            FetchTextsLibraryRoute.Response;
    }
}