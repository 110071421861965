import {DateTime, DateTime as luxon} from 'luxon';

export default class Helpers {
    /**
     * Создает промис, автоматически разрешающийся
     * через заданное количество миллисекунд.
     *
     * @param ms - миллисекунды
     */
    public static async sleep(ms: number): Promise<any> {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(true), ms);
        });
    }
}

export class Strings {

    /**
     * Преобразует строку из "snake_case" в "camelCase"
     * @param str
     * @return string
     */
    public static snakeToCamel(str: string): string {
        return str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
    }

    /**
     * Переводит первый символ строки в верхний регистр
     * @param str
     * @return string
     */
    public static capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }


    /**
     * Создает псевдо-случайною последовательность символов
     * указанной длины.
     */
    public static random(
        length: number,
        feed:   string|null = null
    ): string
    {
        if (length < 1) {
            throw `random: requested length is too small. (${length})`;
        }

        const feedStr = (null !== feed && feed.length > 10)
            ? feed
            : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const feedLength = feedStr.length;

        let result  = '';
        let counter = 0;

        while (counter < length) {
            result += feedStr.charAt(Math.floor(Math.random() * feedLength));
            counter += 1;
        }

        return result;
    }
}

export class Numbers {

    /**
     * Форматирует число, группируя результат по тысячам.
     * (вид разделителей зависит от переданной локали)
     */
    public static numFormat(
        locale: string,
        number: number
    ): string {

        if (typeof Intl === "undefined") {
            return ''+number;
        }

        return Intl
            .NumberFormat(locale)
            .format(number);
    }

    /**
     * Из переданного количества секунд формирует строку
     * со временем в формате HH:mm:ss.
     */
    public static secondsToHHMMSS(payload: number|string): string {
        const sec_num = ('number' === typeof payload)
            ? payload
            : parseInt(payload, 10);

        const hours   = Math.floor(sec_num / 3600);
        const minutes = Math.floor(sec_num / 60) % 60;
        const seconds = sec_num % 60;

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
    }

    /**
     * Преобразует переданное число в строку и разбирает ее
     * знаком "-" через каждые 3 символа.
     */
    public static displaySplitNumber(number: number|string): string {

        const payload = '' + number;

        return (payload.length < 1)
            ? payload
            // @ts-ignore
            : payload
                .match(/.{1,3}/g)
                .join('-')
    }

    /**
     * Округление дробных значений.
     */
    public static round(num: number, dec: number): number {
        let num_sign = num >= 0 ? 1 : -1;
        return parseFloat(
            (Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec)
        );
    }

    public static random(min: number, max: number): number
    {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}

export class Mix {
    /**
     * Возвращает Unix Timestamp
     */
    public static unixTimestamp(): number {
        return Math.round((+Date.now() / 1000));
    }

    /**
     * Возвращает luxonObject к началу дня
     *
     * @param luxonObject
     */
    /*static resetLuxonToDayStart(luxonObject: DateTime) {
        return luxonObject
            .minus({hours: luxonObject.c.hour, minutes: luxonObject.c.minute, seconds: luxonObject.c.second});
    }*/

    /**
     * Преобразует переданный штамп времени в строковое
     * представление, согласно указанным параметрам.
     *
     * @param timestamp   - временной штамп
     * @param utcOffset   - смещение часового пояса
     * @param format      - формат, в котором необходимо вернуть дату/время
     * @param lang        - требуемый язык (при необходимости)
     *
     * @return string
     */
    static fromTimestampToLocal(
        timestamp: number,
        utcOffset: string,
        format:    string,
        lang:      string = 'en'
    ): string
    {
        return luxon
            .fromMillis(
                (timestamp * 1000),
                {
                    locale: lang,
                    zone:   utcOffset //`UTC${utcOffset}`
                }
            )
            .toFormat(format);
    }

    /**
     * @param length  - длина создаваемого массива
     * @param payload - функция наполнения
     */
    public static array<T>(
        length: number,
        payload: ((i: number) => T) | Number | String
    ): T[]
    {
        return (Array
            .apply(null, {length: length} as unknown[])
            .map(Number.call, payload)) as T[];
    }

    /**
     * Возвращает один случайный элемент
     * переданного массива.
     */
    public static randFromArray<Elem>(
        elem: Elem[]|{[index: string|number|symbol]: Elem}
    ): Elem
    {
        const list: Elem[] = Array.isArray(elem)
            ? elem
            : Object.values(elem)

        return list[Math.floor(Math.random()*list.length)];
    }
}
