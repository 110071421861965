import {ref, watch}
    from "@vue/runtime-core";
import Helpers
    from "~lib/helpers";
import ApplicationState
    from "~app/Storage/Application.state";

type RequiredProps = {
    // Время удержания кнопки:
    holdMs: number;
    // Интервал между циклами обновления прогресса
    // по удержанию кнопки:
    transitionMs: number;
};

type EmitterType = (
    event: 'onHoldButtonAborted' | 'onHoldButtonPassed',
    ...args: any[]
) => void;

/**
 * Список событий для прослушивания:
 * v-on:mousedown
 * v-on:mouseup
 * v-on:mouseleave
 * v-on:touchstart
 * v-on:touchend
 * v-on:touchcancel
 * v-on:touchmove
 */
export default function (
    props:   RequiredProps,
    emitter: EmitterType,

) {

    const REACTION_ABORT = 'abort';
    const REACTION_START = 'start';

    const MOUSE_LEFT_BUTTON  = 0;
    const MOUSE_RIGHT_BUTTON = 2;

    type MouseActionType =
        'mouseleave'  |
        'mouseup'     |
        'mousedown'   |
        'touchstart'  |
        'touchend'    |
        'touchcancel';
    //'touchmove';

    const EVENT_TYPES: {[key in MouseActionType]: 'start' | 'abort'} = {
        'mousedown':   REACTION_START,
        'mouseup':     REACTION_ABORT,
        'mouseleave':  REACTION_ABORT,
        'touchstart':  REACTION_START,
        'touchend':    REACTION_ABORT,
        'touchcancel': REACTION_ABORT,
        //'touchmove':   REACTION_ABORT,
    };

    const $app = ApplicationState();

    /**
     * Процент заполнения кнопки.
     */
    const fillPercentage = ref<number>(0);

    /**
     * Пока приложение находится в режиме "занятости",
     * процент заполнения прогресс бара всегда должен быть 100%.
     */
    watch(() => $app.isAppBusy, (actual, old) => {
        fillPercentage.value = (actual)
            ? 100
            : 0;
    });

    /**
     * Идентификатор таймера, рисующего прогресс
     * заполнения кнопки.
     */
    let onHoldTimer: number|null = null;

    /**
     *
     */
    const isHoldPassed = ref<boolean>(false);

    /**
     * Проверка событий, возникающих при взаимодействии с
     * зажимаемой кнопкой, и запуск или отмена отрисовки
     * прогресса ее удержания.
     */
    function missClickCheck(
        event: Event
    ): void
    {
        const eventType: MouseActionType = event.type as unknown as MouseActionType;
        const reaction: 'start' | 'abort' = EVENT_TYPES[eventType];
        const keyCode: number = (event as unknown as {button: number}).button;
        const handlerInterval: number = props.transitionMs;

        if ('undefined' === typeof reaction)
        {
            // события, не относящиеся к зоне ответственности
            // логики зажима кнопки, не обрабатываются:
            return;
        }

        if (
            $app.isAppBusy &&
            REACTION_START === reaction
        )
        {
            return;
        }

        // отмена любых стандартных
        // взаимодействий с кнопкой:
        event
            .preventDefault();

        //
        isHoldPassed.value = false;

        // функция сброса настроек интервала:
        function resetOnHoldInterval(): void
        {
            clearInterval(onHoldTimer ?? 0);
            onHoldTimer = null;
        }

        /**
         * обработка сигнала о прерывании зажатия:
         */
        if (REACTION_ABORT === reaction) {
            resetOnHoldInterval();
            fillPercentage.value = 0;
            emitter('onHoldButtonAborted');
            return;
        }

        if (REACTION_START === reaction && null !== onHoldTimer ) {
            // защита от множественного запуска таймера:
            return;
        }

        if (REACTION_START === reaction && MOUSE_RIGHT_BUTTON === keyCode) {
            // защита от правого клика на мыши:
            return;
        }

        /**
         * Запуск логики, рисующий прогресс бар, по мере
         * удержания кнопки:
         */
        onHoldTimer = setInterval(async () => {
            // значение, на которое должен увеличиться процент заполнения полосы
            // для текущей итерации:
            const iterationPart = 100 / (props.holdMs / handlerInterval);
            // обновление значения заполнения полосы:
            fillPercentage.value = fillPercentage.value + iterationPart;

            // при достижении заданного времени удержания:
            if (fillPercentage.value >= 100) {
                // очистка обработчика отрисовки:
                resetOnHoldInterval();
                // страховочное доведение полосы до 100% заполнения:
                fillPercentage.value = 100;
                // страховочное ожидание на тот же промежуток времени,
                // что задан для итерации обновления прогресс бара:
                await Helpers.sleep(handlerInterval);
                // инициализация события об успешном удержании кнопки
                // в течение требуемого времени:
                emitter('onHoldButtonPassed');

                //
                isHoldPassed.value = true;

                // ВНИМАНИЕ! Прогресс бар останется заполненным на 100%
                // до следующего отключения глобального режима занятости приложения.
            }

        }, handlerInterval);
    }

    return {
        fillPercentage,
        isHoldPassed,
        missClickCheck,
    };
}