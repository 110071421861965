export default class LocalStorage {

    /**
     * Проверяет доступность локального
     * хранилища браузера.
     *
     * @param warnOnError
     */
    public static isAvailable(warnOnError: boolean = false): boolean
    {
        if ('undefined' === typeof localStorage) {
            return false;
        }

        try {
            const anchor = '_LC-TEST';
            localStorage.setItem(anchor, anchor);

            if (anchor !== localStorage.getItem(anchor)) {
                return false;
            }

            localStorage.removeItem(anchor);
            return true;

        } catch (throwable) {
            if (warnOnError) {
                console.warn(throwable);
            }

            return false;
        }
    }

    /**
     * Запись ключа в локальное хранилище.
     *
     * @param key
     * @param value
     */
    public static setValue(
        key: string, value: string
    ): void
    {
        localStorage
            .setItem(key, value);
    }

    /**
     * Возвращает значение, из локального хранилища браузера,
     * по запрошенному ключу.
     *
     * В случае если значение не будет найдено -
     * вернется "null".
     *
     * @param key
     */
    public static getValue(
        key: string
    ): string|null
    {
        return localStorage
            .getItem(key);
    }

    /**
     * Удаление, из локального хранилища браузера,
     * хранящегося ключа.
     */
    public static removeValue(
        key: string
    ): void
    {
        return localStorage
            .removeItem(key);
    }
}