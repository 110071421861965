import {Model}
    from "~lib/eloquent/Model";
import {EposGlobalSettings}
    from "~app/Epos/Contracts/Epos.types";

export const ModelName = Symbol
    .for('EposGlobalSettingsModel');

/**
 * @see EposGlobalSettings
 */
export default class Settings
    extends Model<EposGlobalSettings> {

    protected isExists:     boolean     = false;
    protected isDeleted:    boolean     = false;
    protected isLocked:     boolean     = false;
    protected isWebEpos:    boolean     = false;
    protected isMobileEpos: boolean     = false;
    protected role:         number      = 0;
    protected refId:        number      = 0;
    protected finGroupId:   number      = 0;
    protected eposId:       number|null = null;
    protected workspaceId:  string|null = null;
    protected name:         string|null = null;

    protected timezoneOffset:         number      = 0;
    protected margin:                 number      = 0;
    protected currencyId:             number      = 0;
    protected currencyName:           string|null = null;
    protected currencyCode:           string|null = null;
    protected countryId:              number|null = null;
    protected regionId:               number|null = null;
    protected cityId:                 number|null = null;
    protected regionName:             string|null = null;
    protected cityName:               string|null = null;
    protected isLogoRequired:         boolean     = false;
    protected logoSettingsId:         number|null = 0;
    protected logoFilename:           string|null = null;
    protected cashierMustSignSlip:    boolean     = false;
    protected currencyConvertRateEur: number|null = null;
    protected minStakeAmount:         number|null = null;
    protected balanceObservation:     boolean     = false;
    protected isPrePaidBalanceActive: boolean     = false;
    protected cfLive:                 number      = 0;
    protected cfLine:                 number      = 0;
    protected openingHours:           string|null = null;
    protected stakeLimitToBind:       number|null = null;
    protected payoutLimitToBind:      number|null = null;

    /**
     * @see Model.init
     *
     * @param feed
     */
    constructor(
        feed: Partial<EposGlobalSettings>|null = null
    )
    {
        super();

        this.init(
            feed
        );
    }

    /**
     * @inheritDoc
     */
    public modelName(): symbol
    {
        return ModelName;
    }
}