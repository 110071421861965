<template>
  <div class="workplace-main__inner">
    <guest-window-ico
        ico="application--security"
        size="size-custom-95"
        sprite="cleared"
        :is-flicker-allowed="false"
    />
    <h1 class="workplace-main__title">{{ $T.text('guest-auth-header') }}</h1>
    <div class="workplace-main__description">
      <p
        v-for="text in $T.composite('guest-auth-description')"
        class="workplace-main__text"
      >{{ text }}</p>
    </div>
    <form class="workplace-main__form workplace-main-form">
      <Input
          v-model="workspaceKey"
          :placeholder="$T.text('guest-auth-input-placeholder')"
          :shell-styles="['workplace-main-form__field']"
          size="size-l"
          :is-disabled="$app.isAppBusy"
          :is-error="errorMsg.length > 1"
          :description="errorMsg"
          :i-mask="tokenMask"
      />
      <Button
          @click="tryWorkspaceAuth()"
          size="size-l"
          :is-disabled="$app.isAppBusy"
          :is-processing="$app.isAppBusy"
          :text="$T.text('btn-confirm')"
          label-style="uppercase"
          class="workplace-main-form__button"
      />
    </form>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'WorkspaceAuth.window';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useWorkspaceAuthWindow
  from "~interaction/guest/useWorkspaceAuthWindow";
import useInputMasks
    from "~interaction/useInputMasks";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";
import Input
  from "~tpl-global/Input/Input.vue";
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $app
} = usePiniaState();

const {
    isFormValid,
    errorMsg,
    workspaceKey,

    tryWorkspaceAuth,
} = useWorkspaceAuthWindow();

const {
    tokenMask
} = useInputMasks();
</script>