<template>
  <div class="workspace__empty">
    <empty-window-content
      :ico="winMeta.data.ico as SvgList"
      :ico-flicker="true"
      :title="winMeta.data.name"
      :desc="$T.text('vue-window-preloader-desc')"
    />
  </div>
</template>

<script lang="ts">
import VueComponentType
    from "~lib/render/VueComponent";

const CMP_NAME = 'WindowSwapPreloader';
export default {
    name: CMP_NAME,
} as VueComponentType
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useWindowsManager
    from "~interaction/useWindowsManager";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import {WinSettings}
    from "~app/Providers/Windows/WindowsManager.provider";
import {WindowMeta}
    from "~lib/windows/WindowsManager";
import {SvgList}
    from "~tpl-global/svg/SvgList";

/** IMPORTED VUE TEMPLATES: */
import EmptyWindowContent
    from "~tpl-mobile/shared/EmptyWindowContent.vue";

/** COMPONENT LOGIC: */
const {$app} = usePiniaState();
const {$WM}  = useWindowsManager();
const {$T}   = useInterfaceTexts();

const winMeta: WindowMeta<WinSettings> = $WM.getWindowMeta<WinSettings>($app.windowId ?? '') ?? {
    window: $app.windowId ?? '',
    tag:    '',
    data:   {
        ico:  'server-settings',
        name: '...'
    }
};

</script>