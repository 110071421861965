import {createApp}
    from '@vue/runtime-dom';
import {Container}
    from "inversify";
import {App, ComponentPublicInstance}
    from '@vue/runtime-core';

import Helpers
    from "~lib/helpers";
import RenderSettings
    from "~lib/render/RenderSettings";
import {VueComponentType}
    from "~lib/render/VueComponent";
import ServiceProviderSetup, {ServiceProviderRegistrationType}
    from "~ioc/ServiceProviderSetup";

import {AppCoreErrors}
    from "~lib/Exception/Enum/AppCore.errors";
import {VueRuntimeErrorCodeMap}
    from "~lib/render/Map/VueRuntimeErrorCode.map";
import {RuntimeExceptionHandlerFactory}
    from "~lib/Exception/Contracts/RuntimeExceptionHandlerFactory";
import UnexpectedRuntimeException
    from "~lib/Exception/UnexpectedRuntime.exception";
import Exception
    from "~lib/Exception/Exception";
import ExceptionHandler
    from "~lib/Exception/ExceptionHandler";
import AppRenderException
    from "~lib/render/Exceptions/AppRender.exception";
import AppInterfaceException
    from "~lib/render/Exceptions/AppInterface.exception";
import NotHandledRejectionException
    from "~lib/render/Exceptions/NotHandledRejection.exception";

export type ApplicationCoreType = App<Element>;

/**
 * Символ для привязки в контейнер сервиса
 * по обработки ошибок приложения.
 */
export const RuntimeExceptionHandlerSonar: symbol = Symbol
    .for('RuntimeExceptionHandlerSonar');

const DOCUMENT_STATE_LOADING:     'loading'     = 'loading';     // В процессе загрузки
const DOCUMENT_STATE_INTERACTIVE: 'interactive' = 'interactive'; // Загружена частично (DOM доступен)
const DOCUMENT_STATE_COMPLETED:   'complete'    = 'complete';    // Все ресурсы загружены

export default class Render {

    /**
     * @see https://inversify.io/
     */
    private readonly container: Container;

    /**
     * @see RenderSettings
     */
    private readonly settings: RenderSettings;

    /**
     * Vue компонент, используемый как корневой,
     * для инициализации фреймворка.
     */
    private readonly component: VueComponentType;

    /**
     * Функционал для регистрации сервис провайдеров.
     */
    protected readonly serviceProvider: ServiceProviderSetup;

    /**
     * Экземпляр Vue приложения.
     */
    protected app: ApplicationCoreType|null = null;

    /**
     * Список добавленных в приложение сервис провайдеров
     * и ожидающих регистрации.
     *
     * После успешной регистрации провайдеры будут удалены из очереди.
     */
    private providersQueue: ServiceProviderRegistrationType = [];

    /**
     * Флаг, показывающий успешное завершение
     * инициализации приложения.
     *
     * Большинство настроек становится недоступными к редактированию,
     * после перевода в значение "true".
     */
    private isSetupFinished: boolean = false;

    /**
     * @param container - IoC контейнер приложения
     * @param settings  - настройки инициализации
     * @param component - корневой компонент (оболочка) интерфейса
     */
    constructor(
        container: Container,
        settings:  RenderSettings,
        component: VueComponentType
    )
    {
        this.container        = container;
        this.settings         = settings;
        this.component        = component;
        this.serviceProvider  = new ServiceProviderSetup(container);
    }

    /**
     * Первый шаг.
     *
     * Регистрация сервис провайдеров приложения.
     * @see ServiceProviderSetup.runRegistration
     */
    public register(
        payload: ServiceProviderRegistrationType
    ): Render
    {
        if (this.isSetupFinished) {
            throw new AppRenderException({
                code:    AppCoreErrors.PROVIDER_REGISTERED_AFTER_SETUP_IS_FINISHED,
                message: 'Is not possible to register provider after application setup is finished.'
            });
        }

        this.providersQueue = payload;

        return this;
    }

    /**
     * Второй шаг.
     *
     * Отрисовка HTML элемента, необходимого для
     * инициализации Vue приложения.
     */
    public async render(
        customSetup?: (app: App<Element>) => Promise<void>
    ): Promise<Render>
    {
        const element: HTMLDivElement = document
            .createElement('div');

        element
            .id = this.settings.id;

        element
            .className = this.settings.class || '';

        await this
            .waitTillResourcesIsLoaded();

        document
            .body
            .prepend(element);

        this.app = createApp(
            this.component
        );

        if (!this.app) {
            throw new AppRenderException({
                code:    AppCoreErrors.FAILED_TO_CREATE_VUE_APPLICATION,
                message: 'Failed to create Vue component.' +
                         ' Further initialization of the application is not possible.',
            });
        }

        if ('function' === typeof customSetup) {
            // Обработка пользовательской логики
            // настроек приложения:
            await customSetup(
                this.app
            );
        }

        return this;
    }

    /**
     * Завершающий шаг.
     *
     * Монтирует созданное Vue приложение, и
     * запускает отрисовку корневого шаблона.
     */
    public async mount(): Promise<void>
    {
        // Загрузка логики обработки исключений:
        this.bindExceptionsHandling();

        // Запуск регистрации объявленного списка
        // сервис провайдеров:
        await this.serviceProvider
            .register(this.providersQueue)
            .then((): void => {
                this.isSetupFinished = true;
            });

        if (null === this.app) {
            throw new AppRenderException({
                code:     AppCoreErrors.UNEXPECTED_APP_RENDER_ERROR,
                message: 'Logic error. Impossible to mount not existed vue application.',
            });
        }

        if (!this.isSetupFinished) {
            throw new AppRenderException({
                code:    AppCoreErrors.UNEXPECTED_APP_RENDER_ERROR,
                message: 'Logic error. Impossible to mount application' +
                         ' till setup is not finished yet.',
            });
        }

        this.app
            .mount(`#${this.settings.id}`);
    }

    /**
     * Дожидается загрузки всех необходимых
     * для страницы файлов.
     */
    private async waitTillResourcesIsLoaded(): Promise<void>
    {
        await new Promise(async (resolve, reject): Promise<void> =>
        {
            do
            {
                await Helpers.sleep(30);
            }

            while (
                document.readyState.toLowerCase() !== DOCUMENT_STATE_COMPLETED
                );

            resolve(true);
        });
    }

    /**
     * TODO: описание
     * TODO дать возможность скачать ошибку с трассировкой в файл, для последующей передачи в саппорт,
     * TODO чтобы менеджеры не тыкались в консоль
     * https://developer.chrome.com/docs/devtools/console/api/#styling_console_output_with_css
     * https://segmentfault.com/a/1190000041503731/en
     * https://vuejs.org/guide/reusability/composables.html
     */
    private bindExceptionsHandling(): void
    {
        // Логирование в консоль на случай, если
        // обработчик ошибок не зарегистрирован:
        const logIfHandlerIsNotBound = (
            throwable: unknown
        ): void =>
        {
            // Невозможно обработать ошибку т.к. в контейнере
            // не зарегистрирован обработчик ошибок:
            console.warn(
                `WARNING: Runtime exception handler is not defined.` +
                ` Error handler must be bound into container` +
                ` for key '${RuntimeExceptionHandlerSonar.toString()}'.`
            );

            // Вывод ошибки в консоль "как есть":
            console
                .error(throwable);
        };

        // Извлечение из IoC контейнера зарегистрированного обработчика ошибок,
        // и передача ему исключения на обработку:
        const handleDetectedException = (
            throwable: Exception
        ): void =>
        {
            const exceptionHandler: ExceptionHandler<Exception> = this.container
                .get<RuntimeExceptionHandlerFactory>(
                    RuntimeExceptionHandlerSonar
                )(throwable);

            // Запуск обработки и логгирования:
            exceptionHandler.handle();
            exceptionHandler.log();
        }

        // Логика, если задан экземпляр Vue приложения:
        if (this.app) {
            // Перехват ошибок, возникших внутри функционала Vue.
            // => https://vuejs.org/api/application.html#app-config-errorhandler
            this.app.config.errorHandler = (
                err:       unknown,
                instance:  ComponentPublicInstance|null,
                info:      string
            ): void =>
            {
                // Проверка маппинга необходима т.к продакшен режиме
                // текстовое описание будет преобразовано в код:
                info = (VueRuntimeErrorCodeMap.hasOwnProperty(info))
                    ? VueRuntimeErrorCodeMap[info]
                    : info;

                let message: string = `An error occurred in '${info}'`;

                message += (null !== instance)
                    ? ` at component: '${instance.$.type.name ?? '-'}'.`
                    : `.`;

                const parsedError = UnexpectedRuntimeException
                    .autoConstruct(
                        err,
                        message
                    );

                if (!this.container.isBound(RuntimeExceptionHandlerSonar))
                {
                    logIfHandlerIsNotBound(
                        parsedError
                    );

                    return;
                }

                handleDetectedException(
                    (!parsedError.isFatal)
                        ? parsedError
                        : new AppInterfaceException({
                            code:     AppCoreErrors.VUE_RUNTIME_ERROR,
                            message:  parsedError.message,
                            trace:    parsedError.trace,

                            interfaceComponent: instance,
                        })
                );
            };

            // Перехват предупреждений, возникших внутри функционала Vue.
            // (Работает только в режиме отладки.)
            // => https://vuejs.org/api/application.html#app-config-warnhandler
            this.app.config.warnHandler = (
                msg:      string,
                instance: ComponentPublicInstance|null,
                trace:    string
            ): void =>
            {
                let message: string = `WARNING: '${msg}'`;

                message += (null !== instance)
                    ? ` in component: '${instance.$.type.name ?? '-'}'.`
                    : `.`;

                message += ` Trace: '${trace}'.`;

                const throwable = new AppInterfaceException({
                    code:     AppCoreErrors.VUE_RUNTIME_WARNING,
                    message:  message,

                    interfaceComponent: instance,
                })

                if (!this.container.isBound(RuntimeExceptionHandlerSonar))
                {
                    logIfHandlerIsNotBound(
                        throwable
                    );

                    return;
                }

                handleDetectedException(
                    throwable
                );
            };
        }

        // Перехват не обработанного, отклоненного "Promise":
        window.addEventListener(
            'unhandledrejection',
            (
                event: PromiseRejectionEvent
            ): void =>
            {
                // Исключение выброса в консоль "Uncaught (in promise)":
                event
                    .preventDefault();

                if (!this.container.isBound(RuntimeExceptionHandlerSonar))
                {
                    logIfHandlerIsNotBound(
                        event.reason
                    );

                    return;
                }

                if (event.reason instanceof Exception)
                {
                    handleDetectedException(
                        event.reason
                    );

                    return;
                }

                const isErrorContentHasMessageType = (
                    payload: unknown
                ): boolean => [
                    'string',
                    'number',
                    'boolean'
                ].includes(typeof payload);

                let errorMessage: string = (isErrorContentHasMessageType(event.reason))
                    ? `Unhandled rejection with content: '${event.reason ?? 'null'}' detected.'`
                    : `Unhandled rejection with content of type '${typeof event.reason}' detected.`;

                if (
                    'object' === typeof event.reason &&
                    isErrorContentHasMessageType(event.reason?.message)
                )
                {
                    errorMessage += ` Details: '${event.reason.message}'.`;
                }

                handleDetectedException(
                    new NotHandledRejectionException({
                        message: errorMessage,
                        previous: event.reason,
                    })
                );
            });

        // Перехват глобальных ошибок:
        window.onerror = (
            eventOrMessage: string|ErrorEvent,
            url:            string|undefined,
            line:           number,
            column:         number|undefined,
            error:          Error|undefined,
        ): void =>
        {
            const message: string = ('string' !== typeof eventOrMessage)
                ? `${eventOrMessage.type}: ${eventOrMessage.message}.`
                : `Unhandled global error detected.`;

            const throwable = UnexpectedRuntimeException
                .autoConstruct(
                    ('string' !== typeof eventOrMessage)
                        ? eventOrMessage.error
                        : eventOrMessage,
                    message
                );

            if (!this.container.isBound(RuntimeExceptionHandlerSonar))
            {
                logIfHandlerIsNotBound(
                    throwable
                );

                return;
            }

            handleDetectedException(
                throwable
            );
        };
    }
}
