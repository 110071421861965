export type TypesList = 'RangeError' | 'ReferenceError' | 'SyntaxError' | 'TypeError'
    | 'InternalError' | 'URIError' | 'EvalError' | 'Error' | 'Exception';

/**
 * @param isFatal  - Если "TRUE", то приложение должно немедленно завершить работу.
 * @param code     - Код исключения.
 * @param type     - Тип исключения.
 * @param name     - Название исключения (должно совпадать с названием класса).
 * @param message  - Описание ситуации, приведшей к возникновению исключения.
 *
 * @param at       - (Опционально) Время возникновения исключения.
 * @param label    - (Опционально) Заголовок исключения.
 * @param previous - (Опционально) Исключение, предшествующее созданию текущего исключения.
 */
export type ExceptionConstructor = {
    isFatal:   boolean;
    code:      number,
    type:      TypesList;
    name:      string;
    message:   string|null;

    at?:       number,
    label?:    string;
    previous?: any;
};

export default abstract class Exception {

    public readonly isFatal:  boolean;
    public readonly code:     number;
    public readonly type:     TypesList;
    public readonly name:     string;
    public readonly message:  string|null;

    public readonly at:       number;
    public readonly label:    string;
    public readonly previous: any;

    /**
     * Техническое поле, необходимое для
     * функционала трассировки.
     */
    public readonly trace: Error;

    protected constructor(
        payload: ExceptionConstructor
    )
    {
        this.isFatal  = payload.isFatal  ?? true;
        this.code     = payload.code     ?? 0;
        this.type     = payload.type     ?? 'Exception';
        this.name     = payload.name     ?? 'AbstractException';
        this.message  = payload.message  ?? 'Unexpected exception occurred.';

        this.at       = payload.at       ?? Date.now();
        this.label    = payload.label    ?? 'EXCEPTION';
        this.previous = payload.previous ?? null;

        this.trace = (this.previous instanceof Error)
            ? this.previous
            : new Error('Trace-mock');
    }

    /**
     * Если возвращается "TRUE", то исключение, находящееся
     * в поле 'previous' является ложным экземпляром объекта "Error",
     * который необходим для корректной трассировки исключения.
     */
    public isTraceMock(): boolean
    {
        return !(this.previous instanceof Error);
    }
}