import {ref, reactive, computed, ComputedRef}
    from "@vue/runtime-core";
import {Numbers}
    from "~lib/helpers";

import useEposModule
    from "~app/IoC/useEposModule";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";

import {SvgList}
    from "~tpl-global/svg/SvgList";

import RuntimeException
    from "~app/Exception/Runtime.exception";

export default function ()
{
    const {
        $T
    } = useInterfaceTexts();

    const {
        $app,
        $workspace,
        $shift,
        $modal,
    } = usePiniaState();

    type EposInfoListType  = {
        id:          string,
        isActive:    boolean,
        ico:         SvgList,
        info:        string,
        description: string
    };

    const shiftIdLine = computed<EposInfoListType>(() => {
        return {
            id:          'shift-id',
            ico:         'id-session',
            isActive:    $shift.isActive,
            info:        ($shift.isActive)
                ? Numbers.displaySplitNumber($shift.field('id') ?? '')
                : '',
            description: $T.text('shift-info-id'),
        };
    });

    const shiftOpenedAtLine = computed<EposInfoListType>(() => {
        return {
            id:          'shift-opened-at',
            ico:         'timer',
            isActive:    $shift.isActive,
            info:        ($shift.isActive)
                ? $workspace.timeInWorkspaceTz(
                    $shift.field('openedAt') ?? 0,
                    'HH:mm / MMM dd / yyyy'
                ).toUpperCase()
                : '',
            description: $T.text('shift-info-opened-at'),
        };
    });

    const shiftCurrencyLine = computed<EposInfoListType>(() => {
        return {
            id:          'shift-currency',
            ico:         'money',
            isActive:    true,
            info:        $workspace.workspaceCurrencyInfo,
            description: $T.text('epos-info-currency'),
        };
    });

    const eposIdLine = computed<EposInfoListType>(() => {
        return {
            id:          'epos-id',
            ico:         'id-epos',
            isActive:    true,
            info:        $workspace.settings('workspaceId') ?? '-',
            description: `${$workspace.settings('eposId') ?? '-'}`
        };
    });

    const eposWorkingHoursLine = computed<EposInfoListType>(() => {
        return {
            id:          'epos-working-hours',
            ico:         'time--condition',
            isActive:    true,
            info:        $T.text('epos-info-working-hours-around-the-clock'),
            description: $T.text('epos-info-working-hours'),
        };
    });

    const eposLocationCityLine = computed<EposInfoListType>(() => {
        return {
            id:          'epos-location-city',
            ico:         'enterprise',
            isActive:    true,
            info:        $workspace.settings('cityName') ?? '-',
            description: $T.text('epos-info-location-city'),
        };
    });

    // используется такой формат списка для корректной
    // реактивности изменений в содержимом списка:
    const eposInfoList = reactive<ComputedRef<EposInfoListType>[]>([
        eposIdLine,
        eposLocationCityLine,
        eposWorkingHoursLine,
        shiftCurrencyLine,
        shiftIdLine,
        shiftOpenedAtLine,
    ]);

    /**
     * Обработка нажатия кнопки для открытия
     * новой рабочей смены кассира.
     */
    async function openShift(): Promise<void>
    {
        if ($app.isAppBusy || $shift.isActive) {
            // TODO: добавить проверку с последней синхронизации
            return;
        }

        await $app
            .changeAppBusyStatus(true);

        try
        {
            const {
                $eposModule
            } = useEposModule();

            await $eposModule
                .openNewWorkingShift();
        }

        catch (throwable)
        {
            if (!(throwable instanceof RuntimeException) || throwable.isFatal) {
                throw throwable;
            }

            $modal.show({
                ico:     'do-not',
                title:   $T.text('label-rejected-action'),
                message: $T.composite<string[]>('modal-error-message-default'),

                mainBtn: $T.text('btn-close'),
                mainBtnOnPress: () => {
                    $modal.close();
                },
            });

            await $app
                .changeAppBusyStatus(false);

            throw throwable;
        }

        // новая рабочая смена кассира успешно открыта:
        $modal.show({
            severity:    'success',
            ico:         'checked',
            title:       $T.text('label-success-action'),
            message:     $T.text('shift-has-been-opened-msg'),

            mainBtn:     $T.text('btn-close'),
            mainBtnOnPress: () => {
                $modal.close();
                },
        });

        await $app
            .changeAppBusyStatus(false);

        return;
    }

    /**
     * Запрос на закрытие рабочей смены.
     */
    async function closeShift(): Promise<void>
    {
        if (
            $app.isAppBusy ||
            !$shift.isActive ||
            // необходимо для внутренней бд:
            (($shift.duration() ?? 0) < 10)
        ) {
            return;
        }

        await $app
            .changeAppBusyStatus(true);

        try
        {
            const {
                $eposModule,
            } = useEposModule();

            await $eposModule
                .closeActiveWorkingShift();
        }

        catch (throwable)
        {
            if (!(throwable instanceof RuntimeException) || throwable.isFatal) {
                throw throwable;
            }

            $modal.show({
                ico:     'do-not',
                title:   $T.text('label-rejected-action'),
                message: $T.composite<string[]>('modal-error-message-default'),

                mainBtn: $T.text('btn-close'),
                mainBtnOnPress: () => {
                    $modal.close();
                },
            });

            await $app
                .changeAppBusyStatus(false);

            throw throwable;
        }

        // рабочая смена кассира успешно закрыта:
        $modal.show({
            severity: 'success',
            ico:      'checked',
            title:    $T.text('label-success-action'),
            message:  $T.text('shift-has-been-closed-msg'),
            mainBtn:  $T.text('btn-close'),
            mainBtnOnPress: () => {
                $modal.close();
            },
        });

        await $app
            .changeAppBusyStatus(false);
    }

    return {
        eposInfoList,

        shiftIdLine,
        shiftOpenedAtLine,
        shiftCurrencyLine,
        eposIdLine,
        eposWorkingHoursLine,
        eposLocationCityLine,

        openShift,
        closeShift,
    };
}