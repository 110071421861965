<template>
  <transition name="toggle-tooltip">
    <div v-if="isShowed" :class="shellStyles">
      <div class="tooltip__container">
        <!-- svg элемент удален из-за рекурсии, вернуть переработав -->
<!--        <SvgElem
            v-if="icon"
            sprite="cleared"
            :to="icon"
            size="size-xs"
            class="tooltip__icon"
        />-->
        <span class="tooltip__label">{{ label }}</span>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
const CMP_NAME = 'Tooltip';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
  defineProps, PropType, computed
} from "@vue/runtime-core";
import {SvgList}
  from "~tpl-global/svg/SvgList";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
  from "~tpl-global/svg/SvgElem.vue";

/** COMPONENT LOGIC: */
type tooltipPosition  = 'is-top' | 'is-right' | 'is-bottom' | 'is-left';

const props = defineProps({
  shellStyles:        {type: Array  as PropType<string[]>,        default: []},
  position:           {type: String as PropType<tooltipPosition>, default: 'is-bottom'},
  icon:               {type: String as PropType<SvgList>,         default: ''},
  label:              {type: String,                              default: ''},
  isWarning:          {type: Boolean,                             default: false},
  isShowed:           {type: Boolean,                             default: false}
});

// стили для общей оболочки поля ввода:
const shellStyles = computed<string[]>({
  set: (val) => null,
  get: function () {
    const styles = [
      'tooltip',
      'tooltip--' + props.position,
    ];

    if (props.isWarning) {
      styles.push('tooltip--is-warning')
    }

    return [
      ...styles,
      ...props.shellStyles
    ];
  }
});

</script>

<style lang="scss">
  @import '~scss-desktop/common/components/_tooltip';
</style>