import {
    ref,
    nextTick,
    watch,
} from "@vue/runtime-core";

import usePiniaState
    from "~interaction/Store/usePiniaState";

export default function ()
{
    const {
        $notification
    } = usePiniaState();

    // флаг для анимации появления и
    // исчезновения блока с уведомлениями:
    const isShown  = ref<boolean>(false);
    // выбор позиционирования блока с уведомлениями:
    const position = ref<'left' | 'right'>('left');

    // слежение за маяком показа одиночных уведомления для
    // предотвращения ситуации, когда одиночные уведомления
    // накладываются друг на друга:
    watch(
        () => $notification.notificationBeacon,
        (value) => {
            if (value) {
                isShown.value = false;
            }
        }
    );

    /**
     * Определение позиционирование блока
     * одиночного уведомления по оси X.
     */
    function checkElementsPosition(elementRightSidePosition: number): void {
        const windowWidth:  number = document.documentElement.clientWidth || window.innerWidth || 0;
        const elementWidth: number = 260; //TODO: надо придумать логику вычисления ширины элемента

        if (!windowWidth) {
            return;
        }

        position.value = (windowWidth - elementRightSidePosition <= elementWidth)
            ? 'right'
            : 'left';
    }

    /**
     * Обработчик открытия/закрытия одиночных
     * блоков уведомлений.
     */
    async function clickHandler(event: PointerEvent): Promise<void>
    {
        //TODO: удалить
        return;

        /*if (!isShown.value) {
            // определение позиционирования перед показом
            // блока с уведомлением:
            checkElementsPosition(
                (<SVGSVGElement|Element>event.target)
                    .getBoundingClientRect()
                    .right  || 0
            );

            // ожидание закрытие других одиночных уведомлений:
            await $notifications.triggerNotificationBeacon();
        }

        await nextTick(() => {
            isShown.value = !isShown.value;
        });*/
    }

    return {
        position,
        isShown,

        clickHandler,
        checkElementsPosition,
    }
}