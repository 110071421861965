import {computed, ref,}
    from "@vue/runtime-core";

import {WorkspaceAuthorisationStatus}
    from "~app/Epos/Contracts/Epos.types";
import {ReCaptchaErrors}
    from "~app/Throttling/ReCaptcha/ReCaptcha.errors";
import useEposModule
    from "~app/IoC/useEposModule";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";

import Exception
    from "~lib/Exception/Exception";
import UnexpectedRuntimeException
    from "~lib/Exception/UnexpectedRuntime.exception";

export default function () {

    const {
        $T
    } = useInterfaceTexts();

    const {
        $eposModule,
    } = useEposModule();

    const {
        $app,
    } = usePiniaState();

    /**
     * Поле формы ввода ключа авторизации.
     */
    const workspaceKey = ref<string>('');

    /**
     * Сообщение об ошибке, выводимое в
     * форме авторизации рабочего места.
     */
    const errorMsg = ref<string>('');

    /**
     * Запрос на авторизацию текущего рабочего места кассира
     * через специальный токен.
     *
     * Используется как при регистрации нового рабочего места,
     * так и для восстановления доступа к существующему.
     */
    async function tryWorkspaceAuth(): Promise<void>
    {
        if ($app.isAppBusy || !isFormValid.value) {
            return;
        }

        errorMsg
            .value = '';

        await $app
            .changeAppBusyStatus(
                true,
                100);

        const authResult: WorkspaceAuthorisationStatus|Exception = await $eposModule
            .authorizeCurrentWorkspace(
                workspaceKey.value
            )
            .catch((throwable): Exception =>
            {
                return UnexpectedRuntimeException
                    .autoConstruct(
                        throwable,
                        'Workspace authorization interrupted due to' +
                        ' unexpected critical exception occurred.'
                    );
            });

        if ((authResult instanceof Exception)) {
            if (authResult.isFatal) {
                throw authResult;
            }

            switch (authResult.code)
            {
                case ReCaptchaErrors.PRELOAD_TIMEOUT_EXPIRED:
                    errorMsg.value = $T.text('google_rc_preload_timeout_expired');
                    break;
                case ReCaptchaErrors.PRELOAD_ERROR:
                    errorMsg.value = $T.text('google_rc_failed_at_preload_state');
                    break;
                case ReCaptchaErrors.RE_CAPTCHA_READY_TIMEOUT:
                    errorMsg.value = $T.text('google_rc_google_api_timeout');
                    break;
                case ReCaptchaErrors.CLIENT_CHECK_ERROR:
                    errorMsg.value = $T.text('google_rc_client_get_token_failed');
                    break;

                default:
                    errorMsg.value = $T.text('global_error_0');
                    break;
            }

            await $app
                .changeAppBusyStatus(
                    false,
                    120
                );

            return;
        }

        // Если не удалось связать рабочее место
        // с виртуальной кассой:
        if (!authResult.isAuthorised)
        {
            await $app
                .changeAppBusyStatus(
                    false,
                    120
                );

            if (true === authResult.isTokenNotExists) {
                errorMsg.value = $T.text('access_token_not_exists');
                return;
            }

            if (true === authResult.isTokenExpired) {
                errorMsg.value = $T.text('access_token_expired');
                return;
            }

            if (true === authResult.isCaptchaInternalError)
            {
                if (true === authResult.isCaptchaTokenIncorrect) {
                    errorMsg.value = $T.text('google_rc_error_incorrect_token');
                    return;
                }

                if (true === authResult.isCaptchaInternalError) {
                    errorMsg.value = $T.text('google_rc_error_check_failed');
                    return;
                }

                if (true === authResult.isCaptchaLowScoreDetected) {
                    errorMsg.value = $T.text('google_rc_error_low_score');
                    return;
                }
            }

            errorMsg
                .value = $T.text('global_error_0');

            return;
        }

        // Разблокировка приложения:
        await $app
            .changeAppBusyStatus(
                false,
                120
            );

        // Рабочее место было успешно связано с виртуальной кассой,
        // дальнейших действий не требуется:
        // =>
    }

    /**
     * Проверка является ли форма валидной.
     */
    const isFormValid  = computed(() => {
        return 15 <= workspaceKey.value.length && 30 >= workspaceKey.value.length;
    });

    return {
        isFormValid,
        errorMsg,
        workspaceKey,

        tryWorkspaceAuth,
    };
}