import {injectable}
    from 'inversify';
import {v4 as uuidv4}
    from 'uuid';
import Library
    from "~lib/translate/Library";
import LocalStorage
    from "~lib/LocalStorage";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useAppContainer
    from "~app/IoC/useAppContainer";
import useSupervisor
    from "~app/IoC/useSupervisor";

import {AppInterfaceType}
    from "~app/Epos/Enum/AppInterfaceType";
import {AppLocale}
    from "~app/Epos/Enum/AppLocale";
import * as EposTypes
    from "~app/Epos/Contracts/Epos.types";
import EposService
    from "~app/Epos/Epos.service";
import Credentials
    from "~app/Epos/Models/Credentials";
import Settings
    from "~app/Epos/Models/Settings";
import Cashier
    from "~app/Epos/Models/Cashier";
import WorkingShift
    from "~app/Epos/Models/WorkingShift";
import StorageModule
    from "~app/Epos/Storage.module";
import RoutesPool
    from "~app/Epos/RoutesPool";
import {Response as WorkspaceStateData}
    from "~app/Epos/Routes/WorkspaceState.route";
import {Response as TextsLibraryResponse}
    from "~app/Epos/Routes/FetchTextsLibrary.route";
import ReCaptchaService
    from "~app/Throttling/ReCaptcha/ReCaptcha.service";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

@injectable()
export default class EposModule {

    public static readonly SONAR = Symbol
        .for('EposModule');

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
    ) {}

    /**
     * Запрос на обновление и синхронизацию
     * состояния рабочего места с виртуальной кассой.
     */
    public async synchronizeWorkspaceState(): Promise<void>
    {
        const {
            $workspace,
        } = usePiniaState();

        if (!$workspace.isWorkspaceAuthorized) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: 'Logic error. Workspace synchronization functional is not available' +
                         ' at not authorized workspace.',
            });
        }

        const {
            $container,
        } = useAppContainer();

        // Ошибки выполнения запроса не учитываются т.к. в случае обычных сетевых ошибок
        // ничего предпринимать не требуется, а в случае потери связи с рабочим местом -
        // поведение находится в глобальном обработчике ошибок:
        const workspaceState = (await $container
            .get<EposService>(EposService.SONAR)
            .fetchWorkspaceState()).response;

        if (null === workspaceState) {
            // Все возможные сетевые ошибки должны быть преобразованы в исключения
            // внутри сервиса, потому значение "null" невозможно:
            throw new RuntimeException({
                code:     EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal:  true,
                message: 'Logic error. Is not possible the workspace data' +
                         ' to be empty at this point.'
            });
        }

        await this
            .updateApplicationState(workspaceState);
    }

    /**
     * Запрос авторизации текущего рабочего места и
     * связи его с виртуальной кассой.
     *
     * @param token - Токен авторизации.
     */
    public async authorizeCurrentWorkspace(
        token:    string,
    ): Promise<EposTypes.WorkspaceAuthorisationStatus>
    {
        const {
            $workspace,
            $throttling,
        } = usePiniaState();

        // Проверка на ошибочную попытку авторизовать
        // ранее авторизованное рабочее место:
        if ($workspace.isWorkspaceAuthorized)
        {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: 'Logic error. Workspace authorization functional is not available' +
                         ' because it already authorized.',
            });
        }

        const {
            $container,
        } = useAppContainer();

        /**
         * Токен результата прохождение клиентом проверки:
         */
        let clientCheckToken: string|null = null;

        // Если включена защита через функционал ReCaptcha:
        if ($throttling.isHttpThrottlingActive)
        {
            const ReCaptcha: ReCaptchaService = $container
                .get<ReCaptchaService>(ReCaptchaService.SONAR);

            // Попытка проверить текущий клиент через сервис ReCaptcha,
            // и получить токен результата проверки, необходимый
            // для валидации на бекенде:
            // =>

            await ReCaptcha
                .render();

            clientCheckToken = await ReCaptcha
                .checkClient();

            await ReCaptcha
                .clearDataAndRemove();
        }

        // Попытка авторизовать рабочее место,
        // связав браузер с виртуальной кассой:
        const workspaceAuthRq = await ($container
            .get<EposService>(EposService.SONAR))
            .requestWorkspaceAuthorization({
                token: token,
                rc:    clientCheckToken
            });

        // Авторизация рабочего места была отклонена:
        if (!workspaceAuthRq.isSuccess)
        {
            const response: EposTypes.WorkspaceAuthorisationStatus = {
                isAuthorised: false,
                msg:          workspaceAuthRq.message,

                isTokenNotExists: workspaceAuthRq.response?.isTokenNotExists,
                isTokenExpired:   workspaceAuthRq.response?.isTokenExpired,
            };

            // Если авторизация рабочего места была отклонена
            // при проверке устройства сервисом "Captcha":
            if (true === workspaceAuthRq.response?.hasCaptchaCheckError) {
                response.hasCaptchaCheckError      = workspaceAuthRq.response?.hasCaptchaCheckError;
                response.isCaptchaTokenIncorrect   = workspaceAuthRq.response?.isCaptchaTokenIncorrect;
                response.isCaptchaInternalError    = workspaceAuthRq.response?.isCaptchaInternalError;
                response.isCaptchaLowScoreDetected = workspaceAuthRq.response?.isCaptchaLowScoreDetected;
            }

            return response;
        }

        if ('string' !== typeof workspaceAuthRq.response?.anchor)
        {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: 'Logic error. On successfully workspace authorisation API' +
                         ' must return an anchor for virtual EPOS.',
            });
        }

        // Создание модели, содержащей данные для связи
        // рабочего места с виртуальной кассой:
        const credentials: Credentials = new Credentials(
            workspaceAuthRq.response ?? {}
        );

        // С момента загрузки этой модели в память приложения
        // касса считается авторизованной (для логики интерфейса):
        $workspace.setOrClearAuthCredentials(
            credentials
        );

        // Правильность полученных авторизационных данных
        // определяется запросом на синхронизацию рабочего места:
        const workspaceStateRq = await ($container
            .get<EposService>(EposService.SONAR))
            .fetchWorkspaceState()
            .catch((throwable): never => {
                // Если при получении состояния рабочего места возникло исключение,
                // то данные авторизации считаются бракованными, и
                // необходимо выгрузить их из памяти приложения:
                $workspace
                    .setOrClearAuthCredentials(null);

                // После чего исключение должно быть обработано
                // в общем порядке:
                throw throwable;
            });

        if (null === workspaceStateRq.response) {
            $workspace
                .setOrClearAuthCredentials(null);

            // Все возможные сетевые ошибки должны быть преобразованы в исключения
            // внутри сервиса, потому значение "null" невозможно:
            throw new RuntimeException({
                code:     EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal:  true,
                message: 'Logic error. Is not possible the workspace data' +
                         ' to be empty at this point.'
            });
        }

        const storage: StorageModule = $container
            .get<StorageModule>(StorageModule.SONAR);

        // Если запрос на синхронизацию прошел успешно, то
        // текущие авторизационные данные корректны, и
        // их необходимо сохранить в долговременном хранилище:
        await storage.storeWorkspaceDataIntoDB(
            credentials
        ).catch((throwable): void => {
            // Без сохранения информации в IndexedDB данные авторизации
            // будут потеряны при перезагрузке браузера:
            $workspace
                .setOrClearAuthCredentials(null);

            // Ошибка записи в IndexedDB приведет
            // к аварийному завершению работы:
            throw throwable;
        });

        // После авторизации рабочего места,
        // инициализация должна быть запущена повторно:
        $workspace
            .changeSystemParameter(
                'isWorkspaceInitFinished',
                false
            );

        // Обработка данных рабочего места, и
        // загрузка их в приложение:
        await this.updateApplicationState(
            workspaceStateRq.response
        );

        return {
            isAuthorised: true,
            msg:          null
        };
    }

    /**
     * Запрос на авторизацию кассира на виртуальной кассе.
     *
     * В один момент времени на кассе может быть авторизован
     * только один кассир.
     *
     * Не допускается замена сессии одного кассира на сессию
     * любого другого кассира.
     *
     * @param login    - Логин кассира.
     * @param password - Пароль кассира.
     */
    public async cashierLogIn(
        login:    string,
        password: string
    ): Promise<void>
    {
        const {
            $workspace,
            $syncData,
        } = usePiniaState();

        if ($workspace.isCashierLoggedIn && !$workspace.systemParameter('isCashierSessionBroken'))
        {
            throw new RuntimeException({
                code:    EposCoreErrors.CASHIER_ALREADY_LOGGED_IN,
                isFatal: true,
                message: `There is already an authorized cashier` +
                         ` with login ${$workspace.cashierLogin} at workspace.`
            });
        }

        const {
            $container,
        } = useAppContainer();

        const cashierAuthRq = await ($container
            .get<EposService>(EposService.SONAR))
            .cashierLogIn(
                {
                    login: login,
                    password: password
                }
            );

        if (!cashierAuthRq.isSuccess) {
            if (true === cashierAuthRq.response?.isAlreadyLoggedIn)
            {
                $workspace
                    .setWorkspaceCashier(new Cashier(
                        cashierAuthRq.response.cashier
                    ));

                return;
            }

            throw new RuntimeException({
                code:    EposCoreErrors.CASHIER_LOG_IN_ERROR,
                isFatal: false,
                message: cashierAuthRq.message
            });
        }

        $workspace
            .setWorkspaceCashier(new Cashier(
                cashierAuthRq.response?.cashier
            ));

        // В случае, если на кассе был активен флаг сломанной сессии кассира,
        // то необходимо с нуля пройти процесс инициализации:
        if ($workspace.systemParameter('isCashierSessionBroken'))
        {
            $syncData
                .submitOrClearException(null);

            $syncData
                .flushInitQueue();

            $workspace
                .changeSystemParameter(
                    'isWorkspaceInitFinished',
                    false
                );
        }

        $workspace
            .changeSystemParameter(
                'isCashierSessionExpired',
                false
            );

        $workspace
            .changeSystemParameter(
                'isCashierSessionBroken',
                false
            );
    }

    /**
     * Запрос на завершение сессии кассира на кассе.
     *
     * В случае успеха касса будет доступна
     * для авторизации другим кассиром.
     */
    public async cashierLogOut(): Promise<void>
    {
        const {
            $workspace,
            $shift,
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.LOGGED_IN_CASHIER_REQUIRED,
                isFatal: true,
                message: "There are no an authorized cashier at workspace."
            });
        }

        if ($shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.CASHIER_LOG_OUT_ATTEMPT_ON_ACTIVE_SHIFT,
                isFatal: true,
                message: "Impossible to sign out cashier\'s account when he has" +
                         " active working shift on current workspace."
            });
        }

        const {
            $container,
        } = useAppContainer();

        const cashierLogOffRq = await ($container
            .get<EposService>(EposService.SONAR))
            .cashierLogOut();

        if (!cashierLogOffRq.isSuccess) {
            if (true === cashierLogOffRq.response?.cashierSessionIsNotExists) {

                $workspace
                    .setWorkspaceCashier(null);

                return;
            }

            throw new RuntimeException({
                code:    EposCoreErrors.CASHIER_LOG_IN_ERROR,
                isFatal: false,
                message: cashierLogOffRq.message
            });
        }

        $workspace
            .setWorkspaceCashier(null);
    }

    /**
     * Запрос на открытие новой рабочей смены для аккаунта кассира,
     * авторизованного на кассе в настоящий момент.
     */
    public async openNewWorkingShift(): Promise<void>
    {
        const {
            $workspace,
            $shift,
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.LOGGED_IN_CASHIER_REQUIRED,
                isFatal: true,
                message: 'Logic error. Logged in cashier is required' +
                         ' to open new working shift.',
            });
        }

        if ($shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: `Logic error. Impossible to open new working shift` +
                         ` till workspace has active shift with id '${$shift.field('id')}'.`,
            });
        }

        const {
            $container,
        } = useAppContainer();

        const shiftOpenRq = await ($container
            .get<EposService>(EposService.SONAR))
            .openNewShift();

        const storage: StorageModule = $container
            .get<StorageModule>(StorageModule.SONAR);

        if (!shiftOpenRq.isSuccess) {
            if (true === shiftOpenRq.response?.alreadyOpened)
            {
                const workingShift = new WorkingShift(
                    shiftOpenRq.response?.shift ?? null
                );

                await storage
                    .storeWorkspaceDataIntoDB(workingShift);

                $shift
                    .setActiveShiftData(workingShift);

                return;
            }

            throw new RuntimeException({
                code:    EposCoreErrors.CASHIER_WORKING_SHIFT_OPEN_ERROR,
                isFatal: false,
                message: 'Failed to open new working shift. Unexpected error.'
            });
        }

        const workingShift = new WorkingShift(
            shiftOpenRq.response?.shift ?? null
        );

        await storage
            .storeWorkspaceDataIntoDB(workingShift);

        $shift
            .setActiveShiftData(workingShift);
    }

    /**
     * Запрос на закрытие текущей рабочей смены кассира.
     */
    public async closeActiveWorkingShift(): Promise<void>
    {
        const {
            $workspace,
            $shift,
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.LOGGED_IN_CASHIER_REQUIRED,
                isFatal: true,
                message: 'Logic error. Logged in cashier is required' +
                         ' to close active working shift.',
            });
        }

        if (!$shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: `Logic error. Impossible to close active working shift` +
                         ` because there are no active working shift on current workspace.`,
            });
        }

        const {
            $container,
        } = useAppContainer();

        const shiftCloseRq = await ($container
            .get<EposService>(EposService.SONAR))
            .closeActiveShift();

        const storage: StorageModule = $container
            .get<StorageModule>(StorageModule.SONAR);

        if (!shiftCloseRq.isSuccess) {
            if (true === shiftCloseRq.response?.alreadyClosed)
            {
                $shift
                    .setActiveShiftData(null);

                await storage
                    .removeWorkspaceDataFromDB(new WorkingShift());

                return;
            }

            throw new RuntimeException({
                code:    EposCoreErrors.CASHIER_WORKING_SHIFT_CLOSE_ERROR,
                isFatal: false,
                message: 'Failed to close active working shift. Unexpected error.'
            });
        }

        await storage
            .removeWorkspaceDataFromDB(new WorkingShift());

        $shift
            .setActiveShiftData(null);
    }

    /**
     * Очищает из браузера, и памяти приложения, все данные
     * связанные с текущим рабочим местом и виртуальной кассой.
     */
    public async flushWorkspaceAuthorization(): Promise<void>
    {
        const {
            $workspace,
            $shift,
            $feed,
        } = usePiniaState();

        const {
            $container,
        } = useAppContainer();

        const {
            $SUPERVISOR
        } = useSupervisor();

        if ($SUPERVISOR.isIntervalActive())
        {
            $SUPERVISOR
                .pause();
        }

        const storage: StorageModule = $container
            .get<StorageModule>(StorageModule.SONAR);

        $workspace
            .setOrClearAuthCredentials(null);

        $workspace
            .setWorkspaceCashier(null);

        $shift
            .setActiveShiftData(null);

        $feed
            .flushFeedData();

        await storage
            .removeWorkspaceDataFromDB(new WorkingShift());

        await storage
            .removeWorkspaceDataFromDB(new Cashier());

        await storage
            .removeWorkspaceDataFromDB(new Credentials());

        await storage
            .removeWorkspaceDataFromDB(new Settings());

        if ($SUPERVISOR.isIntervalActive())
        {
            $SUPERVISOR
                .pause();
        }
    }

    /**
     * Загрузка в память приложение текстов интерфейса,
     * соответствующих установленной локали.
     *
     * Сначала производится поиск текстов, уже хранящихся
     * в долговременном хранилище, и их проверка на актуальность.
     *
     * Если текстов не было, либо они устарели, то запрашивается
     * текущий актуальный набор текстов.
     *
     * В случае успешной проверки кеша на актуальность, либо же в случае
     * успешного получения новых текстов, в IoC контейнер
     * загружается функционал по работе с текстами.
     */
    public async loadTextsLibraryIntoApplication(): Promise<void>
    {
        const {
            $container,
        } = useAppContainer();

        const {
            $app,
        } = usePiniaState();

        const storage: StorageModule = $container
            .get<StorageModule>(StorageModule.SONAR);

        const service: EposService = ($container
            .get<EposService>(EposService.SONAR));

        // Поиск наличия текстов приложения
        // в долговременном хранилище:
        const storedLocaleTexts: TextsLibraryResponse|null = await storage
            .extractLocaleData<TextsLibraryResponse>(
                $app.locale
            );

        // Если тексты приложения найдены, и они прошли проверку на актуальность,
        // то их необходимо загрузить в IoC контейнер:
        if (
            null !== storedLocaleTexts &&
            await service.isTextsLibraryRelevant(
                storedLocaleTexts.locale,
                storedLocaleTexts.uuid
            )
        )
        {
            $container
                .rebind<Library>(Library.SONAR)
                .toConstantValue(
                    new Library(
                        storedLocaleTexts.uuid,
                        storedLocaleTexts.locale,
                        storedLocaleTexts.lib
                    )
                );

            return;
        }

        // Получение актуальных текстов приложения для
        // текущей используемой локали:
        const receivedLocaleTexts: TextsLibraryResponse = await ($container
            .get<EposService>(EposService.SONAR))
            .fetchTextsLibraryForLocale($app.locale)
            .catch((throwable): never => {
                // Невозможность получить тексты приложения,
                // при пустом кеше, является фатальной ошибкой:
                throw new RuntimeException({
                    code:     EposCoreErrors.TEXTS_LIBRARY_SYNC_ERROR,
                    isFatal:  true,
                    previous: throwable,
                    message:  `Failed to receive application texts on initialization stage` +
                              ` for locale: '${$app.locale}'.`,
                });
            });

        // Запись обновленных текстов
        // в долговременное хранилище:
        await storage
            .storeLocaleData(
                $app.locale,
                receivedLocaleTexts
            );

        // Загрузка функционала по работе с текстом
        // в IoC контейнер:
        $container
            .rebind<Library>(Library.SONAR)
            .toConstantValue(
                new Library(
                    receivedLocaleTexts.uuid,
                    receivedLocaleTexts.locale,
                    receivedLocaleTexts.lib
                )
            );
    }

    /**
     * Начало инициализации интерфейса, и попытка восстановить
     * его последнее рабочее состояние, сохраненное в кеше.
     *
     * ВНИМАНИЕ!
     * Не все необходимые параметры сохраняются в долговременный кеш,
     * потому сразу за инициализацией обязательно должен следовать
     * запрос на синхронизацию.
     */
    public async checkWorkspaceStateOnInit(): Promise<void>
    {
        const {
            $container,
        } = useAppContainer();

        const {
            $workspace,
            $shift,
        } = usePiniaState();

        const storage: StorageModule = $container
            .get<StorageModule>(StorageModule.SONAR);

        // Проверка наличия связи текущего рабочего места с виртуальной кассой
        // производится через поиск данных модели, хранящей параметры связи:
        const credentials: Credentials|null = await storage
            .getWorkspaceDataFromDB<Credentials>(
                new Credentials()
            );

        if (null === credentials) {
            // Если для модели "Credentials" нет сохраненных данных,
            // то рабочее место не авторизовано:
            return;
        }

        // С момента загрузки этой модели в память приложения
        // касса считается авторизованной (для логики интерфейса):
        $workspace
            .setOrClearAuthCredentials(
                credentials
            );

        // Поиск и загрузка настроек рабочего места:
        const settings: Settings|null = await storage
            .getWorkspaceDataFromDB<Settings>(new Settings());

        if (null !== settings) {
            await $workspace
                .updateEposGlobalSettings(settings);
        }

        // Поиск и загрузка данных авторизованного кассира:
        $workspace
            .setWorkspaceCashier(
                await storage
                    .getWorkspaceDataFromDB<Cashier>(new Cashier())
            );

        // Поиск и загрузка данных рабочей смены кассира:
        $shift
            .setActiveShiftData(
                await storage
                    .getWorkspaceDataFromDB<WorkingShift>(new WorkingShift())
            );
    }

    /**
     * Настройка параметров приложения на основе данных,
     * пришедших вместе с HTML каркасом приложения.
     *
     * @param payload - Содержимое инициализирующей константы.
     */
    public setupCurrentWorkspace(
        payload: EposTypes.InterfaceBootstrapResponse
    ): void
    {
        const {
            $app,
            $throttling,
        } = usePiniaState();

        // Беглая проверка структуры данных:
        if ('object' !== typeof payload || null === payload) {
            throw new RuntimeException({
                isFatal:  true,
                code:     EposCoreErrors.WORKSPACE_INIT_CONSTANT_CORRUPTED,
                message: `Initialization constant have incorrect data type '${typeof payload}'`,
            });
        }

        // ВНИМАНИЕ!
        // Версия приложения должна задаваться только при инициализации,
        // а далее только сверяться с ответом сервера, для обнаружения обновлений:
        $app.changeParameter(
            'appVersion',
            payload.clientVersion
        );

        $app.changeParameter(
            'initHttpCode',
            payload.httpCode
        );

        // =>
        // Проверка корректности параметра, и установка
        // идентификатора оболочки интерфейса:

        if (![
            AppInterfaceType.EPOS_DESKTOP.valueOf(),
            AppInterfaceType.EPOS_MOBILE.valueOf(),
        ].includes(payload.interface))
        {
            throw new RuntimeException({
                isFatal: true,
                code:    EposCoreErrors.UNKNOWN_INTERFACE_TYPE_DETECTED,
                message: `Impossible to bootstrap interface with type '${payload.interface}'.`
            });
        }

        $app.changeParameter(
            'interface',
            payload.interface
        );

        // =>
        // Проверка корректности параметра, и установка
        // идентификатора языковой локали интерфейса:

        if (![
            AppLocale.ru_RU.valueOf(),
            AppLocale.en_GB.valueOf(),
            AppLocale.fr_FR.valueOf(),
            AppLocale.ar_AE.valueOf(),
            AppLocale.bn_BD.valueOf(),
            AppLocale.so_SO.valueOf(),
            AppLocale.uz_UZ.valueOf(),
        ].includes(payload.locale))
        {
            throw new RuntimeException({
                isFatal: true,
                code:    EposCoreErrors.UNKNOWN_APP_LOCALE_DETECTED,
                message: `Texts locale id '${payload.locale}' is not supported.`
            });
        }

        $app.changeParameter(
            'locale',
            payload.locale
        );

        // =>
        // Установка идентификатора цветовой схемы интерфейса, и
        // наличия возможности переключения схемы в "ночной" режим:

        $app.changeParameter(
            'pallet',
            payload.pallet
        );

        $app.changeParameter(
            'hasNightMode',
            true === payload.hasNightMode
        );

        if (payload.hasNightMode)
        {
            // Попытка восстановить ранее выбранный дневной/ночной режим интерфейса:
            $app.switchInterfaceNightMode(
                '1' === LocalStorage.getValue('night-mode')
            );
        }

        else
        {
            // Т.к. скин не поддерживает ночной режим, то
            // для чисто переменную из локального хранилища стоит удалить:
            LocalStorage
                .removeValue('night-mode');
        }

        // =>
        // Установка UUID идентификатора экземпляра приложения,
        // работающего в текущем браузере.
        //
        // (Используется для предотвращения запуска нескольких экземпляров приложения
        // в нескольких окнах и/или вкладках одного и того же браузера.)

        $app.changeParameter(
            'instanceUUID',
            uuidv4(),
        );

        LocalStorage.setValue(
            'app-uuid',
            $app.parameters('instanceUUID') ?? '',
        );

        // Проверка наличия ключа, для доступа
        // к функционалу Google ReCaptcha:
        if ('string' === typeof payload.reCaptchaSiteKey)
        {
            $throttling
                .setOrRemoveReCaptchaKey(payload.reCaptchaSiteKey);
        }

        // =>
        // TODO: проверка наличия режима техобслуживания
    }

    /**
     * Обработка ответа от API сервиса, возвращающего данные
     * текущего состояния виртуальной кассы.
     *
     * Синхронизирует состояние рабочего места с виртуальной кассой.
     */
    private async updateApplicationState(
        state: WorkspaceStateData
    ): Promise<void>
    {
        const {
            $container,
        } = useAppContainer();

        const {
            $workspace,
            $shift,
        } = usePiniaState();

        // Обновление маршрутов, доступных приложению, т.к. их список
        // получается сервисом через IoC контейнер:
        $container
            .rebind<RoutesPool>(RoutesPool.SONAR)
            .toConstantValue(new RoutesPool(state.paths));

        // Загрузка списка доступных модулей:
        $workspace
            .updateAllowedModulesList(
                state?.activeModules ?? []
            );

        if (true === state.hasStaleData) {
            // TODO: доделать
            // заменять данные только если они менее актуальны, чем то что хранится на рабочем месте
        }

        // Модель системных настроек кассы:
        const storage: StorageModule = $container
            .get<StorageModule>(StorageModule.SONAR);

        const settings: Settings = new Settings(
            state?.settings ?? {}
        );

        // Сохранение настроек кассы в долговременном хранилище:
        await storage
            .storeWorkspaceDataIntoDB(
                settings
            );

        // Загрузка настроек в приложение:
        await $workspace
            .updateEposGlobalSettings(
                settings
            );

        // Если на кассе отсутствует авторизованный кассир,
        // то необходимо очистить данные, связанные с этой моделью:
        if (null === state?.cashier || 'object' !== typeof state?.cashier)
        {
            await storage
                .removeWorkspaceDataFromDB(
                    new Cashier()
                );

            $workspace
                .setWorkspaceCashier(null);
        }

        // Иначе данные авторизованного кассира сохраняются
        // в долговременное хранилище:
        else
        {
            const cashier = new Cashier(
                state.cashier
            );

            await storage
                .storeWorkspaceDataIntoDB(cashier);

            $workspace
                .setWorkspaceCashier(cashier);

            if (true === state.cashier?.isAuthBroken)
            {
                $workspace
                    .changeSystemParameter(
                        'isCashierSessionBroken',
                        true
                    );
            }
        }

        // Если на кассе нет открытой рабочей смены для текущего авторизованного кассира,
        // то необходимо очистить данные, связанные с этой моделью:
        if ((null === state?.shift || 'object' !== typeof state?.shift))
        {
            await storage
                .removeWorkspaceDataFromDB(
                    new WorkingShift()
                );

            $shift
                .setActiveShiftData(null);
        }
            // Иначе данные открытой рабочей смены сохраняются
        // в долговременное хранилище:
        else
        {
            const shiftData = new WorkingShift(
                state?.shift
            );

            await storage
                .storeWorkspaceDataIntoDB(shiftData);

            $shift
                .setActiveShiftData(shiftData);
        }
    }
}