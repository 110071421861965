export enum AppCoreErrors {
    VUE_RUNTIME_ERROR   = 91101,
    VUE_RUNTIME_WARNING = 91102,
    GLOBAL_JS_ERROR     = 91103,

    PROVIDER_REGISTERED_AFTER_SETUP_IS_FINISHED = 91104,
    FAILED_TO_CREATE_VUE_APPLICATION            = 91105,
    UNEXPECTED_APP_RENDER_ERROR                 = 91106,

    INCORRECT_PROVIDER_SHAPE        = 91107,
    DUPLICATE_PROVIDER_REGISTRATION = 91108,
    INCORRECT_ASYNC_PROVIDER        = 91109,
    PROVIDER_HANDLE_ERROR           = 91110,
    ASYNC_PROVIDER_HANDLE_ERROR     = 91111,

    APP_UNEXPECTED_ERROR = 91112,
    SUPERVISOR_ERROR     = 91113,
}