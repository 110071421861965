/**
 * Список шагов интерфейса по настройкам
 * проводимой ставки.
 */
export enum BetSettingsStep
{
    StandByMode      = 0,
    SetBetOwner      = 1,
    SetPaymentMethod = 2,
    SetStakeAmount   = 3,
    BetOverview      = 4,
}