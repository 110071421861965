<template>
  <div :class="'overlay--is-' + $app.parameters('interface')" class="overlay overlay--theme-light">
    <div class="overlay__modal modal">
      <!-- (Не используется) Кнопка-крестик, закрывающая модальное окно: -->
      <div v-if="false" class="modal__header">
        <button v-if="showCloseBtn" class="modal__close modal-close">
          <span class="modal-close__container">
            <SvgElem
              sprite="cleared"
              to="close"
              size="size-l"
              class="modal-close__icon"
            />
          </span>
        </button>
      </div>
      <!-- Содержимое: -->
      <div class="modal__content">
        <!-- Иконка модального окна -->
        <svg-elem
          sprite="cleared"
          class="modal__icon"
          :to="ico"
          :size="icoSize"
          :class="severityList[severity]"
        />
        <!-- Блок с текстами и кнопками: -->
        <div class="modal__info">
          <!-- Заголовок модального окна: -->
          <h2 class="modal__title">{{ title }}</h2>
          <!-- Основной текст модального окна: -->
          <p v-if="topIndents" v-for="indent in topIndents" class="modal__description">{{ indent }}</p>
          <!-- Дополнительный текст модального окна, отображающийся после разделителя. -->
          <template v-if="bottomIndents">
            <div class="modal__divider"></div>
            <p v-for="indent in bottomIndents" class="modal__description">{{ indent }}</p>
          </template>
          <!-- Блок с кнопками: -->
          <div v-if="mainBtn || secondBtn" class="modal__buttons">
            <Button
              v-if="mainBtn"
              @click="mainBtn.callable()"
              :text="mainBtn.text"
              :label-style="'uppercase'"
              :is-processing="mainBtn.isProcessing"
              :is-disabled="mainBtn.isProcessing"
              class="modal__button no-select"
            />
            <Button
              v-if="secondBtn"
              @click="secondBtn.callable()"
              :text="secondBtn.text"
              :is-processing="secondBtn.isProcessing"
              :is-disabled="secondBtn.isProcessing"
              :theme="'outlined-primary'"
              theme-style="outlined"
              :label-style="'uppercase'"
              class="modal__button no-select"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~scss-shared/components/modal';
</style>

<script lang="ts">
const CMP_NAME = 'Modal';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {defineProps, withDefaults, computed}
    from "@vue/runtime-core";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import {ModalBtn}
    from "~app/Storage/contracts/Modal";

/** IMPORTED VUE TEMPLATES: */
import {SvgList}
    from "~tpl-global/svg/SvgList";
import {SvgSize}
    from "~tpl-global/svg/SvgSize";
import SvgElem
    from "~tpl-global/svg/SvgElem.vue";
import Button
    from "~tpl-global/Buttons/ButtonDefault/Button.vue";

/** COMPONENT LOGIC: */
const {
    $app,
} = usePiniaState();

const severityList = {
  warning: 'modal__icon--is-alert',
  success: 'modal__icon--is-success',
};

type ComponentProps = {
    severity?: keyof (typeof severityList),

    ico?:     SvgList,
    icoSize?: SvgSize,

    title:             string,
    message:           string | string[],
    topIndentsAmount?: number;

    showCloseBtn?: boolean,
    mainBtn?:      ModalBtn | null,
    secondBtn?:    ModalBtn | null,
}

const props = withDefaults(defineProps<ComponentProps>(), {
    severity: 'warning',

    ico:     'warning-round',
    icoSize: 'size-custom-80',

    topIndentsAmount: 1,

    showCloseBtn: false,
    mainBtn:      null,
    secondBtn:    null,
});

/**
 * Список абзацев, которые будут отображаться
 * в верхней части теста модального окна.
 *
 * Если нет ни одного абзаца, то
 * отображение элемента должно отключаться.
 */
const topIndents = computed<string[]|null>(() => {

    const indents: string[] = [];
    const parts:   string[] = (Array.isArray(props.message))
        ? props.message
        : props.message.split('{-}');

    let counter = 1;

    for (const msg of parts) {
        if (counter > props.topIndentsAmount) {
            break;
        }

        indents.push(msg.trim());
        counter++;
    }

    return (indents.length > 0)
        ? indents
        : null;
});

/**
 * Список абзацев, которые должны отображаться
 * в нижней части текста модального окна,
 * после разделительной черты.
 *
 * Если нет ни одного абзаца, то
 * отображение элемента должно отключаться.
 */
const bottomIndents = computed<string[]|null>(() => {

    const indents: string[] = [];
    const parts:   string[] = (Array.isArray(props.message))
        ? props.message
        : props.message.split('{-}');

    let counter = 1;

    for (const msg of parts) {
        if (counter <= props.topIndentsAmount) {
            counter++;
            continue;
        }

        indents.push(msg.trim());
        counter++;
    }

    return (indents.length > 0)
        ? indents
        : null;
});
</script>