<template>
  <div class="workplace-main__inner">
    <guest-window-ico
      ico="warning-triangle"
      sprite="cleared"
      size="size-custom-95"
      :is-flicker-allowed="false"
    />
    <h1 class="workplace-main__title">{{ $T.text('win-auth-clear-header') }}</h1>
    <div class="workplace-main__description">
      <p
        v-for="textPart in $T.composite<string[]>('win-auth-clear-desc')"
        class="workplace-main__text"
      >{{textPart}}</p>
    </div>
    <div class="workplace-main__buttons">
      <Button
        @click="$workspaceModule.abortInterfaceAuthClearMode()"
        size="size-l"
        theme-style="outlined"
        theme="outlined-primary"
        ico="arrow--left"
        ico-only
        ico-size="size-xs"
        is-mirrored-icon
        class="workplace-main__button workplace-main__button--small"
      />
      <Button
        @click="$workspaceModule.submitWorkspaceAuthClear()"
        size="size-l"
        label-style="uppercase"
        class="workplace-main__button"
        :text="$T.text('btn-clear-workspace-auth')"
      />
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'ClearWorkspaceAuth.window';
export default {
    name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import useWorkspaceModule
    from "~app/IoC/useWorkspaceModule";

/** IMPORTED VUE TEMPLATES: */
import Button
    from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $workspaceModule,
} = useWorkspaceModule();
</script>