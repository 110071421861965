<template>
  <div :class="'location--is-' + type" class="location">
    <SvgElem
        sprite="cleared"
        to="icon-nav"
        class="location__icon"
    />
    <div class="location__info">
      <span class="location__name">{{ $workspace.workspaceName ?? '-' }}</span>
      <div class="location__date is-no-mirrored">
        <span class="location__day">{{ (null === $app.clock) ? 'XXX' : $app.clock?.day }}</span>
        <span class="location__time">{{ (null === $app.clock) ? '--:--' : $app.clock?.time }}</span>
        <span class="location__timezone">({{$workspace.timezone}})</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'Location';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
    from "~tpl-global/svg/SvgElem.vue";
import {labelSize, labelsList} from "~tpl-global/Labels/LabelsTypes";

/** COMPONENT LOGIC: */
const {
    $app,
    $workspace,
} = usePiniaState();

type LocationType = 'desktop' | 'mobile'

type ComponentProps = {
  type: LocationType
};

const props = withDefaults(defineProps<ComponentProps>(), {

});
</script>

<style lang="scss" scoped>
  @import '~scss-shared/components/location';
</style>