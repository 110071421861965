<template>
  <div class="session-control">
    <h3 class="session-control__title">{{ $T.text('shift-control-title') }}</h3>
    <Location type="desktop" class="session-control__location" />
    <div class="session-control__content">
      <transition name="fade-in-out">
        <ul class="session-control__points session-control-points">
          <li v-for="item in eposInfoList" :key="item.value.id" class="session-control-points__item">
            <div class="session-control-points__info session-control-info">
              <svg-elem sprite="cleared" :to="item.value.ico" class="session-control-info__icon" />
              <div class="session-control-info__item">
                <span :class="{ 'is-no-mirrored': item.value.id === 'shift-opened-at' }" class="session-control-info__title">{{ (item.value.isActive) ? item.value.info : '—'}}</span>
                <span class="session-control-info__subtitle">
                <span class="session-control-info__label">{{ item.value.description }}</span>
                  <!-- tooltip="{pos: 'is-bottom', label: 'The cashier works without time limit.'}"  -->
              </span>
              </div>
            </div>
          </li>
        </ul>
      </transition>
      <on-hold-button
          @on-hold-button-passed="($shift.isActive) ? closeShift() : openShift()"
          :theme="($shift.isActive)
             ? 'orange'
             : 'green'"
          :text="($shift.isActive)
             ? $T.composite('shift-close-btn')
             : $T.text('shift-open-btn')"
          :size="'size-l'"
          :description="$T.text('held-button-default-description')"
          class="session-control__action"
      />
    </div>
    <transition-expand>
      <Button
          v-if="$shift.isActive && !$shift.isDurationExceed24Hours"
          @click="$app.changeActiveWindowId('shift')"
          theme-style="outlined"
          :theme="'outlined-primary'"
          :label-style="'uppercase'"
          :text="$T.text('main-menu-shift-overview')"
          class="session-control__button no-select"
      />
    </transition-expand>
      <div class="session-control__footer session-control-footer">
        <p v-if="!$shift.isActive" class="session-control-footer__text">
          {{$T.text('shift-info-type-1')}}
        </p>
        <p v-else-if="$shift.isActive && $shift.isDurationExceed24Hours" class="session-control-footer__text">
          {{$T.text('shift-duration-too-long-info')}}
        </p>
        <p v-else class="session-control-footer__text">
          {{$T.text('shift-info-type-2')}}
        </p>
      </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '~scss-desktop/common/components/top-bar/session-control';
</style>

<script lang="ts">
const CMP_NAME = 'SessionControl';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useShiftControl
    from "~interaction/Shift/useShiftControl";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
    from "~tpl-global/svg/SvgElem.vue";
import OnHoldButton
    from "~tpl-global/Buttons/OnHoldButton/OnHoldButton.vue";
import Button
    from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import Location
    from "~tpl-global/Location/Location.vue";
import TransitionExpand
    from "~tpl-global/TransitionExpand.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $app,
    $workspace,
    $shift,
    $modal,
} = usePiniaState();

const {
    eposInfoList,
    eposWorkingHoursLine,

    openShift,
    closeShift,
} = useShiftControl();

</script>