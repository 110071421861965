import {Model}
    from "~lib/eloquent/Model";
import {CustomerParameters}
    from "~app/Customer/Contracts/Customer.types";

export const ModelName = Symbol
    .for('CustomerModel');

export default class Customer extends Model<CustomerParameters> {

    public static NAME_FORMAT_NSM = 1;
    public static NAME_FORMAT_NMS = 2;
    public static NAME_FORMAT_SNM = 3;

    /**
     * Временный токен гостя, используемый для
     * запросов к сервисам, связанных с управлением
     * либо обслуживанием аккаунтов гостей.
     */
    protected token: string | null = null;

    /**
     * Идентификатор гостя.
     */
    protected id: number = 0;

    /**
     * Является ли аккаунт временным/фейковым.
     */
    protected isFake: boolean = false;

    /**
     * Идентификатор принадлежности гостя.
     */
    protected refId: number = 0;

    /**
     * Подписал ли гость наше соглашение
     * об обслуживании.
     */
    protected isAgreementSigned: boolean = false;

    /**
     * Текущее состояние верификации аккаунта
     *
     * 0 - Не проводилась
     * 1 - Верификация пройдена
     * 2 - Запрос документов
     * 3 - Верификация данных
     * 4 - Видеоконференция
     * 5 - Запрос карты
     * 6 - Анкета
     * 7 - Проверка документов партнером
     * 8 - Авто-запрос доков по сроку давности
     */
    protected verificationStatus: number = 0;

    /**
     * Дата регистрации аккаунта
     * (timestamp)
     */
    protected regDate: number = 0;

    /**
     * Если "null" - аккаунт активен,
     * иначе будет содержать дату блокировки.
     * (timestamp)
     */
    protected banDate: number | null = null;

    /**
     * Номер клубной карты гостя.
     */
    protected clubCardNumber: string | null = null;

    /**
     * Идентификатор валюты кошелька, привязанного к гостю.
     */
    protected walletCurrencyId: number | null = null;

    /**
     * Баланс аккаунта гостя, в его оригинальной валюте.
     */
    protected originWalletAmount: number = 0.00;

    /**
     * Баланс аккаунта гостя, конвертированный
     * в валюту кассы, по внутреннему курсу.
     */
    protected eposWalletAmount: number = 0.00;

    /**
     * Имя гостя
     */
    protected name: string | null = null;

    /**
     * Фамилия гостя
     */
    protected surname: string | null = null;

    /**
     * Отчество/второе имя гостя
     */
    protected middleName: string | null = null;

    /**
     * Дата рождения гостя.
     * (timestamp)
     */
    protected dateOfBirth: number | null = null;

    /**
     * Пол гостя.
     *
     * 0 - мужской
     * 1 - женский
     */
    protected gender: number = 0;

    /**
     * Номер телефона, привязанный к аккаунту.
     * (строка т.к. некоторые номера начинаются с нуля)
     */
    protected phoneNumber: string | null = null;

    /**
     * Электронный почтовый ящик игрока.
     */
    protected email: string | null = null;

    /**
     * Тип документа, которым игрок подтверждал
     * свою личность.
     *
     * 1 Паспорт гражданина СССР
     * 2 Загранпаспорт гражданина СССР
     * 3 Свидетельство о рождении
     * 4 Удостоверение личности офицера
     * 5 Справка об освобождении из  места лишения свободы
     * 6 Паспорт Минморфлота
     * 7 Военный билет солдата (матроса, сержанта, старшины)
     * 8 Временное удостоверение, выданное взамен военного билета
     * 9 Дипломатический паспорт гражданина Российской Федерации
     * 10 Паспорт иностранного гражданина
     * 11 Свидетельство о рассмотрении ходатайства о признании беженцем на территории Российской Федерации по
     * 12 Вид на жительство
     * 13 Удостоверение беженца в Российской Федерации
     * 14 Временное удостоверение личности гражданина Российской Федерации
     * 15 Разрешение на временное проживание в Российской Федерации
     * 16 Паспорт гражданина Республики Беларусь
     * 18 Свидетельство о предоставлении временного убежища на территории Российской Федерации
     * 21 Паспорт гражданина Российской Федерации
     * 22 Загранпаспорт гражданина Российской Федерации
     * 23 Свидетельство о рождении, выданное уполномоченным органом иностранного государства
     * 24 Удостоверение личности военнослужащего Российской Федерации
     * 26 Паспорт моряка
     * 27 Военный билет офицера запаса
     * 29 Паспорт гражданина Республики Казахстан
     * 30 Удостоверение личности гражданина Азербайджанской Республики
     * 91 Иные документы
     * 103 Удостоверение личности
     * 104 Паспорт гражданина Украины
     * 105 National ID card
     * 107 ID for foreign nationals
     * 109 Водительское удостоверение
     * 110 Unified Citizen Number
     * 111 Паспорт гражданина Республики Таджикистан
     * 115 DNI
     * 116 NIE
     * 117 Нигерийский ID
     */
    protected documentType: number | null = null;

    /**
     * Серийный номер документа.
     */
    protected documentSerial: string | null = null;

    /**
     * Номер/идентификатор документа.
     */
    protected documentId: string | null = null;

    /**
     * Дата выдачи документа.
     */
    protected documentDateOfIssue: number | null = null;

    /**
     * Название ведомства, выдавшего документ.
     */
    protected documentIssuedBy: string | null = null;

    /**
     * Идентификатор страны, в которой выдан документ.
     */
    protected documentCountryId: number | null = null;

    /**
     * Название страны, в которой был выдан документ.
     */
    protected documentCountry: string | null = null;

    /**
     * Национальность владельца документа.
     */
    protected nationality: string | null = null;

    /**
     * Место рождения (по документам).
     */
    protected placeOfBirth: string | null = null;

    /**
     * Адрес постоянного места жительства.
     */
    protected permanentResidenceAddress: string | null = null;

    /**
     * Адрес временного проживания.
     */
    protected temporaryResidenceAddress: string | null = null;

    /**
     * @see Model.init
     *
     * @param feed
     */
    constructor(feed: Partial<CustomerParameters>|null = null)
    {
        super();

        this.init(
            feed
        );
    }

    /**
     * @inheritDoc
     */
    modelName(): symbol
    {
        return ModelName;
    }

    /**
     * Возвращает ФИО гостя в запрошенном формате.
     *
     * Варианты:
     * NAME_FORMAT_SNM - фамилия, имя отчество
     * NAME_FORMAT_NMS - имя, фамилия, отчество
     * NAME_FORMAT_NSM - имя, отчество, фамилия
     *
     * @param format - формат порядка слов в имени
     * @param short  - необходимо ли сокращение
     */
    getFullName(
        format: 1|2|3,
        short:  boolean = false,
    ): string
    {
        const chooseField = (n: number): string => {
            switch (n) {
                default:
                    return 'name';
                case 2:
                    return 'surname';
                case 3:
                    return 'middleName';
            }
        };

        type Formats = {[key: number]: number[]};

        const formats: Formats = {
            [Customer.NAME_FORMAT_SNM]: [2, 1, 3],
            [Customer.NAME_FORMAT_NMS]: [1, 3, 2],
            [Customer.NAME_FORMAT_NSM]: [1, 2, 3],
        };

        const data: string[] = [];

        let counter: number  = 1;

        for (const val of formats[format] ?? formats[Customer.NAME_FORMAT_NMS])
        {
            const field: 'name'| 'surname' | 'middleName' | null = this.get(
                chooseField(val) as unknown as 'name'| 'surname' | 'middleName'
            );

            if (null === field || !field || 0 === field.length)
            {
                counter++;

                continue;
            }

            data.push(
                short && counter > 1
                    ? field.substring(0, 1) + '.'
                    : field
            );

            counter++;
        }

        return data
            .join(' ');
    }
}
