<template>
  <div
      :class="[
          { 'switcher--is-toggled': isActive }
      ]"
      class="switcher"
  >
    <div
      @click="emitter('radioSwitcherTriggered')"
      class="switcher__slider switcher-slider"
    >
      <div class="switcher-slider__indicator"></div>
    </div>
    <span v-if="'string' === typeof label" class="switcher__label">{{ label }}</span>
  </div>
</template>

<style lang="scss">
  @import '~scss-desktop/common/components/_switcher';
</style>

<script lang="ts">
const CMP_NAME = 'Switcher';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {defineProps}
    from "@vue/runtime-core";
import {ComponentProps, RadioSwitcherEvents}
    from "~interaction/useRadioSwitcher";

/** IMPORTED VUE TEMPLATES: */

/** COMPONENT LOGIC: */
const props = withDefaults(
    defineProps<ComponentProps>(),
    {}
);

const emitter = defineEmits<RadioSwitcherEvents>();
</script>
