import Exception
    from "~lib/Exception/Exception";

type AppRenderExceptionConstructor = {
    code:      number;
    message:   string|null;
    previous?: Error;
};

/**
 * Исключение, выбрасываемое при проблемах с
 * первичной отрисовкой каркаса приложения.
 *
 * ВНИМАНИЕ!
 * Исключение является фатальным и всегда приводит к
 * аварийному завершению работы приложения.
 */
export default class AppRenderException
    extends    Exception
    implements AppRenderExceptionConstructor {

    constructor(
        payload: AppRenderExceptionConstructor
    ) {
        super({
            isFatal:  true,
            code:     payload.code,
            type:     'Exception',
            name:     'AppRenderException',
            label:    'APP-RENDERING',
            message:  payload.message,
            previous: payload.previous
        });
    }
}