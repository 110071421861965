import {Model}
    from "~lib/eloquent/Model";
import {WorkspaceAuthCredentials}
    from "~app/Epos/Contracts/Epos.types";

export const ModelName = Symbol
    .for('CredentialsModel');

/**
 * Модель, отвечающая за хранение в браузере данных,
 * отвечающих за связь рабочего места кассира с виртуальной кассой.
 */
export default class Credentials
    extends Model<WorkspaceAuthCredentials> {

    /**
     * Ключ, связывающей рабочее место кассира с виртуальной кассой.
     */
    protected anchor: string|null = null;

    /**
     * @see Model.init
     *
     * @param feed
     */
    constructor(
        feed: Partial<WorkspaceAuthCredentials>|null = null
    )
    {
        super();

        this.init(
            feed
        );
    }

    /**
     * @inheritDoc
     */
    public modelName(): symbol
    {
        return ModelName;
    }
}