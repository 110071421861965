/**
 * Возможные уточняющие типы транзакций.
 *
 * 0 - Нет уточняющего типа.
 * 1 - Отмененный депозит.
 * 2 - Возвращенный в полном объеме депозит.
 */
export enum TransactionClarificationType {
    DEFAULT           = 0,
    CANCELLED_DEPOSIT = 1,
    REFUNDED_DEPOSIT  = 2,
}