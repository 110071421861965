import { encode, EncodeOptions } from "@msgpack/msgpack";

export default class MsgpackEncoder {

    /**
     * TODO: описание
     *
     * @param payload
     * @param options
     */
    public static encode<PayloadType = any, ContextType = undefined>(
        payload:  PayloadType,
        options?: any
    ): Uint8Array
    {
        return encode<ContextType>(payload, options);
    };
}