import {defineAsyncComponent}
    from "@vue/runtime-core";
import {VueComponentType}
    from "~lib/render/VueComponent";
import LocalStorage
    from "~lib/LocalStorage";
import WindowManagerException
    from "~lib/windows/exceptions/WindowManager.exception";

export type WindowsSetup<Data extends object = {}> = {[key: string]: {vue:  () => Promise<VueComponentType>, data: Data, tag?: string}};
export type AvailableWindows<Data> = {[key: string]: {vue: VueComponentType, data: Data}}
export type DefaultWindow = keyof WindowsSetup;
export type WindowMeta<Data> = {window: string, data: Data, tag: string};
export type TagsList<Data> = {[key: string]: WindowMeta<Data>};

export default class WindowsManager<PD extends object = {}> {
    /**
     * Компонент, который будет отображаться, пока
     * загружается и инициализируется компонент страницы.
     */
    private readonly preloaderWindow: VueComponentType;

    /**
     * Компонент, который будет отображаться в случае,
     * если загрузка компонента завершится неудачей
     * по какой-либо причине.
     */
    private readonly errorWindow: VueComponentType;

    /**
     * Название переменной для хранения в локальном хранилище
     * сведений об активной странице.
     */
    private readonly localStorageVarName: string;

    /**
     * Доступно ли использование локального хранилища браузера.
     */
    private readonly isLocalStorageAvailable: boolean;

    /**
     * Список страниц, загруженных в маршрутизатор.
     */
    private readonly windows: AvailableWindows<PD>;

    /**
     * Активная по-умолчанию страница.
     * (опционально)
     */
    private readonly defaultWindow: DefaultWindow|null;

    /**
     * Максимальное время, в течение которого будет
     * ожидаться загрузка компонента.
     */
    private readonly maxWaitTimeSec: number;

    /**
     * Привязка группы окон к заданному тегу.
     */
    private readonly tags: {[key: string]: string[]};

    /**
     * @param payload
     */
    constructor(payload: {
        preloaderWindow:      VueComponentType,
        errorWindow:          VueComponentType,
        localStorageVarName?: string|undefined,
        maxWaitTimeSec?:      number|undefined,
        windows:              WindowsSetup<PD>,
        defaultWindow?:       DefaultWindow|null,

    })
    {
        this.preloaderWindow     = payload.preloaderWindow;
        this.errorWindow         = payload.errorWindow;
        this.localStorageVarName = payload.localStorageVarName ?? 'ROUTER-ACTIVE-PAGE';
        this.defaultWindow       = payload.defaultWindow       ?? null;
        this.maxWaitTimeSec      = payload.maxWaitTimeSec      ?? 10

        const bootstrap = this.bootstrap(payload.windows);

        this.windows = bootstrap.windows;
        this.tags    = bootstrap.tags;

        this.isLocalStorageAvailable = LocalStorage.isAvailable();

        if (!this.isLocalStorageAvailable) {
            console.warn(
                'Page remember functional is disabled because local storage is not available.'
            );
        }
    }

    /**
     * Проверка существования запрошенного окна.
     */
    public isWindowExists(key: string): boolean
    {
        return 'undefined' !== typeof this.windows[key];
    }

    /**
     * Помечает окно интерфейса, соответствующее
     * переданному ключу, как активное.
     *
     * @param key
     */
    public activateWindow(key: string): VueComponentType
    {
        if ('undefined' === typeof this.windows[key]) {
            throw new WindowManagerException({
                windowKey: key,
                isFatal:   false, //TODO: вернуть true после правки бага с логикой
                label:     'WINDOW-CHANGE',
                message:   `Window change is not possible because key "${key}" is not exists.`,
            });
        }

        if (this.isLocalStorageAvailable) {
            LocalStorage.setValue(this.localStorageVarName, key);
        }

        return this.windows[key].vue;
    }

    /**
     * Возвращает идентификатор она, заданный
     * как значение по умолчанию.
     */
    public getDefaultWindow(): string|number|null
    {
        return this.defaultWindow;
    }

    /**
     * Возвращает идентификатор последнего запомненного окна.
     *
     * Если функционал запоминания не работает, либо если
     * еще не было активировано ни одно из окон - вернется "null".
     */
    public getRememberedPage(): string|number|null
    {
        if (!this.isLocalStorageAvailable) {
            return null;
        }

        return LocalStorage.getValue(
            this.localStorageVarName
        );
    }

    /**
     *
     * @param windowId
     */
    public getWindowMeta<Data>(
        windowId: string
    ): WindowMeta<Data>|null
    {
        if ('undefined' === typeof this.windows[windowId]) {
            return null;
        }

        const meta = this.windows[windowId];

        return {
            window: windowId,
            tag:    '',
            data:   meta.data as unknown as Data
        };
    }

    /**
     * Возвращает все окна приложения, привязанные
     * к запрошенному тегу.
     *
     * Если для запрошенного тега ничего
     * не найдено - будет возвращен "null".
     *
     * @param tag
     */
    public getTagInfo(
        tag: string
    ): TagsList<PD>|null
    {
        if ('undefined' === typeof this.tags[tag]) {
            return null;
        }

        const data: TagsList<PD> = {};

        for (const winId of this.tags[tag]) {
            data[winId] = {
                window: winId,
                data:   this.windows[winId].data,
                tag:    tag
            };
        }

        return data;
    }

    /**
     * Генерация асинхронных vue компонентов
     * для каждого из заданных окон.
     *
     * @param list
     */
    private bootstrap(
        list: WindowsSetup<PD>
    ): {
        windows: AvailableWindows<PD>,
        tags:    {[key: string]: string[]}
    }
    {
        const windows: AvailableWindows<PD>      = {};
        const tags:    {[key: string]: string[]} = {};

        for (const winId of Object.keys(list)) {
            windows[winId] = {
                data: list[winId].data,
                vue:  defineAsyncComponent({
                    loader:           list[winId].vue,
                    delay:            80,
                    timeout:          this.maxWaitTimeSec * 1000,
                    loadingComponent: this.preloaderWindow,
                    errorComponent:   this.errorWindow
                })
            };

            if ('string' === typeof list[winId]?.tag) {
                const tag: string = list[winId].tag as unknown as string;

                if ('object' !== typeof tags[tag]) {
                    tags[tag] = [];
                }

                tags[tag].push(winId);
            }
        }

        return {
            windows: windows,
            tags:    tags
        };
    }
}