<template>
  <!-- Если приложение аварийно завершило работу: -->
  <application-crash
    v-if="$app.isAppCrashed || $app.isAppStartFailed"
  />
  <!-- Если у приложение есть дубликат, работающий в том же браузере: -->
  <app-duplicated-instance
    v-else-if="$app.isAppHasDuplicatedInstance"
  />
  <!-- Авторизация рабочего места или окно входа для кассира: -->
  <guest-shell
    v-else-if="
      $app.isMaintenanceActive                ||
      !$app.isAppBootstrapFinished            ||
      !$workspace.isWorkspaceInitFinished     ||
      !$workspace.isWorkspaceAuthorized       ||
      $workspace.isWorkspaceAuthLost          ||
      $workspace.isWorkspaceSettingsIncorrect ||
      $workspace.isWorkspaceLocked            ||
      !$workspace.isWorkspaceInitFinished     ||
      !$workspace.isCashierLoggedIn           ||
      $workspace.isCashierSessionBroken
      "
  />
  <!-- Функционал авторизованного кассира: -->
  <workspace-shell v-else />
</template>

<script lang="ts">
import VueComponentType
  from "~lib/render/VueComponent";
const CMP_NAME = 'Epos';
export default {
  name: CMP_NAME,
} as VueComponentType
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import Clock
  from "~app/Other/Clock/Clock";

/** IMPORTED VUE TEMPLATES: */
import ApplicationCrash
  from "~tpl-desktop/guest/ApplicationCrash.vue";
import GuestShell
  from "~tpl-desktop/guest/GuestShell.vue";
import WorkspaceShell
  from "~tpl-desktop/workspace/WorkspaceShell.vue";
import AppDuplicatedInstance
    from "~tpl-desktop/guest/AppDuplicatedInstance.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $app,
    $workspace
} = usePiniaState();

/**
 * TODO: убрать в интервальное задание, и переделать логику часов
 *
 * Интервал для обновления отображения часов,
 * и продолжительности смены кассира.
 */
const clockInterval = setInterval(() => {
  if (!$app.isAppBootstrapFinished) {
    return;
  }

  if ($app.isAppCrashed) {
      return;
  }

  Clock.mainTimersHandler();
}, 400);
</script>
