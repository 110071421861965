import {Container}
    from "inversify";
import EposModule
    from "~app/Epos/Epos.module";

export default function (): {
    $eposModule: EposModule
} {
    return {
        /**
         * @var EposModule - Извлекает из IoC контейнера приложения модуль
         *                   для работы с данными и параметрами, отвечающими
         *                   за связь текущего рабочего места с виртуальной кассой.
         */
        $eposModule: (window as unknown as {$container: Container})
            .$container
            .get<EposModule>(EposModule.SONAR)
    };
}