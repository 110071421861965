import {FeedType}
    from "~app/Betting/Contracts/Feed.types";

/**
 * API запрос: "feed-pre-match-groups":
 */
export type Request = {
    type:                FeedType,
    skipHoursFromToday?: number;
    antiSportsFilter?:   number[];
    champFilter?:        number[];
};

/**
 * API ответ: "feed-pre-match-groups":
 */
export type Response = Sport[]|null;

export type Sport = {
    C:   number;
    CID: number;
    I:   number;
    N:   string;
    L:   EventsGroup[];
    V?:  number,
    Z?:  number,
}

export type EventsGroup = {
    CI: number;
    GC: number;
    L:  string;
    LI: number;
    SI: number;
    O:  number;
    G:  Event[]|null;
}

export type Event = {
    COI: number;
    DI:  string;
    I:   number;
    IC:  number;
    LI:  number;
    N:   number;
    O1:  string;
    O2:  string;
    PN:  string;
    S:   number;
    SI:  number;
    T:   number;
    TN:  string;
    TG:  string;
    SG:  SubEvent[];
}

export type SubEvent = {
    DI:  unknown;
    I:   number;
    IC:  number;
    MG:  number;
    N:   number;
    PN?: string;
    TG?: string;
    R:   number;
    SC:  unknown;
    SI:  number;
    T:   number;
    TI?: number;
};

export enum SportMap {
    ID           = 'I',
    NAME         = 'N',
    GAMES_COUNT  = 'C',
    GROUPS       = 'L',
    VIRTUAL      = 'V',
}

export enum EventsGroupMap {
    COUNTRY_ID   = 'CI',
    EVENTS_COUNT = 'GC',
    GROUP_NAME   = 'L',
    GROUP_ID     = 'LI',
    SPORT_ID     = 'SI',
}

export enum EventMap {
    COUNTRY_ID          = 'COI',
    SPORT_ID            = 'SI',
    ID                  = 'I',
    NUMBER              = 'N',
    FIRST_OPPONENT      = 'O1',
    SECOND_OPPONENT     = 'O2',
    STARTED_AT          = 'S',
    SUB_EVENTS          = 'SG',
    PERIOD_NAME         = 'PN',
    CONDITION           = 'TG',
}