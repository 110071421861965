import {Container}
    from "inversify";
import BettingModule
    from "~app/Betting/Betting.module";

export default function () {
    return {
        /**
         * @var $bettingModule - Извлекает из IoC контейнера приложения модуль
         *                       для работы с функционалом проведения ставок.
         */
        $bettingModule: (window as unknown as {$container: Container})
            .$container
            .get<BettingModule>(BettingModule.SONAR)
    };
}