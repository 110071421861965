import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";
import AsyncProvider
    from "~ioc/contracts/AsyncProvider.contract";
import Helpers
    from "~lib/helpers";

import EposModule
    from "~app/Epos/Epos.module";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/**
 * После инициализации интерфейса провайдер извлекает из хранилища,
 * и проверяет данные, по которым можно первично определить
 * авторизовано ли рабочее место.
 *
 * Причем, наличие этих данных не гарантирует актуальное состояние авторизации,
 * впоследствии их нужно проверить запросом к апи, для синхронизации.
 */
export default class AuthorizationCheckProvider extends ServiceProvider
    implements
        AsyncProvider
{
    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('WorkspaceAuthCheckProvider');
    }

    /**
     * @inheritDoc
     */
    isParallel(): boolean
    {
        return true;
    }

    /**
     * @inheritDoc
     *
     * @param app
     */
    async register(app: Container): Promise<void>
    {
        const {
            $app,
        } = usePiniaState();

        if ($app.isAppStartFailed || $app.isMaintenanceActive) {
            // Нет смысла инициализировать функционал рабочего места,
            // если html страница вернулась с ошибкой, или если
            // глобальный функционал находится в режиме техобслуживания:
            return;
        }

        await app
            .get<EposModule>(EposModule.SONAR)
            .checkWorkspaceStateOnInit();

        await Helpers
            .sleep(300);

        return;
    }
}