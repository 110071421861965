import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";
import mitt, {Emitter}
    from 'mitt';
import {AppGlobalEventBus}
    from "~app/Epos/Contracts/AppGlobalEventBus";

/**
 * Инициализация глобальной шины событий, и ее регистрация
 * в глобальной области видимости браузера.
 *
 * ВНИМАНИЕ!
 * Все события, генерируемые и/или используемые приложением
 * должны быть явно описаны, и являться частью "AppGlobalEventBus".
 */
export default class AppEventBusProvider
    extends ServiceProvider {

    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('AppEventBusProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        (window as unknown as {$emitter: Emitter<AppGlobalEventBus>})
            .$emitter = mitt<AppGlobalEventBus>();
    }
}