import {inject, injectable}
    from 'inversify';
import Helpers
    from "~lib/helpers";

import {Theme}
    from "~app/Throttling/ReCaptcha/Enum/Theme";
import {Size}
    from "~app/Throttling/ReCaptcha/Enum/Size";
import * as ReCaptchaTypes
    from "~app/Throttling/ReCaptcha/ReCaptcha.types";
import {TargetDOMElem}
    from "~app/Throttling/ReCaptcha/Enum/TargetDOMElem";
import ThrottlingState
    from "~app/Storage/Throttling.state";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {ReCaptchaErrors}
    from "~app/Throttling/ReCaptcha/ReCaptcha.errors";

@injectable()
export default class ReCaptchaService {

    public static readonly SONAR: symbol = Symbol
        .for('ReCaptchaService');

    /**
     * Настройки плагина Google ReCaptcha.
     */
    private readonly settings: ReCaptchaTypes.InitSettings;

    /**
     * API успешно инициализированного плагина.
     */
    private reCaptchaApi: ReCaptchaTypes.GreCaptcha|null = null;

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
    )
    {
        // TODO: вынести в отдельный параметр
        this.settings = {
            url:           'https://google.com/recaptcha/api.js',
            lang:          'en',
            id:            'reCaptchaV3',
            siteKey:       (ThrottlingState()).reCaptchaKey ?? '',
            initTimeoutMs: 10000,
            googleParams:  {
                theme: Theme.DARK,
                size:  Size.INVISIBLE
            }
        };
    }

    /**
     * Загрузка и инициализация функционала Google ReCaptcha.
     */
    public async render(): Promise<void>
    {
        if (null !== this.reCaptchaApi) {
            throw new RuntimeException({
                code:    ReCaptchaErrors.RE_CAPTCHA_LOGIC_ERROR,
                isFatal: true,
                message: 'Logic error. Trying to render Google ReCaptcha' +
                         ' when it already rendered.'
            });
        }

        // Попытка загрузить расположенный на сервере Google
        // скрипт с логикой инициализации:
        const result: true|RuntimeException = await new Promise(
            (
                resolve,
                reject
            ): void =>
            {
                // Таймаут, прерывающий promise при превышении
                // отведенного времени:
                const loadTimeout: number = setTimeout(
                    (): void =>
                    {
                        this.clearDataAndRemove();

                        reject(
                            new RuntimeException({
                                code:    ReCaptchaErrors.PRELOAD_TIMEOUT_EXPIRED,
                                isFatal: false,
                                message: 'Failed to load ReCaptcha functional.' +
                                         ' Timeout expired after ' +
                                         Math.round(this.settings.initTimeoutMs / 1000) +
                                         ' seconds.'
                            })
                        );
                    },
                    this.settings.initTimeoutMs
                );

                const script: HTMLScriptElement = <HTMLScriptElement>document
                    .createElement('script');

                script.id   = `${TargetDOMElem.SCRIPT_ELEMENT_PREFIX_ID}${this.settings.id}`;
                script.type = 'text/javascript';
                script.src  = `${this.settings.url}?hl=${this.settings.lang}&render=${this.settings.siteKey}`;

                // Действия в случае успешной загрузки
                // скрипта Google ReCaptcha:
                script.onload = (): void =>
                {
                    clearTimeout(
                        loadTimeout
                    );

                    resolve(true);
                };

                // Действия в случае ошибки при загрузке скрипта
                script.onerror = (
                    throwable
                ): void =>
                {
                    clearTimeout(
                        loadTimeout
                    );

                    this.clearDataAndRemove();

                    reject(
                        new RuntimeException({
                            code:     ReCaptchaErrors.PRELOAD_ERROR,
                            isFatal:  false,
                            message:  'Failed to preload ReCaptcha script.' +
                                      ' See details in "previous" field.',
                            previous: throwable,
                        })
                    );
                };

                // Помещение скрипта в шапку HTML элемента,
                // активируя таким образом загрузку его ресурса:
                document.head
                    .appendChild(script);
            });

        if (true !== result) {
            // Не удалось загрузить инициализирующий скрипт,
            // дальнейшие действия должен предпринимать
            // вызывающий скрипт на основе исключения:
            throw result;
        }

        // Элемент глобального объекта "window", в который
        // ReCaptcha загружает свой функционал:
        const reCaptcha: ReCaptchaTypes.GreCaptcha|undefined = (
            window as unknown as ReCaptchaTypes.WindowReCaptcha
        ).grecaptcha;

        // Небольшое, страховочное, ожидание
        // инициализации функционала:
        await Helpers
            .sleep(200);

        if ('undefined' === typeof reCaptcha) {
            throw new RuntimeException({
                code:    ReCaptchaErrors.RE_CAPTCHA_LOGIC_ERROR,
                isFatal: false,
                message: 'Logic error. Looks like ReCaptcha successfully loaded,' +
                         ' but field "grecaptcha" are empty at "window" object.'
            });
        }

        // Ожидание подтверждения функционалом ReCaptcha
        // своей готовности к работе:
        const confirmIsReadyToUse: true|RuntimeException = await new Promise(
            (
                resolve,
                reject
            ): void =>
            {
                const timeout: number = setTimeout(
                    (): void =>
                    {
                        reject(
                            new RuntimeException({
                                code:    ReCaptchaErrors.RE_CAPTCHA_READY_TIMEOUT,
                                isFatal: false,
                                message: 'Timeout limit reached for waiting for ReCaptcha' +
                                         ' to be ready to use after ' +
                                         Math.round(this.settings.initTimeoutMs / 1000) +
                                         ' seconds.'
                            })
                        );
                    },
                    this.settings.initTimeoutMs
                );

                // Действия после подтверждения ReCaptcha
                // своей готовности к работе:
                reCaptcha.ready((): void => {

                    clearTimeout(
                        timeout
                    );

                    this.reCaptchaApi = reCaptcha;

                    resolve(
                        true
                    );
                });
            });

        if (true !== confirmIsReadyToUse) {
            throw confirmIsReadyToUse;
        }
    }

    /**
     * Очистка данных, загруженных ReCaptcha, и
     * удаление подключаемых файлов из DOM.
     */
    public async clearDataAndRemove(): Promise<void>
    {
        this.reCaptchaApi = null;

        (window as unknown as ReCaptchaTypes.WindowReCaptcha)
            .grecaptcha = undefined;

        const reCaptchaDOMElements = <HTMLCollectionOf<HTMLElement>>document
            .getElementsByClassName(`${TargetDOMElem.WIDGET_ELEMENT_CLASS}`);

        if (reCaptchaDOMElements) {
            reCaptchaDOMElements[0].remove();
        }

        const scriptElement = <HTMLElement|null>document
            .getElementById(`${TargetDOMElem.SCRIPT_ELEMENT_PREFIX_ID}${this.settings.id}`);

        if (scriptElement) {
            scriptElement.remove();
        }

        const tags = document
            .getElementsByTagName('script');

        for (let key = 0; key < tags.length; key++)
        {
            if (
                tags[key] &&
                -1 != tags[key]?.getAttribute('src')?.indexOf('gstatic.com/recaptcha')
            ) {
                tags[key]
                    ?.parentNode
                    ?.removeChild(tags[key]);
            }
        }
    }

    /**
     * Запуск проверки клиента через функционал Google ReCaptcha.
     *
     * В случае успеха будет возвращен токен, который
     * необходимо передать вместе с данными защищенной формы.
     */
    public async checkClient(): Promise<string>
    {
        const token: string|RuntimeException = await new Promise(
            (
                resolve,
                reject
            ): void =>
            {
                if (null === this.reCaptchaApi)
                {
                    reject(
                        new RuntimeException({
                            code:    ReCaptchaErrors.RE_CAPTCHA_LOGIC_ERROR,
                            isFatal: true,
                            message: 'Logic error. ReCaptcha is not rendered or' +
                                     ' or service has no access to API.'
                        })
                    );

                    return;
                }

                const call: ReCaptchaTypes.ExecuteResponse = this.reCaptchaApi
                    .execute(
                        this.settings.siteKey,
                        {
                            action: 'submit'
                        }
                    );

                call.then((
                    payload: string
                ): void =>
                {
                    resolve(payload);
                });

                call.catch((
                    errorMsg: string
                ): void =>
                {
                    reject(
                        new RuntimeException({
                            code:    ReCaptchaErrors.CLIENT_CHECK_ERROR,
                            isFatal: false,
                            message: `ReCaptcha failed to check current client.` +
                                     ` Details: '${errorMsg}'.`
                        })
                    );
                });
            });

        if ('string' !== typeof token) {
            throw token;
        }

        return token;
    }
}