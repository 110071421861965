export const type = Symbol.for('TextsLibrary');

declare type LibraryList  = {[index: string]: string};
declare type TextReplacer = {[index: string]: string};

declare type Settings = {
    /**
     * Сообщение, которое будет подставляться при невозможности
     * найти запрошенную строку.
     */
    translateErrorMsg: string,
    /**
     * Символ-якорь, используемый для разделения на подстроки
     * сложносоставных строк.
     */
    compositeSplitKey: string,
};

export default class Library {

    public static readonly SONAR = Symbol
        .for('TextsLibrary');

    /**
     * Версия-идентификатор набора переведенных текстов.
     */
    protected readonly version: string;

    /**
     * Локаль текстов, загруженных
     * в библиотеку.
     */
    protected readonly locale: string;

    /**
     * Настройки работы библиотеки текстов.
     */
    protected readonly settings: Settings;

    /**
     * @see Settings
     */
    protected lib: LibraryList = {};

    /**
     * @param version
     * @param locale
     * @param payload
     * @param settings
     */
    constructor(
        version:  string,
        locale:   string,
        payload:  LibraryList|null = null,
        settings: Settings|null    = null
    ) {
        this.version = version;
        this.locale  = locale;

        if (null !== payload) {
            this.textsLoader(payload);
        }

        this.settings = settings ?? {
            translateErrorMsg: '-TEXT-NOT-FOUND-',
            compositeSplitKey: '{-}'
        };
    }

    /**
     * Загрузка переведенных строк в библиотеку.
     *
     * При совпадении ключей с уже существующими,
     * старые строки будут заменены новыми.
     *
     * @param payload
     */
    public textsLoader(payload: LibraryList): void
    {
        for (const textKey of Object.keys(payload)) {
            this.lib[textKey] = payload[textKey];
        }
    }

    /**
     * Поиск в библиотеки составной строки, разделенной на сегменты
     * с использованием специального якоря.
     *
     * Можно получить либо массив, содержащий все сегменты строки, либо
     * конкретную ее часть, указав индекс.
     *
     * @param stringKey - Ключ сохраненного текста
     * @param needle    - (Опционально) Интересующая часть строки
     * @param replace   - (Опционально) Массив для замены подстрок в строке
     */
    public composite<ReturnType extends (string[]|string) = string[]|string>(
        stringKey: string,
        needle:    number|null       = null,
        replace:   TextReplacer|null = null
    ): ReturnType
    {
        let parts = this.text(stringKey, replace).split(this.settings.compositeSplitKey);
        for (const i of parts.keys()) {
            parts[i] = parts[i].trim();
        }

        if (null === needle) {
            return parts as unknown as ReturnType;
        }

        if (!parts.hasOwnProperty(needle)) {
            return this.settings.translateErrorMsg as unknown as ReturnType;
        }

        return parts[needle] as unknown as ReturnType;
    }

    /**
     * Ищет в библиотеке и, в случае успеха, возвращает
     * первый сегмент для сегментированной строки.
     *
     * @param stringKey - Ключ сохраненного текста
     * @param replace   - (Опционально) Массив для замены подстрок в строке
     */
    public firstPartOfText<ReturnType extends string = string>(
        stringKey: string,
        replace:   TextReplacer|null = null
    ): ReturnType
    {
        return this.composite(
            stringKey,
            0,
            replace
        );
    }

    /**
     * Ищет в библиотеке текстовую фразу, соответствующую
     * запрошенному ключу.
     *
     * В случае если ничего найти не удалось, то
     * будет возвращена "this.translateErrorMsg".
     *
     * @param stringKey    - Ключ сохраненного текста
     * @param replace      - (Опционально) Массив для замены подстроки в строке
     */
    public text(
        stringKey: string,
        replace:   TextReplacer|null = null,
    ): string
    {
        if (!this.lib.hasOwnProperty(stringKey) || 'string' !== typeof this.lib[stringKey])
        {
            Library
                .consoleWarn(
                    `Text for key "${stringKey}" (${this.locale}) is not found or empty.`
                );

            return this.settings
                .translateErrorMsg;
        }

        return (replace && Object.keys(replace).length > 0)
            ? Library.replacer(this.lib[stringKey], replace).trim()
            : this.lib[stringKey].trim();
    }

    /**
     * Обработка замены подстрок в строке.
     *
     * @param target  - Целевая строка.
     * @param replace - Параметры для поиска и замены.
     */
    protected static replacer(
        target:  string,
        replace: TextReplacer
    ): string
    {
        for (const placeholder of Object.keys(replace))
        {
            target = target.replace(
                new RegExp("\\{"+placeholder+"\\}", 'gi'),
                replace[placeholder]
            );
        }

        return target;
    }

    /**
     * @param msg
     */
    protected static consoleWarn(
        msg: string
    ): void
    {
        console
            .warn(`[DICTIONARY]: ${msg}`);
    }
};