//TODO: перенести в CORE
export default function () {

    function shellCssConstruct(
        settings:  {[key: string]: boolean},
        defaults?: undefined | string[]
    ): string[]
    {
        const list: string[] = [];

        for (const entity of Object.entries(settings)) {
            const [style, isActive] = entity;

            if (!isActive) {
                continue;
            }

            list.push(style);
        }

        return ('undefined' === typeof defaults)
            ? list
            : [...defaults, ...list];
    }

    return {
        shellCssConstruct,
    };
}