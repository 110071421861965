import {ref, computed, Ref}
    from "@vue/runtime-core";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import useEposModule
    from "~app/IoC/useEposModule";

import Exception
    from "~lib/Exception/Exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

/**
 * Общая логика интерфейса по обработке формы ввода логина
 * и пароля кассира, для попытки его авторизации на рабочем месте.
 */
export default function () {

    const {
        $T
    } = useInterfaceTexts();

    const {
        $app,
        $workspace
    } = usePiniaState();

    const {
        $eposModule
    } = useEposModule();

    const errorMsg: Ref<string> = ref<string>('');
    const login:    Ref<string> = ref<string>('');
    const password: Ref<string> = ref<string>('');

    /**
     * Если "TRUE", то окно авторизации должно находится в режиме
     * восстановления сессии кассира, иначе используется
     * обычная логика авторизации кассира.
     */
    const isWorkspaceInSessionRestoreMode = computed<boolean>(
        () => $workspace.isCashierLoggedIn &&  true === $workspace.systemParameter('isCashierSessionBroken')
    );

    /**
     * Предварительная валидация вводимых данных.
     *
     * Возвращает "TRUE", если форму в текущем виде
     * нет смысла отправлять на API.
     */
    const isFormInvalid = computed(() => {

        if (isWorkspaceInSessionRestoreMode.value) {
            return password.value.length < 3
                && password.value.length > 35;
        }

        return login.value.length < 3
            && login.value.length > 20
            && password.value.length < 3
            && password.value.length > 35;
    });

    /**
     * Обработка формы авторизации кассира на рабочем месте.
     */
    async function tryCashierAuth()
    {
        if ($app.isAppBusy || isFormInvalid.value) {
            return;
        }

        errorMsg.value = '';

        await $app
            .changeAppBusyStatus(true);

        $eposModule
            .cashierLogIn(
                (isWorkspaceInSessionRestoreMode.value)
                    ? $workspace.cashier('login') ?? 'abcdef'
                    : login.value,
                password.value
            )
            .then((): void =>
            {
                $app.changeAppBusyStatus(
                    false,
                    150
                );
            })
            .catch((throwable): void =>
            {
                if (!(throwable instanceof Exception) ||  throwable.isFatal)
                {
                    // Непредвиденная ошибка, приводящая к
                    // завершению работы интерфейса:
                    throw throwable;
                }

                // Выбор сообщения об ошибке, которое будет отображено
                // возле формы авторизации кассира:
                errorMsg.value = (EposCoreErrors.CASHIER_LOG_IN_ERROR === throwable.code)
                    ? $T.text('cashier-auth-error-1')
                    : $T.text('global_error_0');

                $app.changeAppBusyStatus(
                    false,
                    150
                );
            });
    }

    return {
        isWorkspaceInSessionRestoreMode,
        errorMsg,
        login,
        password,
        isFormInvalid,

        tryCashierAuth,
    };
}