<template>
  <div class="empty-state">
    <svg-elem
        v-if="null !== ico"
        :to="ico"
        :class="{'empty-state__icon--is-flicker': icoFlicker}"
        sprite="cleared"
        :size="icoSize"
        class="empty-state__icon"
        is-mirrored
    />
    <progress-bar
        v-if="'undefined' !== typeof barPercent"
        :percentage="barPercent"
        class="empty-state__progressbar"
    />
    <h3 v-if="null != title" class="empty-state__title">{{ title }}</h3>
    <p
      v-if="'undefined' !== typeof desc"
      class="empty-state__subtitle"
      v-html="('string' === typeof desc) ? desc : desc.join('<br/>')">
    </p>
    <Button
        v-if="'undefined' !== typeof btn"
        :text="btn.text"
        @click="btn.callable()"
        class="empty-state__button"
        label-style="uppercase"
    />
  </div>
</template>

<style lang="scss" scoped>
  @import '~scss-desktop/common/components/_empty-state';
</style>

<script lang="ts">
const CMP_NAME = 'EmptyWindowContent';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
  PropType,
  defineProps,
} from "@vue/runtime-core";

/** IMPORTED VUE TEMPLATES: */
import {SvgList}
  from "~tpl-global/svg/SvgList";
import SvgElem
  from "~tpl-global/svg/SvgElem.vue";
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import ProgressBar
  from "~tpl-global/ProgressBar/ProgressBar.vue";
import {SvgSize}
  from "~tpl-global/svg/SvgSize";

/** COMPONENT LOGIC: */
type ComponentProps = {
  ico:         SvgList;
  icoSize?:    SvgSize;
  icoFlicker?: boolean|undefined;
  title:       string;
  desc?:       string|string[]|undefined;
  barPercent?: number|undefined;
  btn?:        {
    text:     string,
    callable: () => void|Promise<void>
  }|undefined;
}

// server-settings
const props = withDefaults(defineProps<ComponentProps>(), {
  icoFlicker: false,
  icoSize: 'size-xl'
});
</script>