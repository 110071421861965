import {Model}
    from "~lib/eloquent/Model";
import {CashierWorkingShift}
    from "~app/Epos/Contracts/Epos.types";

export const ModelName = Symbol
    .for('WorkingShift');

/**
 * @see CashierWorkingShift
 */
export default class WorkingShift
    extends Model<CashierWorkingShift> {

    protected syncAt:         number      = 0;
    protected id:             number      = 0;
    protected openedAt:       number      = 0;
    protected previousId:     number|null = 0;
    protected onStartBalance: number      = 0;
    protected currentBalance: number      = 0;
    protected prepaidBalance: number      = 0;
    protected income:         number      = 0;
    protected outcome:        number      = 0;

    /**
     * @see Model.init
     *
     * @param feed
     */
    constructor(
        feed: Partial<CashierWorkingShift>|null = null
    )
    {
        super();

        this.init(
            feed
        );
    }

    /**
     * @inheritDoc
     */
    public modelName(): symbol
    {
        return ModelName;
    }
}