import {FeedType}
    from "~app/Betting/Contracts/Feed.types";

/**
 * Маршрут:
 * POST: "feed-markets":
 */
export type Request = {
    type:        FeedType;
    id:          number;
    customerId?: number;
};

export type Response = {
    isFound:     boolean;
    feedType:    FeedType;
    eventId:     number;
    customerId:  number|null;
    rawMarkets:  RawMarket[];
    marketNames: RawMarketName[];
};

export type RawMarket = {
    T:   number;
    C:   number;
    G:   number;
    B?:  boolean;
    P?:  number;
    PL?: {I: number, N: string};
};

export type RawMarketName = {
    groupId:         number;
    templateId:      number;
    groupName:       string;
    groupNameShort:  string;
    marketId:        number;
    marketName:      string;
    marketNameShort: string;
};

export enum MarketMap {
    ID        = 'T',
    ODDS      = 'C',
    GROUP_ID  = 'G',
    IS_LOCKED = 'B',
    PARAMETER = 'P',

    PLAYER_DATA      = 'PL',
    PLAYER_DATA_ID   = 'I',
    PLAYER_DATA_NAME = 'N',
}