<template>
  <!-- Панель уведомлений -->
  <ul class="statuses">
    <notifications class="statuses__inner" />
    <status-notification
        :target-data="$notifications.dbStatus"
        :default-data="{
            severity:    'notice',
            title:       'Error Database connection',
            description: 'Please try refreshing the page later or contact your administrator for details.',
            icon:        'warning-triangle',
          }"
        button-icon="data--check"
        class="statuses__inner"
    />
  </ul>
</template>

<script lang="ts">
const CMP_NAME = 'IcoList';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import NotificationsState
    from "~app/Storage/Notifications.state";

/** IMPORTED VUE TEMPLATES: */
import Notifications
  from "~tpl-desktop/workspace/TopBar/Notifications.vue";
import StatusNotification
  from "~tpl-desktop/workspace/TopBar/StatusNotification.vue";

/** COMPONENT LOGIC: */
const $notifications = NotificationsState();

</script>

<style lang="scss" scoped>
  @import '~scss-desktop/common/components/top-bar/statuses';
</style>