import {RuntimeExceptionHandlerSonar}
    from "~lib/render/Render";

export enum SystemSonar {
    AppInitConstant     = 'APP-INIT-CONSTANT',
    AppInitTasks        = 'SONAR-APP-INIT-TASKS',
    AppInitTasksHandler = 'APP-INIT-TASKS-HANDLER',
}

export const AppExceptionHandler: symbol = RuntimeExceptionHandlerSonar;
