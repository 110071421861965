import {Container}
    from "inversify";
import {OnAppInitTask}
    from "~app/Epos/Contracts/Epos.types";
import {WorkspaceLogicModule}
    from "~app/Epos/WorkspaceLogicModule";
import FeedService
    from "~app/Betting/Feed.service";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import {Sport}
    from "~app/Betting/Contracts/Feed.types";
import AVAILABLE_BETTING_LOCALES
    from "~app/Betting/BettingLocales.tech";

export const SPORTS_CACHE_KEY = Symbol
    .for('sports-feed-storage-key');

/**
 * Задание на проверку актуальности кеша типов спортов,
 * хранящегося на клиенте.
 *
 * В случае если кеша нет, либо если он устарел - то
 * будут запрошены свежие данные, и сохранены в IndexedDB.
 *
 * Актуальный кеш загружается в runtime хранилище приложения,
 * и используется при формировании фидов live / pre-match.
 */
const UpdateSportsFeedTask: OnAppInitTask =
    {
        id:          'sports-data-update',
        description: 'interface-init-step-sync-sports',
        attempts:    2,

        requiredInterface: [
            'epos-desktop'
        ],
        requiredModules:   [
            WorkspaceLogicModule.BETTING
        ],

        allowedLocales: AVAILABLE_BETTING_LOCALES,

        // TODO: вынести в одноразовый таск, отдельный от инициализации
        runner: async function (app: Container): Promise<boolean>
        {
            const {
                $feed
            } = usePiniaState();

            const $feedService = app
                .get<FeedService>(FeedService.SONAR);

            type SportsCacheStructure = {
                at:   number,
                data: {[key: number]: Sport}
            };

            // Логика обновления данных фида в runtime
            // хранилище приложения:
            const loadFeedCacheIntoApp = (
                cache: SportsCacheStructure
            ): void =>
            {
                $feed.updateSportsFeed(
                    cache.data
                );
            };

            // Поиск сохраненного кеша спортов в IndexedDB:
            const storedFeedCache = await $feedService
                .extractFeedFromCache<SportsCacheStructure>(
                    SPORTS_CACHE_KEY.toString()
                );

            // Если кеш загружен в интерфейс и актуален, то
            // его содержимое загружается в интерфейс:
            if (
                null !== storedFeedCache &&
                await $feedService.checkSportsFeedRelevance(storedFeedCache.at)
            )
            {
                loadFeedCacheIntoApp(
                    storedFeedCache
                );

                return true;
            }

            // Запрос актуального кеша списка спортов:
            const retrievedFeedCache = await $feedService
                .retrieveSportsFeed();

            // Сохранение полученного фида в IndexedDB
            await $feedService.storeFeedToCache(
                SPORTS_CACHE_KEY.toString(),
                retrievedFeedCache
            );

            loadFeedCacheIntoApp(
                retrievedFeedCache
            );

            return true;
        }
    };

export default UpdateSportsFeedTask;