import {array}
    from "~lib/CustomTypes";

export const isArrayable = (item: object): boolean =>
    'function' === typeof (item as {[index: string]: any})['toArray'];

export default interface Arrayable {
    /**
     * Get the instance as an array.
     */
    toArray(): array;
}