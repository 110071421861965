import {
    inject, injectable
} from 'inversify';
import HttpClient
    from "~lib/client/HttpClient";
import RoutesPool
    from "~app/Epos/RoutesPool";
import IndexedDb
    from "~lib/indexeddb/IndexedDb";
import MsgpackEncoder
    from "~lib/msgpack/Encoder";
import MsgpackDecoder
    from "~lib/msgpack/Decoder";
import {WorkspaceDataDB, TABLE_FEEDS, TABLE_BETTING_CACHE}
    from "~app/Providers/AppInit/WorkspaceDb.provider";

import * as SrvHelper
    from "~app/Other/Api/ApiService.helpers";
import * as SportsFeed
    from "~app/Betting/Routes/Feed/SportsFeed.route"
import * as CountriesFeed
    from "~app/Betting/Routes/Feed/CountriesFeed.route"
import * as FeedTypes
    from "~app/Betting/Contracts/Feed.types";
import * as FeedLiveGroups
    from "~app/Betting/Routes/Feed/FeedLiveGroups.route";
import * as FeedPreMatchGroups
    from "~app/Betting/Routes/Feed/FeedPreMatchGroups.route"
import * as FeedLiveEvents
    from "~app/Betting/Routes/Feed/FeedLiveEvents.route"
import * as FeedPreMatchEvents
    from "~app/Betting/Routes/Feed/FeedPreMatchEvents.route"
import * as FeedMarkets
    from "~app/Betting/Routes/Feed/FeedMarkets.route";
import * as SearchReservedBet
    from "~app/Betting/Routes/SearchReservedBet.route";
import OutcomeNameParser
    from "~app/Betting/MarketParser";
import CountryToSvgMap
    from "~app/Betting/Map/CountryToSvg.map";
import SportToSvgMap
    from "~app/Betting/Map/SportToSvg.map";
import {BetFeedSource}
    from "~app/Betting/Emum/BetFeedSource";
import {BetType}
    from "~app/Betting/Emum/BetType";
import {Event, FeedType, Outcome, OutcomeInBetSlip}
    from "~app/Betting/Contracts/Feed.types";
import usePiniaState
    from "~interaction/Store/usePiniaState";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {BettingErrors}
    from "~app/Betting/Betting.errors";

export type AvailableCacheTableName = typeof TABLE_FEEDS | typeof TABLE_BETTING_CACHE;

@injectable()
export default class FeedService {

    public static readonly SONAR = Symbol.for('FeedService');

    private readonly client: HttpClient;
    private readonly routes: RoutesPool;
    private readonly db:     IndexedDb;

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
        @inject(HttpClient.SONAR) client: HttpClient,
        @inject(RoutesPool.SONAR) routes: RoutesPool,
        @inject(WorkspaceDataDB)  db:     IndexedDb,
    )
    {
        this.client = client;
        this.routes = routes;
        this.db     = db;
    }

    /**
     * @deprecated
     *
     * Поиск сохранённого купона по сгенерированному коду.
     *
     * @param code
     */
    public async searchReservedBet(
        code: string,
    ): Promise<{
        betSlip:     {[key: string]: OutcomeInBetSlip},
        stakeAmount: number|null,
    }|null>
    {
        const feedRq = await this.client
            .post<SearchReservedBet.Response>(
                this.routes.route('feed-reserved-bet'),
                {code: code}
            );

        SrvHelper
            .checkApiResponse(feedRq);

        if (
            !feedRq.isSuccess
        ) {
            throw new RuntimeException({
                code:    0,
                isFatal: false,
                label:   "BET-SETTINGS",
                message: `Failed to retrieve bet by code: ${code}.` +
                         ` Details: ${feedRq.message ?? '-'}`,
            });
        }

        const betSlip: {[key: string]: OutcomeInBetSlip} = {};

        // TODO: такое должно быть в настройках кассы.
        // Список фидов, которые доступны на кассе.
        const availableFeedSource: BetFeedSource[] = [
            BetFeedSource.Live,
            BetFeedSource.Line,
        ];

        // TODO: такое должно быть в настройках кассы.
        // Список типов ставок, которые доступны на кассе.
        const availableBetTypes: BetType[] = [
            BetType.Single,
            BetType.Express,
        ];

        // Маппинг для подбора типа фида.
        const feedTypeMapping: {[key: number]: FeedTypes.FeedType} = {
            1: "live",
            3: "pre-match",
        };

        // TODO: возможно стоит извещать кассира, что в купоне недоступный тип ставок.
        // Фильтрация ставок, которые недоступны на кассе.
        if (!availableBetTypes.includes(feedRq?.response?.data?.Vid ?? -1)) {
            return null;
        }

        // TODO: ошибка типизации, пофиксить
        // @ts-ignore
        for (const rawEvent of feedRq?.response?.data?.Events ?? []) {
            // Фильтрация событий, которые недоступны на кассе.
            if (!availableFeedSource.includes(rawEvent.Kind)) {
                continue;
            }

            const type: FeedType  = feedTypeMapping[rawEvent.Kind];
            const sportId: number = rawEvent.SportId;

            const event: Event = {
                feed:           type,
                groupKey:       "",            //TODO: есть sportId и champId. Id страны нет
                groupName:      rawEvent.Liga, //TODO: проверить
                id:             rawEvent.GameId,
                number:         rawEvent.Number,
                startAt:        rawEvent.Start,
                isSpecial:      null === FeedService
                    .extractEventNames(type, 'secondOpponent', rawEvent), //TODO: взято из src/ts/app/Feed/Feed.service.ts:847
                isEventOfDay:   false, //TODO: доделать
                firstOpponent:  rawEvent.Opp1,
                secondOpponent: rawEvent.Opp2,
                period:         rawEvent.PeriodName, // При поиске с помощью кода купона, все события являются основными
                condition:      null, // При поиске с помощью кода купона, все события являются основными
                sportId:        sportId,
                sportName:      rawEvent.SportName,
                sportIco:      (SportToSvgMap.hasOwnProperty(sportId))
                    ? SportToSvgMap[sportId]
                    : "0_question-mark",
                isSubEvent:     false, // При поиске с помощью кода купона, все события являются основными
                mainEventId:    null,  // При поиске с помощью кода купона, все события являются основными
                subEvents:      null,  // При поиске с помощью кода купона, все события являются основными
            };

            const outcome: Outcome = {
                eventKey:   "",               //TODO: берется из groupKey эвента
                eventId:    rawEvent.GameId,
                key:        "",               //TODO: откуда брать
                id:         rawEvent.Type,    //TODO: проверить. ЭТО ИДЕНТИФИАКТОР МАРКЕТА?
                groupId:    rawEvent.GroupId,
                templateId: rawEvent.ShortGroupId, //TODO: поверить
                odds:       rawEvent.Coef,
                prevOdds:   null, // на данном этапе всегда "null" src/ts/app/Feed/Feed.service.ts:1004
                name:       rawEvent.MarketName,
                groupName:  rawEvent.GroupName,
                isLocked:   rawEvent.Block,
                parameter:  null, // TODO: По идее, если при поиске выводятся только основные события - значение должно быть null rawEvent.Param,
                playerId:   rawEvent.PlayerId,
                playerName: rawEvent.PlayerName,
            };

            betSlip[`${outcome.eventId}`] = {
                at:  0,
                key: `${outcome.eventId}`,
                event: event,
                outcome: outcome,
            };
        }
        // TODO: по окончанию работы удалить
        console.log(feedRq, betSlip);
        return {
            betSlip:     betSlip,
            stakeAmount: feedRq?.response?.data?.Summ ?? null,
        };
    }

    /**
     * Проверка актуальности кеша для списка видов спортов,
     * хранящегося в интерфейсе.
     *
     * "TRUE" будет возвращено, если интерфейс
     * содержит актуальный список спортов.
     *
     * @param timestamp - Время обновления фида, хранящегося
     *                    в приложении.
     */
    public async checkSportsFeedRelevance(
        timestamp: number
    ): Promise<boolean>
    {
        const feedRq = await this.client
            .get<SportsFeed.ResponseGet>(this.routes.route('cache-sports-check'));

        SrvHelper
            .checkApiResponse(feedRq);

        if (
            !feedRq.isSuccess ||
            null === feedRq.response ||
            'number' !== typeof feedRq.response?.at
        ) {
            throw new RuntimeException({
                code:    BettingErrors.WORKSPACE_FEED_UNEXPECTED_ERROR,
                isFatal: false,
                label:   "SPORTS-FEED",
                message: `Failed to retrieve relevance date for sports feed.` +
                         ` Details: ${feedRq.message ?? '-'}`,
            });
        }

        return timestamp >= feedRq.response.at;
    }

    /**
     * В случае успешного запроса возвращает кеш с данными типов спортов,
     * подходящих для использования в интерфейсе, при генерации фидов live / pre-match.
     */
    public async retrieveSportsFeed(

    ): Promise<{at: number, data: {[key: number]: FeedTypes.Sport}}>
    {
        const feedRq = await this.client
            .post<SportsFeed.ResponsePost>(this.routes.route('cache-sports-retrieve')
        );

        SrvHelper
            .checkApiResponse(feedRq);

        if (!feedRq.isSuccess || null === feedRq.response) {
            throw new RuntimeException({
                code:    BettingErrors.WORKSPACE_FEED_UNEXPECTED_ERROR,
                isFatal: false,
                label:   "SPORTS-FEED",
                message: `Failed to retrieve data for sports feed.` +
                         ` Details: ${feedRq.message ?? '-'}`,
            });
        }

        const data: {[key: number]: FeedTypes.Sport} = {};

        for (const rawSport of Object.values(feedRq.response.data))
        {
            data[rawSport.id] = {
                id:   rawSport.id,
                name: rawSport.name,
                ico:  '0_question-mark',
            };
        }

        return {
            at:   feedRq.response.at,
            data: data,
        };
    }

    /**
     * Проверка актуальности кеша для списка стран,
     * хранящегося в интерфейсе.
     *
     * "TRUE" будет возвращено, если интерфейс
     * содержит актуальный список стран.
     *
     * @param timestamp - Время обновления фида, хранящегося
     *                    в приложении.
     */
    public async checkCountriesFeedRelevance(
        timestamp: number
    ): Promise<boolean>
    {
        const feedRq = await this.client
            .get<CountriesFeed.ResponseGet>(
                this.routes.route('cache-countries-check')
            );

        SrvHelper
            .checkApiResponse(feedRq);

        if (
            !feedRq.isSuccess ||
            null === feedRq.response ||
            'number' !== typeof feedRq.response?.at
        ) {
            throw new RuntimeException({
                code:    BettingErrors.WORKSPACE_FEED_UNEXPECTED_ERROR,
                isFatal: false,
                label:   "COUNTRIES-FEED",
                message: `Failed to retrieve relevance date for countries feed.` +
                         ` Details: ${feedRq.message ?? '-'}`,
            });
        }

        return timestamp >= feedRq.response.at;
    }

    /**
     * В случае успешного запроса возвращает кеш с данными списка стран,
     * подходящих для использования в интерфейсе, при генерации фидов live / pre-match.
     */
    public async retrieveCountriesFeed(
    ): Promise<{at: number, data: {[key: number]: FeedTypes.Country}}>
    {
        const feedRq = await this.client
            .post<CountriesFeed.ResponsePost>(this.routes.route('cache-countries-retrieve'));

        SrvHelper
            .checkApiResponse(feedRq);

        if (!feedRq.isSuccess || null === feedRq.response) {
            throw new RuntimeException({
                code:    BettingErrors.WORKSPACE_FEED_UNEXPECTED_ERROR,
                isFatal: false,
                label:   "COUNTRIES-FEED",
                message: `Failed to retrieve data for countries feed.` +
                         ` Details: ${feedRq.message ?? '-'}`,
            });
        }

        const data: {[key: number]: FeedTypes.Country} = {};

        for (const rawSport of Object.values(feedRq.response?.data))
        {
            data[rawSport.id] = {
                id:   rawSport.id,
                code: rawSport.code,
                name: rawSport.name,
            };
        }

        return {
            at:   feedRq.response.at,
            data: data,
        };
    }

    /**
     * Возвращает необработанный фид, содержащий группы событий,
     * распределенных по типу спорта и по стране.
     *
     * @param settings - Настройки запроса к фиду.
     */
    public async retrieveGroupsOfEvents<Return extends FeedLiveGroups.Response|FeedPreMatchGroups.Response>(
        settings: FeedLiveGroups.Request|FeedPreMatchGroups.Request
    ): Promise<Return>
    {
        if (!['live', 'pre-match'].includes(settings.type)) {
            throw new RuntimeException({
                isFatal:  true,
                code:     BettingErrors.FEED_UNEXPECTED_ERROR,
                message:  `Unexpected feed type '${settings.type}' provided` +
                          ` on groups of events request.`
            });
        }

        const feedRq = await this.client
            .post<
                FeedLiveGroups.Response|FeedPreMatchGroups.Response,
                FeedLiveGroups.Request|FeedPreMatchGroups.Request
            >(
                this.routes.route('feed-groups'),
                settings
            );

        SrvHelper
            .checkApiResponse(feedRq);

        if (!feedRq.isSuccess) {
            throw new RuntimeException({
                isFatal:  false,
                code:     BettingErrors.FEED_UNEXPECTED_ERROR,
                message:  SrvHelper.srvErrorMessageWithDetails(
                    `Failed to fetch groups of events for feed: '${settings.type}'.`,
                    feedRq
                ),
            });
        }

        return feedRq.response as unknown as Return;
    }

    /**
     * Запрос списка событий, относящихся к указанной
     * группе событий, и находящихся в одном из двух фидов.
     *
     * В случае успеха вернется массив обработанных событий,
     * подходящий для использования в интерфейсе.
     *
     * @param group - Группа событий, для которой запрашивается список.
     */
    public async getEventsList(
        group: FeedTypes.EventsGroup
    ): Promise<FeedTypes.Event[]|null>
    {
        if (!['live', 'pre-match'].includes(group.feed)) {
            throw new RuntimeException({
                isFatal:  true,
                code:     BettingErrors.FEED_UNEXPECTED_ERROR,
                message:  `Unexpected feed type '${group.feed}' provided` +
                          ` on events list request.`
            });
        }

        const feedRq = await this.client
            .post<
                FeedLiveEvents.Response|FeedPreMatchEvents.Response,
                FeedLiveEvents.Request|FeedPreMatchEvents.Request
            >(
                this.routes.route('feed-events'),
                {
                    type: group.feed,
                    id:   group.id
                }
            );

        SrvHelper
            .checkApiResponse(feedRq);

        if (!feedRq.isSuccess) {
            // Обработка ошибки запроса:
            if (200 !== feedRq.statusCode) {
                throw new RuntimeException({
                    isFatal: false,
                    code:    BettingErrors.FEED_API_FETCH_ERROR,
                    message: SrvHelper.srvErrorMessageWithDetails(
                        `Failed to fetch events list for feed: '${group.feed}' and` +
                             ` group id: '${group.id}'.`,
                             feedRq
                    ),
                });
            }

            // В группе больше нет активных событий,
            // либо группы никогда не существовало:
            return null;
        }

        // Список событий, подходящий для
        // использования в интерфейсе:
        const list: FeedTypes.Event[] = [];

        // Формирование списка событий из необработанного ответа апи,
        // на основе мапппинга полей:
        for (const elem of Object.values(feedRq.response?.G ?? {})) {
            list.push(
                FeedService
                    .generateMainEventData(
                        group.feed,
                        group,
                        elem
                    )
            );
        }

        return list;
    }

    /**
     * Запрос списка маркетов для указанного события, и, в случае успеха,
     * генерация для него списка исходов, на которые есть возможность
     * сделать ставку в настоящий момент.
     *
     * Поле "outcomes" будет "NULL" в случае, если событие не найдено,
     * завершилось, или по еще каким-либо причинам стало недоступно.
     *
     * В случае ошибок получения и/или обработки будет выброшено
     * не фатальное исключение.
     *
     * При указании идентификатора игрока, вернувшиеся маркеты будет
     * с примененными порезками и ограничениями, заданными для него букмекерами.
     *
     * @param event      - Событие, для которого запрашиваются маркеты.
     * @param lang       - Язык, на котором запрашиваются маркеты.
     * @param customerId - (опционально) Идентификатор кассира, с которым
     *                     касса работает в настоящий момент.
     */
    public async getEventMarket(
        event:       FeedTypes.Event,
        lang:        string,
        customerId?: number|null,
    ): Promise<{
        event:      FeedTypes.Event,
        customerId: number|null,
        outcomes:   {[key: string]: FeedTypes.Outcome}|null
    }>
    {
        let settings: FeedMarkets.Request = {
            type: event.feed,
            id:   event.id,
        };

        if ('number' === typeof customerId) {
            settings['customerId'] = customerId;
        }

        const feedRq = await this.client
            .post<FeedMarkets.Response, FeedMarkets.Request>(
                this.routes.route('feed-markets'),
                settings
            );

        // Не удалось получить данные от апи:
        // (метод сам по себе не должен заниматься повторными запросами)
        if (!feedRq.isSuccess) {
            const errorMsg: string = (null === feedRq.message)
                ? ' No reason provided.'
                : ` Reason: ${feedRq.message}`;

            throw new RuntimeException({
                code:    BettingErrors.MARKETS_FETCH_ERROR,
                isFatal: false,
                label:   "RetrieveEventMarkets",
                message: "Failed to fetch markets data from API." + errorMsg
            });
        }

        // Не найдено событие, либо его маркеты.
        // Возможно событие уже завершилось, или
        // доступ к нему был ограничен:
        if (!feedRq.response?.isFound) {
            return {
                event:      event,
                customerId: customerId ?? null,
                outcomes:   null,
            };
        }

        // Апи вернуло маркеты не для того же события,
        // для которого было запрошено:
        if (feedRq.response.eventId !== event.id) {
            throw new RuntimeException({
                code:    BettingErrors.MARKETS_EVENT_MISMATCH,
                isFatal: false,
                label:   "RetrieveEventMarkets",
                message: `Failed to fetch event market.` +
                         ` API return event id '${feedRq.response.eventId}'` +
                         ` that does not match with requested id '${event.id}'.`
            });
        }

        // Массив, содержащий маппинг идентификатора шаблона маркета
        // к данным, необходимым для его отрисовки в интерфейсе:
        const marketNames: {[key: number]: FeedMarkets.RawMarketName} = {};

        // Индексирование массива с именами маркетов:
        for (const rawMarketName of Object.values(feedRq.response.marketNames))
        {
            marketNames[rawMarketName.marketId] = rawMarketName;
        }

        // Список исходов, на которые есть возможность сделать ставку
        // в рамках запрошенного события:
        const outcomesList: {[key: string]: FeedTypes.Outcome} = {};

        for (const rawMarket of Object.values(feedRq.response.rawMarkets))
        {
            const marketId: number = rawMarket[
                FeedMarkets.MarketMap.ID
                ];

            if ('object' !== typeof marketNames[marketId]) {
                // TODO: диагностическое логгирование
                console.warn(
                    `Market name for ID '${marketId}' is not exists`,
                    marketId,
                    rawMarket,
                );

                // Пропуск маркета, для которого нет описания
                // и правил парсинга:
                continue;
            }

            const outcome: FeedTypes.Outcome = FeedService
                .generateOutcomeFromRawMarket(
                    event,
                    rawMarket,
                    marketNames[marketId],
                    lang
                );

            // Страховочная проверка на коллизию ключей исходов события:
            if (outcomesList.hasOwnProperty(outcome.key)) {
                // TODO: диагностическое логгирование
                console.warn(
                    `Collision for outcome key '${outcome.key}' detected.`,
                    outcome
                );

                continue;
            }

            outcomesList[outcome.key] = outcome;
        }

        return {
            event:      event,
            customerId: customerId ?? null,
            outcomes:   (Object.keys(outcomesList).length >= 1)
                ? outcomesList
                : null
        };
    }

    /**
     * Преобразование переданных данных в Uint8Array и
     * сохранение в указанную таблицу IndexedDB.
     *
     * По умолчанию используется таблица "TABLE_FEEDS".
     *
     * @param key     - Ключ записи.
     * @param payload - Сохраняемое содержимое.
     * @param table   - (Опционально) Название используемой таблицы.
     */
    public async storeFeedToCache<Payload = any>(
        key:     string,
        payload: Payload,
        table:   AvailableCacheTableName = TABLE_FEEDS,
    ): Promise<void>
    {
        if (!([
            TABLE_FEEDS,
            TABLE_BETTING_CACHE
        ].includes(table))) {
            throw new RuntimeException({
                isFatal:  true,
                label:    'FEED-CACHE',
                code:     BettingErrors.WORKSPACE_FEED_CACHE_STORE_ERROR,
                message:  `Failed to store feeds cache with key '${key}' into table '${table}'.` +
                          ` Reason: incorrect table provided.`,
            });
        }

        await this.db
            .put(
                table,
                MsgpackEncoder.encode(payload),
                key,
            )
            .catch((error) => {
                throw new RuntimeException({
                    isFatal:  false,
                    label:    'FEED-CACHE',
                    code:     BettingErrors.WORKSPACE_FEED_CACHE_STORE_ERROR,
                    message:  `Failed to store feeds cache with key '${key}' into table '${table}'.`,
                    previous: error
                });
            });
    }

    /**
     * Извлечение данных из кеша, сохраненного в IndexedDB
     * с помощью метода "storeFeedToCache".
     *
     * По умолчанию используется таблица "TABLE_FEEDS".
     * При сохранении в другую таблицу, необходимо
     * указать название таблицы.
     *
     * Возвращает "null" если запись не найдена.
     *
     * @param key   - Ключ записи в таблице.
     * @param table - (Опционально) Название используемой таблицы.
     */
    public async extractFeedFromCache<Return = Uint8Array>(
        key:   string,
        table: AvailableCacheTableName = TABLE_FEEDS,
    ): Promise<Return|null>
    {
        if (!([
            TABLE_FEEDS,
            TABLE_BETTING_CACHE
        ].includes(table))) {
            throw new RuntimeException({
                isFatal:  true,
                label:    'FEED-CACHE',
                code:     BettingErrors.WORKSPACE_FEED_CACHE_STORE_ERROR,
                message:  `Failed to store feeds cache with key '${key}' into table '${table}'.` +
                          ` Reason: incorrect table provided.`,
            });
        }

        const packedCache: Uint8Array | null = await this.db
            .get<Uint8Array>(
                table,
                key
            )
            .then((data: Uint8Array|null) => data)
            .catch((error): never => {
                throw new RuntimeException({
                    isFatal:  false,
                    code:     BettingErrors.WORKSPACE_FEED_CACHE_EXTRACT_ERROR,
                    message:  `Failed to extract feeds cache record from` +
                              ` IndexedDb table '${table}' with key '${key}'.`,
                    previous: error
                });
            });

        if (null === packedCache) {
            return null;
        }

        return MsgpackDecoder
            .decode<Return>(packedCache);
    }

    /**
     * Создает идентификатор (ключ) для группы событий.
     *
     * Нужен для предотвращения коллизий при
     * использовании групп.
     */
    public static createGroupId(
        sportId:   number,
        groupId:   number,
        countryId: number
    ): string
    {
        return `${sportId}|${groupId}|${countryId}`;
    }

    /**
     * Преобразует LIVE или PRE-MATCH ответ фида в список,
     * содержащий обработанные группы событий.
     *
     * @param type    - тип фида
     * @param payload - содержимое ответа от фида
     */
    public async generateEventGroupsFromFeedData<T extends FeedLiveGroups.Response | FeedPreMatchGroups.Response>(
        type:    FeedTypes.FeedType,
        payload: T
    ): Promise<FeedTypes.EventsGroup[]>
    {
        if (null === payload) {
            return [];
        }

        type SportType = T extends FeedLiveGroups.Response
            ? FeedLiveGroups.Sport
            : FeedPreMatchGroups.Sport;

        type LeagueType = T extends FeedLiveGroups.Response
            ? FeedLiveGroups.EventsGroup
            : FeedPreMatchGroups.EventsGroup;

        const list: FeedTypes.EventsGroup[] = [];

        const {
            $feed
        } = usePiniaState();

        //const sports = $feed.sportsFeed;
        const countries = $feed.countriesFeed;

        for (const sportTmpKey of Object.keys(payload)) {
            const sport: SportType = (
                payload[sportTmpKey as unknown as number] as unknown as SportType
            );

            for (const leagueTmpKey of Object.keys(sport.L)) {
                const league = (
                    sport.L[leagueTmpKey as unknown as number] as unknown as LeagueType
                );

                list.push(
                    FeedService
                        .generateEventsGroup(
                            type,
                            sport,
                            league,
                            countries
                        )
                );
            }
        }

        return list;
    }

    /**
     * Создание модели данных для группы событий,
     * в зависимости от типа фида.
     *
     * @param type      - Тип фида.
     * @param sport     - Необработанные данные спорта.
     * @param league    - Необработанные данные группы событий.
     * @param countries - Данные страны, к которой относится группа.
     */
    private static generateEventsGroup(
        type:      FeedTypes.FeedType,
        sport:     FeedLiveGroups.Sport | FeedPreMatchGroups.Sport,
        league:    FeedLiveGroups.EventsGroup | FeedPreMatchGroups.EventsGroup,
        countries: {[key: number]: FeedTypes.Country}
    ): FeedTypes.EventsGroup
    {
        const GROUP_MAPPER = ('live' === type)
            ? FeedLiveGroups.EventsGroupMap
            : FeedPreMatchGroups.EventsGroupMap;

        const SPORT_MAPPER = ('live' === type)
            ? FeedLiveGroups.SportMap
            : FeedPreMatchGroups.SportMap;

        const countryId: number = Number(league[GROUP_MAPPER.COUNTRY_ID]);
        const sportId:   number = Number(sport[SPORT_MAPPER.ID]);

        const countryIco = (
            countries.hasOwnProperty(countryId) &&
            CountryToSvgMap.hasOwnProperty(countries[countryId].code)
        )
            ? CountryToSvgMap[countries[countryId].code]
            : 'WW';

        return {
            feed:      type,
            key:       FeedService.createGroupId(
                Number(sport[SPORT_MAPPER.ID]),
                Number(league[GROUP_MAPPER.COUNTRY_ID]),
                Number(league[GROUP_MAPPER.GROUP_ID])
            ),
            id:        Number(league[GROUP_MAPPER.GROUP_ID]),
            isVirtual: 1 === sport[SPORT_MAPPER.VIRTUAL ?? 0],
            sportId:   sportId,
            countryId: countryId,
            sportIco:  (SportToSvgMap.hasOwnProperty(sportId))
                ? SportToSvgMap[sportId]
                : '0_question-mark',
            eventIco:  countryIco,
            sportName: `${sport[SPORT_MAPPER.NAME]}`,
            name:      `${league[GROUP_MAPPER.GROUP_NAME]}`,
            amount:    Number(league[GROUP_MAPPER.EVENTS_COUNT]),
            events:    [],
        }
    }

    /**
     * Генерация структуры события, используемого
     * для работы и отображения в интерфейсе.
     *
     * @param type        - Тип фида.
     * @param activeGroup - Данные группы, к которой относится событие.
     * @param rawData     - Необработанные данные события, полученные от фида.
     * @private
     */
    private static generateMainEventData(
        type:        FeedTypes.FeedType,
        activeGroup: FeedTypes.EventsGroup,
        rawData:     FeedLiveGroups.Event | FeedPreMatchGroups.Event
    ): FeedTypes.Event
    {
        const EVENT_MAPPER = ('live' === type)
            ? FeedLiveGroups.EventMap
            : FeedPreMatchGroups.EventMap;

        let event: FeedTypes.Event = {
            feed:           type,
            groupKey:       activeGroup.key,
            groupName:      activeGroup.name,
            id:             rawData[EVENT_MAPPER.ID],
            number:         rawData[EVENT_MAPPER.NUMBER],
            startAt:        rawData[EVENT_MAPPER.STARTED_AT],
            isSpecial:      null === FeedService
                .extractEventNames(type, 'secondOpponent', rawData),
            isEventOfDay:   false, // TODO: доделать
            firstOpponent:  FeedService
                .extractEventNames(type, 'firstOpponent', rawData),
            secondOpponent: FeedService
                .extractEventNames(type, 'secondOpponent', rawData),
            period:         null, // всегда "null"  в основном событии
            condition:      null, // всегда "null"  в основном событии
            sportId:        activeGroup.sportId,
            sportName:      activeGroup.sportName,
            sportIco:       activeGroup.sportIco ?? '0_question-mark',

            isSubEvent:     false, // всегда "false" в основном событии
            mainEventId:    null,  // всегда "null"  в основном событии
            subEvents:      null,  // всегда "null"  в основном событии
        };

        event.subEvents = FeedService
            .createSubEventsList(
                event,
                rawData[EVENT_MAPPER.SUB_EVENTS] ?? null
            );

        return event;
    }

    /**
     * Преобразование необработанных данных фида
     * в модель события, используемого интерфейсом.
     *
     * @param originEvent - Модель события, к которому относится вложенное событие.
     * @param rawData     - Необработанные данные вложенного события.
     */
    private static createSubEventsList(
        originEvent: FeedTypes.Event,
        rawData:     FeedLiveGroups.SubEvent[] | FeedPreMatchGroups.SubEvent[] |null
    ): FeedTypes.Event[]|null
    {
        if (null === rawData || 'object' !== typeof rawData) {
            return null;
        }

        const EVENT_MAPPER = ('live' === originEvent.feed)
            ? FeedLiveGroups.EventMap
            : FeedPreMatchGroups.EventMap;

        const list: FeedTypes.Event[] = [];

        for (const rawSubEvent of rawData) {
            list.push({
                feed:           originEvent.feed,
                groupKey:       originEvent.groupKey,
                groupName:      originEvent.groupName,
                id:             rawSubEvent[EVENT_MAPPER.ID],
                number:         rawSubEvent[EVENT_MAPPER.NUMBER],
                startAt:        originEvent.startAt,
                isSpecial:      originEvent.isSpecial,
                isEventOfDay:   originEvent.isEventOfDay,
                firstOpponent:  originEvent.firstOpponent,
                secondOpponent: originEvent.secondOpponent,
                period:         FeedService
                    .extractEventNames(originEvent.feed, 'period', rawSubEvent),
                condition:      FeedService
                    .extractEventNames(originEvent.feed, 'condition', rawSubEvent),
                sportId:        originEvent.sportId,
                sportName:      originEvent.sportName,
                sportIco:       originEvent.sportIco,

                isSubEvent:     true,
                mainEventId:    originEvent.id,
                subEvents:      null,
            });
        }

        return (list.length < 1)
            ? null
            : list;
    }

    /**
     * Поиск в необработанных данных события, или вложенного события,
     * названия имен оппонентов или уточняющих условий.
     *
     * @param type    - Тип фида.
     * @param field   - Название поля.
     * @param rawData - Данные от апи.
     * @private
     */
    private static extractEventNames(
        type:    FeedTypes.FeedType,
        field:   'firstOpponent' | 'secondOpponent' | 'period' | 'condition',
        rawData: FeedLiveGroups.Event | FeedLiveGroups.SubEvent,

    ): string|null
    {
        let payload: unknown = null;

        const EVENT_MAPPER = ('live' === type)
            ? FeedLiveGroups.EventMap
            : FeedPreMatchGroups.EventMap;

        switch (field)
        {
            case 'firstOpponent':
                // @ts-ignore
                payload = rawData[EVENT_MAPPER.FIRST_OPPONENT];
                break;
            case 'secondOpponent':
                // @ts-ignore
                payload = rawData[EVENT_MAPPER.SECOND_OPPONENT];
                break;
            case 'period':
                payload = rawData[EVENT_MAPPER.PERIOD_NAME];
                break;
            case 'condition':
                payload = rawData[EVENT_MAPPER.CONDITION];
                break;
        }

        return ('string' !== typeof payload || payload.length < 1)
            ? null
            : payload
    }

    /**
     * Генерация исхода события, на который
     * можно сделать ставку.
     *
     * @param event         - Событие, к которому относится маркет.
     * @param rawMarket     - Необработанные данные маркета.
     * @param rawMarketName - Необработанные данные названия маркета.
     * @param lang          - Alpha-2 код языка/локали.
     */
    private static generateOutcomeFromRawMarket(
        event:         FeedTypes.Event,
        rawMarket:     FeedMarkets.RawMarket,
        rawMarketName: FeedMarkets.RawMarketName,
        lang:          string,
    ): FeedTypes.Outcome
    {
        const MAPPER = FeedMarkets.MarketMap;

        // Формирование исхода события из комбинации
        // параметров маркета и его названия:
        let outcome : FeedTypes.Outcome = {
            eventKey:   event.groupKey,
            eventId:    event.id,
            key:        '', // генерация ниже по коду метода
            id:         rawMarket[MAPPER.ID],
            groupId:    rawMarketName.groupId,
            templateId: rawMarketName.templateId,
            odds:       rawMarket[MAPPER.ODDS],
            prevOdds:   null, // на данном этапе всегда "null"
            name:       rawMarketName.marketName, // генерация ниже по коду метода
            groupName:  rawMarketName.groupName,
            isLocked:   true === rawMarket[MAPPER.IS_LOCKED],
            parameter:  rawMarket[MAPPER.PARAMETER] || 0,
            playerId:   ('object' === typeof rawMarket[MAPPER.PLAYER_DATA])
                ? (rawMarket[MAPPER.PLAYER_DATA] as unknown as {I: number})[MAPPER.PLAYER_DATA_ID]
                : null,
            playerName: ('object' === typeof rawMarket[MAPPER.PLAYER_DATA])
                ? (rawMarket[MAPPER.PLAYER_DATA] as unknown as {N: string})[MAPPER.PLAYER_DATA_NAME]
                : null,
        };

        // Подстановка параметра в маркет, благодаря чему получится
        // "исход события", который можно отобразить в интерфейсе:
        outcome.name = OutcomeNameParser.parse(
            event.sportId,
            lang,
            outcome
        );

        // Ключ, определяющий уникальность исхода, т.к.
        // айди может быть один и тот же, на несколько исходов,
        // и отличаться будет только параметр для подстановки:
        outcome.key = `${outcome.groupId}:${outcome.id}:${outcome.parameter}`;

        // Дополнение ключа для случаев, когда
        // в исходе есть данные об игроке:
        if (outcome.playerId) {
            outcome.key += `:${outcome.playerId}`;
        }

        return outcome;
    }
}