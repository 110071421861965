import {defineStore}
    from 'pinia'
import {ref, computed, triggerRef}
    from "@vue/runtime-core";

import WorkspaceState
    from "~app/Storage/Workspace.state";
import ApplicationState
    from "~app/Storage/Application.state";

import * as ShiftTypes
    from "~app/Epos/Contracts/Shift.types";
import {CashierWorkingShift}
    from "~app/Epos/Contracts/Epos.types";
import WorkingShift
    from "~app/Epos/Models/WorkingShift";
import {TransactionType}
    from "~app/Epos/Enum/TransactionType";
import {Numbers}
    from "~lib/helpers";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";
import {TransactionClarificationType}
    from "~app/Epos/Enum/TransactionClarificationType";

export const ShiftModuleKey: symbol = Symbol
    .for('PiniaShiftModule');

const ShiftState = defineStore(ShiftModuleKey.toString(), () =>
{
    /**
     * Модель состояния смены на кассе.
     *
     * При отсутствии загруженной модели считается,
     * что на кассе нет открытой смены кассира.
     *
     * ВНИМАНИЕ!
     * Рабочая смена кассира не может быть открыта если
     * на кассе нет авторизованного кассира.
     */
    const _workingShift = ref<WorkingShift|null>(null);

    /**
     * Список транзакций за текущую смену.
     *
     * Если "NULL", то список еще ни разу не синхронизировался
     * с момента инициализации кассы.
     */
    const _currentTransactions = ref<ShiftTypes.Transaction[]|null>(null);

    /**
     * Список транзакций за текущую смену.
     */
    const transactions = computed(
        () => _currentTransactions.value ?? []
    );

    /**
     * Список транзакций за предыдущую смену.
     *
     * Если "NULL", то список еще ни разу не синхронизировался
     * с момента инициализации кассы.
     */
    const _previousTransactions = ref<ShiftTypes.Transaction[]|null>(null);

    /**
     * Список транзакций за предыдущую смену.
     */
    const previousTransactions = computed(
        () => _previousTransactions.value ?? []
    );

    /**
     * Список одобренных выводов со счета клиента, назначенных
     * на текущее рабочее место.
     *
     * Если "NULL", то список еще ни разу не синхронизировался
     * с момента инициализации кассы.
     */
    const _approvedWithdrawals = ref<
        ShiftTypes.ApprovedWithdrawalRequest[]|null
    >(null);

    /**
     * Список одобренных выводов со счета клиента, назначенных
     * на текущее рабочее место.
     */
    const approvedWithdrawals = computed(
        () => _approvedWithdrawals ?? []
    );

    /**
     * TODO: автоподсчет
     *
     * Маркер, показывающий превышение максимальной
     * продолжительности открытой смены.
     */
    const isDurationExceed24Hours = ref<boolean>(false);

    /**
     * Возвращает одно из полей, относящееся
     * к состоянию смены на кассе.
     */
    const field = computed(() => {
        return <T extends keyof CashierWorkingShift>(
            field: T
        ): CashierWorkingShift[T]|null =>
        {
            return _workingShift.value
                ?.get(field) ?? null;
        }
    });

    /**
     * Лейбл и часы продолжительности смены.
     * (формат часов: 00:00)
     */
    const clock = ref<{
        label: 'disabled'|'active'|'warn'|'exceed',
        duration: string
    }|null>(null);

    /**
     * Маркер наличия транзакций текущей смены,
     * доступных для отображения.
     */
    const isTransactionsListEmpty = computed<boolean>(
        () => {
            return transactions.value.length < 1;
        });

    /**
     * Маркер наличия транзакций предыдущей смены,
     * доступных для отображения.
     */
    const isPreviousTransactionsListEmpty = computed<boolean>(
        () => {
            return previousTransactions.value.length < 1;
        });

    /**
     * Возвращает "TRUE" если у кассы есть предыдущая смена.
     */
    const hasPreviousShift = computed<boolean>(
        () => {
            return null !== field.value('previousId');
        });

    /**
     * Возвращает "TRUE" если в течение текущей смены кассира
     * был сделан хотя бы один депозит.
     *
     * ВНИМАНИЕ!
     * Используется авто подсчет на основе списка транзакций.
     */
    const hasDeposits = computed<boolean>(
        () => {

            if (null === _currentTransactions.value) {
                return false;
            }

            for (const transaction of _currentTransactions.value) {
                if (TransactionType.DEPOSIT === transaction.typeId) {
                    return true;
                }
            }

            return false;
        });

    /**
     * Возвращает "TRUE" если в течение текущей смены кассира
     * был сделан хотя бы один вывод со счета игрока.
     *
     * ВНИМАНИЕ!
     * Используется авто подсчет на основе списка транзакций.
     */
    const hasWithdrawals = computed<boolean>(
        () => {

            if (null === _currentTransactions.value) {
                return false;
            }

            for (const transaction of _currentTransactions.value) {
                if (
                    TransactionType.WITHDRAWAL === transaction.typeId &&
                    TransactionClarificationType.REFUNDED_DEPOSIT !== transaction.clarificationId
                ) {
                    return true;
                }
            }

            return false;
        });

    /**
     * Возвращает "true" если на кассе
     * есть активная смена кассира.
     */
    const isActive = computed<boolean>(
        () => {
            return _workingShift.value instanceof WorkingShift;
        });

    /**
     * Возвращает "true" если на кассе используется
     * система предоплаченного баланса.
     */
    const isPrePaidBalanceActive = computed<boolean>(
        () => {
            return true === (WorkspaceState().settings('isPrePaidBalanceActive'));
        });

    /**
     * Общая сумма всех выплат, назначенных
     * на текущее рабочее место.
     */
    const totalWithdrawalAmount = computed<number>(
        () => {

            if (null === _approvedWithdrawals.value) {
                return 0;
            }

            let amount: number = 0;

            for (const withdrawalRequest of _approvedWithdrawals.value) {
                amount += withdrawalRequest.amount
            }

            return amount;
        });

    /**
     * Общее количество выплат, назначенных
     * на текущее рабочее место.
     */
    const totalWithdrawalsCount = computed(
        () => {
            return (_approvedWithdrawals.value ?? []).length;
        });

    /**
     * Возвращает "true", если для текущего рабочего места
     * есть активные запросы на выплату.
     */
    const isWorkspaceHasWithdrawalRequests = computed<boolean>(
        () => {
            return (_approvedWithdrawals.value ?? []).length > 0;
        });

    /**
     * Возвращает "true", если сумма запрошенных выводов через кассу
     * превышает количество доступных наличных.
     */
    const isWithdrawalAmountOverdraft = computed<boolean>(
        () => {
            return totalWithdrawalAmount.value > (field.value('currentBalance') ?? 0);
        });

    /**
     * Процент использованных средств на кассе
     * с активной предоплатной системой.
     *
     * При достижении 100% лимит считается исчерпанным.
     */
    const balanceLimit = computed<number>(() => {

        if (!isPrePaidBalanceActive) {
            return 0;
        }

        const currentBalance: number = field.value('currentBalance') ?? 0;
        const prepaidBalance: number = field.value('prepaidBalance') ?? 0;
        const sumOfBalances:  number = currentBalance + prepaidBalance;

        if (0 > currentBalance || 0 >= sumOfBalances) {
            return 0;
        }

        const result: number = Numbers
            .round(currentBalance * 100 / (sumOfBalances), 2);

        return 100 >= result
            ? result
            : 100;
    });

    /**
     * Загрузка в интерфейс данных об открытой
     * смене кассира.
     *
     * При передаче значения "NULL" данные
     * будут очищены.
     */
    function setActiveShiftData(
        payload: WorkingShift|null
    ): void
    {
        _workingShift.value = payload;

        // Обязательная очистка данных,
        // связанных со сменой:
        if (null === payload) {
            _currentTransactions.value  = null;
            _previousTransactions.value = null;
            _approvedWithdrawals.value  = null;
        }

        triggerRef(
            _workingShift
        );
    }

    /**
     * Временная корректировка баланса наличных на кассе.
     *
     * ВНИМАНИЕ! Скорректированные через метод данные будут заменены
     * при следующей синхронизации кассы.
     *
     * Если касса использует предоплатную систему, то
     * автоматически будет скорректирован предоплаченный баланс.
     *
     * @param amount - Положительная или отрицательная сумма, на которую
     *                 необходимо изменить баланс кассы.
     */
    function tmpAdjustCashBalance(
        amount: number
    ): void
    {
        if ('number' !== typeof amount) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: 'Logic error. Payload passed to the ‘tmpAdjustCashBalance’' +
                         `must be number, but ${typeof amount} payload provided.`,
            });
        }

        _workingShift.value
            ?.set(
                'currentBalance',
                (field.value('currentBalance') ?? 0) + amount
            );

        if (isPrePaidBalanceActive.value) {
            tmpAdjustPrePaidBalance(
                amount * -1
            );
        }
    }

    /**
     * Корректировка предоплаченного баланса кассы.
     *
     * ВНИМАНИЕ! Скорректированные через метод данные будут заменены
     * при следующей синхронизации кассы.
     *
     * @param amount - Положительная или отрицательная сумма, на которую
     *                 необходимо изменить предоплаченный баланс кассы.
     */
    function tmpAdjustPrePaidBalance(
        amount: number
    ): void
    {
        _workingShift.value
            ?.set(
                'prepaidBalance',
                (field.value('prepaidBalance') ?? 0) + amount
            );
    }

    /**
     * TODO: рассмотреть возможность автоподсчета
     *
     * Обновляет таймер продолжительности смены,
     * предназначенный только для отображения в интерфейсе.
     */
    function updateClock(
        payload: {
            label:    'disabled'|'active'|'warn'|'exceed',
            duration: string
        }|null
    ): void
    {
        clock.value = payload;
    }

    /**
     * TODO: рассмотреть возможность замены на computed
     *
     * Возвращает продолжительность открытой смены
     * кассира на кассе.
     *
     * Так же, если есть активная смена, то каждый
     * запрос ее продолжительности так же спровоцирует
     * проверку на превышение максимальной
     * продолжительности смены.
     *
     * Возвращает "null" если смена не открыта.
     */
    function duration(): number|null
    {
        if (null === _workingShift.value) {
            return null;
        }

        const openedAt: number|null = field.value('openedAt')
            ?? null;

        const timestamp: number = (ApplicationState())
            .getWorkspaceTimestamp();

        const duration: number = (null === openedAt || openedAt >= timestamp)
            ? 0
            : timestamp - openedAt;

        // обновление маркера превышения смены
        // при каждом ее запросе:
        isDurationExceed24Hours.value = duration > 86400;

        return duration;
    }

    /**
     * Устанавливает список транзакций за текущую,
     * или предыдущую смену.
     *
     * Заданный список используется для
     * отображения в интерфейсе.
     */
    async function updateTransactionsList(
        payload: {
            current?:  ShiftTypes.Transaction[],
            previous?: ShiftTypes.Transaction[]
        }
    ): Promise<void>
    {
        if (payload.current) {
            // Заполнение для текущей смены:
            _currentTransactions.value = payload.current;
        }

        if (payload.previous) {
            // Заполнение для предыдущей смены
            _previousTransactions.value = payload.previous;
        }
    }

    /**
     * ВНИМАНИЕ! Внесенные изменения будут изменены
     * при следующей синхронизации списка транзакций.
     *
     * Временная корректировка конкретной транзакции
     * на стороне клиента.
     *
     * В случае успеха вернется измененная транзакция,
     * иначе будет возвращен "NULL".
     *
     *
     * @param transactionId - Идентификатор изменяемой транзакции.
     * @param payload       - Список изменяемых полей.
     */
    async function temporaryAdjustTransaction(
        transactionId: number,
        payload:        Omit<Partial<ShiftTypes.Transaction>, 'id' | 'cashierId'>
    ): Promise<ShiftTypes.Transaction|null>
    {
        if (null === _currentTransactions.value) {
            return null;
        }

        for (const key of transactions.value.keys()) {

            let transaction = transactions.value[key];

            if (transaction.id !== transactionId) {
                continue;
            }

            // Замена полей в конкретной транзакции.
            for (const fieldKey of Object.keys(payload)) {
                // @ts-ignore TODO: разобраться
                transaction[fieldKey] = payload[fieldKey];
            }

            // Обновление транзакции в общем списке
            _currentTransactions.value[key] = transaction;

            // Триггер реактивных зависимостей:
            triggerRef(
                _currentTransactions
            );

            // Возврат успешно измененной транзакции:
            return transaction as unknown as ShiftTypes.Transaction;
        }

        // Искомая транзакция не найдена:
        return null;
    }

    /**
     * Задает или обновляет список выплат, назначенных
     * на текущее рабочее место.
     */
    function updateApprovedWithdrawalsList(
        payload: ShiftTypes.ApprovedWithdrawalRequest[]
    ): void
    {
        _approvedWithdrawals.value = payload;
    }

    return {
        transactions,
        previousTransactions,
        approvedWithdrawals,
        isActive,
        field,
        clock,
        isPrePaidBalanceActive,
        totalWithdrawalAmount,
        totalWithdrawalsCount,
        isTransactionsListEmpty,
        isDurationExceed24Hours,
        isWorkspaceHasWithdrawalRequests,
        isWithdrawalAmountOverdraft,
        hasDeposits,
        hasWithdrawals,
        hasPreviousShift,
        isPreviousTransactionsListEmpty,
        balanceLimit,

        duration,
        updateClock,
        updateTransactionsList,
        updateApprovedWithdrawalsList,
        temporaryAdjustTransaction,
        tmpAdjustCashBalance,
        tmpAdjustPrePaidBalance,
        setActiveShiftData,
    };
});

export default ShiftState;