<template>
  <header class="header">
    <location type="desktop" class="header__location" />
    <div
        v-if="$shift.isActive"
        class="header__balance header-balance"
    >
      <div class="header-balance__inner">
        <div class="header-balance__current header-balance-current">
          <span class="header-balance-current__value">{{ $workspace.amountInWorkspaceCurrency($shift.field('currentBalance')) }}</span>
          <span class="header-balance-current__subtitle">{{ $T.text('shift-current-balance-title') }}</span>
        </div>
        <div
            v-if="$shift.isPrePaidBalanceActive"
            class="header-balance__prepaid header-balance-prepaid"
        >
          <span class="header-balance-prepaid__sum">{{ $workspace.amountInWorkspaceCurrency($shift.field('prepaidBalance')) }}</span>
          <balance-limit
              :status="balanceLimitStatus"
              :percentage="$shift.balanceLimit"
              class="header-balance-prepaid__limit"
          />
          <div class="header-balance-prepaid__text">
            <span class="header-balance-prepaid__subtitle">{{ $T.text('top-bar-pre-paid-balance-info') }}</span>
<!--            <SvgElem-->
<!--              :class="['header-balance-prepaid__info&#45;&#45;is-' + balanceLimitStatus]"-->
<!--              :tooltip="{pos: 'is-bottom', label: balanceLimitInfo[0]}"-->
<!--              class="header-balance-prepaid__info"-->
<!--              sprite="cleared"-->
<!--              to="information&#45;&#45;filled"-->
<!--              size="size-xxs"-->
<!--              tooltip-label="Test"-->
<!--            />-->
          </div>
        </div>
      </div>
    </div>
    <div v-else class="header__balance header-balance"></div>
    <!-- Список иконок статус бара: -->
    <ico-list class="header__statuses" />
    <!-- Часы продолжительности смены -->
    <div class="header__time header-time">
      <span
          @click="toggleSessionControl"
          :class="shiftDurationLabel"
          class="header-time__current"
      >
        {{ ($shift.isActive) ? $shift.clock?.duration : '--:--'}}
      </span>
      <transition name="session-control">
        <session-control v-if="showSessionControl" class="header-time__control"/>
      </transition>
    </div>
    <!-- Меню аккаунта кассира -->
    <cashier-top-menu />
  </header>
</template>

<style lang="scss" scoped>
  @import '~scss-desktop/common/components/top-bar/_top-bar';
</style>

<script lang="ts">
const CMP_NAME = 'TopBar';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {computed, ref}
    from "@vue/runtime-core";

import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useBalanceLimit
  from "~interaction/Shift/useBalanceLimit";

/** IMPORTED VUE TEMPLATES: */
import CashierTopMenu
  from "~tpl-desktop/workspace/TopBar/CashierTopMenu.vue";
import SessionControl
  from "~tpl-desktop/workspace/TopBar/SessionControl.vue";
import Location
  from "~tpl-global/Location/Location.vue";
import IcoList
  from "~tpl-desktop/workspace/TopBar/IcoList.vue";
import BalanceLimit
    from "~tpl-global/BalanceLimit/BalanceLimit.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $workspace,
    $shift,
} = usePiniaState();

const {
    balanceLimitInfo,
    balanceLimitStatus,
} = useBalanceLimit();

/**
 * Выбор стиля для подсветки продолжительности смены.
 */
const shiftDurationLabel = computed<string>(() => {
  const map = {
    disabled: 'header-time__current--is-disabled',
    active:   'header-time__current--is-active',
    warn:     'header-time__current--is-warning',
    exceed:   'header-time__current--is-exceed',
  };

  return (!$shift.isActive)
      ? map.disabled
      : map[$shift.clock?.label ?? 'disabled'];
});

const showSessionControl = ref<boolean>(false);

function toggleSessionControl() {
  return showSessionControl.value = !showSessionControl.value;
}
</script>