import {Container}
    from "inversify";
import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import {AppInterfaceType}
    from "~app/Epos/Enum/AppInterfaceType";
import {WorkspaceLogicModule}
    from "~app/Epos/WorkspaceLogicModule";
import AVAILABLE_BETTING_LOCALES
    from "~app/Betting/BettingLocales.tech";
import useBettingModule
    from "~app/IoC/useBettingModule";

/**
 * Задание на обновление списка принятых за смену купонов, для касс,
 * поддерживающих данный функционал.
 */
const CouponsPerShiftUpdateInterval: IntervalTaskContract = {
    requiredInterface: [
        AppInterfaceType.EPOS_DESKTOP,
    ],

    requiredModules: [
        WorkspaceLogicModule.COUPON_OVERVIEW,
    ],

    allowedLocales: AVAILABLE_BETTING_LOCALES,

    intervalMs:        12 * 1000,
    startOnInit:       true,
    authRequired:      true,
    cashierRequired:   true,
    shiftRequired:     true,

    id:       'coupons-per-shift-update',
    sequence: 'shift-data',
    payload:  async function (
        ioc: Container
    ): Promise<void>
    {
        const {
            $bettingModule,
        } = useBettingModule();

        await $bettingModule
            .updateBetsPerShiftList();
    }
};

export default CouponsPerShiftUpdateInterval;