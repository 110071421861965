export enum BettingErrors {
    BETTING_UNEXPECTED_ERROR                  = 101001,
    BETTING_LOGIC_ERROR                       = 101002,
    MARKETS_ERROR_OUTCOME_SELECT              = 101003,
    INTERFACE_MODULE_BETTING_ERROR            = 101004,
    COUPON_PER_SHIFT_FETCH_ERROR              = 101007,
    WORKSPACE_FEED_UNEXPECTED_ERROR           = 101008,
    FEED_UNEXPECTED_ERROR                     = 101009,
    FEED_API_FETCH_ERROR                      = 101010,
    MARKETS_UNEXPECTED_ERROR                  = 101011,
    MARKETS_FETCH_ERROR                       = 101012,
    MARKETS_EVENT_MISMATCH                    = 101013,
    WORKSPACE_FEED_CACHE_STORE_ERROR          = 101014,
    WORKSPACE_FEED_CACHE_EXTRACT_ERROR        = 101015,
    BET_SETTINGS_ERROR_INCORRECT_PAYMENT_TYPE = 101016,
    COMMIT_BET_HOLD_IN_CHAIN_ERROR            = 101017,
    COMMIT_BET_ERROR_INCORRECT_RESTRICTIONS   = 101018,
    COMMIT_BET_UNEXPECTED_ERROR               = 101019,
    BIND_BET_UNEXPECTED_ERROR                 = 101020,
    BIND_BET_INCORRECT_PARAMETERS_PROVIDED    = 101021,
    COUPON_OUTCOME_ERROR                      = 101022,
}