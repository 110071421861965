import {inject, injectable}
    from 'inversify';
import HttpClient
    from "~lib/client/HttpClient";
import RoutesPool
    from "~app/Epos/RoutesPool";
import {array}
    from "~lib/CustomTypes";

import usePiniaState
    from "~interaction/Store/usePiniaState";
import Customer
    from "~app/Customer/Models/Customer";
import * as SrvHelper
    from "~app/Other/Api/ApiService.helpers";
import * as Search
    from "~app/Customer/Routes/CustomerSearch.route";
import * as CustomerReplenish
    from "~app/Customer/Routes/CustomerReplenish.route"
import * as CustomerWithdrawal
    from "~app/Epos/Routes/PayOffWithdrawalRequest.route";
import * as CustomerRefund
    from "~app/Customer/Routes/CustomerRefund.route";
import {ApiStatusCode}
    from "~app/Other/Api/ApiStatusCode";
import {ServiceMethodResponse}
    from "~app/Epos/Contracts/ServiceMethodResponse";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

@injectable()
export default class CustomerService {

    public static readonly SONAR = Symbol
        .for('CustomerService');

    private readonly client: HttpClient;
    private readonly routes: RoutesPool;

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
        @inject(HttpClient.SONAR) client: HttpClient,
        @inject(RoutesPool.SONAR) routes: RoutesPool,
    )
    {
        this.client = client;
        this.routes = routes;
    }

    /**
     * Поиск аккаунта гостя/игрока по указанным параметрам.
     *
     * В случае нахождения аккаунта вернется его модель, иначе
     * будет возвращено сообщение от API. В случае,
     * если сообщение от API пустое - вернется "null".
     *
     * @param payload - Параметры поиска. Если передано число,
     *                  то поиск будет произведен по идентификатору гостя.
     *                  В случае строки поиск будет произведен по номеру телефона.
     */
    public async search(
        payload: number|string
    ): Promise<Customer|number|string|null>
    {
        const requestBody: Search.Request = {
            id: ('number' === typeof payload) && !Number.isNaN(payload)
                ? payload
                : null,
            phone: ('string' === typeof payload)
                ? payload
                : null,
        };

        for (const key of Object.keys(requestBody)) {
            const value = requestBody[key as unknown as keyof Search.Request];

            if (null === value) {
                delete requestBody[key as unknown as keyof Search.Request];
            }
        }

        const searchRq = await this.client
            .post<Search.Response, Search.Request>(
                this.routes.route('customer-search'),
                requestBody
            );

        SrvHelper
            .checkApiResponse(searchRq);

        if (!searchRq.isSuccess) {
            return ApiStatusCode.INTERNAL_SERVICE_THROTTLING === searchRq.code
                ? searchRq.code
                : searchRq.message;
        }

        return new Customer(
            searchRq.response as unknown as array
        );
    }

    /**
     * Запрос на пополнение аккаунта гостя.
     */
    public async replenish(
        payload: {
            token:  string,
            amount: number
        }
    ): Promise<CustomerReplenish.SuccessfulReplenishData|string|null>
    {
        const replenishRq = await this.client
            .post<CustomerReplenish.Response, CustomerReplenish.Request>(
                this.routes.route('customer-replenish'),
                {
                    token:  payload.token,
                    amount: payload.amount,
                }
            );

        SrvHelper
            .checkApiResponse(replenishRq);

        if (!replenishRq.isSuccess) {
            return replenishRq.message;
        }

        const {
            $shift,
        } = usePiniaState();

        // Корректировка отображаемого баланса кассы
        // после успешного принятия депозита:
        $shift
            .tmpAdjustCashBalance(
                (replenishRq.response as unknown as {amount: number})
                    .amount
            );

        return replenishRq.response as
            unknown as
            CustomerReplenish.SuccessfulReplenishData;
    }

    /**
     * Запрос на вывод средств с аккаунта гостя.
     */
    public async withdrawal(
        payload: CustomerWithdrawal.Request
    ): Promise<CustomerWithdrawal.Response|string|null>
    {
        const withdrawalRq = await this.client
            .post<CustomerWithdrawal.Response, CustomerWithdrawal.Request>(
                this.routes.route('customer-withdrawal'),
                payload
            );

        SrvHelper
            .checkApiResponse(withdrawalRq);

        if (!withdrawalRq.isSuccess) {
            return withdrawalRq.message;
        }

        const {
            $shift,
        } = usePiniaState();

        // Корректировка отображаемого баланса кассы
        // после успешного вывода денежных средств:
        $shift
            .tmpAdjustCashBalance(
                (withdrawalRq.response?.amount ?? 0) * -1
            );

        return withdrawalRq.response as
            unknown as
            CustomerWithdrawal.Response;
    }

    /**
     * Запрос на возврат средств, внесенных ранее
     * через функционал пополнения аккаунта игрока.
     *
     * Возврат доступен в течение примерно 30 минут
     * после проведения транзакции, и если средства
     * еще не были потрачены.
     */
    public async depositRefund(
        payload: CustomerRefund.Request
    ): Promise<ServiceMethodResponse<{
        customerId?:    number;
        transactionId?: number;
        amount?:        number;
    }>>
    {
        const {
            $shift
        } = usePiniaState();

        if (!$shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   "DepositRefund",
                message: "Opened shift is required to initiate deposit refund."
            });
        }

        const refundRq = await this.client
            .post<CustomerRefund.Response, CustomerRefund.Request>(
                this.routes.route('customer-refund'),
                payload
            )

        SrvHelper
            .checkApiResponse(refundRq);

        if (!refundRq.isSuccess) {
            return {
                isSuccess: false,
                message:   refundRq.message ?? null,
                code:      0,
            }
        }

        // Корректировка отображаемого баланса кассы
        // после успешного вывода денежных средств:
        $shift
            .tmpAdjustCashBalance(
                (refundRq.response?.amount ?? 0) * -1
            );

        return {
            isSuccess: true,
            message:   refundRq.message ?? null,
            code:      0,

            payload: {
                customerId:    refundRq.response?.customerId,
                transactionId: refundRq.response?.transactionId,
                amount:        refundRq.response?.amount
            }
        };
    }
}