import {inject, injectable}
    from 'inversify';

import MsgpackEncoder
    from "~lib/msgpack/Encoder";
import IndexedDb
    from "~lib/indexeddb/IndexedDb";
import {TABLE_WORKSPACE, TABLE_LOCALIZATION, WorkspaceDataDB}
    from "~app/Providers/AppInit/WorkspaceDb.provider";
import {Model}
    from "~lib/eloquent/Model";

import Credentials
    from "~app/Epos/Models/Credentials";
import Settings
    from "~app/Epos/Models/Settings";
import Cashier
    from "~app/Epos/Models/Cashier";
import WorkingShift
    from "~app/Epos/Models/WorkingShift";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";
import MsgpackDecoder from "~lib/msgpack/Decoder";

@injectable()
export default class StorageModule {

    public static readonly SONAR: symbol = Symbol
        .for('StorageModule');

    private readonly db: IndexedDb;

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
        @inject(WorkspaceDataDB) db: IndexedDb,
    )
    {
        this.db = db;
    }

    /**
     * Сохранение моделей с данными состояния
     * рабочего места в IndexedDB.
     *
     * @param payload - Сохраняемая модель данных.
     */
    public async storeWorkspaceDataIntoDB(
        payload: Credentials|Settings|Cashier|WorkingShift
    ): Promise<void>
    {
        if (!(payload instanceof Model)) {
            throw new RuntimeException({
                isFatal: true,
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                message: `Method 'storeWorkspaceDataIntoDB' accepted instance of 'Model' only,` +
                         ` but '${typeof payload}' has been provided.`,
            });
        }

        await this.db
            .put<Uint8Array>(
                TABLE_WORKSPACE,
                MsgpackEncoder.encode(payload.toArray()),
                payload.modelName().toString(),
            )
            .catch((throwable): never => {
                throw new RuntimeException({
                    isFatal:  true,
                    code:     EposCoreErrors.FAILED_TO_STORE_WORKSPACE_DATA_TO_DB,
                    previous: throwable,
                    message:  `Failed to store workspace data with key '${payload.modelName().toString()}'` +
                              ` for table '${TABLE_WORKSPACE}' into IndexedDB.`,
                });
            });
    }

    /**
     * Поиск в кеше данных запрошенной модели рабочего места.
     *
     * В случае успеха, данные будут извлечены, декодированы
     * и загружены в модель. После чего обновленная модель будет возвращена.
     *
     * Будет возвращено значение "NULL", если в кеше не будет
     * найдены данные запрошенной модели.
     *
     * @param payload - Запрашиваемая модель.
     */
    public async getWorkspaceDataFromDB<T extends (Credentials|Settings|Cashier|WorkingShift)>(
        payload: T
    ): Promise<T|null>
    {
        // Попытка извлечение из кеша
        // сохраненных данных модели:
        const cache: Uint8Array|null = await this.db
            .get<Uint8Array>(
                TABLE_WORKSPACE,
                payload.modelName().toString(),
            )
            .catch((throwable): never => {
                throw new RuntimeException({
                    isFatal:  true,
                    code:     EposCoreErrors.WORKSPACE_CACHE_EXTRACTION_ERROR,
                    previous: throwable,
                    message:  `Failed to extract workspace data from db table: '${TABLE_WORKSPACE}',` +
                              ` for model name: '${payload.modelName().toString()}'.`
                });
            });

        if (null === cache) {
            return null;
        }

        try
        {
            // Декодирование и загрузка данных в модель:
            payload
                .fillFromArrayFeed(
                    MsgpackDecoder.decode(cache)
                );

            return payload;
        }

        catch (throwable)
        {
            throw new RuntimeException({
                isFatal:  true,
                code:     EposCoreErrors.WORKSPACE_CACHE_EXTRACTION_ERROR,
                previous: throwable,
                message:  `An error occurred while trying to decode cache data` +
                          ` for model name: '${payload.modelName().toString()}'.`
            });
        }
    }

    /**
     * Удаление данных переданной модели из IndexedDB.
     *
     * @param payload - Модель, данные которой необходимо
     *                  удалить из хранилища.
     */
    public async removeWorkspaceDataFromDB(
        payload: Credentials|Settings|Cashier|WorkingShift
    ): Promise<boolean>
    {
        if (!(payload instanceof Model)) {
            throw new RuntimeException({
                isFatal: true,
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                message: `Method 'removeWorkspaceDataFromDB' accepted instance of 'Model' only,` +
                         ` but '${typeof payload}' has been provided.`,
            });
        }

        return await this.db
            .remove(
                TABLE_WORKSPACE,
                payload.modelName().toString()
            );
    }

    /**
     * Запись каких-либо данных, относящихся к локализации интерфейса,
     * в хранилище IndexedDB.
     *
     * ВНИМАНИЕ!
     * Перед записью, данные будут кодированы в бинарный формат
     * с помощью MessagePack.
     *
     * @param key     - Ключ хранимых данных.
     * @param payload - Записываемые данные.
     */
    public async storeLocaleData(
        key:     string,
        payload: any
    ): Promise<void>
    {
        await this.db
            .put(
                TABLE_LOCALIZATION,
                MsgpackEncoder.encode(payload),
                key,
            )
            .catch((throwable): never =>
            {
                throw new RuntimeException({
                    code:    EposCoreErrors.TEXTS_LIBRARY_CACHE_ERROR,
                    isFatal: true,
                    previous: throwable,
                    message: `Failed to store locale data in table '${TABLE_LOCALIZATION}'` +
                             ` for key: '${key}'.`,
                });
            });
    }

    /**
     * Поиск, в хранилище данных локализации интерфейса,
     * данных по запрошенному ключу.
     *
     * В случае, если по запрошенному ключу ничего не найдено,
     * то вернется значение "NULL".
     *
     * ВНИМАНИЕ!
     * Декодирование из формата MessagePack происходит автоматически.
     *
     * @param key - Ключ хранимых данных.
     */
    public async extractLocaleData<T = any>(
        key: string
    ): Promise<T|null>
    {
        const cache: Uint8Array|null = await this.db
            .get<Uint8Array>(
                TABLE_LOCALIZATION,
                key
            )
            .catch((throwable): never => {
                throw new RuntimeException({
                    code:     EposCoreErrors.TEXTS_LIBRARY_CACHE_ERROR,
                    isFatal:  true,
                    previous: throwable,
                    message:  `Failed to EXTRACT locale data for key: '${key}'` +
                              ` from table '${TABLE_WORKSPACE}'.`
                });
            });

        if (null === cache) {
            return null;
        }

        try {
            return MsgpackDecoder.decode<T>(
                cache
            );
        }

        catch (throwable)
        {
            throw new RuntimeException({
                code:     EposCoreErrors.TEXTS_LIBRARY_CACHE_ERROR,
                isFatal:  true,
                previous: throwable,
                message:  `Failed to DECODE locale data for key: '${key}'` +
                          ` from table '${TABLE_WORKSPACE}'.`
            });
        }
    }

    /**
     * Запрос на удаление хранимых данных локализации интерфейса.
     *
     * @param key - Ключ хранимых данных.
     */
    public async removeLocaleData(
        key: string
    ): Promise<boolean>
    {
        return await this.db
            .remove(
                TABLE_LOCALIZATION,
                key
            );
    }
}