import {Outcome}
    from "~app/Betting/Contracts/Feed.types";

/**
 * Интерфейс сохранен от оригинального плагина
 * для более удобного редактирования в случае изменений.
 */
type SearchAndReplaceSettings = {
    dopValue:     number;
    playerName:   string|null;
    betId:        number|null;
    sportId:      number|null;
    betName:      string;
    templateType: number|null;
    groupId:      number|null;
    lng:          string;
}

export default class OutcomeNameParser
{
    public static parse(
        sportId: number,
        lang:    string,
        outcome: Outcome
    ): string
    {
        const search: SearchAndReplaceSettings = {
            dopValue:     outcome.parameter ?? 0,
            playerName:   outcome.playerName,
            betId:        outcome.playerId,
            sportId:      sportId,
            betName:      outcome.name,
            templateType: outcome.templateId,
            groupId:      outcome.groupId,
            lng:          lang,
        };

        return this
            .replaceByTemplateType(search);
    }

    /**
     * До переосмысливания метод
     * перенесен как есть.
     *
     * @param betName
     * @param args
     * @param groupId
     * @param lng
     * @private
     */
    private static replaceAllRTL(
        betName: string        = '',
        args:    Array<number|string> = [], //TODO: очень странная логика, смотри if
        groupId: number|null,
        lng:     string
    ) {
        let aRTL_groups = [136, 2472],
            aRTL_langs  = ['fa'];
        let isString = function(val: any) {
            return typeof val === 'string' || ((!!val && typeof val === 'object') && Object.prototype.toString.call(val) === '[object String]');
        };
        if (aRTL_langs.indexOf(lng) !== -1 && groupId && aRTL_groups.indexOf(groupId) !== -1) {
            // Разбор для RTL языков
            for (let i = args.length - 1; i >= 0; i--) {
                betName = betName.replace(isString(args[i]) ? '[]' : '()', `${args[i]}`);
            }
        } else {
            for (let i = 0; i < args.length; i++) {
                betName = betName.replace(isString(args[i]) ? '[]' : '()', `${args[i]}`);
            }
        }
        return betName;
    }

    /**
     * До переосмысливания метод взят как есть
     * с минимальной корректировкой.
     *
     * @param settings
     * @private
     */
    private static replaceByTemplateType(settings: SearchAndReplaceSettings): string {
        let dopValue     = settings.dopValue,
            playerName   = settings.playerName,
            betId        = settings.betId,
            sportId      = settings.sportId,
            betName      = settings.betName,
            templateType = settings.templateType,
            groupId      = settings.groupId,
            lng          = settings.lng;

        let c0 = undefined;
        let c1 = undefined;
        let c2 = undefined;
        let c3 = undefined;
        let c4 = undefined;
        let c5 = undefined;
        let c6 = undefined;

        let tmpBuffer   = undefined;
        let strDopValue = undefined;

        switch (templateType) {
            case 1:
                return betName;

            case 3:
                if (groupId && !([2, 109, 2234, 2854].includes(groupId))) {
                    return betName.replace('()', `${dopValue}`);
                }

                return betName.replace(
                    '()',
                    (dopValue > 0)
                        ? `(+${dopValue})`
                        : `(${dopValue})`
                );

            case 5:
                c0 = Math.trunc(dopValue);
                c1 = +(((dopValue - c0) * 1000).toFixed(2));

                if (groupId === 311) {
                    c1 /= 10;
                }

                return  OutcomeNameParser.replaceAllRTL(betName, [c0, c1], groupId, lng);

            case 7:
                return (!playerName)
                    ? betName
                    : betName.replace('[]', playerName);

            case 9:
                if (!playerName) {
                    return betName;
                }

                return betName
                    .replace('[]', playerName)
                    .replace(
                        '()',
                        (groupId === 3388 && dopValue > 0)
                            ? `(+${dopValue})`
                            : `(${dopValue})`
                    );

            case 11:
                if (!playerName) {
                    return betName;
                }

                c0 = Math.trunc(dopValue);
                c1 = parseInt(((dopValue - c0) * 1000).toFixed());

                return betName
                    .replace('[]', playerName)
                    .replace('()', `${c0}`)
                    .replace('()', `${c1}`);


            case 13:
                c0 = Math.trunc(dopValue);
                c1 = parseFloat(((dopValue - c0) * 100).toFixed(2));

                if (betId == 1965 || betId == 1966) {
                    c1 = Math.abs(c1);
                }

                return betName
                    .replace('()', `${c0 / 100}`)
                    .replace('()', `${c1}`);


            case 15:
                c0 = Math.trunc(dopValue);
                c1 = Math.round(+((dopValue - c0) * 1000).toFixed(5));

                return betName
                    .replace('()', `(${c0 / 1000})`) //TODO: у Ботевае c0 / 1000 без скобок
                    .replace('()', `${c1}`);

            case 17:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);

                if (betId == 1195 || betId == 1196) {
                    c1 = Math.abs(c1);
                }

                return betName
                    .replace('()', `${c0 / 100}`)
                    .replace('()', `${c1}`);

            case 21:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);
                c2 = `${c1 + 1}`; // TODO: было parseInt(c1), но по типу смысла в этом нет

                c2 += (sportId == 1 && (c2 === '44' || c2 === '89'))
                    ? ':59+'
                    : ':59';

                return betName
                    .replace('()', `${c0 / 100}`)
                    .replace('(1)', c1 + ':00')
                    .replace('(2)', c2);


            case 23:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);
                c2 = `${c1 + 4}`;

                c2 += (sportId == 1 && (c2 == '44' || c2 == '89'))
                    ? ':59+'
                    : ':59';

                return betName
                    .replace('()', `${c0 / 100}`)
                    .replace('(1)', c1 + ':00')
                    .replace('(5)', c2);

            case 25:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);
                c2 = `${c1 + 9}`;

                c2 += (sportId == 1 && (c2 == '44' || c2 == '89'))
                    ? ':59+'
                    : ':59';

                return betName
                    .replace('()', `${c0 / 100}`)
                    .replace('(1)', c1 + ':00')
                    .replace('(10)', c2);

            case 27:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);
                c2 = `${c1 + 14}`;

                c2 += (sportId == 1 && (c2 == '44' || c2 == '89'))
                    ? ':59+'
                    : ':59';

                return betName
                    .replace('()', `${c0 / 100}`)
                    .replace('(1)', c1 + ':00')
                    .replace('(15)', c2);

            case 29:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);
                c2 = `${c1 + 29}`;

                c2 += (sportId == 1 && (c2 == '44' || c2 == '89'))
                    ? ':59+'
                    : ':59';

                return betName
                    .replace('()', `${c0 / 100}`)
                    .replace('(1)', c1 + ':00')
                    .replace('(30)', c2);

            case 31:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);

                return betName
                    .replace('()', `${c0}:00`)
                    .replace('()', `${c1}:59`);

            case 33:
                return betName.replace(
                    '()',
                    (dopValue === 0)
                        ? ''
                        : `${dopValue}`
                );

            case 35:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);
                c2 = +((c0 / 100 - Math.trunc(c0 / 100)) * 100).toFixed(5);

                return betName
                    .replace('()', `${c2}`)
                    .replace('()', `${c1}`)
                    .replace('()', `${Math.trunc(c0 / 100)}`);

            case 37:
                return betName
                    .replace('()', `${Math.trunc(dopValue)}`);

            case 39:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);

                return betName
                    .replace('()', `${c1}`)
                    .replace('()', `${c0}`);

            case 41:
                return betName.replace(
                    '()',
                    (dopValue > 0)
                        ? `${dopValue}:0`
                        : `0:${Math.abs(dopValue)}`
                );

            case 43:
                c0 = betId && ([2144, 2145, 2257, 2258].includes(betId))
                    ? Math.abs(Math.trunc(dopValue))
                    : Math.trunc(dopValue);
                c1 = +(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2));

                return betName
                    .replace('()', `${c0}`)
                    .replace('()', `${c1}`);

            case 45:
                c0 = betId && ([2255, 2256].includes(betId))
                    ? Math.abs(Math.trunc(dopValue))
                    : Math.trunc(dopValue);
                c1 = +(((dopValue - Math.trunc(dopValue)) * 1000).toFixed(2));

                return betName
                    .replace('()', `${c0}`)
                    .replace('()', `${c1}`);

            case 49:
                if (!playerName) {
                    return betName;
                }

                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000).toFixed(5);

                return betName
                    .replace('[]', playerName)
                    .replace('()', `${c0 / 100}`)
                    .replace('()', `${c1}`);

            case 51:
                if (!playerName) {
                    return betName;
                }

                c0 = Math.trunc(dopValue);
                c1 = (((dopValue / 1000) - Math.trunc(c0 / 1000)) * 1000).toFixed();
                c2 = ((dopValue - c0) * 1000).toFixed();

                return betName
                    .replace('[]', playerName)
                    .replace('()', `${Math.trunc(c0 / 1000)}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 53:
                c0 = Math.trunc(dopValue);
                c1 = (((c0 / 100) - Math.trunc(c0 / 100)) * 100).toFixed(5);
                c2 = (parseFloat(((dopValue - c0) * 1000).toFixed(2))).toFixed(5);

                return betName
                    .replace('()', `${Math.trunc(c0 / 100)}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 55:
                if (!playerName) {
                    return betName;
                }

                c0 = Math.trunc(dopValue);
                c1 = (parseFloat(((dopValue - c0) * 100).toFixed(2))).toFixed(5);

                return betName
                    .replace('[]', playerName)
                    .replace('()', `${c0}`)
                    .replace('()', c1);

            case 59:
                if (!playerName) {
                    return betName
                }

                c0 = Math.trunc(dopValue);
                c1 = (parseFloat(((dopValue - c0) * 1000)
                    .toFixed(2)))
                    .toFixed(5);

                return betName
                    .replace('[]', playerName)
                    .replace('()', `${c0}`)
                    .replace('()', c1);

            case 61:
                c0 = Math.trunc(dopValue);
                c1 = ((c0 / 100 - Math.trunc(c0 / 100) * 100)).toFixed(5);
                c2 = (((dopValue - c0) * 1000).toFixed(2));

                return betName
                    .replace('()', `${Math.trunc(c0 / 100)}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 65:
                c0 = Math.trunc(dopValue);
                c1 = ((((dopValue / 1000) - Math.trunc(c0 / 1000)) * 1000).toFixed());
                c2 = ((((dopValue) - c0) * 1000).toFixed(2));

                return betName
                    .replace('()', `${Math.trunc(c0 / 1000)}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 69:
                if (!playerName) {
                    return betName;
                }

                c0 = Math.trunc(dopValue);
                c1 = (((dopValue / 100) - Math.trunc(c0 / 100)) * 100).toFixed();
                c2 = ((dopValue - c0) * 1000).toFixed(2);

                return betName
                    .replace('()', `${Math.trunc(c0 / 100)}`)
                    .replace('()', c1)
                    .replace("[]", playerName)
                    .replace('()', c2);

            case 71:
                c0 = Math.trunc(dopValue);
                c1 = ((c0 - +((dopValue - c0) * 10).toFixed(5))).toFixed();
                c2 = ((c0 + +((dopValue - c0) * 10).toFixed(5))).toFixed();

                return betName
                    .replace('()', c1)
                    .replace('()', c2);

            case 75:
                c0 = Math.trunc(dopValue);
                c1 = Math.trunc((dopValue - c0) * 100);
                const a = ((dopValue - c0) - Math.trunc((dopValue - c0))) * 100;
                c2 = +((((a) - Math.trunc(a)) * 100).toFixed());

                return betName
                    .replace('()', `${c0 / 100}`)
                    .replace('()', `${Math.abs(c1)}`)
                    .replace('()', `${Math.abs(c2)}`);

            case 77:
                if (!playerName) {
                    return betName;
                }

                betName.replace(
                    '()',
                    (dopValue > 0)
                        ? `${dopValue}:0`
                        : `0:${Math.abs(dopValue)}`
                );

                return betName.replace('[]', playerName);

            case 81:
                c0 = Math.trunc(dopValue);
                c1 = +((((c0 / 100) - Math.trunc(c0 / 100)) * 100).toFixed(2));
                const b = (dopValue - Math.trunc(dopValue)) * 100;
                c2 = Math.trunc(parseFloat(b.toFixed(2)));
                c3 = +((parseFloat(b.toFixed(2)) - parseInt(b.toFixed(2))) * 100).toFixed(5);

                return betName
                    .replace('()', `${Math.trunc(c0 / 100)}`)
                    .replace('()', `${c1}`)
                    .replace('()', `${c2}`)
                    .replace('()', `${c3}`);

            case 83:
                c0 = Math.trunc(dopValue);
                c1 = (((c0 / 100) - Math.trunc(c0 / 100)) * 100).toFixed(2);
                c2 = ((dopValue - Math.trunc(dopValue)) * 100).toFixed(2);

                return betName
                    .replace('()', `${Math.trunc(c0 / 100)}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 85:
                c0 = Math.trunc(dopValue);
                c1 = (((c0 / 10000000) - Math.trunc(c0 / 10000000)) * 100).toFixed(1);
                c2 = (+((c0 / 10000) - Math.trunc(c0 / 10000)).toFixed(2) * 100).toFixed(2);
                c3 = (((c0 / 100) - Math.trunc(c0 / 100)) * 100).toFixed(5);
                c4 = parseInt(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2));
                c5 = ((parseFloat(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2)) - parseInt(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2))) * 100).toFixed(5);

                return betName
                    .replace('()', c1)
                    .replace('()', c2)
                    .replace('()', c3)
                    .replace('()', `${c4}`)
                    .replace('()', c5);

            case 87:
                c0 = Math.trunc(dopValue);
                c1 = (((c0 / 10000) - Math.trunc(c0 / 10000)) * 10).toFixed(1);
                c2 = Math.trunc((((c0 / 100) - Math.trunc(c0 / 100)) * 100));
                c3 = Math.trunc(parseFloat(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2)));
                c4 = ((parseFloat(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2)) - parseInt(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2))) * 100).toFixed(5);

                return betName
                    .replace('()', c1)
                    .replace('()', `${c2}`)
                    .replace('()', `${c3}`)
                    .replace('()', c4);

            case 89:
                c0 = Math.trunc(dopValue);
                c1 = (((c0 / 100) - Math.trunc(c0 / 100)) * 100).toFixed(5);
                c2 = (((dopValue - c0) * 1000).toFixed(2));

                return betName
                    .replace('()', `${Math.trunc(c0 / 100)}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 91:
                return this.replaceByTemplateType(
                    Object.assign(
                        {},
                        settings,
                        { templateType: 145 }
                    )
                );

            case 93:
                c0 = Math.trunc(dopValue);
                c1 = Math.trunc(c0 / 100);
                c2 = +(((c0 / 100) - Math.trunc(c0 / 100))).toFixed(5) * 100;
                c3 = parseInt(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2));
                c4 = +((parseFloat(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2)) - parseInt(((dopValue - Math.trunc(dopValue)) * 100).toFixed(2))) * 100).toFixed(5);

                return betName
                    .replace('()', `${c1}:${c2}`)
                    .replace('()', `${c3}`)
                    .replace('()', `${c4}`);

            case 95:
                c0 = Math.trunc(dopValue);
                c1 = ((c0 / 100)).toFixed(5);
                c2 = (((dopValue - Math.trunc(dopValue)) * 100)).toFixed(5);

                return betName
                    .replace('()', c1)
                    .replace('()', c2);

            case 97:
                c0 = Math.trunc(dopValue);
                c1 = ((c0 / 1000)).toFixed(5);
                c2 = (((dopValue - Math.trunc(dopValue)) * 10)).toFixed(5);

                return betName
                    .replace('()', c1)
                    .replace('()', c2);

            case 99:
                return this.replaceByTemplateType(
                    Object.assign(
                        {},
                        settings,
                        { templateType: 145 }
                    )
                );

            case 101:
                c0 = Math.trunc(dopValue);
                c1 = ((dopValue - c0) * 1000).toFixed(5);
                c2 = (((dopValue - Math.trunc(c0)) * 1000 + 0.5)).toFixed(5);

                return betName
                    .replace('()', `${c0}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 103:
                c0 = Math.trunc(dopValue);
                c1 = ((dopValue - Math.trunc(c0)) * 1000).toFixed(5);
                c2 = (Math.trunc(dopValue) - 0.5).toFixed(5);

                return betName
                    .replace('()', `${c0}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 105:
                c0 = Math.trunc(dopValue);
                c1 = (((c0 / 100) - Math.trunc(c0 / 100)) * 100).toFixed(2);
                c2 = ((dopValue - Math.trunc(dopValue)) * 1000).toFixed(2);

                return betName
                    .replace('()', `${Math.trunc(c0 / 100)}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 107:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 91 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 5 })
                );

            case 111:
                c0 = Math.trunc(dopValue);
                c1 = Math.trunc(c0 / 1000000);
                c2 = Math.trunc(((c0 / 1000000) - Math.trunc(c0 / 1000000)) * 100);
                c3 = Math.trunc(((c0 / 10000) - Math.trunc(c0 / 10000)) * 100);
                c4 = (+((c0 / 100) - Math.trunc(c0 / 100)).toFixed(2) * 100).toFixed(2);
                c5 = Math.trunc((dopValue - c0) * 100);
                c6 = Math.trunc(+(+(dopValue * 100).toFixed(2) - Math.trunc(dopValue * 100)).toFixed(2) * 100);

                if (playerName) {
                    betName = betName.replace('[]', playerName);
                }

                return betName
                    .replace('()', `${c1}`)
                    .replace('()', `${c2}`)
                    .replace('()', `${c3}`)
                    .replace('()', c4)
                    .replace('()', `${c5}`)
                    .replace('()', `${c6}`);

            case 113:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 91 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 3 })
                );

            case 115:
                c0 = Math.trunc(dopValue);
                c1 = Math.trunc(c0 / 100);
                c2 = Math.trunc(+((c0 / 100) - Math.trunc(c0 / 100)).toFixed(2) * 100);
                c3 = Math.trunc(+(dopValue - c0).toFixed(2) * 100);
                c4 = Math.trunc(+(+(dopValue * 100).toFixed(2) - Math.trunc(dopValue * 100)).toFixed(2) * 100);

                if (playerName) {
                    betName = betName.replace('[]', playerName);
                }

                return betName
                    .replace('()', `${c1}`)
                    .replace('()', `${c2}`)
                    .replace('()', `${c3}`)
                    .replace('()', `${c4}`);

            case 117:
                c0 = Math.trunc(dopValue);
                c1 = (c0 / 1000).toFixed(1);
                c2 = Math.abs((dopValue - c0) * 1000).toFixed(1);

                return betName
                    .replace('()', c1)
                    .replace('()', c2);

            case 119:
                c0 = Math.trunc(dopValue);
                c1 = (c0 / 10000).toFixed(1);
                c2 = (+((c0 / 1000) - Math.trunc(c0 / 1000)).toFixed(5) * 100).toFixed(1);
                c3 = (+((dopValue) - c0).toFixed(5) * 100).toFixed(1);

                return betName
                    .replace('()', c1)
                    .replace('()', c2)
                    .replace('()', c3);

            case 121:
                c0 = Math.trunc(dopValue);
                c1 = (+((dopValue - c0).toFixed(5)) * 10000).toFixed(5);

                return betName
                    .replace('()', `${c0}`)
                    .replace('()', c1);

            case 123:
                c0 = Math.trunc(dopValue);
                c1 = Math.trunc(c0 / 1000000);
                c2 = Math.trunc(+((c0 / 1000000) - Math.trunc(c0 / 1000000)) * 100);
                c3 = parseInt((+((c0 / 10000) - Math.trunc(c0 / 10000)) * 100).toFixed(2));
                c4 = +(+((c0 / 100) - Math.trunc(c0 / 100)).toFixed(2) * 100).toFixed(2);
                c5 = Math.trunc((dopValue - c0) * 100);
                c6 = Math.trunc(+(+(dopValue * 100).toFixed(2) - Math.trunc(dopValue * 100)).toFixed(2) * 100);

                return betName
                    .replace('()', `${c1}`)
                    .replace('()', `${c2}`)
                    .replace('()', `${c3}`)
                    .replace('()', `${c4}`)
                    .replace('()', `${c5}`)
                    .replace('()', `${c6}`);

            case 125:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 91 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 35 })
                );

            case 127:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 7 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 121 })
                );

            case 129:
                if (dopValue === 0) {
                    return betName;
                }

                strDopValue = `${dopValue}`;

                const fraction = strDopValue.split('.')?.[1];
                const minLen   = 6;

                if ((fraction?.length ?? 0) < minLen) {
                    strDopValue = strDopValue.split('.')[0] + '.' + (fraction ?? '') + new Array(6 - (strDopValue.split('.')[1]?.length ?? 0) + 1).join('0');
                }

                c0 = parseInt(strDopValue.slice(-3));
                c1 = parseInt(strDopValue.slice(-6, -3));
                c2 = parseInt(strDopValue.slice(-10, -7));
                c3 = parseInt(strDopValue.slice(-13, -10));

                return betName
                    .replace('()', `${c3}`)
                    .replace('()', `${c2}`)
                    .replace('()', `${c1}`)
                    .replace('()', `${c0}`);

            case 131:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 99 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 35 })
                );

            case 133:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 99 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 3 })
                );
            case 134:
                c0 = Math.trunc(dopValue);
                c1 = ((c0 / 1000)).toFixed(10);
                c2 = (parseFloat((dopValue - c0).toFixed(10)) * 100).toFixed(10);

                return betName
                    .replace('()', c1)
                    .replace('()', c2);

            case 135:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 99 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 5 })
                );

            case 136:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 91 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 81 })
                );

            case 137:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 91 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 123 })
                );

            case 138:
                c0 = Math.trunc(dopValue);
                c1 = ((c0 / 1000)).toFixed(5);
                c2 = (Math.abs(((dopValue - c0) * 1000))).toFixed(5);

                return betName
                    .replace('()', c1)
                    .replace('()', c2);

            case 139:
                c0 = +(dopValue).toFixed(1);
                c1 = Math.round((+(Math.abs(dopValue) - Math.abs(c0)).toFixed(5)) * 100);
                c2 = +((+(Math.abs(dopValue) - +(+(Math.abs(dopValue))).toFixed(2)).toFixed(5)) * 10000).toFixed(5);

                return betName
                    .replace('()', `${c0}`)
                    .replace('()', `${c2 * (c1 ? -1 : 1)}`);

            case 140:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 91 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 13 })
                );

            case 143:
                strDopValue = `${dopValue}`;

                c0 = parseInt(strDopValue.slice(-2));
                c1 = parseInt(strDopValue.slice(-4, -2));
                c2 = parseInt(strDopValue.slice(-7, -5));
                c3 = parseInt(strDopValue.slice(-9, -7));
                c4 = parseInt(strDopValue.slice(-11, -9));
                c5 = parseInt(strDopValue.slice(-13, -11));
                c6 = parseInt(strDopValue.slice(-15, -13))

                return betName
                    .replace('()', `${c6}`)
                    .replace('()', `${c5}`)
                    .replace('()', `${c4}`)
                    .replace('()', `${c3}`)
                    .replace('()', `${c2}`)
                    .replace('()', `${c1}`)
                    .replace('()', `${c0}`);

            case 144:
                c0 = Math.abs(Math.trunc(dopValue));
                c1 = +(dopValue - Math.trunc(dopValue)).toFixed(5) * 1000;

                return betName
                    .replace('()', `${c0}`)
                    .replace('()', `${c1}`);

            case 145:
                if (!playerName) {
                    return betName;
                }

                tmpBuffer = (playerName.split(' - ').length === 1)
                    ? playerName.split('/')
                    : playerName.split(' - ');

                if (!tmpBuffer.length) {
                    return betName;
                }

                for (const name of tmpBuffer) {
                    betName = betName.replace('[]', name);
                }

                return betName;

            case 146:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 145 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 3 })
                );

            case 147:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 145 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 5 })
                );

            case 148:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 99 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 81 })
                );

            case 149:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 99 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 85 })
                );

            case 150:
                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 145 })
                );

            case 151:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 91 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 85 })
                );

            case 152:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 119 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 7 })
                );

            case 153:
                c0 = Math.trunc(dopValue);
                c1 = (((dopValue) - c0) * 1000).toFixed(5);
                c2 = ((c0 / 1000 - Math.trunc(c0 / 1000)) * 1000).toFixed(5);

                return betName
                    .replace('()', `${Math.trunc(c0 / 1000)}`)
                    .replace('()', c2)
                    .replace('()', c1);

            case 154:
                settings.betName = this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 99 })
                );

                return this.replaceByTemplateType(
                    Object.assign({}, settings, { templateType: 153 })
                );

            case 155:
                c0 = Math.trunc(dopValue);
                c1 = +((dopValue - c0) * 1000000).toFixed(5);

                return betName
                    .replace('()', `${c0}`)
                    .replace('()', `${Math.round(c1)}`);

            case 156:
                c0 = Math.trunc(dopValue);
                c1 = (c0 / 10000 % 1 * 10000).toFixed(5);
                c2 = (dopValue % 1 * 10000).toFixed(5);

                return betName
                    .replace('()', `${Math.trunc(c0 / 10000)}`)
                    .replace('()', c1)
                    .replace('()', c2);

            case 157:
                c0 = Math.trunc(dopValue);
                c1 = parseInt((c0 / 100000 % 1 * 100).toFixed(5));
                c2 = parseInt((c0 / 1000 % 1 * 1000).toFixed(5));
                c3 = parseInt((dopValue % 1 * 100).toFixed(5));
                c4 = parseInt((+(dopValue * 100).toFixed(5) % 1 * 100).toFixed(5));
                c5 = parseInt((+(dopValue * 10000).toFixed(5) % 1 * 1000).toFixed(5));

                return betName
                    .replace('()', Math.trunc(c0 / 100000).toString().padStart(2, '0'))
                    .replace('()', c1.toString().padStart(2, '0'))
                    .replace('()', c2.toString().padStart(3, '0'))
                    .replace('()', c3.toString().padStart(2, '0'))
                    .replace('()', c4.toString().padStart(2, '0'))
                    .replace('()', c5.toString().padStart(3, '0'));

            case 158:
                c0 = Math.trunc(dopValue);
                c1 = +(c0 / 100 % 1 * 100).toFixed(5);
                c2 = +(dopValue % 1 * 10000).toFixed(5);

                return betName
                    .replace('()', Math.trunc(c0 / 100).toString().padStart(2, '0'))
                    .replace('()', c1.toString().padStart(2, '0'))
                    .replace('()', c2.toString().padStart(3, '0'));


            default:
                return betName;
        }
    }
}