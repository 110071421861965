import {OnAppInitTask}
    from "~app/Epos/Contracts/Epos.types";

import WorkspaceSyncTask
    from "~app/Task/Init/WorkspaceSync.task";
import TextsLibrarySyncTask
    from "~app/Task/Init/TextsLibrarySync.task";
import UpdateSportsFeedTask
    from "~app/Task/Init/Feeds/UpdateSportsFeed.task";
import UpdateCountriesFeedTask
    from "~app/Task/Init/Feeds/UpdateCountriesFeed.task";

/**
 * Список заданий, который должны быть запущены
 * в момент инициализации интерфейса.
 */
const DefineAppInitTasksList: OnAppInitTask[] = [
    WorkspaceSyncTask,
    TextsLibrarySyncTask,
    UpdateSportsFeedTask,
    UpdateCountriesFeedTask,
];

export default DefineAppInitTasksList;