<template>
  <span
      :class="shellStyles"
      @mouseover="(null !== tooltip)  ? isTooltipVisible = true  : null"
      @mouseleave="(null !== tooltip) ? isTooltipVisible = false : null"
  >
    <svg class="icon__svg">
      <use :href="url"/>
    </svg>
    <tooltip-elem
        v-if="null !== tooltip"
        :icon="tooltip.ico"
        :label="tooltip.label"
        :is-warning="true === tooltip.isWarn"
        :is-showed="isTooltipVisible"
        :position="tooltip.pos ?? 'is-bottom'"
    />
  </span>
</template>

<script lang="ts">
const CMP_NAME = 'SvgElem';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
  defineProps,
  computed,
  ref
} from "@vue/runtime-core";

import {SvgSize}
  from "~tpl-global/svg/SvgSize";
import {SvgList}
  from "~tpl-global/svg/SvgList";
import {StyleExtend}
  from "~tpl-global/StyleExtend";
import useSvgUrlExtractor
    from "~interaction/useSvgUrlExtractor";
import useShellStyles
    from "~interaction/useShellStyles";

/** IMPORTED VUE TEMPLATES: */
import TooltipElem
  from "~tpl-desktop/shared/Tooltip.vue";

/** COMPONENT LOGIC: */
type SpriteType  = 'cleared' | 'colorful';
type TooltipType = {
  ico?:    SvgList,
  label:   string,
  pos?:    'is-top' | 'is-right' | 'is-bottom' | 'is-left',
  isWarn?: boolean
};

type ComponentProps = {
  sprite:       SpriteType,
  to:           SvgList,
  size?:        SvgSize,
  isLoader?:    boolean,
  tooltip?:     TooltipType|null,
  shellStyles?: StyleExtend,
  isMirrored?:  boolean, // отзеркаливание иконки на rtl
};

const props = withDefaults(defineProps<ComponentProps>(), {
  size:        'size-m',
  isLoader:    false,
  tooltip:     null,
  shellStyles: () => []
});

const {
    getSvgUrl
} = useSvgUrlExtractor();

const {
    shellCssConstruct,
} = useShellStyles();

/**
 * Путь до отрисовываемой SVG иконки.
 */
const url = computed<string>(() => {
    return getSvgUrl(props.sprite, props.to);
});

/**
 * Фдаг для переключения отображения тултипа.
 */
const isTooltipVisible = ref<boolean>(false);

/**
 * Стили для оболочки SVG компонента.
 */
const shellStyles = computed<string[]>(() =>
{
    const settings: {[key: string]: boolean} = {
        'icon':              true,
        'icon--has-tooltip': null !== props.tooltip,
        'icon--preloader':   props.isLoader,
        'icon--is-mirrored': props.isMirrored,
    };

    settings[`icon--${props.size}`] = true;

    return shellCssConstruct(settings);
});

</script>

<style lang="scss">
  @import '~scss-shared/components/icon';
</style>
