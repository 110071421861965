export type AvailableList =
    '$' | '€' | '₾' | 'Kč' | 'zł' | '₽' | '₴' | '₺' | '¥' | '₩' | '৳' | '₹' |
    '₭' | '₱' | 'R$' | '₨' | '฿' | '₫' | '₮' | '₸' | '£' | '₵' | '₦' | 'ብር';

export const MappingList: {
    [index: number]: {
        mark:     string,
        code:     string,
        htmlMark: string
    }
} = {
    10: {
            mark:     '$',
            code:     'USD',
            htmlMark: '&#36',
    },
    81: {
            mark:     'C$',
            code:     'CAD',
            htmlMark: '&#36',
    },
    12: {
            mark:     '€',
            code:     'EUR',
            htmlMark: '&#8364',
    },
    6: {
            mark:     '₾',
            code:     'GEL',
            htmlMark: '&#8382',
    },
    53: {
            mark:     'Kč',
            code:     'CZK',
            htmlMark: 'K&#x10D',
    },
    47: {
            mark:     'zł',
            code:     'PLN',
            htmlMark: 'z&#x142',
    },
    1: {
            mark:     '₽',
            code:     'RUB',
            htmlMark: '&#8381',
    },
    3: {
            mark:     '₴',
            code:     'UAH',
            htmlMark: '&#8372',
    },
    20: {
            mark:     '₺',
            code:     'TRY',
            htmlMark: '&#8378',
    },
    //TODO: исправить
    37: {
            mark:     'AR$',
            code:     'ARS',
            htmlMark: '&#36',
    },
    103: {
            mark:     '¥',
            code:     'JPY',
            htmlMark: '&#165;',
    },
    93: {
            mark:     '₩',
            code:     'KRW',
            htmlMark: '&#8361',
    },
    89: {
            mark:     '৳',
            code:     'BDT',
            htmlMark: '&#2547',
    },
    30: {
            mark:     '¥',
            code:     'CNY',
            htmlMark: '&#165',
    },
    99: {
            mark:     '₹',
            code:     'INR',
            htmlMark: '&#8377',
    },
    320: {
            mark:     '₭',
            code:     'LAK',
            htmlMark: '&#8365',
    },
    107: {
            mark:     '₱',
            code:     'PHP',
            htmlMark: '&#8369',
    },
    28: {
            mark:     'R$',
            code:     'BRL',
            htmlMark: 'R&#36', //TODO: кода нет, проставил от себя
    },
    185: {
            mark:     '₨',
            code:     'PKR',
            htmlMark: '&#8360',
    },
    111: {
            mark:     '฿',
            code:     'THB',
            htmlMark: '&#3647',
    },
    91: {
            mark:     '₫',
            code:     'VND',
            htmlMark: '&#8363',
    },
    22: {
            mark:     '₮',
            code:     'MNT',
            htmlMark: '&#8366',
    },
    9: {
            mark:     '₸',
            code:     'KZT',
            htmlMark: '&#8376',
    },
    77: {
            mark:     'AU$',
            code:     'AUD',
            htmlMark: '&#36',
    },
    119: {
            mark:     '£',
            code:     'EGP',
            htmlMark: '&#65505',
    },
    137: {
            mark:     '₵',
            code:     'GHS',
            htmlMark: '&#8373',
    },
    253: {
            mark:     '₨',
            code:     'MUR',
            htmlMark: '&#8360',
    },
    243: {
            mark:     'N$',
            code:     'NAD',
            htmlMark: '&#36',
    },
    115: {
            mark:     '₦',
            code:     'NGN',
            htmlMark: '&#8358',
    },
    296: {
            mark:     '£',
            code:     'SYP',
            htmlMark: '&pound',
    },
    117: {
            mark:     'ብር',
            code:     'ETB',
            htmlMark: 'ETB', //TODO: кода нет, проставил от себя
    },
};