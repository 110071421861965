import HttpResponse
    from "~lib/client/Response";

import {ApiHttpCode}
    from "~app/Other/Api/ApiHttpCode";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

import RuntimeException
    from "~app/Exception/Runtime.exception";

/**
 * Логика проверки результатов запроса к кассовому API,
 * на возникновение специфических, заранее определенных ситуаций.
 *
 * @param response - Структура HTTP ответа от API.
 * @param options  - Опции дополнительных проверок результатов запроса.
 */
export function checkApiResponse(
    response: HttpResponse,
    options?:  {
        responseMustBeNotEmpty?: boolean,
        responseMustBeSuccess?:  boolean
    },
): void
{
    // Преобразование определенных HTTP кодов
    // в конкретные исключения:
    switch (response.statusCode)
    {
        case ApiHttpCode.FORBIDDEN:
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_AUTH_REJECTED,
                isFatal: false,
                message: 'Workspace authorization anchor is rejected by API.' +
                         ` ${generateResponseMetaMessage(response)}`,
            });

        case ApiHttpCode.NOT_FOUND:
            throw new RuntimeException({
                code:    EposCoreErrors.UNEXPECTED_API_ERROR,
                isFatal: false,
                message: `Requested API endpoint is not found.` +
                         ` ${generateResponseMetaMessage(response)}`,
            });

        case ApiHttpCode.CONFLICT:
            throw new RuntimeException({
                code:    EposCoreErrors.CASHIER_TOKEN_EXPIRED,
                isFatal: false,
                message: "Cashier token is rejected by authentication service." +
                         ` ${generateResponseMetaMessage(response)}`,
            });

        case ApiHttpCode.SERVER_ERROR:
            throw new RuntimeException({
                code:    EposCoreErrors.UNEXPECTED_API_ERROR,
                isFatal: false,
                message: `An unexpected error occurred while fetching API endpoint.` +
                         ` ${generateResponseMetaMessage(response)}`,
            });
    }

    // Будет выброшено исключение, если API вернет ответ,
    // обозначающий неудачу при выполнении запроса.
    if (true === options?.responseMustBeSuccess && !response.isSuccess)
    {
        throw new RuntimeException({
            code:    EposCoreErrors.UNEXPECTED_API_ERROR,
            isFatal: false,
            label:   'API-REQUEST-ERROR',
            message: 'Unexpected UNSUCCESSFUL response returned from API.' +
                     ` ${generateResponseMetaMessage(response)}`,
        });
    }

    // Будет выброшено исключение, если тело API ответа пустое:
    if (true === options?.responseMustBeNotEmpty && null === response.response)
    {
        throw new RuntimeException({
            code:    EposCoreErrors.UNEXPECTED_API_ERROR,
            isFatal: false,
            label:   'API-REQUEST-ERROR',
            message: 'Unexpected EMPTY response returned from API.' +
                     ` ${generateResponseMetaMessage(response)}`,
        });
    }
}

/**
 * Добавление в переданную строку информации
 * из текстового поля ответа API, если оно не пустое.
 *
 * @param message  - Добавляемая часть сообщения.
 * @param response - Структура HTTP ответа от API.
 */
export function srvErrorMessageWithDetails(
    message:  string,
    response: HttpResponse,
): string
{
    return (!response.message || '' === response.message)
        ? message
        : message + ` Details: ${response.message}`;
}

/**
 * Генерация информационного сообщения, на основе
 * данных о результате запроса.
 *
 * @param response - Структура HTTP ответа от API.
 */
export function generateResponseMetaMessage(
    response: HttpResponse
): string
{
    return `Request to: [${response.method}] '${response.url}.'` +
           ` Status code: ${response.statusCode}.` +
           ` Duration: ${response.duration}.`
}