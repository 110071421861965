<template>
  <div class="page__main main">
    <div class="main__workspace workspace">
      <!-- Заголовок интерфейса, присутствует на всех страницах авторизованного кассира: -->
      <top-bar class="workspace__header"/>
      <!-- Основное окно: -->
      <main class="workspace__wrapper">
        <!-- Главная навигационная панель: -->
        <left-hot-menu />
        <!-- Переключаемые страницы интерфейса кассира: -->
        <div class="workspace__inner">
          <div v-if="false" class="workspace__content workspace-content">
            <!-- TODO: доделать прелоадер -->
            <div class="workspace-content__empty">
              <empty-window-content
                  :ico-flicker="true"
                  title="Что-то пошло не так"
                  ico="warning-round"
                  desc="Возникла ошибка при загрузке. Нажмите кнопку обновления."
                  :btn="{text: 'Обновить', callable: () => {}}"
              />
            </div>
          </div>
          <component v-if="null !== activeWindowTemplate" :is="activeWindowTemplate" />
        </div>
      </main>
    </div>
   <!-- Глобальный элемент диалогового окна: -->
    <transition name="modal-overlay">
      <Modal
          v-if="$modal.isActive"
          :severity="$modal.severity"
          :ico="$modal.ico"
          :ico-size="$modal.icoSize"
          :title="$modal.title"
          :message="$modal.message"
          :top-indents-amount="$modal.topIndentsAmount"
          :main-btn="$modal.mainBtn"
          :second-btn="$modal.secondBtn"
      />
    </transition>
    <!-- Компонент для печати купонов: -->
    <!-- (скрыт стилями для всех "viewport", кроме печати.) -->
    <coupon
      v-if="$betting.isCouponPrintElemMustBeRendered"
    />
  </div>
</template>

<script lang="ts">
import VueComponentType
  from "~lib/render/VueComponent";

const CMP_NAME = 'Workspace';
export default {
  name: CMP_NAME,
}  as VueComponentType
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {computed}
    from "@vue/runtime-core";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useWindowsManager
    from "~interaction/useWindowsManager";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";

/** IMPORTED VUE TEMPLATES: */
import TopBar
  from "~tpl-desktop/workspace/TopBar/TopBar.vue";
import LeftHotMenu
  from "~tpl-desktop/workspace/Navigation/LeftHotMenu.vue";
import Modal
  from "~tpl-global/Modal/Modal.vue";
import Coupon
  from "~tpl-desktop/workspace/Print/Coupon.vue";
import EmptyWindowContent
  from "~tpl-desktop/shared/EmptyWindowContent.vue";

/** COMPONENT LOGIC: */
const {
    $T
}  = useInterfaceTexts();
const {
    $WM
} = useWindowsManager();

const {
    $app,
    $shift,
    $modal,
    $betting,
} = usePiniaState();

/**
 * Хранилище текущего активного окна приложения, использующегося
 * для отрисовки содержимого в шаблоне:
 */
const activeWindowTemplate = computed(() =>
{
    const defaultWindow: string = 'shift';
    // определение идентификатора отображаемого окна:
    // (включая холодный старт)
    let windowId: string = $app.windowId
        ?? ($WM.getRememberedPage() ?? defaultWindow).toString();

    // ограничение на выход за пределы окна контроля смены, когда смена не открыта,
    // либо превышена ее максимальная продолжительность:
    windowId = (!$shift.isActive || $shift.isDurationExceed24Hours)
        ? defaultWindow
        : windowId;

    // проверка существования страницы:
    if (!$WM.isWindowExists(windowId)) {
        windowId = defaultWindow;
    }

    // страховочная синхронизация отображаемого окна с тем идентификатором,
    // который содержится в хранилище приложения:
    if (windowId !== $app.windowId) {
        $app.changeActiveWindowId(windowId);
    }

    return $WM.activateWindow(windowId);
});

</script>

<style lang="scss">
  @import '~scss-desktop/workspace/component';
</style>