/**
 * Список HTTP кодов, которые сервер может отдавать приложению.
 */
export enum ApiHttpCode {
    OK            = 200,
    RESET_CONTENT = 205,
    NOT_MODIFIED  = 304,
    BAD_REQUEST   = 400,
    NOT_FOUND     = 404,
    FORBIDDEN     = 403,
    CONFLICT      = 409,
    SERVER_ERROR  = 550,
}