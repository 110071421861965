import {defineStore}
    from 'pinia'
import {ref, computed, triggerRef}
    from "@vue/runtime-core";
import * as FeedFilterType
    from "~app/Betting/Contracts/FeedFilter.types";
import * as FeedTypes
    from "~app/Betting/Contracts/Feed.types";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

export const FeedStateKey: symbol = Symbol.for('FeedStateModule');

const FeedState = defineStore(FeedStateKey.toString(), () =>
{
    /**
     * @see sportsFeed
     */
    const _sportsFeed = ref<{[key: number]: FeedTypes.Sport}>({});

    /**
     * Список с данными спортов, необходимых для
     * отображения в фидах.
     */
    const sportsFeed = computed(() => _sportsFeed.value);

    /**
     * @see countriesFeed
     */
    const _countriesFeed = ref<{[key: number]: FeedTypes.Country}>({});

    /**
     * Список с данными стран, необходимых для
     * отображения в фидах.
     */
    const countriesFeed = computed(() => _countriesFeed.value);

    /**
     * @see searchField
     */
    const _searchField = ref<string>('');

    /**
     * Поле для поиска по функционалу фидов.
     */
    const searchField = computed(() => _searchField.value);

    /**
     * @see searchFieldAutocomplete
     */
    const _searchFieldAutocomplete = ref<FeedFilterType.SearchFieldAutoCompleteElem[]>([]);

    /**
     * Список, для предложения авто подстановки значений в поле поиска.
     */
    const searchFieldAutocomplete = computed(() => _searchFieldAutocomplete.value);

    /**
     * @see activeFeedTab
     */
    const _activeFeedTab = ref<FeedTypes.FeedType|null>(null);

    /**
     * Активный в настоящий момент таб со списком фидов.
     */
    const activeFeedTab = computed(() => _activeFeedTab.value);

    /**
     * @see feedData
     */
    const _feedData = ref<{[key: string]: FeedTypes.EventsGroup}|null>(null);

    /**
     * Содержимое фида событий, предназначенное для
     * отображения в интерфейсе.
     */
    const feedData = computed(() => _feedData.value);

    /**
     * Возвращает "TRUE" если список групп событий загружен,
     * но пуст, т.е. отображать нечего.
     */
    const isFeedDataEmpty = computed<boolean>(() => {
        return (Object.keys(_feedData.value ?? []).length < 1);
    });

    /**
     * @see activeEventsGroup
     */
    const _activeEventsGroup = ref<FeedTypes.EventsGroup|null>(null);

    /**
     * Содержит информацию о выбранной (читай развернутой)
     * группе событий.
     */
    const activeEventsGroup = computed(() => _activeEventsGroup.value);

    /**
     * @see activeEventsList
     */
    const _activeEventsList = ref<{[key: string]: FeedTypes.Event}|null>(null);

    /**
     * Список событий, предназначенный для отрисовки в интерфейсе.
     */
    const activeEventsList = computed(() => _activeEventsList.value);

    /**
     * @see selectedEvent
     */
    const _selectedEvent = ref<FeedTypes.Event|null>(null);

    /**
     * Событие, выбранное в интерфейс фида, для
     * отображения списка маркетов.
     *
     * ВНИМАНИЕ! Не путать с "activeEvent" внутри "BettingState", это поле
     * отвечает только за выбор внутри фида.
     */
    const selectedEvent = computed(() => _selectedEvent.value);

    /**
     * Идентификатор текущей активной группы.
     */
    const activeEventsGroupId = computed<string|null>(
        () => (!_activeEventsGroup.value) ? null : _activeEventsGroup.value.key
    );

    /**
     * Возвращает "TRUE" если в настоящий момент
     * выбрана какая-либо группа для отображения.
     *
     * В один момент времени может быть выбрана для отображения
     * только одна группа.
     */
    const isGroupSelected = computed<boolean>(
        () => null !== _activeEventsGroup.value
    );

    /**
     * Возвращает "TRUE" если список событий, относящийся к группе,
     * был успешно загружен, и готов для отображения в интерфейсе.
     */
    const isGroupContentLoaded = computed<boolean>(
        () => null !== _activeEventsGroup.value && null !== _activeEventsList.value
    );

    /**
     * Предполагаемое количество событий, которое
     * должна содержать активная группа.
     */
    const amountOfExpectedGroupEvents = computed<number>(
        () => _activeEventsGroup.value?.amount ?? 0
    );

    /**
     * @see selectedSubEventsMapping
     */
    const _selectedSubEventsMapping = ref<{[key: number]: number}>({});

    /**
     * Маппинг идентификатора основного события
     * к идентификатору вложенного события.
     *
     * Пример:
     * [mainEventId]: subEventId
     *
     * Заполняется при выборе в интерфейсе вложенного события.
     */
    const selectedSubEventsMapping = computed(
        () => _selectedSubEventsMapping.value
    );

    /** --- --- --- --- --- --- --- --- --- */

    /**
     * Обновление данных подготовленного фида.
     *
     * @param type    - тип фида
     * @param payload - содержимое фида
     */
    function updateFeedData(
        type:    FeedTypes.FeedType,
        payload: FeedTypes.EventsGroup[]|null
    ): void
    {
        if (null !== activeFeedTab.value && type !== activeFeedTab.value) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   "FEED-STORAGE",
                message: `Logic error. Trying to set feed data for '${type}' feed, but` +
                         ` '${activeFeedTab.value}' feed is active now.`,
            });
        }

        if (null === payload || payload.length < 1) {
            _feedData.value = {};
            return;
        }

        const data: {[key: string]: FeedTypes.EventsGroup} = {};

        for (const raw of payload) {
            data[raw.key] = raw;
        }

        _feedData.value = data;
    }

    /**
     * Возвращает "TRUE" если в настоящий момент
     * в фиде присутствует группа с запрошенным ключом.
     */
    function isEventsGroupExists(
        key: string
    ): boolean
    {
        return null !== _feedData.value && 'object' === typeof _feedData.value[key];
    }

    /**
     * Возвращает объект группы фида, если
     * он был найден по указанному ключу.
     *
     * Иначе вернет "NULL".
     */
    function getGroupFromFeedByKey(
        key: string
    ): FeedTypes.EventsGroup|null
    {
        return (null === _feedData.value || !_feedData.value.hasOwnProperty(key))
            ? null
            : _feedData.value[key];
    }

    /**
     * Поиск данных конкретного события внутри
     * активной группы событий.
     *
     * @param eventId  - Идентификатор события.
     */
    function searchEventDataInActiveGroup(
        eventId:  number,
    ): FeedTypes.Event|null
    {
        if (
            null === activeEventsList.value ||
            'undefined' === typeof activeEventsList.value[eventId]
        ) {
            return null;
        }

        return activeEventsList
            .value[eventId];
    }

    /**
     * Очистка данных подготовленного фида.
     */
    function flushFeedData(): void
    {
        _activeFeedTab.value = null;
        _feedData.value      = null;
        _searchField.value   = '';

        selectEventsGroup(null)
            .then();
    }

    /**
     * Переключатель групп списка фидов.
     *
     * При успешном переключении так же очистит
     * данные фида для отрисовки.
     *
     * @param type - Тип фида, таб с содержимым которого должен
     *               стать активным. Так же допускается передать "NULL"
     *               для перевода интерфейса в режим отрисовки скелета загрузки.
     */
    function switchActiveFeedTab(
        type: FeedTypes.FeedType|null
    ): void
    {
        if (type === activeFeedTab.value) {
            return;
        }

        _activeFeedTab.value = type;
        _feedData.value      = null;
    }

    /**
     * Обновление списка спортов, используемых
     * при отрисовке групп событий.
     */
    function updateSportsFeed(
        payload: {[key: number]: FeedTypes.Sport}
    ): void
    {
        _sportsFeed.value = payload;
    }

    /**
     * Обновление списка стран, используемых
     * при отрисовке групп событий.
     */
    function updateCountriesFeed(
        payload: {[key: number]: FeedTypes.Country}
    ): void
    {
        _countriesFeed.value = payload;
    }

    /**
     * Устанавливает или очищает текущую
     * активную группу
     *
     * @param payload
     */
    async function selectEventsGroup(
        payload: FeedTypes.EventsGroup|null
    ): Promise<void>
    {
        if (
            null === payload ||
            _activeEventsGroup.value?.key !== payload.key
        )
        {
            // Очистка событий внутри активной группы:
            await updateEventsList(null);

            // Очистка маппинга выбора вложенных событий:
            _selectedSubEventsMapping.value = {};

            // Принудительный триггер реактивности:
            triggerRef(selectedSubEventsMapping);

            // Очистка выбора события внутри фида:
            _selectedEvent.value = null;
        }

        // Обновления списка событий, отображаемых
        // для активной группы:
        _activeEventsGroup.value = payload;

        // Принудительный триггер реактивности:
        triggerRef(selectedSubEventsMapping);
    }

    /**
     * Загрузка списка событий, относящихся
     * к текущей активной группе.
     *
     * @param payload
     */
    async function updateEventsList(
        payload: FeedTypes.Event[]|null
    ): Promise<void>
    {
        if (null === payload) {
            _activeEventsList.value = null;
            return;
        }

        if (null === _activeEventsGroup.value) {
            return;
        }

        const list: {[key: string]: FeedTypes.Event} = {};

        for await (const event of payload) {
            list[`${event.id}`] = event;
        }

        _activeEventsList.value = list;
    }

    /**
     * Обработка запроса на переключение
     * типа отображаемого в интерфейсе события.
     *
     * @param request
     */
    function handleSubEventChange(
        request: FeedTypes.SubEventChangeRequest
    ): void
    {
        // Если был выбран основной тип события:
        if (
            request.id === request.subId &&
            _selectedSubEventsMapping.value.hasOwnProperty(request.id)
        ) {
            delete _selectedSubEventsMapping
                .value[request.id];

            // ВНИМАНИЕ! Обновлять реактивность необходимо
            // у переменной, смотрящей наружу:
            triggerRef(
                selectedSubEventsMapping
            );

            return;
        }

        // Отметка о том, что для переданного события
        // было выбрано вложенное событие, с которым
        // и должен взаимодействовать интерфейс:
        _selectedSubEventsMapping
            .value[request.id] = request.subId;

        // ВНИМАНИЕ! Обновлять реактивность необходимо
        // у переменной, смотрящей наружу:
        triggerRef(
            selectedSubEventsMapping
        );
    }

    return {
        searchField,
        searchFieldAutocomplete,
        activeFeedTab,
        sportsFeed,
        countriesFeed,
        feedData,
        isFeedDataEmpty,
        activeEventsGroupId,
        isGroupSelected,
        isGroupContentLoaded,
        amountOfExpectedGroupEvents,
        activeEventsGroup,
        activeEventsList,
        selectedEvent,
        selectedSubEventsMapping,

        isEventsGroupExists,
        updateFeedData,
        flushFeedData,
        switchActiveFeedTab,
        updateSportsFeed,
        updateCountriesFeed,
        selectEventsGroup,
        updateEventsList,
        getGroupFromFeedByKey,
        searchEventDataInActiveGroup,
        handleSubEventChange,
    };
});

export default FeedState;