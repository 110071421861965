import {inject, injectable}
    from 'inversify';
import HttpClient
    from "~lib/client/HttpClient";
import RoutesPool
    from "~app/Epos/RoutesPool";

import * as SrvHelper
    from "~app/Other/Api/ApiService.helpers";
import * as TransactionsList
    from "~app/Epos/Routes/TransactionsList.route";
import * as PendingWithdrawalRequestsRoute
    from "~app/Epos/Routes/PendingWithdrawalRequests.route";
import {Transaction}
    from "~app/Epos/Contracts/Shift.types";

@injectable()
export default class AccountingService {

    public static readonly SONAR = Symbol
        .for('AccountingService');

    private readonly client: HttpClient;
    private readonly routes: RoutesPool;

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
        @inject(HttpClient.SONAR) client: HttpClient,
        @inject(RoutesPool.SONAR) routes: RoutesPool,
    )
    {
        this.client = client;
        this.routes = routes;
    }

    /**
     * Запрос у апи списка транзакций за текущую смену кассира,
     * и предыдущую смену любого кассира, работавшего с кассой.
     *
     * Так же корректирует структуру транзакций для
     * соответствия логике интерфейса.
     */
    public async retrieveTransactionsList(): Promise<TransactionsList.Response>
    {
        const request = await this.client
            .post<TransactionsList.Response>(
                this.routes.route('workspace-transaction'),
                {},
                {
                    // На всякий случай увеличено
                    // время ожидания ответа:
                    timeout: 16,
                }
            );

        SrvHelper
            .checkApiResponse(
                request,
                {
                    responseMustBeNotEmpty: true,
                    responseMustBeSuccess:  true,
                }
            );

        // Модификация некоторых полей для каждого из списка транзакций,
        // для соответствия логики интерфейса:
        for (const chunkName of ['current', 'previous'])
        {
            for (const indexStr of Object.keys(
                request.response?.[chunkName as unknown as 'current'|'previous'] ?? {})
                )
            {
                const index: number = Number(indexStr);

                if ('object' !== typeof request.response
                    ?.[chunkName as unknown as 'current'|'previous'][index]) {
                    continue;
                }

                const tr: Transaction = request.response
                    ?.[chunkName as unknown as 'current'|'previous'][index];

                request.response[chunkName as unknown as 'current'|'previous'][index]
                    .combinedTypeId = `${tr.typeId}:${tr.statusId}:${tr.clarificationId}`;
            }
        }

        return request.response as unknown as
            TransactionsList.Response;
    }

    /**
     * Запрос у апи списка одобренных выводов со счета клиента,
     * назначенных на текущее рабочее место.
     */
    public async retrievePendingWithdrawalRequests(): Promise<PendingWithdrawalRequestsRoute.Response>
    {
        const request = await this.client
            .post<TransactionsList.Response>(
                this.routes.route('withdrawal-requests'),
                {},
                {
                    // На всякий случай увеличено
                    // время ожидания ответа:
                    timeout: 8,
                }
            );

        SrvHelper
            .checkApiResponse(
                request,
                {
                    responseMustBeNotEmpty: true,
                    responseMustBeSuccess:  true,
                }
            );

        return request.response as unknown as
            PendingWithdrawalRequestsRoute.Response;
    }
}