/**
 * Принадлежность ставки/купона к фиду.
 */
export enum BetFeedSource {
    // Live
    Live        = 1,
    //Линия
    Line        = 3,
    // ZonePlay
    ZonePlay    = 4,
    //Finance365
    Finance365  = 6,
    //Bonus
    Bonus       = 7,
    // BM
    Bm          = 8,
    //BMLive
    BmLive      = 9,
}