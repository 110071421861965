import {defineStore}
    from 'pinia'
import {ref, computed, triggerRef}
    from "@vue/runtime-core";
import {Numbers}
    from "~lib/helpers";
import {Mix}
    from "~lib/helpers";

import ApplicationState
    from "~app/Storage/Application.state";

import * as EposTypes
    from "~app/Epos/Contracts/Epos.types";
import Workspace
    from "~app/Epos/Models/Workspace";
import Credentials
    from "~app/Epos/Models/Credentials";
import Settings
    from "~app/Epos/Models/Settings";
import Cashier
    from "~app/Epos/Models/Cashier";

import {WorkspaceLogicModule}
    from "~app/Epos/WorkspaceLogicModule";
import {calendarNamesReplacer} //TODO: проблемный функционал
    from "~app/Other/Calendar/NameMap";
import {LocalesFormatException}
    from "~app/Settings/NumberFormat.settings";
import * as CurrencyIdToCode
    from "~app/Other/Currency/CurrencyIdToCode";
import {AvailableList, MappingList as CurrencyToSymbolMapping}
    from "~app/Other/Currency/CurrencyIdToCode";

export const WorkspaceModuleKey: symbol = Symbol
    .for('PiniaWorkspaceModule');

const WorkspaceState = defineStore(WorkspaceModuleKey.toString(), () => {

    /**
     * Системные параметры рабочего места.
     */
    const _workspace = ref<Workspace>(new Workspace({}));

    /**
     * Данные, связывающие рабочее место с виртуальной кассой.
     */
    const _credentials = ref<Credentials|null>(null);

    /**
     * Хранение модели с настройками виртуальной кассы.
     */
    const _eposSettings = ref<Settings|null>(null);

    /**
     * Хранение данных авторизованного на кассе кассира.
     *
     * В один момент времени на кассе может быть авторизован только один кассир,
     * причем перетирать сессию авторизованного кассира, другим кассиром, не допускается.
     *
     * Если "NULL", то в настоящий момент на кассе нет авторизованного кассира.
     */
    const _authorizedCashier = ref<Cashier|null>(null);

    /**
     * Список функционала, доступного рабочему месту.
     *
     * Список идентификаторов доступных модулей получается из
     * ответа апи на синхронизацию рабочего места.
     */
    const _allowedModules = ref<EposTypes.RuntimeModulesList>({});

    /**
     * ВНИМАНИЕ!
     * Необходимо использовать в computed параметрах, ссылающихся
     * на проверку доступности модуля через метод "hasAccessFor".
     *
     * Иначе реактивное изменение не сработает.
     */
    const allowedModules = computed(
        () => _allowedModules.value
    );

    /**
     * Возвращает одно из полей, относящееся
     * к системным параметрам рабочего места.
     */
    const systemParameter = computed(() =>
    {
        return <T extends  keyof EposTypes.WorkspaceSystemParameters>(
            field: T
        ): EposTypes.WorkspaceSystemParameters[T]|null =>
        {
            return _workspace.value.get(field) ?? null;
        }
    });

    /**
     * Модификация значения системного параметра рабочего места.
     *
     * @param field   - Параметр.
     * @param payload - Значение.
     */
    function changeSystemParameter<T extends keyof EposTypes.WorkspaceSystemParameters>(
        field:   T,
        payload: EposTypes.WorkspaceSystemParameters[T]
    ): void
    {
        _workspace.value.set(
            field,
            payload
        );

        triggerRef(
            _workspace
        );
    }

    /**
     * Возвращает одно из полей, относящееся
     * к настройкам виртуальной кассы.
     */
    const settings = computed(() => {
        return <T extends keyof EposTypes.EposGlobalSettings>(
            field: T
        ): EposTypes.EposGlobalSettings[T]|null =>
        {
            return _eposSettings.value
                ?.get(field) ?? null;
        }
    });

    /**
     * Возвращает одно из полей, относящихся
     * к данным авторизованного кассира.
     */
    const cashier = computed(() => {
        return <T extends keyof EposTypes.AuthorizedCashier>(
            field: T
        ): EposTypes.AuthorizedCashier[T]|null =>
        {
            return _authorizedCashier.value
                ?.get(field) ?? null;
        }
    });

    /**
     * Возвращает "TRUE" если настройки виртуальной кассы
     * препятствуют правильной работе функционала веб касс.
     */
    const isWorkspaceSettingsIncorrect = computed<boolean>(
        () =>
        {
            const isWebEpos: boolean = settings
                .value('isWebEpos') ?? false;

            const isMobileEpos: boolean = settings
                .value('isMobileEpos') ?? false;

            return Object.keys(_allowedModules).length < 1 ||
                (!isWebEpos && !isMobileEpos);
        });

    /**
     * Если "TRUE", то рабочее место закончило обязательную синхронизацию,
     * проводимую при старте приложения.
     */
    const isWorkspaceInitFinished = computed<boolean>(
        () => true === systemParameter.value('isWorkspaceInitFinished')
    );

    /**
     * @see Credential.anchor
     */
    const getWorkspaceAnchor = computed<string|null>(() => {
        return _credentials.value?.get<string|null>('anchor') ?? null;
    });

    /**
     * Возвращает "TRUE" если рабочее место авторизовано и связано
     * с конкретной виртуальной кассой.
     */
    const isWorkspaceAuthorized = computed<boolean>(() => {
        return null !== _credentials.value && null !== _eposSettings.value;
    });

    /**
     * Если рабочее место авторизовано, но флаг потери авторизации установлен в "TRUE",
     * то рабочее место не может продолжать использоваться, и интерфейс
     * должен отобразить соответствующее уведомление кассиру.
     */
    const isWorkspaceAuthLost = computed<boolean>(
        () => isWorkspaceAuthorized.value &&
            true === systemParameter.value('isWorkspaceAuthLost')
    );

    /**
     * Возвращает "TRUE" если в настоящий момент
     * на кассе есть авторизованный кассир.
     */
    const isCashierLoggedIn = computed<boolean>(() => {
        return _authorizedCashier.value instanceof Cashier;
    });

    /**
     * Возвращает "TRUE" если интерфейс переходит в режим запроса
     * на очистку привязки рабочего места.
     */
    const isAuthClearRequested = computed<boolean>(
        () => true === systemParameter.value('isAuthClearRequested')
    );

    /**
     * Возвращает "TRUE" если на кассе есть авторизованный кассир, но при этом
     * в виртуальной кассе его данные авторизации устарели, и
     * не могут быть восстановлены автоматически.
     *
     * В таком случае интерфейс должен быть переведен в гостевой режим,
     * и кассир должен заново ввести свой пароль (без логина).
     */
    const isCashierSessionBroken = computed<boolean>(
        () => isCashierLoggedIn.value &&
            true === systemParameter.value('isCashierSessionBroken')
    );

    /**
     * Возвращает "TRUE" если с авторизационной сессией текущего кассира все в порядке, и
     * виртуальная касса может делать запросы к API от его имени.
     */
    const isCashierHasValidSession = computed<boolean>(() =>
        isCashierLoggedIn.value &&
        false === systemParameter.value('isCashierSessionBroken') &&
        false === systemParameter.value('isCashierSessionExpired')
    );

    /**
     * Публичное название рабочего места.
     */
    const workspaceName = computed<string|null>(() => {
        return settings.value('name');
    });

    /**
     * Код рабочего места кассира.
     */
    const workspaceCode = computed<string|null>(() => {
        return settings.value('workspaceId');
    });

    /**
     * Глобальный идентификатор виртуальной кассы, связанной с рабочим местом.
     */
    const workspaceGlobalId = computed<number|null>(() => {
        return settings.value('eposId')
    });

    /**
     * Возвращает название аккаунта текущего авторизованного кассира.
     */
    const cashierLogin = computed<string|null>(() => {
        return cashier.value('login');
    });

    /**
     * Возвращает "TRUE" если касса и/или рабочее место кассира
     * по какой-либо причине заблокированы, и дальнейшая работа невозможна.
     *
     * Как правило, самостоятельно кассир с этим ничего сделать не сможет,
     * и будет вынужден обращаться в службу поддержки.
     */
    const isWorkspaceLocked = computed<boolean>(() => {
        return !(settings.value('isExists') ?? true) ||
            (settings.value('isLocked')  ?? true)    ||
            (settings.value('isDeleted') ?? true);
    });

    /**
     * Возвращает "TRUE", если рабочее место было отмечено как удаленное.
     */
    const isWorkspaceDeleted = computed<boolean>(
        () => true === settings.value('isDeleted')
    );

    /**
     * Принимает число, и возвращает строку, являющейся суммой
     * в валюте кассы.
     *
     * (не производит конвертации)
     */
    const amountInWorkspaceCurrency = computed(() => {
        return (payload: string|number): string => {
            return numFormatInAppLocale.value(payload) + ` ${currencyMark.value}`;
        }
    });

    /**
     * Принимает число, и возвращает строку, отформатированную
     * по правилам локали кассы без символа валюты.
     *
     * (не производит конвертации)
     */
    const numFormatInAppLocale = computed(() => {
        return (payload: string|number): string => {
            const locale: string = LocalesFormatException[(ApplicationState()).locale]
                ?? (ApplicationState()).locale;

            return Numbers
                .numFormat(
                    locale.replace('_', '-'),
                    ('number' === typeof payload)
                        ? payload
                        : parseFloat(payload)
                );
        }
    })

    /**
     * Возвращает символ валюты кассы, в соответствии с
     * таблицей маппинга CurrencyToSymbolMapping.
     *
     * Если символ не будет найден, то вернется
     * трехбуквенный код валюты.
     */
    const currencyMark = computed<string>(() => {

        const currencyId: number = settings
            .value('currencyId') ?? 0;

        const currencyCode: string = settings
            .value('currencyCode') ?? 'XXX';

        return (CurrencyToSymbolMapping.hasOwnProperty(currencyId))
            ? CurrencyToSymbolMapping[currencyId].mark
            : currencyCode;
    });

    /**
     * Возвращает информационную сводку о валюте,
     * используемой на кассе.
     */
    const workspaceCurrencyInfo = computed(() => {

        const currencyId: number = settings
            .value('currencyId') ?? 0;

        const currencyCode: string = settings
            .value('currencyCode') ?? 'XXX';

        return (CurrencyIdToCode.MappingList.hasOwnProperty(currencyCode))
            ? `${CurrencyIdToCode.MappingList[currencyId]} | ${currencyCode}`
            : currencyCode;
    });

    /**
     * Возвращает временную зону кассы, основанную
     * на смещении времени относительно UTC.
     */
    const timezone = computed<string>(
        () =>
        {
            const offset: number|null = settings
                .value('timezoneOffset') ?? null;

            if (null === offset || 0 === offset) {
                return 'UTC+00'
            }

            let offsetPayload: string = (Math.abs(offset) < 10)
                ? `0${Math.trunc(Math.abs(offset))}`
                : `${Math.trunc(Math.abs(offset))}`

            const minutesOffset: number = Math.round(((Math.abs(offset) - Math.trunc(Math.abs(offset)))) * 60);

            if (minutesOffset > 0) {
                offsetPayload += (minutesOffset < 10)
                    ? `:0${minutesOffset}`
                    : `:${minutesOffset}`;
            }

            return (offset >= 0)
                ? `UTC+${offsetPayload}`
                : `UTC-${offsetPayload}`;
        });

    /**
     * Принимает Unix Timestamp, и возвращает
     * дату/время в запрошенном формате,
     * с учетом временной зоны и языка кассы.
     *
     * Исключения:
     * uz_UZ -> en_GB
     * so_SO -> en_GB
     *
     */
    function timeInWorkspaceTz(
        timestamp: number,
        format:    string,
    ): string
    {
        const lang: string = (ApplicationState()).lang;

        // Маппинг на максимально близкий язык
        // для не поддерживаемых языков:
        const map: {[key: string]: string} = {
            uz: 'en',
            so: 'en',
        };

        const isMappingRequired: boolean = map.hasOwnProperty(lang);

        //TODO: убрать toUpperCase
        const timeString: string = Mix.fromTimestampToLocal(
            timestamp,
            timezone.value,
            format,
            (isMappingRequired)
                ? map[lang]
                : lang
        ).toUpperCase();

        return (!isMappingRequired)
            ? timeString
            : calendarNamesReplacer(lang, 'all', timeString);
    }

    /**
     * Устанавливает или очищает модель данных, на которых
     * основывается связь рабочего места с виртуальной кассой.
     *
     * Если передать заполненную модель, то рабочее место
     * будет считаться авторизованным.
     *
     * Если передать "NULL", то будут очищены данные авторизации,
     * настроек виртуальной кассы и авторизованного кассира.
     */
    function setOrClearAuthCredentials(
        payload: Credentials|null
    ): void
    {
        changeSystemParameter(
            'isWorkspaceAuthLost',
            false
        );

        // Если данные авторизации обновляются:
        if (null !== payload)
        {
            _credentials.value = payload;

            triggerRef(
                _credentials
            );

            return;
        }

        // Если данные авторизации стираются:
        // =>

        _credentials.value       = null;
        _eposSettings.value      = null;
        _authorizedCashier.value = null;
        _allowedModules.value    = {};

        triggerRef(
            _credentials
        );

        triggerRef(
            _eposSettings
        );
    }

    /**
     * Обновление модели данных с настройками виртуальной кассы.
     */
    async function updateEposGlobalSettings(
        payload: Settings
    ): Promise<void>
    {
        _eposSettings.value = payload;

        triggerRef(
            _eposSettings
        );
    }

    /**
     * Обновление списка модулей, использование которых
     * разрешено на текущем рабочем месте.
     *
     * @param payload
     */
    function updateAllowedModulesList(
        payload: number[]
    ): void
    {
        const cache: EposTypes
            .RuntimeModulesList = {};

        for (const moduleId of payload)
        {
            cache[moduleId] = moduleId;
        }

        _allowedModules
            .value = cache;

        triggerRef(
            _allowedModules
        );
    }

    /**
     * Возвращает "TRUE" если переданный модуль
     * может быть использован на текущем рабочем месте.
     *
     * @param module - Идентификатор модуля.
     */
    function hasAccessFor(
        module: WorkspaceLogicModule
    ): boolean
    {
        return 'number' === typeof _allowedModules.value[module] &&
            _allowedModules.value[module] === module;
    }

    /**
     * Загрузка в функционал интерфейса данных
     * о текущем авторизованном кассире.
     */
    function setWorkspaceCashier(
        cashier: Cashier | null
    ): void
    {
        _authorizedCashier.value = cashier;

        triggerRef(
            _authorizedCashier
        );
    }

    return {
        isCashierHasValidSession,
        isCashierSessionBroken,
        isWorkspaceAuthorized,
        isWorkspaceAuthLost,
        isCashierLoggedIn,
        getWorkspaceAnchor,
        workspaceName,
        workspaceCode,
        workspaceGlobalId,
        cashierLogin,
        isWorkspaceSettingsIncorrect,
        isWorkspaceLocked,
        isAuthClearRequested,
        isWorkspaceInitFinished,
        isWorkspaceDeleted,
        amountInWorkspaceCurrency,
        numFormatInAppLocale,
        workspaceCurrencyInfo,
        timezone,
        currencyMark,
        systemParameter,
        settings,
        cashier,
        allowedModules,

        timeInWorkspaceTz,
        setOrClearAuthCredentials,
        setWorkspaceCashier,
        updateAllowedModulesList,
        hasAccessFor,
        updateEposGlobalSettings,
        changeSystemParameter,
    };
});

export default WorkspaceState;