import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";
import SvgSprite, {SpriteFileList}
    from "~lib/assets/SvgSprite";

import {SystemSonar}
    from "~app/Epos/Enum/SystemSonar";
import {InterfaceBootstrapResponse}
    from "~app/Epos/Contracts/Epos.types";

/**
 * Формирование данных для работы с SVG спрайтом.
 */
export default class CreateSvgSpriteDataProvider
    extends ServiceProvider
{
    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('CreateSvgSpriteDataProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        const AppInitData: InterfaceBootstrapResponse = app
            .get<InterfaceBootstrapResponse>(SystemSonar.AppInitConstant);

        // Поиск файлов со спрайтами в assets файле
        // интерфейса приложения.
        function searchSpriteFiles(
            assets: unknown
        ): SpriteFileList
        {
            type ExpectedAssetsStruct = {
                [key in 'spritemap']: {[key in 'svg'|'js']: string|string[]};
            };

            const files: SpriteFileList = {};

            const filesExtractor = (payload: string|string[]): void => {
                const list = ('object' === typeof payload)
                    ? Object.values(payload)
                    : [`${payload}`];

                for (const file of list) {
                    const parts = (file.split('.')[0]).split('/');
                    files[parts[parts.length - 1]] = file;
                }
            };

            if ('undefined' !== typeof (assets as ExpectedAssetsStruct).spritemap.svg) {
                filesExtractor(
                    (assets as ExpectedAssetsStruct).spritemap.svg
                );
            }

            return files;
        }

        // Загрузка функционала в глобальный объект window, т.к.
        // эти данные не будут извлекаться из контейнера:
        (window as unknown as {$svgSprite: SvgSprite}).$svgSprite = new SvgSprite(
            searchSpriteFiles(AppInitData.assetsMap)
        );
    }
}