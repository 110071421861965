import useAppContainer
    from "~app/IoC/useAppContainer";
import WindowsManager
    from "~lib/windows/WindowsManager";
import {WinSettings, WM}
    from "~app/Providers/Windows/WindowsManager.provider";

export default function () {

    const {
        $container
    } = useAppContainer();

    const $WM= $container
        .get<WindowsManager<WinSettings>>(WM);

    return {
        $WM,
    };
}