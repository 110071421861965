import {Container}
    from "inversify";
import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import EposModule
    from "~app/Epos/Epos.module";

/**
 * Повторяющееся задание на синхронизацию состояния
 * рабочего места кассира.
 */
const StateUpdateInterval: IntervalTaskContract = {
    id:        'workspace-state-update',
    intervalMs: 10 * 1000,

    startOnInit:         true,
    authRequired:        true,
    cashierRequired:     false,
    shiftRequired:       false,

    workOnMaintenance:   true,
    workOnSettingsError: true,

    sequence: 'system-data-update',

    payload: async function(
        ioc: Container
    ): Promise<void>
    {
        await (ioc.get<EposModule>(EposModule.SONAR))
            .synchronizeWorkspaceState();
    }
};

export default StateUpdateInterval;
