export enum WorkspaceLogicModule {
    FETCH_PREMATCH_FEED   = 1,
    FETCH_LIVE_FEED       = 2,
    FETCH_MARKETS         = 3,
    BETTING               = 4,
    PRINT_MARKETS         = 5,

    ALLOW_BET_BIND         = 6,
    ALL_BETS_MUST_BE_BOUND = 7,

    COUPON_OVERVIEW       = 8,
    COUPON_PROCESSING     = 9,

    CUSTOMER_SEARCHING    = 10,
    CUSTOMER_REGISTRATION = 11,
    CUSTOMER_REPLENISH    = 12,
    CUSTOMER_WITHDRAWAL   = 13,
}