import {Container}
    from "inversify";
import {OnAppInitTask}
    from "~app/Epos/Contracts/Epos.types";
import EposModule
    from "~app/Epos/Epos.module";

/**
 * Загрузка (и обновление, при необходимости) полной
 * текстовой библиотеки интерфейса.
 */
const TextsLibrarySyncTask: OnAppInitTask =
    {
        id:          'texts-library-sync',
        description: 'interface-init-step-texts-library',
        attempts:    2,

        runner: async function (
            app: Container
        ): Promise<boolean>
        {
            await (app.get<EposModule>(EposModule.SONAR))
                .loadTextsLibraryIntoApplication();

            return true;
        }
    };

export default TextsLibrarySyncTask;