import {Container}
    from "inversify";
import {OnAppInitTask}
    from "~app/Epos/Contracts/Epos.types";
import EposModule
    from "~app/Epos/Epos.module";

/**
 * Проверка состояния и синхронизация
 * рабочего места кассира с виртуальной кассой.
 */
const WorkspaceSyncTask: OnAppInitTask =
    {
        id:          'workspace-sync',
        description: 'interface-init-step-sync-workspace',
        attempts:    3,

        runner: async function (
            app: Container
        ): Promise<boolean>
        {
            await app.get<EposModule>(EposModule.SONAR)
                .synchronizeWorkspaceState();

            return true;
        }
    };

export default WorkspaceSyncTask;