import Exception            from "~lib/Exception/Exception";
import {AxiosRequestConfig} from 'axios';
import {RequestOptions}     from "~lib/client/HttpClient";


type ConstructorType = {
    message:    string;
    rqOptions?: RequestOptions;
    config?:    AxiosRequestConfig;
};

export default class HttpClientSetupException extends Exception implements ConstructorType {

    public readonly rqOptions?: RequestOptions;
    public readonly config?:    AxiosRequestConfig;

    constructor(payload: ConstructorType) {
        super({
            type:    'Exception',
            name:    'HttpClientSetupException',
            label:   'HTTP-CLIENT',
            message: payload.message,
        });

        this.rqOptions = payload.rqOptions;
        this.config    = payload.config;
    }
}