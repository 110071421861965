import {Container}
    from "inversify";
import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import {WorkspaceLogicModule}
    from "~app/Epos/WorkspaceLogicModule";
import AccountingModule
    from "~app/Epos/Accounting.module";

/**
 * Повторяющееся задание на обновление списка выплат со счета гостя,
 * назначенных на текущее рабочее место.
 */
const PendingWithdrawalsUpdateInterval: IntervalTaskContract = {
    requiredModules: [
        WorkspaceLogicModule.CUSTOMER_WITHDRAWAL,
    ],

    id:              'withdrawals-list-update',
    intervalMs:      25 * 1000,
    startOnInit:     true,
    authRequired:    true,
    cashierRequired: true,
    shiftRequired:   true,

    sequence: 'shift-data',
    payload:  async function (ioc: Container): Promise<void>
    {
        await (ioc.get<AccountingModule>(AccountingModule.SONAR))
            .updateWorkspaceWithdrawalRequests();
    }
};

export default PendingWithdrawalsUpdateInterval;