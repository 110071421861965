import ApplicationState
    from "~app/Storage/Application.state";
import WorkspaceState
    from "~app/Storage/Workspace.state";
import ShiftState
    from "~app/Storage/Shift.state";
import CustomerState
    from "~app/Storage/Customer.state";
import ModalState
    from "~app/Storage/Modal.state";
import NotificationsState
    from "~app/Storage/Notifications.state";
import ThrottlingState
    from "~app/Storage/Throttling.state";
import FeedState
    from "~app/Storage/Feed.state";
import BettingState
    from "~app/Storage/Betting.state";
import SettingsState
    from "~app/Storage/Settings.state";
import SyncDataState
    from "~app/Storage/SyncData.state";

export default function ()
{
    return {
        $app:          ApplicationState(),
        $workspace:    WorkspaceState(),
        $shift:        ShiftState(),
        $customer:     CustomerState(),
        $modal:        ModalState(),
        $notification: NotificationsState(),
        $throttling:   ThrottlingState(),
        $feed:         FeedState(),
        $betting:      BettingState(),
        $settings:     SettingsState(),
        $syncData:     SyncDataState(),
    }
}