import {defineStore}
    from 'pinia';
import {computed, ref}
    from "@vue/runtime-core";
import {ModalBtn}
    from "~app/Storage/contracts/Modal";

import {SvgList}
    from "~tpl-global/svg/SvgList";
import {SvgSize}
    from "~tpl-global/svg/SvgSize";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

export const ModalModuleKey: symbol = Symbol
    .for('PiniaModalModule');

type ModalSeverity = 'warning' | 'success';

const ModalState = defineStore(ModalModuleKey.toString(), () => {

    /**
     * @see isActive
     */
    const _isActive= ref<boolean>(false);

    /**
     * Должно ли модальное окно отображаться в настоящий момент.
     */
    const isActive= computed<boolean>(
        () => _isActive.value
    );

    /**
     * @see severity
     */
    const _severity = ref<ModalSeverity>('warning');

    /**
     * Уровень информации в сообщении.
     */
    const severity= computed<ModalSeverity>(
        () => _severity.value
    );

    /**
     * @see ico
     */
    const _ico= ref<SvgList>('warning-round');

    /**
     * Иконка модального окна.
     */
    const ico= computed<SvgList>(
        () => _ico.value
    );

    /**
     * @see icoSize
     */
    const _icoSize= ref<SvgSize>('size-custom-80');

    /**
     * Размер иконки модального окна.
     */
    const icoSize= computed<SvgSize>(
        () => _icoSize.value
    );

    /**
     * @see title
     */
    const _title= ref<string>('');

    /**
     * Заголовок модального окна.
     */
    const title = computed<string>(
        () => _title.value
    );

    /**
     * @see message
     */
    const _message= ref<string|string[]>('');

    /**
     * Сообщение (или список сообщений) модального окна.
     */
    const message = computed<string|string[]>(
        () => _message.value
    );

    /**
     * @see topIndentsAmount
     */
    const _topIndentsAmount = ref<number>(1);

    /**
     * Количество абзацев текста до отображения
     * разделительной линии.
     *
     * Если абзацев меньше или равно указанному числу,
     * то разделительная линия не будет отображена.
     */
    const topIndentsAmount = computed<number>(
        () => _topIndentsAmount.value
    )

    /**
     * @see mainBtn
     */
    const _mainBtn= ref<ModalBtn|null>(null);

    /**
     * Основная кнопка.
     */
    const mainBtn = computed(
        () => _mainBtn.value
    );

    /**
     * @see secondBtn
     */
    const _secondBtn= ref<ModalBtn|null>(null);

    /**
     * Дополнительная кнопка.
     */
    const secondBtn = computed(
        () => _secondBtn.value
    );

    /**
     * Закрывает диалоговое окно, и восстанавливает
     * все значения по-умолчанию.
     */
    function close(): void
    {
        _isActive.value    = false;
        _severity.value    = 'warning';
        _ico.value         = 'warning-round';
        _icoSize.value     = 'size-custom-80';
        _title.value       = '';
        _message.value     = '';
        _mainBtn.value     = null;
        _secondBtn.value   = null;
    }

    /**
     * Отрисовка диалогового окна согласно
     * переданным параметрам.
     */
    function show(payload: {
        severity?:      ModalSeverity;
        ico?:           SvgList;
        icoSize?:       SvgSize;

        title:             string;
        message:           string|string[];
        topIndentsAmount?: number;

        mainBtn?:               string;
        mainBtnOnPress?:        () => void;
        mainBtnIsProcessing?:   boolean;
        secondBtn?:             string;
        secondBtnOnPress?:      (() => void);
        secondBtnIsProcessing?: boolean;
    }): void
    {
        if (isActive.value) {
            throw new RuntimeException({
                isFatal: false,
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                label:   "MODAL-STATE",
                message: "Logic error. Trying to display modal when it already displayed.",
            });
        }

        if (payload.severity) {
            _severity.value = payload.severity;
        }

        if (payload.ico) {
            _ico.value = payload.ico;
        }

        if (payload.icoSize) {
            _icoSize.value = payload.icoSize;
        }

        _title.value   = payload.title;
        _message.value = payload.message;

        if (payload.topIndentsAmount) {
            _topIndentsAmount.value = payload.topIndentsAmount;
        }

        if (payload.mainBtn && 'function' === typeof payload.mainBtnOnPress) {
            _mainBtn.value = {
                text:         payload.mainBtn,
                callable:     payload.mainBtnOnPress,
                isProcessing: payload.mainBtnIsProcessing ?? false
            };
        }

        if (payload.secondBtn && 'function' === typeof payload.secondBtnOnPress) {
            _secondBtn.value        = {
                text:         payload.secondBtn,
                callable:     payload.secondBtnOnPress,
                isProcessing: payload.secondBtnIsProcessing ?? false,
            };
        }

        _isActive.value = true;
    }

    return {
        isActive,
        severity,
        ico,
        icoSize,
        title,
        message,
        topIndentsAmount,
        mainBtn,
        secondBtn,

        show,
        close,
    };
});

export default ModalState;