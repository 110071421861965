import {Container}
    from "inversify";
import Library, {type as LibraryType}
    from "~lib/translate/Library";

export default function () {
    return {
        $T: (window as unknown as {$container: Container}).$container
            .get<Library>(LibraryType),
    };
}