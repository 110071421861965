import {Container}
    from "inversify";
import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/**
 * Проверка наличия дубликатов приложения
 * в рамках одного браузера.
 *
 * В случае их обнаружения - текущий экземпляр
 * приложения будет остановлен.
 */
const CheckAppUUIDInterval: IntervalTaskContract = {
    intervalMs:      3 * 1000,
    startOnInit:     true,
    authRequired:    false,
    cashierRequired: false,
    shiftRequired:   false,

    id:       'check-app-instance-uuid',
    sequence: 'local-system',
    payload:   async function (
        ioc: Container
    ): Promise<void>
    {
        const {
            $app,
        } = usePiniaState();

        if (false !== $app.checkAppUUID()) {
            return;
        }

        $app.changeParameter(
            'isDuplicatedInstanceDetected',
            true
        );
    }
};

export default CheckAppUUIDInterval;