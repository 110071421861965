import {SvgList as SportIco} from "~svg/cleared/sports/SvgList";

const SportToSvgMap: {[key: number]: SportIco} = {

			1: "sport--football",  //Футбол
			2: "sport--hockey",  //Хоккей
			3: "sport--basketball",  //Баскетбол
			4: "sport--tennis",  //Теннис
			5: "sport--baseball",  //Бейсбол
			6: "sport--volleyball",  //Волейбол
			7: "sport--rugby",  //Регби
			8: "sport--handball",  //Гандбол
			9: "sport--boxing",  //Бокс
			10: "sport--table-tennis",  //Настольный теннис
			11: "sport--chess",  //Шахматы
			12: "sport--billiards",  //Бильярд
			13: "sport--american-football",  //Американский футбол
			14: "sport--futsal-5x5",  //Футзал
			15: "sport--bandy",  //Хоккей с мячом
			16: "sport--badminton-shuttlecock",  //Бадминтон
			17: "sport--water-polo",  //Водное поло
			18: "18_car-racing",  //Автогонки
			19: "sport--biathlon",  //Биатлон
			20: "entertainment--tv-games",  //ТВ-игры
			21: "sport--darts",  //Дартс
			22: "sport--alpine-skiing",  //Горные лыжи
			23: "sport--ski-jumping",  //Прыжки с трамплина
			24: "sport--skiing",  //Лыжи
			25: "sport--beach-football",  //Пляжный футбол
			26: "entertainment--formula-1",  //Формула-1
			27: "sport--field-hockey",  //Хоккей на траве
			28: "sport--australian-football",  //Австралийский футбол
			29: "sport--beach-volleyball",  //Пляжный волейбол
			30: "sport--snooker",  //Снукер
			31: "sport--motorcycle",  //Мотогонки
			32: "sport--bowls",  //Шары
			33: "33_inline-hockey",  //Инлайнхоккей
			34: "34_padel-tennis",  //Падель теннис
			35: "35_beach-handball",  //Пляжный гандбол
			36: "sport--cycling",  //Велоспорт
			37: "sport--swimming",  //Плавание
			38: "entertainment--poker",  //Покер
			39: "sport--squash-ball",  //Сквош
			40: "sport--cybersport",  //КиберСпорт
			41: "sport--golf",  //Гольф
			42: "42_financial-rates",  //Финансовые ставки
			43: "43_indoor-hockey",  //Индорхоккей
			44: "entertainment--horse-racing",  //Скачки
			45: "45_futsal",  //Минифутбол
			46: "sport--curling",  //Керлинг
			47: "47_ball-hockey",  //Хоккей-болл
			48: "sport--lacrosse",  //Лакросс
			49: "49_netball",  //Нетбол
			50: "50_olympic-games",  //Олимпиада
			51: "sport--gymnastics",  //Гимнастика
			52: "sport--equestrian",  //Конный спорт
			53: "entertainment--wrestling",  //Борьба
			54: "sport--archery",  //Стрельба из лука
			55: "sport--judo",  //Дзюдо
			56: "sport--martial-arts",  //Единоборства
			57: "sport--athletics",  //Атлетика
			58: "sport--canoe-kayak-flatwater",  //Каноэ/байдарка
			59: "sport--diving",  //Прыжки в воду
			60: "sport--fencing",  //Фехтование
			61: "sport--pentathlon",  //Пятиборье
			62: "sport--rowing",  //Гребля
			63: "sport--sailing",  //Парусный спорт
			64: "sport--shooting-sports",  //Стрельба
			65: "65_triathlon",  //Триатлон
			66: "sport--cricket",  //Крикет
			67: "sport--floorball",  //Флорбол
			68: "entertainment--greyhoun-racing",  //Собачьи бега
			69: "entertainment--toto",  //Тото
			70: "70_roller-hockey",  //Хоккей на роликах
			71: "sport--figure-skating-skates",  //Фигурное катание
			72: "sport--speed-skating",  //Конькобежный спорт
			73: "sport--skeleton",  //Скелетон
			74: "sport--freestyle-skiing",  //Фристайл
			75: "sport--bobsleigh",  //Бобслей
			76: "76_nordic-combined",  //Лыжное двоеборье
			77: "sport--luge",  //Санный спорт
			78: "sport--short-track",  //Шорт-трек
			79: "79_mixed-martial-arts",  //Бои без правил
			80: "sport--gaelic-football",  //Гэльский футбол
			81: "sport--sumo",  //Сумо
			82: "entertainment--lottery",  //Лотерея
			83: "83_softball",  //СофтБол
			84: "sport--snowboarding",  //Сноубординг
			85: "cyber-zone--fifa",  //FIFA
			86: "cyber-zone--cs",  //Counter Strike
			87: "87_special-bets",  //Специальные ставки
			88: "88_alternative-tennis",  //Альтернативный теннис
			89: "cyber-sport--hockey",  //Кибер хоккей
			90: "cyber-sport--ufc",  //Кибер UFC
			91: "cyber-sport--basketball",  //Кибер баскетбол
			92: "entertainment--trotting",  //Троттинг
			94: "cyber-sport--tennis",  //Кибер теннис
			95: "95_alternative-sport",  //Альтернативный спорт
			96: "cyber-zone--wot",  //World of tanks
			97: "cyber-zone--dota",  //Dota
			98: "98_worms",  //Worms
			99: "cyber-sport--cricket",  //Кибер крикет
			100: "cyber-sport--handball",  //Кибер гандбол
			101: "cyber-sport--wrestling",  //Кибер рестлинг
			102: "sport--motorcycle-speedway",  //Спидвей
			103: "cyber-zone--mortal-kombat",  //Mortal Kombat
			105: "105_streetball",  //Стритбол
			106: "cyber-zone--league-of-legends",  //Лига легенд
			107: "107_hearthstone",  //HearthStone
			109: "cyber-zone--rocket-league",  //Rocket League
			110: "110_battleField",  //BattleField
			111: "sport--taekwondo",  //Тхэквондо
			112: "sport--weightlifting",  //Тяжелая атлетика
			113: "sport--bowling",  //Боулинг
			114: "cyber-sport--rugby",  //Кибер регби
			115: "cyber-sport--nfl",  //Кибер NFL
			116: "cyber-sport--rally",  //Кибер ралли
			117: "117_need-for-speed",  //Need for Speed
			118: "118_sepaktakraw",  //Тайский волейбол
			119: "119_us-elections",  //Выборы США
			120: "cyber-sport--golf",  //Кибер гольф
			121: "cyber-sport--baseball",  //Кибер бейсбол
			122: "122_indoor-soccer",  //Индор Соккер
			125: "125_callofduty",  //CallOfDuty
			126: "sport--hurling",  //Херлинг
			128: "cyber-zone--street-fighter",  //StreetFighter
			129: "cyber-sport--formula-1",  //Кибер формула 1
			130: "cyber-sport--lacrosse",  //Кибер лакросс
			131: "131_quake-4",  //Quake 4
			132: "entertainment--long-time--horse-racing",  //Скачки. Долгосрочные
			133: "entertainment--long-time--trotting",  //Троттинг. Долгосрочные
			135: "cyber-zone--steep",  //Steep
			136: "136_angryBirds",  //AngryBirds
			138: "sport--surfing",  //Сёрфинг
			139: "cyber-sport--floorball",  //Кибер флорбол
			140: "140_crossfit",  //Кроссфит
			141: "cyber-zone--king-of-fighters",  //KingOfFighters
			142: "sport--long-time--motorcycle",  //Мотогонки. Долгосрочные
			143: "cyber-zone--injustice",  //Injustice
			144: "cyber-zone--pes",  //PES
			145: "cyber-zone--tekken",  //Tekken
			146: "entertainment--twenty-one",  //21 очко
			147: "147_bodybuilding",  //Бодибилдинг
			148: "cyber-zone--heroes",  //Heroes
			149: "149_pesapallo",  //Песапалло
			150: "150_starcraft2",  //StarCraft 2
			151: "entertainment--long-time--greyhoun-racing",  //Собачьи бега. Долгосрочные
			152: "152_discJam",  //Discjam
			153: "entertainment--card-fool",  //Карточный дурак
			154: "cyber-zone--flatout",  //Flatout
			155: "155_armwrestling",  //Армрестлинг
			156: "156_cycle-ball",  //Велобол
			157: "157_board-games",  //Настольные игры
			158: "158_cyber-table-tennis",  //Кибер настольный теннис
			159: "159_snow-volleyball",  //Волейбол на снегу
			160: "160_cyber-billiards",  //Кибер бильярд
			161: "sport--skateboarding",  //Скейтборд
			162: "cyber-zone--battlegrounds",  //PUBG
			164: "164_kok-boru",  //Кок-бору
			165: "cyber-zone--nba-playgrounds",  //NBA Playgrounds
			166: "cyber-zone--laser-league",  //LaserLeague
			167: "167_cyber-poker",  //Cyber Poker
			168: "168_rainbow-six",  //Rainbow Six
			169: "169_mutantLeague",  //Mutant League
			170: "170_battleRite",  //BattleRite
			171: "171_cyber-snooker",  //Кибер снукер
			172: "cyber-zone--crossout",  //CrossOut
			173: "173_korfball",  //Корфбол
			174: "174_broomball",  //Брумбол
			175: "175_footvolley",  //Футволей
			176: "entertainment--weather",  //Погода
			177: "177_cyber-mini-hockey",  //Кибер мини-хоккей
			178: "178_cyber-footvolley",  //Кибер футволей
			179: "179_pixelCupSoccer",  //PixelCupSoccer
			180: "sport--kabaddi",  //Кабадди
			181: "cyber-zone--killer-instinct",  //Killer Instinct
			182: "182_muay-thai",  //Тайский бокс
			183: "183_tableFootballPro",  //TableFootballPro
			184: "cyber-zone--sociable-soccer",  //SociableSoccer
			187: "cyber-sport--table-soccer",  //Кибер настольный футбол
			188: "entertainment--tvbet",  //TvBet
			189: "sport--ufc",  //UFC
			190: "cyber-sport--australian-football",  //Кибер австралийский футбол
			191: "191_superArcadeFootball",  //SuperArcadeFootball
			192: "cyber-zone--blase-and-soul",  //Blade and Soul
			193: "cyber-zone--assult-squad",  //AssaultSquad
			194: "cyber-zone--cut-the-rope",  //CutTheRope
			195: "cyber-zone--subway-surfers",  //SubwaySurfers
			196: "cyber-zone--sonic",  //Sonic
			197: "197_air-race",  //Авиагонки
			198: "198_loto",  //Лото
			199: "cyber-zone--crash",  //Crash
			200: "cyber-sport--volleyball",  //Кибер волейбол
			201: "201_spykebots",  //SpykeBots
			202: "entertainment--politics",  //Политика
			203: "203_fistball",  //Фистбол
			204: "204_short-football",  //Шорт-футбол
			205: "cyber-sport--cycling",  //Кибер велоспорт
			206: "206_ringette",  //Рингетт
			207: "cyber-zone--jumpforce",  //JumpForce
			208: "cyber-zone--overcooked",  //Overcooked
			209: "209_ballgrabbers",  //BallGrabbers
			210: "entertainment--card-football",  //Карточный футбол
			211: "marble--football",  //Марбл Футбол
			212: "212_big-bash-cricket",  //Big Bash Cricket
			213: "cyber-zone--sekiro",  //Sekiro
			214: "cyber-zone--bomberman",  //Бомбермен
			215: "215_ciber-taekwondo",  //Кибер тхэквондо
			216: "216_eurovision",  //Евровидение
			217: "217_skeet-shooting",  //Стендовая стрельба
			218: "cyber-zone--dead-or-alive",  //Dead Or Alive
			219: "cyber-zone--tabs",  //TABS
			220: "entertainment--boat-race",  //Гонки на лодках
			221: "cyber-zone--rumle-stars",  //RumbleStars
			222: "222_fishing",  //Рыбалка
			223: "223_spike-ball",  //Spike Ball
			224: "cyber-zone--brawlout",  //Brawlout
			225: "225_super-blood-hockey",  //Супер Кровавый Хоккей
			226: "226_pinball",  //Пинбол
			227: "227_robot-champions",  //Robot Champions
			228: "cyber-zone--boxing-champs",  //Boxing Champs
			229: "229_warThunder",  //WarThunder
			230: "230_football-tennis",  //Футболтеннис
			231: "231_dota-auto-chess",  //Dota Auto Chess
			232: "marble--golf",  //Марбл Гольф
			233: "marble--shooting",  //Марбл Стрельба
			234: "234_darts-live",  //Дартс Лайв
			235: "entertainment--dice",  //Кости
			236: "entertainment--baccara",  //Баккара
			237: "cyber-zone--kopanito-socccer",  //Kopanito Soccer
			238: "238_mega-baseball",  //Mega Baseball
			239: "sport--teqball",  //Текбол
			240: "marble--spinner",  //Марбл Спиннеры
			241: "cyber-zone--raid-shadow-legends",  //Raid: Shadow Legends
			242: "sport--keirin",  //Кейрин
			243: "entertainment--sea-battle",  //Морской бой
			244: "marble--billiards",  //Марбл Бильярд
			245: "marble--collision",  //Марбл Коллижн
			246: "marble--slides",  //Марбл Слайдс
			247: "marble--target",  //Марбл Мишень
			248: "marble--waves",  //Марбл Вэйвс
			249: "sport--auto-racing-wheel",  //Спидвей (Япония)
			250: "marble--curling",  //Марбл Керлинг
			251: "cyber-zone--super-kicker-league",  //Super Kickers League
			252: "entertainment--table-soccer",  //Настольный футбол
			253: "marble--race",  //Марбл Гонки
			254: "marble--lotto",  //Марбл Лото
			255: "marble--mma",  //Марбл ММА
			256: "256_air-hockey",  //Аэрохоккей
			257: "entertainment--seka",  //Сека
			258: "entertainment--victory-formula",  //Формула победы
			259: "259_world-of-warships",  //World of Warships
			260: "marble--smash",  //Марбл Смэш
			261: "marble--baseball",  //Марбл Бейсбол
			262: "262_headis",  //Хэдис
			263: "cyber-zone--street-power-football",  //Street Power Football
			264: "264_cyber-horse-racing",  //Cyber Horse Racing
			265: "marble--volleyball",  //Марбл Волейбол
			266: "marble--basketball",  //Марбл Баскетбол
			268: "entertainment--russian-lotto",  //Русское лото
			269: "269_winter-olympic-games",  //Зимние Олимпийские Игры
			270: "sport--climbing",  //Спортивное скалолазание
			271: "271_karate",  //Карате
			272: "cyber-sport--bowling",  //Кибер боулинг
			273: "273_wwe-battlegrounds",  //WWE Battlegrounds
			274: "274_killer-joker",  //Killer Joker
			275: "275_kun-khmer",  //Кун Кхмер
			276: "276_sega-football",  //SEGA Football
			277: "277_guilty-gear",  //Guilty Gear
			278: "278_fist-fight",  //Кулачные бои
			279: "279_hyper-brawl",  //Hyper Brawl
			280: "280_big-rumble-boxing",  //Big Rumble Boxing
			281: "281_roller-hockey",  //Хоккей с мячом на роликовых коньках
			282: "282_padel",  //Падел
			283: "283_pickleball",  //Пиклбол
			284: "284_sette-e-mezzo",  //Sette e mezzo
			286: "286_pelota",  //Пелота
			287: "287_crystal",  //Crystal
			288: "288_ultimate-frisbee",  //Алтимат фрисби
			289: "289_carrom",  //Карром
			291: "291_gigabash",  //GigaBash
			292: "292_higer-vs-lower",  //Higher vs Lower
			293: "293_super-soccer-blast",  //Super Soccer Blast
			294: "294_polo",  //Поло
			295: "295_indoor-cricket",  //Индор Крикет
			296: "296_robot-sport",  //Робот спорт
			297: "297_dice-poker",  //Покер на костях
			298: "298_overwatch",  //Overwatch
			299: "299_cornhole",  //Корнхол
			300: "300_tape-to-tape",  //Tape To Tape
};

export default SportToSvgMap;