import {Container}
    from "inversify";
import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import useBettingModule
    from "~app/IoC/useBettingModule";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import {AppInterfaceType}
    from "~app/Epos/Enum/AppInterfaceType";
import {WorkspaceLogicModule}
    from "~app/Epos/WorkspaceLogicModule";
import AVAILABLE_BETTING_LOCALES
    from "~app/Betting/BettingLocales.tech";

export const MarketUpdateTaskName: string = 'loaded-market-update';

/**
 * Задание на фоновое обновление
 * данных активного маркета.
 */
const MarketUpdateInterval: IntervalTaskContract = {
    requiredInterface: [
        AppInterfaceType.EPOS_DESKTOP,
    ],

    requiredModules: [
        WorkspaceLogicModule.BETTING,
    ],

    allowedLocales: AVAILABLE_BETTING_LOCALES,

    intervalMs:        6 * 1000,
    startOnInit:       true,
    authRequired:      true,
    cashierRequired:   true,
    shiftRequired:     true,

    id:       MarketUpdateTaskName,
    sequence: 'betting-feed',
    payload:  async function (
        ioc: Container
    ): Promise<void>
    {
        const {
            $betting,
        } = usePiniaState();

        if (
            null === $betting.activeEvent ||
            null === $betting.availableMarkets
        ) {
            // Автоматическое обновление маркета не требуется, если
            // событие не загружено в маркет, либо если
            // маркет по какой-либо причине пуст:
            return;
        }

        const {
            $bettingModule,
        } = useBettingModule();

        await $bettingModule
            .loadMarketForEvent();
    }
}

export default MarketUpdateInterval;