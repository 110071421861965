<template>
  <li
      :class="buttonIconCLass"
      class="status">
    <!--    Кнопка для раскрытия/скрытия блока с уведомлением-->
    <button
        @click="clickHandler($event)"
        class="status__button status-button"
    >
      <svg-elem
          sprite="cleared"
          :to="buttonIcon"
          class="status-button__icon"
      />
    </button>
    <transition name="header-status-tooltip">
      <ul
          v-show="isShown"
          :class="'status-tooltip--position-' + position"
          class="status__tooltip status-tooltip"
      >
        <li class="status-tooltip__item">
          <div class="status-tooltip__notification status-notification">
            <svg-elem
                sprite="cleared"
                :to="notificationIcon"
                :class="notificationIconHighlightClass"
                size="size-s"
                class="status-notification__icon"
            />
            <div class="status-notification__content">
              <span class="status-notification__title">{{ title }}</span>
              <p
                  v-if="!Array.isArray(description)"
                  class="status-notification__text"
              >
                {{ description }}
              </p>
              <ol
                  v-else
                  class="status-notification__list status-notification-list"
              >
                <li
                    v-for="item in description"
                    class="status-notification-list__item"
                >
                  {{ item }}
                </li>
              </ol>
              <!-- Кнопка для скрытия блока уведомления-->
              <Button
                  @click="isShown = false"
                  theme-style="outlined"
                  size="size-s"
                  theme="outlined-primary"
                  text="remove"
                  class="status-notification__button"
              />
            </div>
            <Button
                v-if="isCrossNedeed"
                @click="isShown = false"
                class="status-notification__close status-notification-close"
            >
              <svg-elem
                  sprite="cleared"
                  to="close"
                  size="size-xs"
                  class="status-notification-close__icon"
              />
            </Button>
          </div>
        </li>
      </ul>
    </transition>
  </li>
</template>

<script lang="ts">
const CMP_NAME = 'ConnectionStatus';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
import {
  computed,
  defineProps,
  PropType,
} from "@vue/runtime-core";

/** IMPORTED SCRIPTS: */
import {StatusNotification}
  from "~app/Storage/contracts/Notification";
import {Severity}
  from "~lib/logger/Severity";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
  from "~tpl-global/svg/SvgElem.vue";
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import {SvgList}
  from "~tpl-global/svg/SvgList";
import UseStandaloneNotification
  from "~tpl-desktop/workspace/TopBar/useNotifications";

/** COMPONENT LOGIC: */
const {
  isShown,
  position,
  clickHandler,
} = UseStandaloneNotification();

type SeverityClassMap        = {[key in Severity]: string};
type SeverityIcoMapType      = {[key in Severity]: SvgList};
type SeverityIcoClassMapType = {[key in Severity]: string};

const props = defineProps({
  buttonIcon:    {type: String as PropType<SvgList>,                 required: true},
  defaultData:   {type: Object as PropType<StatusNotification>,      required: true},
  targetData:    {type: Object as PropType<StatusNotification|null>},
  isCrossNedeed: {type: Boolean,                                     default: false}
});

/**
 * Определение severity на основании актуальных данных
 * или значении по-умолчанию
 */
const severity = computed<Severity>(() => {
  return (!props.targetData)
      ? props.defaultData.severity
      : props.targetData.severity;
});

/**
 * Определение заголовка уведомление на основании
 * актуальных данных или значении по-умолчанию
 */
const title = computed<string>(() => {
  return (!props.targetData)
      ? props.defaultData.title
      : props.targetData.title;
});

/**
 * Определение текста уведомления на основании актуальных данных
 * или значении по-умолчанию
 */
const description = computed<string|string[]>(() => {
  return (!props.targetData)
      ? props.defaultData.description
      : props.targetData.description;
});

/**
 * Определение иконки уведомления на основании актуальных данных
 * или значении по-умолчанию
 */
const notificationIcon = computed<SvgList>(() => {
  return  (!props.targetData)
      ? props.defaultData.icon
      : props.targetData.icon;
});

/**
 * Определяем цвет кнопки в топ меню на основании severity
 */
const buttonIconCLass = computed<string>(() => {
  return severityButtonIconClassMap[severity.value] ?? '';
});

/**
 * Определяем иконку кнопки в топ меню на основании severity
 */
const notificationIconHighlightClass = computed<string>(() => {
  return severityIcoClassMap[severity.value] ?? '';
});

/**
 * Маппинг соответствия класса уровню severity для определения
 * класса подсветки иконки кнопки в топ меню
 */
const severityButtonIconClassMap: SeverityIcoClassMapType = {
  emergency: 'status--is-bad',
  alert:     'status--is-bad',
  critical:  'status--is-bad',
  error:     'status--is-bad',
  warning:   'status--is-bad',
  notice:    'status--is-good',
  info:      '',
  debug:     '',
}

/**
 * Маппинг соответствия класса уровню severity для определения
 * класса подсветки иконки в уведомлении
 */
const severityIcoClassMap: SeverityIcoClassMapType = {
  emergency: 'status-notification__icon--is-alert',
  alert:     'status-notification__icon--is-alert',
  critical:  'status-notification__icon--is-alert',
  error:     'status-notification__icon--is-alert',
  warning:   'status-notification__icon--is-alert',
  notice:    'status-notification__icon--is-good',
  info:      '',
  debug:     '',
}
</script>

<style lang="scss" scoped>
  @import '~scss-desktop/common/components/header-statuses/_header-statuses';
</style>