import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";

import EposService
    from "~app/Epos/Epos.service";
import AccountingService
    from "~app/Epos/Accounting.service";
import FeedService
    from "~app/Betting/Feed.service";
import CustomerService
    from "~app/Customer/Customer.service";
import BettingService
    from "~app/Betting/Betting.service";
import ReCaptchaService
    from "~app/Throttling/ReCaptcha/ReCaptcha.service";

import EposModule
    from "~app/Epos/Epos.module";
import BettingModule
    from "~app/Betting/Betting.module";
import AccountingModule
    from "~app/Epos/Accounting.module";
import StorageModule
    from "~app/Epos/Storage.module";
import WorkspaceModule
    from "~app/Epos/Workspace.module";

/**
 * Регистрация сервисов и модулей приложения, инициализация которых
 * должна быть доступна через IoC контейнер.
 */
export default class DependenciesInjectionProvider
    extends ServiceProvider {

    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('ApplicationServicesProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        // Сервисы, доступные
        // приложению:
        this.defineService(app);

        // Модули бизнес логики,
        // доступные приложению:
        this.defineModules(app);
    }

    /**
     * Список сервисов приложения.
     */
    private defineService(app: Container): void
    {
        app.bind<EposService>(EposService.SONAR)
            .to(EposService);

        app.bind<AccountingService>(AccountingService.SONAR)
            .to(AccountingService);

        app.bind<FeedService>(FeedService.SONAR)
            .to(FeedService);

        app.bind<CustomerService>(CustomerService.SONAR)
            .to(CustomerService);

        app.bind<BettingService>(BettingService.SONAR)
            .to(BettingService);

        app.bind<ReCaptchaService>(ReCaptchaService.SONAR)
            .to(ReCaptchaService);
    }

    /**
     * Список модулей приложения.
     */
    private defineModules(app: Container): void
    {
        app.bind<EposModule>(EposModule.SONAR)
            .to(EposModule);

        app.bind<BettingModule>(BettingModule.SONAR)
            .to(BettingModule);

        app.bind<AccountingModule>(AccountingModule.SONAR)
            .to(AccountingModule);

        app.bind<StorageModule>(StorageModule.SONAR)
            .to(StorageModule);

        app.bind<WorkspaceModule>(WorkspaceModule.SONAR)
            .to(WorkspaceModule);
    }
}