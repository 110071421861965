import Exception
    from "~lib/Exception/Exception";
import {AppCoreErrors}
    from "~lib/Exception/Enum/AppCore.errors";

export type ConstructorType = {
    message:   string|null;
    at?:       number
    previous?: any;
};

/**
 * Техническое исключение, выбрасываемое обработчиком исключений,
 * когда перехваченную ошибку/исключение невозможно корректно обработать.
 *
 * Всегда приводит к аварийному завершению работы приложения.
 */
export default class UnexpectedRuntimeException
    extends Exception

    implements ConstructorType {

    constructor(
        payload: ConstructorType
    )
    {
        super({
            isFatal:  true,
            code:     AppCoreErrors.APP_UNEXPECTED_ERROR,
            type:     'Exception',
            name:     'UnexpectedRuntimeException',
            message:  payload.message,

            at:       payload.at,
            label:    'UNEXPECTED-RUNTIME-EXCEPTION',
            previous: payload.previous
        });
    }

    /**
     * Автоматическая обработка исключения неизвестного типа
     * на основе переданных параметров.
     *
     * Если в поле 'throwable' был передан экземпляр 'Exception',
     * то он вернется без изменений.
     *
     * Иначе будет создан экземпляр исключения 'UnexpectedRuntimeException',
     * в который будут добавлены описания и времени, переданные в метод.
     *
     * @param throwable - Данные, перехваченные как исключение.
     * @param message   - Сообщение об ошибке, которое будет подставлено в начало текста исключения.
     * @param at        - (Опционально) Unix timestamp времени перехвата исключения.
     */
    public static autoConstruct(
        throwable: unknown,
        message:   string,
        at?:       number,
    ): Exception
    {
        if (throwable instanceof Exception)
        {
            return throwable;
        }

        if (throwable instanceof Error)
        {
            return new UnexpectedRuntimeException({
                message:  `${message} Details: '${throwable.message}'.`,
                previous: throwable,
                at:       at,
            });
        }

        message += `  Details: unexpected throwable with type '${typeof throwable}'`

        if ([
            'string',
            'number',
            'boolean',
        ].includes(typeof throwable))
        {
            message += ` and value '${throwable}'.`
        }

        else message += '.'

        return new UnexpectedRuntimeException({
            message: message,
            at:      at
        });
    }
}