import {computed}
    from "@vue/runtime-core";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";

export type LimitStatuses = "normal" | "caution" | "alert";

export default function ()  {
    const {
        $shift,
    } = usePiniaState();

    const {
        $T
    } = useInterfaceTexts();

    /**
     * Возвращаем информационный текст о состоянии баланса
     * в зависимости от процента использованных средств.
     */
    const balanceLimitInfo = computed<string[]>((): string[] =>
    {
        if (100 === $shift.balanceLimit)
        {
            return $T.composite(
                "shift-current-pre-paid-balance-is-empty"
            );
        }

        if (100 > $shift.balanceLimit && 80 < $shift.balanceLimit)
        {
            return $T.composite(
                "shift-current-pre-paid-balance-almost-empty"
            );
        }

        if (80 >= $shift.balanceLimit && 50 < $shift.balanceLimit)
        {
            return $T.composite(
                "shift-current-pre-paid-balance-less-than-half"
            );
        }

        return $T.composite(
            "shift-current-pre-paid-balance-is-full"
        );
    })

    /**
     * Возвращаем статус шкалы баланса в зависимости
     * от процента использованных средств.
     */
    const balanceLimitStatus = computed<LimitStatuses>(() =>
    {
        if (100 >= $shift.balanceLimit && 80 < $shift.balanceLimit)
        {
            return "alert";
        }

        if (80 >= $shift.balanceLimit && 50 < $shift.balanceLimit)
        {
            return "caution";
        }

        return "normal"
    })

    return {
        balanceLimitInfo,
        balanceLimitStatus,
    };
}