import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";

import {SystemSonar}
    from "~app/Epos/Enum/SystemSonar";
import RoutesPool
    from "~app/Epos/RoutesPool";
import {InterfaceBootstrapResponse}
    from "~app/Epos/Contracts/Epos.types";

/**
 * До синхронизации приложения доступно ограниченное количество
 * api маршрутов, которые берутся из инициализирующей константы.
 *
 * Если рабочее место не авторизовано, то должны быть доступно
 * минимально необходимое количество маршрутов.
 */
export default class DefineInitRoutesProvider
    extends ServiceProvider
{
    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('DefineInitRoutesProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        const appInitData: InterfaceBootstrapResponse = app
            .get<InterfaceBootstrapResponse>(SystemSonar.AppInitConstant);

        app.bind<RoutesPool>(RoutesPool.SONAR)
            .toConstantValue(
                new RoutesPool(appInitData.pathList)
            );
    }
}