export const DayOfWeek: {[key: string]: {[key: string]: string}} = {
    UZ: {
        MON: 'DUS',
        TUE: 'SES',
        WED: 'CHO',
        THU: 'PAY',
        FRI: 'JUM',
        SAT: 'SHA',
        SUN: 'YAK',
    },
    SO: {
        MON: 'ISN',
        TUE: 'TAL',
        WED: 'ARB',
        THU: 'KHA',
        FRI: 'JIM',
        SAT: 'SAB',
        SUN: 'AXA',
    },
}

export const MonthOfYear: {[key: string]: {[key: string]: string}} = {
    UZ: {
        JAN: 'YAN',
        FEB: 'FEV',
        MAR: 'MAR',
        APR: 'APR',
        MAY: 'MAY',
        JUN: 'IYUN',
        JUL: 'IYUL',
        AUG: 'AVG',
        SEP: 'SEN',
        OCT: 'OKT',
        NOV: 'NOY',
        DEC: 'DEK',
    },
    SO: {
        JAN: 'JAN',
        FEB: 'FEB',
        MAR: 'MAR',
        APR: 'ABR',
        MAY: 'MAA',
        JUN: 'JUU',
        JUL: 'LUL',
        AUG: 'AGO',
        SEP: 'SEB',
        OCT: 'OKT',
        NOV: 'NOF',
        DEC: 'DIS',
    },
};

/**
 * Замена значений календарных значений для языков,
 * не поддерживаемых функционалом дат.
 *
 * @param lang    - 2-х символьный код языка.
 * @param type    - Тип перебора.
 * @param payload - Строка для поиска и замены.
 */
export function calendarNamesReplacer(
    lang:    string,
    type:    'week' | 'month' | 'all',
    payload: string,
): string
{
    lang = lang.toUpperCase();

    if (!['week', 'month', 'all'].includes(type)) {
        return payload;
    }

    if (['week', 'all'].includes(type) && DayOfWeek.hasOwnProperty(lang)) {
        for (const placeholder of Object.keys(DayOfWeek[lang])) {
            payload = payload.replace(
                new RegExp(placeholder, 'gi'),
                DayOfWeek[lang][placeholder]
            );
        }
    }

    if (['month', 'all'].includes(type) && MonthOfYear.hasOwnProperty(lang)) {
        for (const placeholder of Object.keys(MonthOfYear[lang])) {
            payload = payload.replace(
                new RegExp(placeholder, 'gi'),
                MonthOfYear[lang][placeholder]
            );
        }
    }

    return payload;
}