import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";

export type ErrorMessage = {
    message?: string|null,
    code?:    number|string|null,
}

export default function () {

    const {$T} = useInterfaceTexts();

    function getError(payload: ErrorMessage = {}): string
    {
        const messagePart: string = payload.message
            ?? $T.text('modal-error-message-default');

        const codePart: string|null = (!payload.code)
            ? null
            : `${$T.text('modal-error-code-attachment', {'error-code': `${payload.code}`})}`;
        
        return (!codePart)
            ? messagePart
            : `${messagePart} {-} ${codePart}`;
    }

    return {
        getError,
    };
}