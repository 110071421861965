import {Container}
    from "inversify";
import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import AccountingModule
    from "~app/Epos/Accounting.module";

/**
 * Повторяющееся задание на обновление списка транзакций,
 * совершенных на рабочем месте в текущую активную смену.
 */
const TransactionsUpdateInterval: IntervalTaskContract = {
    id:              'transactions-list-update',
    intervalMs:      12 * 1000,
    startOnInit:     true,
    authRequired:    true,
    cashierRequired: true,
    shiftRequired:   true,
    sequence:        'shift-data',

    payload: async function (
        ioc: Container
    ): Promise<void>
    {
        await (ioc.get<AccountingModule>(AccountingModule.SONAR))
            .updateWorkspaceTransactions();
    }
};

export default TransactionsUpdateInterval;