import {Container}
    from "inversify";
import {OnAppInitTask}
    from "~app/Epos/Contracts/Epos.types";
import {WorkspaceLogicModule}
    from "~app/Epos/WorkspaceLogicModule";
import FeedService
    from "~app/Betting/Feed.service";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import {Country}
    from "~app/Betting/Contracts/Feed.types";
import AVAILABLE_BETTING_LOCALES
    from "~app/Betting/BettingLocales.tech";

export const COUNTRIES_CACHE_KEY = Symbol
    .for('countries-feed-storage-key');

/**
 * Задание на проверку актуальности кеша списка стран,
 * хранящегося на клиенте.
 *
 * В случае если кеша нет, либо если он устарел - то
 * будут запрошены свежие данные, и сохранены в IndexedDB.
 *
 * Актуальный кеш загружается в runtime хранилище приложения,
 * и используется при формировании фидов live / pre-match.
 */
const UpdateSportsFeedTask: OnAppInitTask =
    {
        id:          'countries-data-update',
        description: 'interface-init-step-sync-countries',
        attempts:    2,

        requiredInterface: ['epos-desktop'],
        requiredModules:   [WorkspaceLogicModule.BETTING],

        allowedLocales: AVAILABLE_BETTING_LOCALES,

        // TODO: вынести в одноразовый таск, отдельный от инициализации
        runner: async function (
            app: Container
        ): Promise<boolean>
        {
            const {
                $feed
            } = usePiniaState();

            const $feedService = app
                .get<FeedService>(FeedService.SONAR);

            type CountriesCacheStructure = {
                at:   number,
                data: {[key: number]: Country}
            };

            // Логика обновления данных фида
            // в runtime хранилище приложения:
            const loadFeedCacheIntoApp = (
                cache: CountriesCacheStructure
            ): void =>
            {
                $feed.updateCountriesFeed(
                    cache.data
                );
            };

            // Поиск сохраненного кеша спортов в IndexedDB:
            const storedFeedCache = await $feedService
                .extractFeedFromCache<CountriesCacheStructure>(
                    COUNTRIES_CACHE_KEY.toString()
                );

            // Если кеш загружен в интерфейс и актуален, то
            // его содержимое загружается в интерфейс:
            if (
                null !== storedFeedCache &&
                await $feedService.checkCountriesFeedRelevance(storedFeedCache.at)
            )
            {
                loadFeedCacheIntoApp(
                    storedFeedCache
                );

                return true;
            }

            // Запрос актуального кеша списка спортов:
            const retrievedFeedCache = await $feedService
                .retrieveCountriesFeed();

            // Сохранение полученного фида в IndexedDB
            await $feedService.storeFeedToCache(
                COUNTRIES_CACHE_KEY.toString(),
                retrievedFeedCache
            );

            loadFeedCacheIntoApp(
                retrievedFeedCache
            );

            return true;
        }
    };

export default UpdateSportsFeedTask;