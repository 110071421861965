import { Container, interfaces }
    from "inversify";
import Bowser
    from "bowser";

import HttpClient, {HeadersList}
    from "~lib/client/HttpClient";

import ServiceProvider
    from "~ioc/ServiceProvider";
import WorkspaceHeaders
    from "~app/Epos/WorkspaceHeaders";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/**
 * Регистрирует HTTP клиент, используемый для всех запросов,
 * совершаемых приложением.
 */
export default class HttpClientProvider
    extends ServiceProvider {

    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('HttpClientProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        const {
            $app,
        } = usePiniaState();

        const info= Bowser
            .getParser(window.navigator.userAgent);

        const appVersion: string = $app
            .parameters('appVersion') ?? '';

        const headers: HeadersList = {};

        // Заголовок с информацией о рабочем месте:
        headers[
            WorkspaceHeaders.WorkspaceClient
            ] = `EPOS-Interface/${appVersion};` +
                ` ${info.getBrowserName()}/${info.getBrowserVersion()};` +
                ` ${info.getOSName()}/${info.getOSVersion()};` +
                ` Locale/${$app.locale}`;

        // Заголовок с локалью, которую необходимо
        // использовать для обработки запроса:
        headers[
            WorkspaceHeaders.X_CLIENT_TEXTS_LIBRARY_LOCALE
            ] = $app.locale;

        app.bind<HttpClient>(HttpClient.SONAR)
            .toDynamicValue((context: interfaces.Context): HttpClient =>
            {
                const {
                    $workspace,
                } = usePiniaState();

                if (null !== $workspace.getWorkspaceAnchor) {
                    headers[
                        WorkspaceHeaders.WorkspaceAnchor
                        ] = $workspace.getWorkspaceAnchor;
                }

                return new HttpClient(
                    {
                        timeout:      12,
                        payloadType:  'msgpack',
                        responseType: 'msgpack',
                        headers:      headers,
                    },
                )
            });
    }
}