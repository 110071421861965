import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";

import StateUpdateInterval
    from "~app/Task/StateUpdate.interval";
import LiveFeedUpdateInterval
    from "~app/Task/Betting/LiveFeedUpdate.interval";
import PreMatchFeedUpdateInterval
    from "~app/Task/Betting/PreMatchFeedUpdate.interval";
import TransactionsUpdateInterval
    from "~app/Task/Shift/TransactionsUpdate.interval";
import PendingWithdrawalsUpdateInterval
    from "~app/Task/Shift/PendingWithdrawalsUpdate.interval";
import CheckAppUUIDInterval
    from "~app/Task/System/CheckAppUUID.interval";
import MarketUpdateInterval
    from "~app/Task/Betting/MarketUpdate.interval";
import CouponsPerShiftUpdateInterval
    from "~app/Task/Betting/CouponsPerShiftUpdate.interval";

/**
 * Список заданий, которые должны выполнятся при работе приложения,
 * с заранее заданной периодичностью.
 *
 * Задания, не подходящие по параметрам, не меняющимся после инициализации интерфейса,
 * будут исключены из списка супервизором.
 */
const DefinedAppIntervalTasksList: IntervalTaskContract[] = [
    CheckAppUUIDInterval,
    StateUpdateInterval,
    TransactionsUpdateInterval,
    PendingWithdrawalsUpdateInterval,
    LiveFeedUpdateInterval,
    PreMatchFeedUpdateInterval,
    MarketUpdateInterval,
    CouponsPerShiftUpdateInterval,
];

export default DefinedAppIntervalTasksList;