import {array} from "~lib/CustomTypes";

export default class Arr {

    /**
     * Set an array item to a given value using "dot" notation.
     * If no key is given to the method, the entire array will be replaced.
     *
     * @param target
     * @param key
     * @param value
     */
    public static set<T = any>(
        target: array|[],
        key:    string|number,
        value:  T
    ): void
    {
        if ('number' === typeof key) {
            target[key] = value as unknown as array;
            return;
        }

        const map = key.split('.');

        for (const i of map.keys()) {
            if ((map.length - 1) === i) {
                break;
            }

            if (
                'object' !== typeof (target as {[index: string|number]: object})[map[i]] ||
                null === (target as {[index: string|number]: object})[map[i]]
            ) {
                (target as {[index: string|number]: object})[map[i]] = {};
            }

            target = (target as {[index: string|number]: object})[map[i]] as unknown as array;
        }

        (target as {[index: string|number]: any})[map.splice(-1)[0]] = value;
    }

    /**
     * Swap keys and values in provided object.
     *
     * If the type of swapped key is not "string" or "number"
     * exception will be thrown.
     *
     * @param target
     */
    public static flip(target: array|string[]|number[]|[]): array
    {
        const swapped = Object.entries(target).map(
            ([key, value]) => {

                if (!['string', 'number'].includes(typeof value)) {
                    throw `Forbidden to set value with type "${typeof value}" as flipped index. ` +
                    `Original index is: "${key}"`;
                }

                return [value, key];
            }
        );

        return Object.fromEntries(swapped);
    }
}