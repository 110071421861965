import {Axios, AxiosRequestConfig, AxiosResponse, ResponseType}
    from 'axios';
import {Base64}
    from "js-base64";
import {injectable}
    from "inversify";

import MsgpackDecoder
    from "~lib/msgpack/Decoder";
import MsgpackEncoder
    from "~lib/msgpack/Encoder";
import Headers
    from "~lib/client/Headers";
import MsgpackContentTypes
    from "~lib/client/MsgpackContentTypes";
import Binary
    from "~lib/helpers/Binary";
import HttpResponse, {
    ResponseConstructor
} from "~lib/client/Response";

import HttpClientSetupException
    from "~lib/client/exceptions/HttpClientSetup.exception";

export type ContentType = ResponseType | 'msgpack' | 'msgpack-hex' | 'msgpack-base64';
export type HeadersList = {[index: string]: string};

/**
 * Значения по-умолчанию, используемые для
 * всех HTTP запросов.
 *
 * @param baseURL      - корневой урл запроса (axios)
 * @param timeout      - время ожидания ответа
 * @param headers      - дополнительные заголовки запроса
 * @param payloadType  - тип содержимого запроса
 * @param responseType - ожидаемый тип содержимого с ответом от сервера
 */
export type DefaultRequestOptions = {
    baseURL?:      string;
    timeout?:      number;
    headers?:      HeadersList;
    payloadType?:  ContentType;
    responseType?: ContentType;

    rspSuccessMarker?: string,
    rspApiCodeMarker?: string,
    rspMessageMarker?: string,
    rspDataMarker?:    string,
};

/**
 * Параметры конкретного запроса, перетирающие или дополняющие
 * настройки запроса по умолчанию.
 *
 * @param params - URL параметры запроса
 */
export type RequestOptions = DefaultRequestOptions & {
    params?:  {[index: string]: string};
    headers?: {[index: string]: string};
};

@injectable()
export default class HttpClient {

    public static SONAR: symbol = Symbol
        .for('HttpClient');

    /**
     * Режим отладки запросов.
     *
     * @protected
     */
    protected readonly debugMode = false;

    /**
     * Опции запроса по умолчанию, в случае
     * если какие-либо из полей не были заданы
     * для конкретного запроса.
     *
     * @protected
     */
    protected readonly defaultRequest: DefaultRequestOptions;

    /**
     * @param defaultRequest -
     * @param debug          -
     */
    constructor(
        defaultRequest: DefaultRequestOptions,
        debug:          boolean                = false,
    )
    {
        this.defaultRequest = {
            payloadType:  defaultRequest.payloadType  ?? 'msgpack',
            responseType: defaultRequest.responseType ?? 'msgpack',
            headers:      defaultRequest.headers      ?? {},
            timeout:      defaultRequest.timeout      ?? 10,
            baseURL:      defaultRequest.baseURL      ?? '',

            rspSuccessMarker: defaultRequest.rspSuccessMarker ?? 'status',
            rspApiCodeMarker: defaultRequest.rspApiCodeMarker ?? 'code',
            rspMessageMarker: defaultRequest.rspMessageMarker ?? 'msg',
            rspDataMarker:    defaultRequest.rspDataMarker    ?? 'payload',
        };
    }

    /**
     * POST запрос к указанному адресу.
     *
     * @param to        - адрес запроса
     * @param payload   - содержимое запроса
     * @param rqOptions - (опционально) параметры запроса
     */
    public async post<Response = any, Payload = any>(
        to:         string,
        payload:    Payload|null = null,
        rqOptions?: RequestOptions
    ): Promise <HttpResponse<Response, Payload>>
    {
        const options: RequestOptions = this
            .mergeCommitOptions(
                rqOptions
            );

        const config: AxiosRequestConfig<Payload> = {
            baseURL: options.baseURL ?? this.defaultRequest.baseURL,
            url:     to,
            method: 'POST',
            timeout: (options?.timeout ?? 10) * 1000, // milliseconds
            headers: this.mergeRequestHeaders(options?.headers),
            data:    (null === payload) ? undefined : payload,
            params:  options.params
        };

        return this
            .commit<Response, Payload>(
                config,
                options
            );
    }

    /**
     * GET запрос к указанному адресу.
     *
     * @param to        - адрес запроса
     * @param params    - URL параметры запроса
     * @param rqOptions - (опционально) параметры запроса
     */
    public async get<Response = any, Payload = any>(
        to:         string,
        params?:    {[index: string]: string},
        rqOptions?: RequestOptions
    ): Promise <HttpResponse<Response, Payload>>
    {
        const options: RequestOptions = this.mergeCommitOptions(
            rqOptions
        );

        const config: AxiosRequestConfig<Payload> = {
            baseURL: options.baseURL ?? this.defaultRequest.baseURL,
            url:     to,
            method: 'GET',
            timeout: (options?.timeout ?? 10) * 1000, // milliseconds
            headers: this.mergeRequestHeaders(options?.headers),
            params:  params,
        };

        return this
            .commit<Response, Payload>(
                config,
                options
            );
    }

    /**
     * HEAD запрос к указанному адресу.
     *
     * @param to        - адрес запроса
     * @param params    - URL параметры запроса
     * @param rqOptions - (опционально) параметры запроса
     */
    public async head(
        to:         string,
        params?:    {[index: string]: string},
        rqOptions?: RequestOptions
    ): Promise<HttpResponse<null>>
    {
        const options: RequestOptions = this
            .mergeCommitOptions(
                rqOptions
            );

        const config: AxiosRequestConfig = {
            baseURL: options.baseURL ?? this.defaultRequest.baseURL,
            url:     to,
            method: 'HEAD',
            timeout: (options?.timeout ?? 10) * 1000, // milliseconds
            headers: this.mergeRequestHeaders(options?.headers),
            params:  params,
        };

        return this
            .commit<null>(
                config,
                options
            );
    }

    /**
     * @param config
     * @param options
     *
     * @protected
     */
    protected async commit<Response = any, Payload = any>(
        config:  AxiosRequestConfig<Payload>,
        options: RequestOptions
    ): Promise <HttpResponse<Response, Payload>>
    {
        if (!config.baseURL && !config.url) {
            throw new HttpClientSetupException({
                message:   'You must setup "baseURL" or "url" config parameter to make request.',
                config:    config,
                rqOptions: options,
            });
        }

        if (!config.method) {
            throw new HttpClientSetupException({
                message:   'Request method is not set.',
                config:    config,
                rqOptions: options,
            });
        }

        const at = Date.now();
        const response: ResponseConstructor <Response, Payload> = {
            url:                  (config.baseURL ?? '') + (config.url ?? ''),
            method:               config.method        ?? 'unknown',
            params:               config.params        ?? null,
            payload:              config.data          ?? null as unknown as Payload,
            expectedPayloadType:  options.payloadType  ?? 'unknown',
            expectedResponseType: options.responseType ?? 'unknown',
            statusCode:           -1,
            statusText:           '',
            timeout:              config.timeout       ?? -1,
            duration:             -1,
            isSuccess:            false,
            code:                 null,
            message:              null,
            response:             null as unknown as Response,
        };

        const extractor = <T> (data: Response, key: string, def: T): T => ('object' === typeof data)
            ? (data as unknown as {[index: string]: T})[key] ?? def
            : def;

        return await this.generateAxiosInstance(config, options)
            .request<Response>(config)
            .then((rsp) => {

                response.statusCode = rsp.status;
                response.statusText = rsp.statusText;
                response.duration   = Date.now() - at;

                if ('object' === typeof rsp.data) {
                    response.isSuccess = extractor<boolean>(rsp.data, options.rspSuccessMarker as string, false);
                    response.code      = extractor<number|null>(rsp.data, options.rspApiCodeMarker as string, null);
                    response.message   = extractor<string|null>(rsp.data, options.rspMessageMarker as string, null);
                    response.response  = extractor<Response|null>(rsp.data, options.rspDataMarker as string, null);
                }

                return new HttpResponse<Response, Payload>(response);

            }).catch((error) => {
                console.log(error);
                //TODO: распарсить ошибки axios и преобразовать их в HttpResponse
                return new HttpResponse(response);
            });
    }

    /**
     * @param config
     * @param options
     *
     * @protected
     */
    protected generateAxiosInstance(
        config:  AxiosRequestConfig,
        options: RequestOptions
    ): Axios
    {
        const payloadType = options?.payloadType ?? this.defaultRequest.payloadType ?? 'text';

        // модификация настроек на случай если используется MsgPack:
        if (
            config.data &&
            ['PUT', 'POST', 'DELETE', 'PATCH'].includes((config.method as string).toUpperCase()) &&
            ['msgpack', 'msgpack-hex', 'msgpack-base64'].includes(payloadType)
        ) {
            // фикс на случай если объект с доп. заголовками пустой:
            config.headers = config.headers ?? {};

            // кодируем данные в MsgPack, согласно выбранной схеме:
            switch (payloadType) {
                case "msgpack-hex":
                    config.data = Binary.uint8ArrayToHex(MsgpackEncoder.encode(config.data));
                    config.headers[Headers.ContentType] = MsgpackContentTypes.HEX;
                    break;
                case 'msgpack-base64':
                    config.data = Base64.fromUint8Array(MsgpackEncoder.encode(config.data));
                    config.headers[Headers.ContentType] = MsgpackContentTypes.BASE64;
                    break;
                default:
                    config.data = MsgpackEncoder.encode(config.data);
                    config.headers[Headers.ContentType] = MsgpackContentTypes.MSGPACK;
                    break;
            }
        }

        // корректировка конфига на случай, если ожидаемый ответ сервера
        // так же закодирован в MsgPack:
        if (options.responseType && ['msgpack', 'msgpack-hex', 'msgpack-base64'].includes(options.responseType)) {
            config.responseType = ('msgpack' === options.responseType)
                ? 'arraybuffer'
                : 'text';

        } else {
            config.responseType = options.responseType as ResponseType;
        }

        const instance = new Axios(config);

        // для ответов от сервера, ожидаемых в формате MsgPack,
        // используем ручное преобразование тела ответа:
        if (options?.responseType && ['msgpack', 'msgpack-hex', 'msgpack-base64'].includes(options.responseType)) {
            instance
                .interceptors
                .response
                .use((response): AxiosResponse => HttpClient.decodeMsgpackResponse(
                    options.responseType as 'msgpack' | 'msgpack-hex' | 'msgpack-base64',
                    response
                ));
        }

        return instance;
    }

    /**
     * Возвращает массив с заголовками запроса, объединенный
     * с заголовками по-умолчанию.
     *
     * @param fromRequest - заголовки текущего запроса
     *
     * @protected
     */
    protected mergeRequestHeaders(fromRequest?: HeadersList): HeadersList
    {
        // TODO: привести все в lowercase, и потом в PascaleCase
        const headers = this.defaultRequest.headers ?? {};

        if (fromRequest) {
            for (const headerKey of Object.keys(fromRequest)) {
                headers[headerKey] = fromRequest[headerKey];
            }
        }

        return headers;
    }

    /**
     * Объединяет настройки по умолчанию, заданные для всех запросов,
     * с настройками для конкретного запроса.
     *
     * @param options
     *
     * @protected
     */
    protected mergeCommitOptions(options?: RequestOptions): RequestOptions
    {
        if ('undefined' === typeof options) {
            return this.defaultRequest;
        }

        return {
            ...this.defaultRequest,
            ...options
        };
    }

    /**
     * Преобразует ответ от сервера, полученный в формате Msgpack.
     *
     * @param expectedType
     * @param response
     *
     * @protected
     */
    protected static decodeMsgpackResponse<Response>(
        expectedType: 'msgpack' | 'msgpack-hex' | 'msgpack-base64',
        response:     AxiosResponse
    ): AxiosResponse<Response>
    {
        if (response.data.byteLength < 1) {
            return response;
        }

        if (['msgpack-hex', 'msgpack-base64'].includes(expectedType) && 'string' === typeof response.data) {
            if ('msgpack-hex' === expectedType) {
                response.data = MsgpackDecoder.decode(Binary.hexToUint8Array(response.data as string));

            } else {
                response.data = MsgpackDecoder.decode(Base64.toUint8Array(response.data as string));
            }

            return response;
        }

        response.data = MsgpackDecoder.decode<Response>(response.data);

        return response;
    }
}