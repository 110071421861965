<template>
  <li class="workplace-info-list__item">
    <div class="workplace-info-list__icon">
      <SvgElem sprite="cleared" :to="ico" class="workplace-info-list__svg"/>
    </div>
    <div class="workplace-info-list__text">
      <span :class="{ 'is-no-mirrored': noMirrored }" class="workplace-info-list__title">{{ title }}</span>
      <span class="workplace-info-list__subtitle">{{ description }}</span>
    </div>
  </li>
</template>

<script lang="ts">
const CMP_NAME = 'EposInfoLine';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {defineProps, withDefaults}
  from "@vue/runtime-core";
import {SvgList}
  from "~svg/cleared/pictograms/SvgList";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
  from "~tpl-global/svg/SvgElem.vue";

/** COMPONENT LOGIC: */
type ComponentProps = {
  ico:         SvgList,
  title:       string,
  description: string,
  noMirrored?: boolean
};

const props = withDefaults(defineProps<ComponentProps>(), {

});
</script>