import Exception
    from "~lib/Exception/Exception";

export type RuntimeExceptionConstructor = {
    isFatal:   boolean,
    code:      number;
    message:   string|null;

    label?:    string;
    previous?: any;
};

/**
 * Исключение, возникшее во время работы интерфейса, и
 * относящееся непосредственно к нему, либо бизнес логике.
 */
export default class RuntimeException
    extends    Exception
    implements RuntimeExceptionConstructor
{
    constructor(
        payload: RuntimeExceptionConstructor
    )
    {
        super({
            isFatal:  payload.isFatal ?? false,
            code:     payload.code,
            type:     'Exception',
            name:     'RuntimeException',
            label:    (payload.label)
                ? payload.label.toUpperCase()
                : 'RUNTIME-EXCEPTION',
            message:  payload.message,
            previous: payload.previous
        });
    }
}