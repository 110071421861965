/**
 * Помощь при работе с некоторыми объектами.
 */
export default class Obj {

    /**
     * Определяет, является ли переданная сущность
     * объектом, реализующим функционал Promise().
     *
     * @param item
     */
    public static isPromise(item: object|undefined|void): boolean
    {
        if (!item || 'object' !== typeof item) {
            return false;
        }

        return 'function' === typeof (item as {[index: string]: any})['then'];
    }
}