import {injectable} from "inversify";

import DbConnectionException   from "~lib/indexeddb/exceptions/DbConnection.exception";
import IdbTableModifyException from "~lib/indexeddb/exceptions/IdbTableModify.exception";
import IdbReadException        from "~lib/indexeddb/exceptions/IdbRead.exception";

export type DbEnvironment = 'browser' | 'worker';

/**
 * Настройки таблицы, хранящейся в IndexedDB.
 */
export interface TableSettings {
    // имя таблицы
    name: string;
    // ключ-поле таблицы
    primary: string;
    autoIncrement: boolean;
}

/**
 * Настройки подключения к конкретной базе IndexedDB
 */
export interface ConnectionSettings {
    // имя базы данных
    name: string;
    // версия базы данных
    version: number;
    // список таблиц, содержащихся в IndexedDB
    tables: {[index: string]: TableSettings}
}

@injectable()
export default class IndexedDb {

    public static readonly ENV_WORKER  = 'worker';
    public static readonly ENV_BROWSER = 'browser';

    /**
     * Среда, в которой ведется обращение к IndexedDB.
     * Может быть внутри контекста страницы браузера,
     * либо внутри контекста воркера.
     *
     * @protected
     */
    protected readonly env: DbEnvironment;

    /**
     * API для создания подключений к IndexedDB.
     *
     * @protected
     */
    protected readonly factory: IDBFactory;

    /**
     * Настройки подключаемой базы данных.
     *
     * @protected
     */
    protected readonly settings: ConnectionSettings;

    /**
     * Активное соединение с IndexedDB.
     *
     * @protected
     */
    protected connection: IDBDatabase|null = null;

    /**
     * @param settings
     */
    constructor(settings: ConnectionSettings) {

        this.settings = settings;
        this.env      = (self.document === undefined)
            ? IndexedDb.ENV_WORKER
            : IndexedDb.ENV_BROWSER;

        if (
            (this.env === 'browser' && !('indexedDB' in window)) ||
            (this.env === 'worker' && 'undefined' === typeof self.indexedDB)
        ) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  "IndexedDB API is not supported.",
            });
        }

        this.factory = (this.env === IndexedDb.ENV_WORKER)
            ? self.indexedDB
            : window.indexedDB;
    }

    /**
     * Пытается подключиться базе данных, указанной
     * в настройках при инициализации API.
     *
     * В случае успеха вернет Promise, содержащий
     * готовый к работе API.
     *
     * @return IndexedDb
     * @throws DbConnectionException
     */
    public async connect(): Promise<IndexedDb>
    {
        if (null !== this.connection) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  `Connection to DB "${this.settings.name} (ver. {${this.settings.version})" already established`,
            });
        }

        const db: IDBOpenDBRequest = this.factory.open(
            this.settings.name ,
            this.settings.version
        );

        this.connection = await new Promise<IDBDatabase>(async (resolve, reject) => {
            // обработка успешного подключения:
            db.onsuccess = (event: Event) => {
                resolve((event.target as unknown as {result: IDBDatabase}).result);
            };

            // обработка подключения, завершившегося неудачей:
            db.onerror = (event: Event) => {
                reject(new DbConnectionException({
                    env:      this.env,
                    settings: this.settings,
                    message:  db.error?.message
                        ?? 'Unexpected exception occurred while trying to connect to ' +
                        `DB "${this.settings.name} (ver. {${this.settings.version})".`,
                }));
            };

            // обработка запроса на обновление структуры базы данных:
            db.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                // получаем интерфейс для работы с бд:
                const database: IDBDatabase = (event.target as unknown as {result: IDBDatabase}).result;

                // сначала удаляем все старые таблицы базы, чтобы
                // не оставлять пустыми те, что более не нужны:
                const existedTables: string[] = [];
                for (const tableName of database.objectStoreNames) {
                    existedTables.push(tableName);

                    if ('undefined' !== typeof this.settings.tables[tableName]) {
                        continue;
                    }

                    database.deleteObjectStore(tableName);
                }

                // создаем необходимые таблицы, исключая уже существующие:
                for (const table of Object.values(this.settings.tables)) {
                    if (existedTables.includes(table.name)) {
                        continue;
                    }

                    database.createObjectStore(
                        table.name,
                        {
                            autoIncrement: table.autoIncrement
                        }
                    );
                }

                (event.target as unknown as {transaction: {oncomplete: ((ev: Event) => any)}})
                    .transaction
                    .oncomplete = (event: Event) => {

                    console.info(
                        `Database "${database.name}" successfully updated to version ${database.version}`
                    );

                    resolve(database);
                };
            };
        });

        return this;
    }

    /**
     * Закрывает активное соединение с базой данных.
     */
    public disconnect(): void
    {
        this.connection?.close();
    }

    /**
     * Добавляет новую запись в выбранную таблицу.
     *
     * При попытке добавить запись с уже существующем ключом
     * будет выброшено исключение.
     *
     * В случае успешного выполнения операции вернет ключ добавленной строки.
     *
     * Если хранилище использует встроенные ключи и передан ключ строки -
     * будет выброшено исключение.
     *
     * @param table - таблица, в которую будет осуществляться запись
     * @param value - записываемое значение
     * @param key   - (опционально) ключ записи в таблице
     *
     * @throws DbConnectionException
     * @throws IdbTableModifyException
     */
    public async add<Val = any, Key extends IDBValidKey = string>(
        table: string,
        value: Val,
        key?:  Key,
    ): Promise<Key>
    {
        if (null === this.connection) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  'API not connected to database. You must call "connect" before.',
            });
        }

        if (!this.settings.tables.hasOwnProperty(table)) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  `Requested table "${table}" is not exists in DB: "${this.settings.name}"`,
            });
        }

        const io = this.connection
            .transaction([table], "readwrite");

        return await new Promise<Key>((resolve, reject) => {
            const trs = io.objectStore(table)
                .add(value, key);

            io.oncomplete = (event: Event) => {
                resolve(trs.result as unknown as Key);
            };

            io.onerror = (event: Event) => {
                const keyName = key ?? '[auto-key]';
                const msg     = (event instanceof DOMException)
                    ? ' ' + event.message
                    : '';

                reject(new IdbTableModifyException({
                    env:      this.env,
                    settings: this.settings,
                    message:  `Failed to 'ADD' new row into "${table} (${this.settings.name}) with key "${keyName}".${msg}`,
                    field:    key ?? null,
                    value:    value
                }));
            };
        });
    }

    /**
     * Добавляет новую запись в таблицу, либо заменяет
     * уже существующую с таким же ключом.
     *
     * Если хранилище использует встроенные ключи и передан ключ строки -
     * будет выброшено исключение.
     *
     * @param table - таблица, в которую будет осуществляться запись
     * @param value - записываемое значение
     * @param key   - (опционально) ключ записи в таблице
     *
     * @throws DbConnectionException
     * @throws IdbTableModifyException
     */
    public async put<Val = any, Key extends IDBValidKey = string>(
        table: string,
        value: Val,
        key?:  Key,
    ): Promise<Key>
    {
        if (null === this.connection) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  'API not connected to database. You must call "connect" before.',
            });
        }

        if (!this.settings.tables.hasOwnProperty(table)) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  `Requested table "${table}" is not exists in DB: "${this.settings.name}"`,
            });
        }

        const io = this.connection
            .transaction([table], "readwrite");

        return await new Promise<Key>((resolve, reject) => {
            const trs = io.objectStore(table)
                .put(value, key);

            io.oncomplete = (event: Event) => {
                resolve(trs.result as unknown as Key);
            };

            io.onerror = (event: Event) => {
                const keyName = key ?? '[auto-key]';
                const msg     = (event instanceof DOMException)
                    ? ' ' + event.message
                    : '';

                reject(new IdbTableModifyException({
                    env:      this.env,
                    settings: this.settings,
                    message:  `Failed to 'PUT' new row into "${table} (${this.settings.name})" with key "${keyName}".${msg}`,
                    field:    key ?? null,
                    value:    value
                }));
            };
        });
    }

    /**
     * Удаляет из указанной таблицы записи по переданному ключу,
     * или диапазону ключей.
     *
     * @param table
     * @param query
     *
     * @throws DbConnectionException
     * @throws IdbTableModifyException
     */
    public async remove(
        table: string,
        query: IDBValidKey | IDBKeyRange
    ): Promise<boolean>
    {
        if (null === this.connection) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  'API not connected to database. You must call "connect" before.',
            });
        }

        if (!this.settings.tables.hasOwnProperty(table)) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  `Requested table "${table}" is not exists in DB: "${this.settings.name}"`,
            });
        }

        const io = this.connection
            .transaction([table], "readwrite");

        return await new Promise<boolean>((resolve, reject) => {
            io.oncomplete = (event: Event) => {
                resolve(true);
            };

            io.onerror = (event: Event) => {
                const msg = (event instanceof DOMException)
                    ? ' ' + event.message
                    : '';

                reject(new IdbTableModifyException({
                    env:      this.env,
                    settings: this.settings,
                    message:  `Failed to 'REMOVE' field(s) from "${table} (${this.settings.name})". Query is: "${query}".${msg}`,
                    field:    query,
                    value:    null
                }));
            };

            io.objectStore(table).delete(query);
        });
    }

    /**
     * Полностью очищает указанную таблицу.
     *
     * @param table
     *
     * @throws DbConnectionException
     * @throws IdbTableModifyException
     */
    public async flush(
        table: string
    ): Promise<boolean>
    {
        if (null === this.connection) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  'API not connected to database. You must call "connect" before.',
            });
        }

        if (!this.settings.tables.hasOwnProperty(table)) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  `Requested table "${table}" is not exists in DB: "${this.settings.name}"`,
            });
        }

        const io = this.connection
            .transaction([table], "readwrite");

        return await new Promise<boolean>((resolve, reject) => {
            io.oncomplete = (event: Event) => {
                resolve(true);
            };

            io.onerror = (event: Event) => {
                const msg = (event instanceof DOMException)
                    ? ' ' + event.message
                    : '';

                reject(new IdbTableModifyException({
                    env:      this.env,
                    settings: this.settings,
                    message:  `Failed to 'FLUSH' table "${table} (${this.settings.name})".${msg}`,
                    field:    null,
                    value:    null
                }));
            };

            io.objectStore(table).clear();
        });
    }

    /**
     * Ищет и возвращает значение первой записи, совпадающей с
     * запрошенным ключом, или массивом ключей.
     *
     * В случае успешного запроса возвращает хранимое значение,
     * иначе возвращает "null".
     *
     * @param table - таблица, в которой будет происходить поиск
     * @param key   - ключ записи в таблице
     *
     * @throws DbConnectionException
     * @throws IdbReadException
     */
    public async get<Val = null, Key extends IDBValidKey = string>(
        table: string,
        key:   Key
    ): Promise<Val|null>
    {
        if (null === this.connection) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  'API not connected to database. You must call "connect" before.',
            });
        }

        if (!this.settings.tables.hasOwnProperty(table)) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  `Requested table "${table}" is not exists in DB: "${this.settings.name}"`,
            });
        }

        const io = this.connection
            .transaction([table], "readonly");

        return await new Promise<Val|null>((resolve, reject) => {
            const trs = io.objectStore(table)
                .get(key);

            io.oncomplete = (event: Event) => {
                const result = trs.result as unknown as Val|undefined;
                resolve(('undefined' === typeof result)
                    ? null
                    : result
                );
            };

            io.onerror = (event: Event) => {
                const msg = (event instanceof DOMException)
                    ? ' ' + event.message
                    : '';

                reject(new IdbReadException({
                    env:      this.env,
                    settings: this.settings,
                    message:  `Failed to 'GET' record data from "${table} (${this.settings.name})" with key "${key}".${msg}`,
                    field:    key
                }));
            };
        });
    }

    /**
     * Возвращает из таблицы все записи, соответствующие настройка запроса.
     *
     * В случае если в таблице нет ни одной записи вернется "null".
     *
     * TODO: возможность добавить ключи в выборку
     *
     * @param table - таблица, в которой будет происходить поиск
     * @param query - (опционально) если передано, то в выборку попадут только записи,
     *                соответствующие указанным ключам
     * @param limit - (опционально) максимальное количество возвращаемых строк
     *
     * @throws DbConnectionException
     * @throws IdbReadException
     */
    public async getAll<Val = null>(
        table: string,
        query?: IDBValidKey | IDBKeyRange | null,
        limit?: number,
    ): Promise<Val[]|null>
    {
        if (null === this.connection) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  'API not connected to database. You must call "connect" before.',
            });
        }

        if (!this.settings.tables.hasOwnProperty(table)) {
            throw new DbConnectionException({
                env:      this.env,
                settings: this.settings,
                message:  `Requested table "${table}" is not exists in DB: "${this.settings.name}"`,
            });
        }

        const io = this.connection
            .transaction([table], "readonly");

        return await new Promise<Val[]|null>((resolve, reject) => {
            const trs = io.objectStore(table)
                .getAll(query, limit);

            io.oncomplete = (event: Event) => {
                const result = trs.result as unknown as Val[]|undefined;
                resolve(('undefined' === typeof result || result.length < 1)
                    ? null
                    : result
                );
            };

            io.onerror = (event: Event) => {
                const retrieveType = ('undefined' === typeof query)
                    ? 'GET ALL'
                    : 'GET MANY';
                const limitType = ('undefined' === typeof limit)
                    ? 'Limit: -'
                    : `Limit: ${limit}`;
                const msg = (event instanceof DOMException)
                    ? ' ' + event.message
                    : '';

                const errorMsg = `Failed to '${retrieveType}' records from "${table} (${this.settings.name})"` +
                    `, ${limitType}.${msg}`

                reject(new IdbReadException({
                    env:      this.env,
                    settings: this.settings,
                    message:  errorMsg,
                    field:    query ?? null
                }));
            };
        });
    }
}