<template>
  <div class="coupon-print">
    <!-- Блок с логотипом -->
    <div v-if="false" class="coupon-print__header">
      <img src="https://v2l.traincdn.com/genfiles/cms/pg/70/images/09ef1ad2e0b8613684c2d1cd91f4d3a6.svg" class="coupon-print__logo">
    </div>

    <div class="coupon-print__sections">
      <!-- Блок с базовой информацией о купоне -->
      <section class="coupon-print__section coupon-print__section--base-info">
        <div class="coupon-print__row">
          <span class="coupon-print__cell">Код купона</span>
          <span class="coupon-print__cell">123-456789</span>
        </div>
        <div class="coupon-print__row">
          <span class="coupon-print__cell">Дата</span>
          <span class="coupon-print__cell">MMM dd, yyyy - HH:mm:ss</span>
        </div>
        <div class="coupon-print__row">
          <span class="coupon-print__cell">ППС</span>
          <span class="coupon-print__cell">coupon.epos_name</span>
        </div>
        <div class="coupon-print__row">
          <span class="coupon-print__cell">Идентификатор игрока</span>
          <span class="coupon-print__cell">coupon.owner_id</span>
        </div>
      </section>

      <!-- Блок со списком событий -->
      <section class="coupon-print__section">
        <span class="coupon-print__heading">coupon.bet_type_name</span>
        <ul class="coupon-print__events coupon-print-events">
          <li class="coupon-print-events__item">
            <div class="coupon-print-events__event coupon-print-event">
              <div class="coupon-print-event__row">
                <div class="coupon-print-event__cell">
                  <span class="coupon-print-event__info">row.game.sport_name</span>
                  <span class="coupon-print-event__info">row.game.champ_name</span>
                </div>
                <div class="coupon-print-event__cell">
                  <span class="coupon-print-event__info">MMM dd, yyyy</span>
                </div>
              </div>
              <div class="coupon-print-event__row">
                <div class="coupon-print-event__cell">
                  <span class="coupon-print-event__info coupon-print-event__info--is-bold">row.game.first_opponent</span>
                  <span class="coupon-print-event__info coupon-print-event__info--is-bold">- row.game.second_opponent</span>
                </div>
                <div class="coupon-print-event__cell">
                  <span class="coupon-print-event__info">HH:mm</span>
                </div>
              </div>
              <div class="coupon-print-event__row coupon-print-event__row--is-odds">
                <span class="coupon-print-event__cell coupon-print-event__info--is-bold">W1</span>
                <span class="coupon-print-event__cell coupon-print-event__info--is-bold">21</span>
              </div>
            </div>
          </li>
        </ul>
      </section>

      <!-- Блок с суммой ставки -->
      <section class="coupon-print__section">
        <div class="coupon-print__stake coupon-print-stake">
          <span class="coupon-print-stake__title">Сумма ставки</span>
          <span class="coupon-print-stake__dots"></span>
          <span class="coupon-print-stake__amount">coupon.stake</span>
        </div>
      </section>

      <!-- Блок с общим кефом, суммой бонуса и максимальным выигрышем -->
      <section class="coupon-print__section coupon-print-total">
        <div class="coupon-print-total__row">
          <span class="coupon-print-total__cell">Общий коэффициент</span>
          <span class="coupon-print-total__cell">coupon.odds.toFixed(2)</span>
        </div>
        <div class="coupon-print-total__row coupon-print-total-max">
          <span class="coupon-print-total-max__heading">Максимально возможный выигрыш</span>
        </div>
        <div class="coupon-print-total__row coupon-print-total-max">
          <span class="coupon-print-total-max__value">III potentialPayoutGross III</span>
        </div>
      </section>

      <!-- Блок с информацией о налогах и комиссиях -->

      <!-- Блок с подписью -->
      <section class="coupon-print__section">
        <div class="coupon-print__sub coupon-print-sub">
          <div class="coupon-print-sub__row">
            <div class="coupon-print-sub__cell">Подпись кассира</div>
          </div>
          <div class="coupon-print-sub__row">
            <div class="coupon-print-sub__cell">Подпись гостя</div>
          </div>
          <div class="coupon-print-sub__info">
            <p class="coupon-print-sub__text">Для подтверждения подлинности купона, пожалуйста, убедитесь, что на чеке стоит подпись кассира и клиента.</p>
          </div>
        </div>
      </section>

      <!-- Блок с баркодом -->
      <section class="coupon-print__section">
        <div class="coupon-print__barcode coupon-print-barcode">
          <img src="https://svgsilh.com/svg/306926.svg" class="coupon-print-barcode__code">
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~scss-desktop/common/components/coupon/print/_coupon-print';
</style>

<script lang="ts">
const CMP_NAME = 'CouponForPrint';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {defineProps, PropType, computed}
    from "@vue/runtime-core";

/** IMPORTED VUE TEMPLATES: */
// -

/** COMPONENT LOGIC: */
const props = defineProps({
  logoUrl: {type: [String, Object], default: null}
});
</script>