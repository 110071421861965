<template>
  <div class="workplace-main__inner">
    <guest-window-ico
        ico="server-settings"
        size="size-custom-95"
        sprite="cleared"
        :is-flicker-allowed="false"
    />
    <h1 class="workplace-main__title">{{ $T.text('win-workspace-maintenance-header') }}</h1>
    <div class="workplace-main__description">
      <p class="workplace-main__text">{{ $T.text('win-workspace-maintenance-description') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'Maintenance';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";

/** IMPORTED VUE TEMPLATES: */
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();
</script>