export default class DOMAttr {

    /**
     * Запись значения в атрибут переданного элемента.
     *
     * @param target - HTML элемент.
     * @param field  - Поле атрибута
     * @param value  - Значение атрибута.
     */
    public static set<Field extends string = string>(
        target: HTMLElement,
        field:  Field,
        value:  string|number
    ): void
    {
        target
            .setAttribute(field, `${value}`);
    }

    /**
     * Получение значения из атрибута переданного элемента.
     *
     * @param target -
     * @param field  -
     */
    public static get<Field extends string = string>(
        target: HTMLElement,
        field:  Field,
    ): string|null
    {
        type struct = {[key in Field]: {nodeValue: string}};

        const value:string|undefined = (target.attributes as unknown as HTMLElement & struct)
            [field].nodeValue;

        return ('string' === typeof value)
            ? value
            : null;
    }

    /**
     * Удаление атрибута из переданного элемента.
     *
     * @param target -
     * @param field  -
     */
    public static delete<Field extends string = string>(
        target: HTMLElement,
        field:  Field,
    ): void
    {
        target
            .removeAttribute(field);
    }
}