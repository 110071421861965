<template>
  <div class="page__main main page__main--not-auth">
    <div class="main__workplace workplace">
      <!-- Блок с информацией об авторизованном рабочем месте: -->
      <workspace-info v-if="$app.isAppBootstrapFinished && $workspace.isWorkspaceAuthorized" />
      <!-- Центральный информационный блок гостевого экрана: -->
      <div class="workplace__main workplace-main">
        <div class="workplace-main__header">
          <span class="workplace-main__version">{{ $T.text('version-info-short') }}: {{ $app.parameters('appVersion') }}</span>
        </div>
        <!-- Загрузчик подготовки интерфейса: -->
        <template v-if="!$app.isAppBootstrapFinished">
          <preloader />
        </template>
        <!-- ВНИМАНИЕ! Важен порядок подключения окон. -->
        <!-- Изменяемые окна гостевого функционала, для авторизованного рабочего места: -->
        <template v-else>
          <maintenance               v-if="$app.isMaintenanceActive" />
          <workspace-auth-request    v-else-if="!$workspace.isWorkspaceAuthorized" />
          <workspace-auth-broken     v-else-if="$workspace.isWorkspaceAuthLost" />
          <clear-workspace-auth      v-else-if="!$workspace.isWorkspaceAuthLost && $workspace.isAuthClearRequested" />
          <workspace-synchronization v-else-if="!$workspace.isWorkspaceInitFinished && !$workspace.isCashierSessionBroken" />
          <!-- Окна, в которых отображаются информационные статусы, -->
          <!-- на которые кассир не может повлиять своими действиями: -->
          <info-only-window
            v-else-if="$workspace.isWorkspaceSettingsIncorrect"
            ico="server-settings"
            :title="$T.composite('win-incorrect-settings-header')"
            :description="$T.composite('win-incorrect-settings-description')"
          />
          <info-only-window
            v-else-if="$workspace.isWorkspaceDeleted"
            ico="trash"
            :title="$T.composite('win-workspace-deleted-header')"
            :description="$T.composite('win-workspace-deleted-description')"
          />
          <info-only-window
            v-else-if="$workspace.isWorkspaceLocked"
            ico="lock"
            :title="$T.composite('win-workspace-locked-header')"
            :description="$T.composite('win-workspace-locked-description')"
          />
          <cashier-auth
            v-else-if="!$workspace.isCashierLoggedIn || $workspace.isCashierSessionBroken"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "~scss-desktop/guest/component";
</style>

<script lang="ts">
import VueComponentType
    from "~lib/render/VueComponent";
const CMP_NAME = 'GuestShell';
export default {
  name: CMP_NAME,
} as VueComponentType
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import WorkspaceInfo
    from "~tpl-desktop/guest/WorkspaceInfo.vue";
import Preloader
    from "~tpl-desktop/guest/windows/Preloader.window.vue";
import Maintenance
    from "~tpl-desktop/guest/windows/Maintenance.vue";
import WorkspaceSynchronization
    from "~tpl-desktop/guest/windows/WorkspaceSynchronization.vue";
import WorkspaceAuthRequest
    from "~tpl-desktop/guest/windows/WorkspaceAuth.window.vue";
import CashierAuth
    from "~tpl-desktop/guest/windows/CashierAuth.window.vue";
import WorkspaceAuthBroken
    from "~tpl-desktop/guest/windows/WorkspaceAuthBroken.window.vue";
import ClearWorkspaceAuth
    from "~tpl-desktop/guest/windows/ClearWorkspaceAuth.window.vue";
import InfoOnlyWindow
    from "~tpl-desktop/guest/windows/InfoOnly.window.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $app,
    $workspace
} = usePiniaState();
</script>