import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";

import {OnAppInitTask}
    from "~app/Epos/Contracts/Epos.types";
import AppInitTasksList
    from "~app/Task/Init/AppInitTasks.list";
import {SystemSonar}
    from "~app/Epos/Enum/SystemSonar";

import usePiniaState
    from "~interaction/Store/usePiniaState";

/**
 * Определение списка заданий, которые должны быть запущены
 * для инициализации рабочего места с определенным набором параметров.
 */
export default class DefineInitTasksProvider
    extends ServiceProvider
{
    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('DefineInitTasksProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        const {
            $app
        } = usePiniaState();

        // Итоговый список заданий, который должен быть запущен
        // для инициализации приложения:
        const queue: OnAppInitTask[] = [];

        // Часть задач заранее исключаются, т.к. параметры,
        // влияющие на исключение из списка, известны на данный момент,
        // и не изменяются в процессе работы приложения:
        for (const task of AppInitTasksList)
        {
            const interfaceType = $app
                .parameters('interface');

            if (
                null !== interfaceType &&
                task.requiredInterface &&
                !task.requiredInterface.includes(interfaceType)
            ) {
                // Сразу исключаются типы заданий, не подходящие
                // для текущего типа интерфейса, т.к.
                // он не может поменяться в процессе работы:
                continue;
            }

            if (
                Array.isArray(task.allowedLocales) &&
                (!task.allowedLocales.includes($app.locale))
            ) {
                // Если задан список локалей, с которыми может работать задание,
                // то проверяется наличие локали интерфейса в указанном в задании списке:
                continue;
            }

            queue.push(task);
        }

        // Список шагов инициализации сохраняется в памяти приложения:
        app.bind<OnAppInitTask[]>(SystemSonar.AppInitTasks)
            .toConstantValue(queue);
    }
}