import * as Type
    from "~lib/supervisor/Types";

import Date
    from "~lib/helpers/Date";
import SupervisorException
    from "~lib/supervisor/Supervisor.exception";

import {DateTime} from "luxon";
import Exception from "~lib/Exception/Exception";

export default class Supervisor {

    public static readonly SONAR = Symbol
        .for('Supervisor');

    /**
     * Настройки для выполнения логики
     * повторяющихся заданий.
     *
     * @see Type.IntervalTasksScheme
     */
    private readonly interval: Type.IntervalTasksScheme;

    constructor(settings: {
        interval: Type.IntervalTasksSetup,
    })
    {
        const tasks: Type.IntervalTasksList = {};

        for (const taskSettings of settings.interval.intervalTasks)
        {
            tasks[taskSettings.id] = {
                settings: taskSettings,
                state:    {
                    isActive:  taskSettings.startOnInit,
                    updatedAt: Date.clientTimestamp(),
                    waitMs:    null,
                    isLocked:  false,
                }
            };
        }

        this.interval = {
            ms:        settings.interval.intervalMs,
            id:        null,
            locked:    false,
            isPaused:  false,
            list:      tasks,
            sequences: {},
            handler:   settings.interval.intervalHandler
        }
    }

    /**
     * Возвращает флаг активности самого обработчика,
     * либо указанного задания.
     *
     * ВМНИАНИЕ!
     * Будет выброшено исключение, если передать
     * не существующий идентификатор задания.
     */
    public isIntervalActive(
        id?: string
    ): boolean
    {
        if ('string' !== typeof id)
        {
            return null !== this.interval.id;
        }

        if (!this.interval.list.hasOwnProperty(id))
        {
            throw new SupervisorException({
                message: `Failed to check interval task activity.` +
                         ` Task with id "${id}" is not exists.`,
            });
        }

        return this.interval.list[id]
            .state
            .isActive;
    }

    /**
     * Проверяет существование повторяющегося задания
     * с указанным идентификатором.
     */
    public isIntervalTaskExists(
        id: string
    ): boolean
    {
        return this.interval
            .list
            .hasOwnProperty(id) ?? false;
    }

    /**
     * Если передан существующий идентификатор задания,
     * то оно будет поставлено на паузу.
     *
     * Если вызвать без передачи идентификатора, то на паузу
     * будет поставлена обработка всех заданий.
     *
     * ВМНИАНИЕ!
     * Будет выброшено исключение, если передать
     * не существующий идентификатор задания.
     */
    public pause(
        id?: string
    ): void
    {
        if ('string' === typeof id)
        {
            if (!this.interval.list.hasOwnProperty(id))
            {
                throw new SupervisorException({
                    message: `Failed to PAUSE interval task activity.` +
                             ` Task with id "${id}" is not exists.`,
                });
            }

            this.interval.list[id]
                .state
                .isActive = false;

            return;
        }

        if (null === this.interval.id)
        {
            return;
        }

        clearInterval(
            this.interval.id
        );

        this.interval.id = null;
    }

    /**
     * Запускает обработку заданий, которые должны
     * выполнятся через заданный интервал.
     *
     * Так же делает активным задание, ранее
     * поставленное на паузу.
     *
     * Если попытаться перезапустить не существующее задание,
     * то будет выброшено исключение.
     */
    public resume(
        id?: string
    ): void
    {
        if ('string' === id)
        {
            if (!this.interval.list.hasOwnProperty(id))
            {
                throw new SupervisorException({
                    message: `Failed to RESUME interval task activity.` +
                             ` Task with id "${id}" is not exists.`,
                });
            }

            this.interval.list[id]
                .state
                .isActive = true;

            return;
        }

        if (null !== this.interval.id)
        {
            return;
        }

        // Инициализация интервала, и функции-обработчика итераций:
        this.interval.id = setInterval(async (): Promise<void> =>
            {
                if (this.interval.locked)
                {
                    // Пропуск итерации запуска интервала, если он заблокирован,
                    // для избегания состояния гонки:
                    return;
                }

                // Обязательная блокировка интервала
                // перед запуском очередной итерации:
                this.interval
                    .locked = true;

                // Итерационное выполнение логики, отвечающее за
                // обработку запуска каждого из загруженных заданий:
                await this.intervalTasksHandler()
                    .then((): void =>
                    {
                        this.interval
                            .locked = false;

                    })
                    .catch((throwable): void =>
                    {
                        if (throwable instanceof Exception && !throwable.isFatal)
                        {
                            this.pause();

                            return;
                        }

                        this.interval
                            .locked = false;

                        throw throwable;
                    });
            },
            this.interval.ms
        );
    }

    /**
     * Выполнение логики по обработке и запуску заданий, загруженных
     * в супервизор, в порядке указанной последовательности.
     *
     * Если задание заблокировано либо не активно, то текущая итерация запуска
     * задания будет пропущена.
     *
     * Если для задания задана очередь, но в момент попытки запуска очередь заблокирована,
     * то задание будет запущенно в сразу при следующем освобождении очереди.
     *
     * После успешного, либо неудачного, выполнения задания будет записано время взаимодействия,
     * после чего само задание и его очередь будут разблокированы.
     *
     * Если задание вернуло значение "VOID" или "TRUE", то считается что оно было успешно выполнено,
     * и следующий запуск будет через указанный в настройках интервал.
     *
     * В случае если задание вернуло "false", и при этом задано поле настроек "waitMsIfFailed",
     * то следующий запуск задания будет через количество миллисекунд, указанных в поле.
     * Если поле настроек не задано, то следующий запуск задания будет согласно интервалу.
     *
     * Если задание вернуло число, то это число будет обработано как количество миллисекунд,
     * через которые  задание должно быть повторно запущено, в обход логики интервалов.
     *
     * В случае возникновения не фатального исключения, задание будет поставлено на паузу,
     * в течение "waitMsIfFailed", либо же следующий запуск будет в рамках настроек интервала.
     */
    private async intervalTasksHandler(): Promise<void>
    {
        // Перебор списка загруженных заданий, для
        // проверки возможности и запуска каждого из них:
        for (const taskId of Object.keys(this.interval.list))
        {
            // Временная переменная, для упрощения
            // взаимодействия с заданием:
            const task: Type.IntervalRuntime = this.interval
                .list[taskId];

            // проверка возможности запуска задания
            // на текущей итерации обработчика:
            if (
                task.state.isLocked  ||
                !task.state.isActive ||
                !this.isIntervalPassed(task)
            )
            {
                continue;
            }

            // Если задание работает в режиме
            // приватных очередей:
            if (this.isSequenceEnabled(task))
            {
                if (!this.takeSequence(task))
                {
                    // Используемая заданием очередь занята, потому
                    // проверка на возможность запуска должна быть
                    // осуществлена при следующем проходе по списку заданий:
                    task.state.updatedAt = Date.clientTimestamp();
                    task.state.waitMs    = 0;

                    continue;
                }

                // Задание успешно заблокировало свою очередь
                // и может быть запущено:
                // =>
            }

            // Блокировка на время выполнения:
            task.state.isLocked = true;

            // Запуск задания и обработка результатов:
            // (задание выполняется асинхронно и параллельно)
            this.interval
                .handler(task.settings)
                .then((response: number|boolean|void): void =>
                {
                    // Время обновляется после окончания работы задания,
                    // для того чтобы интервал запуска конкретного задания
                    // не зависел от времени, потраченного на его обработку:
                    task.state.updatedAt = Date.clientTimestamp();

                    // После успешного выполнения задания поле "waitMs" ставится в значение "NULL",
                    // т.к. ранее установленный отложенный запуск обрабатывается единожды,
                    // и для повторных отложенных запусков это поле должны быть снова переопределено:
                    task.state.waitMs = null;

                    // Освобождение очереди (если использовалась):
                    if (this.isSequenceEnabled(task))
                    {
                        this.freeSequence(task);
                    }

                    // Задание выполнено успешно если:
                    //
                    // - Задание вернуло пустой ответ.
                    // - Задание вернуло "TRUE".
                    // - Задание вернуло "FALSE", но
                    //   поле "waitMsIfFailed" не задано в настройках.
                    if (
                        'undefined' === typeof response ||
                        true === response ||
                        (false === response && 'number' !== typeof task.settings.waitMsIfFailed)
                    )
                    {
                        // Следующее время выполнения задания наступит
                        // согласно настройкам его интервала:
                        task.state.waitMs = null;

                        // Разблокирование возможности
                        // повторного запуска задания:
                        task.state.isLocked = false;

                        return;
                    }

                    // Задание не удалось успешно выполнить.
                    // Следующий его запуск будет согласно настройкам,
                    // либо же через количество миллисекунд, вернувшихся в ответе:
                    task.state.waitMs = (false === response)
                        ? task.settings.waitMsIfFailed ?? task.settings.intervalMs
                        : response;

                    // Разблокирование возможности
                    // повторного запуска задания:
                    task.state.isLocked = false;
                })
                .catch((throwable): void =>
                {
                    task.state.updatedAt = Date
                        .clientTimestamp();

                    // Освобождение очереди (если использовалась):
                    if (this.isSequenceEnabled(task))
                    {
                        this.freeSequence(task);
                    }

                    task.state.waitMs = task.settings.waitMsIfFailed
                        ?? task.settings.intervalMs;

                    // Разблокирование возможности
                    // повторного запуска задания:
                    task.state.isLocked = false;

                    // Выбрасывание перехваченного исключение обратно,
                    // для его централизованной обработки:
                    // (Не повлияет на запуск остальных заданий в списке)
                    throw throwable;
                });
        }
    }

    /**
     * Возвращает "TRUE", если пришло время
     * очередного запуска задания.
     *
     * @param task
     */
    private isIntervalPassed(
        task: Type.IntervalRuntime
    ): boolean
    {
        // разница между временем последнего взаимодействия с заданием
        // и текущем временем:
        const diff: number = Date.clientTimestamp() - task.state.updatedAt;

        // если для задания не задано время ожидания
        // (работающее как исключение)
        if (null === task.state.waitMs)
        {
            // то время следующей обработки наступит только если
            // прошло больше секунд, чем указано в настройках:
            return diff > (task.settings.intervalMs / 1000);
        }

        if (0 === task.state.waitMs)
        {
            // если время ожидания-исключения выставлено в ноль,
            // то задача должна быть снова запущена сразу,
            // как только появится возможность:
            return true;
        }

        // в остальных случаях для определения времени следующей итерации
        // используется значение из параметра времени ожидания:
        return diff > (task.state.waitMs / 1000);
    }

    /**
     * Возвращает "TRUE" если для задания
     * включен режим приватных очередей.
     *
     * @param task
     */
    private isSequenceEnabled(
        task: Type.IntervalRuntime
    ): boolean
    {
        return 'string' === typeof task.settings.sequence;
    }

    /**
     * Возвращает "TRUE", если удалось заблокировать очередь
     * под выполнение переданного задания.
     *
     * Возвращает "TRUE", если очередь была успешно занята заданием,
     * иначе очередь в данный момент уже занята.
     *
     * ВНИМАНИЕ!
     * Передача задания, для которого не задан идентификатор очереди,
     * вызовет критическое исключение.
     *
     * @param task
     */
    private takeSequence(
        task: Type.IntervalRuntime
    ): boolean
    {
        if ('string' !== typeof task.settings.sequence)
        {
            throw new SupervisorException({
                message: `Failed to take sequence for task id "${task.settings.id}"` +
                         ` because sequence id is not defined in task settings.`,
            });
        }

        if ('string' === typeof this.interval?.sequences[task.settings.sequence]) {
            // Использование очереди с указанным идентификатором невозможно,
            // т.к. она занята другим заданием:
            return false;
        }

        this.interval
            .sequences[task.settings.sequence] = `${task.settings.id}`;

        return true;
    }

    /**
     * Освобождает очередь, предположительно
     * занимаемую переданным заданием.
     *
     * ВНИМАНИЕ!
     * Передача задания, для которого не задан идентификатор очереди,
     * вызовет критическое исключение.
     *
     * @param task
     */
    private freeSequence(
        task: Type.IntervalRuntime
    ): void
    {
        if ('string' !== typeof task.settings.sequence)
        {
            throw new SupervisorException({
                message: `Failed to free sequence for task id "${task.settings.id}"` +
                         ` because sequence id is not defined in task settings.`,
            });
        }

        this.interval
            .sequences[task.settings.sequence] = null;
    }
}