<template>
  <transition
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      name="expand">
    <slot/>
  </transition>
</template>

<script lang="ts">
const CMP_NAME = 'TransitionExpand';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
// -

/** IMPORTED VUE TEMPLATES: */
// -

/** COMPONENT LOGIC: */
const enter = (element: any) => {
  const height = getComputedStyle(element).height;

  element.style.height = 'auto';

  element.style.height = 0;

  setTimeout(() => element.style.height = height);
}

const afterEnter = (element: any) => element.style.height = 'auto';
const leave = (element: any) => {
  element.style.height = getComputedStyle(element).height;

  setTimeout(() => element.style.height = 0);
};

</script>

<style scoped>
  .expand-enter-active,
  .expand-leave-active {
    transition: height .5s ease-in-out;
    overflow: hidden;
  }

  .expand-enter,
  .expand-leave-to {
    height: 0;
  }
</style>
