import usePiniaState
    from "~interaction/Store/usePiniaState";
import FeedService
    from "~app/Betting/Feed.service";
import * as LiveFeed
    from "~app/Betting/Routes/Feed/FeedLiveGroups.route";
import * as PreMatchFeed
    from "~app/Betting/Routes/Feed/FeedPreMatchGroups.route";
import {EventsGroupIDBCache, FeedType, LIVE_FEED_CACHE_KEY, PRE_MATCH_FEED_CACHE_KEY}
    from "~app/Betting/Contracts/Feed.types";

export default function () {

    /**
     * Обработка ответа от фида событий, и логика
     * реакции интерфейса на данные от фида.
     *
     * @param service - Сервис для работы с фидами.
     * @param type    - Тип фида.
     * @param payload - Ответа от апи фида.
     */
    async function handleRetrievedFeedData(
        service: FeedService,
        type:    FeedType,
        payload: LiveFeed.Response|PreMatchFeed.Response
    ): Promise<void>
    {
        const {
            $app,
            $feed,
        } = usePiniaState();

        // Преобразование необработанных данных фида в группы,
        // данные из которых используются для отображения в интерфейсе:
        const groups = await service
            .generateEventGroupsFromFeedData<LiveFeed.Response|PreMatchFeed.Response>(
                type,
                payload
            );

        // Сохранение обработанного фида в IndexedDB:
        await service.storeFeedToCache<EventsGroupIDBCache>(
            ('live' === type)
                ? LIVE_FEED_CACHE_KEY.toString()
                : PRE_MATCH_FEED_CACHE_KEY.toString(),
            {
                at:   $app.getWorkspaceTimestamp(),
                data: groups,
            }
        );

        if ('betting' !== $app.windowId) {
            // Нет смысла загружать в интерфейс фид,
            // если окно беттинга сейчас не используется:
            return;
        }

        if (null === $feed.feedData) {
            // Если содержимое фида "null" - то он еще
            // не выгружен из IndexedDB, и этим должна заниматься
            // логика компонента с фидом:
            return;
        }

        if (type !== $feed.activeFeedTab) {
            // Если в настоящий момент в интерфейсе
            // не отображается LIVE фид, то
            // нет необходимости обновлять данные в pinia:
            return;
        }

        // Обновление групп событий в интерфейс:
        $feed
            .updateFeedData(type, groups);
    }

    return {
        handleRetrievedFeedData,
    };
}