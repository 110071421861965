import ExceptionHandler
    from "~lib/Exception/ExceptionHandler";
import Exception
    from "~lib/Exception/Exception";

import usePiniaState
    from "~interaction/Store/usePiniaState";
import UnexpectedRuntimeException, {ConstructorType as UnexpectedRuntimeExceptionConstructor}
    from "~lib/Exception/UnexpectedRuntime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

export default class RuntimeExceptionHandler
    extends ExceptionHandler<Exception> {

    /**
     * @param exception - Обрабатываемое исключение.
     */
    constructor(
        exception: unknown
    )
    {
        super(
            RuntimeExceptionHandler
                .processingIncomingException<Exception, UnexpectedRuntimeExceptionConstructor>(
                    exception,
                    UnexpectedRuntimeException,
                    {
                        message: "Unexpected runtime exception occurred. The application was crashed." +
                                 " See additional information in the 'previous' field."
                    }
                )
        );
    }

    /**
     * @inheritDoc
     *
     * @param data
     */
    async handle<Data = unknown>(
        data?: Data
    ): Promise<void>
    {
        const {
            $app,
            $workspace
        } = usePiniaState();

        // Обработка аварийного завершения работы приложения:
        if (this.throwable.isFatal && !$app.isAppCrashed)
        {
            $app.changeParameter(
                'isAppCrashed',
                true
            );

            // Кроме переключения флага, больше ничего не требуется
            // т.к. приложение должно аварийно завершить работу.
            return;
        }

        // Реакция интерфейса на
        // специфические коды исключений:
        switch (this.throwable.code)
        {
            // Обработка ситуации, когда рабочее место
            // потеряло связь с виртуальной кассой:
            case EposCoreErrors.WORKSPACE_AUTH_REJECTED:
                $workspace
                    .changeSystemParameter(
                        'isWorkspaceAuthLost',
                        true
                    );

                break;

            // Сессия кассира на сервисе авторизации истекла,
            // дальнейшие запросы к апи, требующие наличие кассира,
            // не имеют смысла до момента обновления сессии кассира:
            case EposCoreErrors.CASHIER_TOKEN_EXPIRED:
                $workspace
                    .changeSystemParameter(
                        'isCashierSessionExpired',
                        true
                    );

                break;
        }

        return;
    }

    /**
     * @inheritDoc
     *
     * @param data
     */
    async log<Data = unknown>(
        data?: Data
    ): Promise<void>
    {
        // Основная строка с текстом ошибки:
        let mainMsg: string = `[${this.throwable.label.toUpperCase()}]: ${this.throwable.message}`;

        // Дополнительные уточняющие данные в случае если было выброшено "неожиданное" исключение,
        // и у него есть информация, которой стоит дополнить текст:
        if (this.throwable instanceof UnexpectedRuntimeException && this.throwable.previous) {
            mainMsg += ` Details: ${this.throwable.previous.message}.`
        }

        // Таблица с трассировкой:
        await ExceptionHandler
            .generateTraceTable(
                await this.getTrace(),
                this.throwable.isTraceMock()
                    ? 2
                    : 0
            )
            .then((
                table
            ): void =>
            {
                // Запись сообщения в консоль:
                console[(this.throwable.isFatal)
                    ? 'error'
                    : 'warn'
                    ](mainMsg);

                // Добавление таблицы с трассировкой:
                console
                    .warn(table);
            });

        // Уведомление об ошибке и таблица с трассировкой
        // вложенного исключения (если есть):
        if (this.throwable.previous instanceof Exception)
        {
            await ExceptionHandler
                .generateTraceTable(await this.getTrace(this.throwable.previous.trace))
                .then((
                    table
                ): void =>
                {
                    // Запись сообщения в консоль:
                    console[(this.throwable.previous.isFatal)
                        ? 'error'
                        : 'warn'
                        ](this.throwable.previous.message);

                    // Добавление таблицы с трассировкой:
                    console
                        .warn(table);
                });
        }
    }
}