import {injectable}
    from 'inversify';
import Helpers, {Mix}
    from "~lib/helpers";

import {OnAppInitTask}
    from "~app/Epos/Contracts/Epos.types";
import {SystemSonar}
    from "~app/Epos/Enum/SystemSonar";

import usePiniaState
    from "~interaction/Store/usePiniaState";
import useEposModule
    from "~app/IoC/useEposModule";
import useAppContainer
    from "~app/IoC/useAppContainer";
import useSupervisor
    from "~app/IoC/useSupervisor";

import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";
import Exception
    from "~lib/Exception/Exception";
import RuntimeException
    from "~app/Exception/Runtime.exception";
import UnexpectedRuntimeException
    from "~lib/Exception/UnexpectedRuntime.exception";

@injectable()
export default class WorkspaceModule {

    public static readonly SONAR = Symbol
        .for('WorkspaceModule');

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
    ) {}

    /**
     * Запуск процедуры по обработке списка задач,
     * выполнение которых требуется для запуска интерфейса.
     */
    public async runWorkspaceSyncTasks(): Promise<void>
    {
        const {
            $container
        } = useAppContainer();

        const {
            $workspace,
            $syncData,
        } = usePiniaState();

        $workspace
            .changeSystemParameter(
                'isWorkspaceInitFinished',
                false
            );

        // Извлечение из контейнера списка заданий, которые должны быть выполнены
        // при инициализации/синхронизации приложения:
        const tasksQueue: OnAppInitTask[] = $container
            .get<OnAppInitTask[]>(SystemSonar.AppInitTasks);

        // Очистка информации о ранее загруженном исключении т.к.
        // оно больше не влияет на логику выполнения:
        $syncData
            .submitOrClearException(null);

        // Загрузка всего списка заданий необходимо только в том случае, если он пуст,
        // иначе не получится при повторном вызове провайдера продолжить обработку очереди
        // с места, где она была прервана:
        if ($syncData.isQueueEmpty) {
            // Загрузка списка заданий в специальное хранилище для того,
            // чтобы процесс их выполнения корректно отображался в интерфейсе:
            $syncData
                .loadInitQueue(tasksQueue);
        }

        // Поиск задания в очереди по идентификатору,
        // переданному их хранилища "$syncData":
        const getActiveTask = (
            id: string|null
        ): OnAppInitTask =>
        {
            if (null === id) {
                throw new RuntimeException({
                    isFatal: true,
                    code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                    message: `Logic error. Seems like there is no active task.`
                });
            }

            for (const task of tasksQueue) {
                if (task.id === id) {
                    return task;
                }
            }

            throw new RuntimeException({
                isFatal: true,
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                message: `Logic error. Failed to find task with ID '${id}',` +
                         ` but current task must be in queue.`
            });
        }

        try
        {
            // Перебор списка заданий для
            // поочередного запуска их обработки:
            for await (const useless of tasksQueue)
            {
                // Задание, которое необходимо обработать
                // в настоящий момент:
                const activeTask: OnAppInitTask = getActiveTask(
                    $syncData.handledTaskId
                );

                let isTaskSuccess: boolean = false;

                // Количество повторов выполнения задания,
                // завершившегося ошибкой:
                // (Итоговое количество запусков задания всегда равно "количество попыток" + 1)
                for await (const iteration of Mix
                    .array<number>(activeTask.attempts + 1, Number))
                {
                    if (iteration > 0) {
                        // Ожидание некоторого количества времени,
                        // перед каждой попыткой повторить задание:
                        await Helpers
                            .sleep(2000);
                    }

                    // ВНИМАНИЕ!
                    // Любое не обработанное исключение прерывает выполнение
                    // остальных заданий и оставшиеся попытки повторить текущее.

                    // Запуск задания и ожидание результата:
                    try
                    {
                        isTaskSuccess = await activeTask
                            .runner($container);
                    }
                    // Если во время выполнения задания
                    // возникло исключение:
                    catch (attemptError)
                    {
                        attemptError = UnexpectedRuntimeException
                            .autoConstruct(
                                attemptError,
                                `Unexpected error occurred in the init task '${activeTask.id}'` +
                                        `  at attempt '${iteration}'.`
                            );

                        if (EposCoreErrors.WORKSPACE_AUTH_REJECTED === (attemptError as Exception).code)
                        {
                            // Нет смысла на новые попытки выполнить задание, если
                            // рабочее место потеряло связь с виртуальной кассой:
                            throw attemptError;
                        }

                        if ((attemptError as Exception).isFatal || iteration >= activeTask.attempts)
                        {
                            // Прерывание выполнения очереди необходимо если
                            // перехвачена критическая ошибка, или
                            // достигнут лимит попыток:
                            throw attemptError;
                        }

                        // Переход к следующей попытке
                        // выполнить задание:
                        continue;
                    }

                    // Если задание завершилось неудачей, и притом
                    // его работа не вызвала исключение:
                    if (!isTaskSuccess) {
                        if (iteration >= activeTask.attempts) {
                            // Исключение выбрасывается т.к. достигнут лимит попыток
                            // за который задание должно быть успешно выполнено.
                            //
                            // Данные не очищаются для возможности продолжить обработку
                            // с того же задания, на котором возникли проблемы:
                            throw new RuntimeException({
                                isFatal: false,
                                code:    EposCoreErrors.WORKSPACE_INIT_TASK_FAILED_MAX_ATTEMPTS,
                                message: `Init tasks handling interrupted due to` +
                                         ` max amount of attempts (${activeTask.attempts}) reached` +
                                         ` for task id "${activeTask.id}".`,
                            });
                        }

                        // Переход к следующей попытке
                        // выполнить задание:
                        continue;
                    }

                    // дополнительное ожидание после каждого этапа,
                    // чтобы они не мелькали, если шаг синхронизации
                    // проходит быстро:

                    // Дополнительное ожидание после успешного выполнения задания
                    // необходимо для того, чтобы они не "мелькали" в интерфейсе,
                    // если подряд идет несколько мгновенно выполняющихся заданий:
                    await Helpers
                        .sleep(250);

                    // Отметка текущего задания как выполненного,
                    // и переход к следующему:
                    $syncData
                        .commitCurrentTaskAsCompleted(
                            activeTask.id
                        );

                    // Страховочное ожидание обновления хранилища:
                    await Helpers
                        .sleep(50);

                    // Проверка корректности переключения
                    // на следующую задачу:
                    if ($syncData.handledTaskId === activeTask.id) {
                        throw new RuntimeException({
                            isFatal:  true,
                            code:     EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                            message:  `Logic error. After current task id '${activeTask.id}'` +
                                      ` successfully completing, logic must be switched to the next one,` +
                                      ` but active task still same.`
                        });
                    }

                    // =>
                    // Переход к следующему заданию...
                    break;
                }
            }
        }

        catch (throwable)
        {
            throwable = UnexpectedRuntimeException
                .autoConstruct(
                    throwable,
                    'Unexpected exception occurred while trying to handle init tasks.'
                );

            if (!(throwable as Exception).isFatal)
            {
                $syncData
                    .submitOrClearException(throwable as Exception);
            }

            // Само исключение будет обработано глобально,
            // дополнительный действий не требуется:
            throw throwable;
        }

        // Очистка данных инициализации:
        // (Обязательно, при успешном выполнении всех заданий.)
        $syncData
            .flushInitQueue();

        $workspace
            .changeSystemParameter(
                'isWorkspaceInitFinished',
                true
            );

        const {
            $SUPERVISOR,
        } = useSupervisor();

        // Запуск обработчика заданий:
        if (!$SUPERVISOR.isIntervalActive())
        {
            $SUPERVISOR
                .resume();
        }
    }

    /**
     * Переключение интерфейса в режим сброса
     * привязки рабочего места.
     */
    public async switchInterfaceToAuthClearMode(): Promise<void>
    {
        const {
            $workspace
        } = usePiniaState();

        if (!$workspace.isWorkspaceAuthorized) {
            return;
        }

        $workspace
            .changeSystemParameter(
                'isAuthClearRequested',
                true
            );
    }

    /**
     * Возврат приложения из режима для
     * сброса привязки рабочего места.
     */
    public async abortInterfaceAuthClearMode(): Promise<void>
    {
        const {
            $workspace
        } = usePiniaState();

        $workspace
            .changeSystemParameter(
                'isAuthClearRequested',
                false
            );
    }

    /**
     * Обработка, поступившего от интерфейса, запроса на очистку
     * связи текущего рабочего места с виртуальной кассой.
     */
    public async submitWorkspaceAuthClear(): Promise<void>
    {
        const {
            $app,
            $workspace
        } = usePiniaState();

        if ($app.isAppBusy) {
            return;
        }

        const {
            $eposModule
        } = useEposModule();

        await $app
            .changeAppBusyStatus(true);

        await $eposModule
            .flushWorkspaceAuthorization();

        $workspace
            .changeSystemParameter(
                'isAuthClearRequested',
                false
            );

        await $app
            .changeAppBusyStatus(false);
    }
}