import {injectable}
    from 'inversify';
import {toRaw}
    from "@vue/runtime-core";
import {cloneDeep}
    from "lodash";
import useAppContainer
    from "~app/IoC/useAppContainer";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useSupervisor
    from "~app/IoC/useSupervisor";
import {MarketUpdateTaskName}
    from "~app/Task/Betting/MarketUpdate.interval";
import Helpers
    from "~lib/helpers";

import FeedService
    from "~app/Betting/Feed.service";
import BettingService
    from "~app/Betting/Betting.service";
import * as FeedTypes
    from "~app/Betting/Contracts/Feed.types";
import * as CommitBetRoute
    from "~app/Betting/Routes/CommitBet.route";
import * as BindCouponRoute
    from "~app/Betting/Routes/BindCoupon.route"
import * as BettingTypes
    from "~app/Betting/Contracts/Betting.types";
import {BetPaymentType}
    from "~app/Betting/Emum/BetPaymentType";
import {CouponOddsChangeAction}
    from "~app/Betting/Emum/CouponOddsChangeAction";
import {WorkspaceLogicModule}
    from "~app/Epos/WorkspaceLogicModule";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {BettingErrors}
    from "~app/Betting/Betting.errors";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

@injectable()
export default class BettingModule {

    public static readonly SONAR = Symbol
        .for('BettingModule');

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor()
    {}

    /**
     * Запрос на загрузку в интерфейс маркета
     * указанного события.
     *
     * В случае, если маркет события уже загружен,
     * то будет сделан повторный запрос для поиска
     * изменившихся коэффициентов.
     *
     * ВНИМАНИЕ! Если в интерфейс загружена информация
     * об аккаунте клиента, то маркет будет отображен
     * с учетом порезок, наложенных на аккаунт.
     */
    public async loadMarketForEvent(): Promise<BettingTypes.LoadedEventMarket>
    {
        const {
            $SUPERVISOR
        } = useSupervisor();

        // Приостановка фонового задания обновления маркета на время,
        // пока выполняется логика метода:
        if ($SUPERVISOR.isIntervalActive(MarketUpdateTaskName))
        {
            $SUPERVISOR
                .pause(MarketUpdateTaskName);
        }

        const {
            $app,
            $betting,
            $customer,
            $workspace,
            $shift
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: 'Logic error. Logged in cashier is required' +
                         ' to retrieve outcomes market.',
            });
        }

        if (!$shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: 'Logic error. Opened cashier\'s shift is required' +
                         ' to retrieve outcomes market.',
            });
        }

        if (!$workspace.hasAccessFor(WorkspaceLogicModule.BETTING)) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_MODULE_ACCESS_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: `Logic error. Current EPOS has no access to` +
                         ` module id: '${WorkspaceLogicModule.BETTING}'`,
            });
        }

        // Получение структуры основного или вложенного события,
        // для которого будет запрошены данные и сформирован маркет:
        const targetEvent: FeedTypes.Event|null = cloneDeep(
            toRaw(
                $betting.eventForRequestedMarket
            )
        );

        if (null === targetEvent) {
            throw new RuntimeException({
                code:    BettingErrors.BETTING_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: `Logic error. Trying to update market data` +
                         ` but event is not loaded to the market.`,
            });
        }

        const {
            $container,
        } = useAppContainer();

        const customerId: number|null = ($customer.isLoaded)
            ? $customer.id
            : null;

        const marketData = await ($container
            .get<FeedService>(FeedService.SONAR))
            .getEventMarket(
                targetEvent,
                $app.lang,
                customerId
            );

        // Если для запрошенного события не был найден маркет, либо
        // к моменту ответа от АПИ было выбрано другое события или закрыт сам маркет:
        if (null === marketData.outcomes || marketData.event.id !== targetEvent.id) {
            $SUPERVISOR
                .resume(MarketUpdateTaskName);

            return {
                event:      targetEvent,
                customerId: customerId,

                isFound:    null !== marketData.outcomes,
                isExpired:  marketData.event.id !== $betting.activeEvent?.id,
                isLoaded:   false,
                isUpdated:  false
            };
        }

        // Содержит "TRUE" если маркет события был загружен ранее,
        // а сейчас идет запрос на его обновление:
        const isMarketDataUpdated: boolean = marketData.event.id === targetEvent.id &&
            null !== $betting.availableMarkets;

        // Если маркет уже загружен, значит необходимо
        // подсветить обновленную информацию по
        // изменившимся коэффициентам и блокировкам:
        if (isMarketDataUpdated) {
            for (const outcome of Object.values(marketData.outcomes)) {
                // Поиск существования исхода события
                // в ранее загруженном маркете:
                const oldOutcome: FeedTypes.Outcome | null = (
                    null !== $betting.availableMarkets &&
                    'object' === typeof $betting.availableMarkets[outcome.key]
                )
                    ? $betting.availableMarkets[outcome.key]
                    : null;

                // Если исход существовал ранее, и коэффициенты устаревшего и нового исхода отличаются,
                // то их необходимо записать в поле "prevOdds" для корректной работы подсветки.
                // Если после обновления коэффициент остается неизменным, то поле очищается:
                outcome.prevOdds = (null !== oldOutcome && outcome.odds !== oldOutcome.odds)
                    ? oldOutcome.odds
                    : null;

                // Обновление данных в возвращаемом списке:
                marketData.outcomes[outcome.key] = outcome;
            }
        }

        // Загрузка в интерфейс списка маркетов
        // для текущего события:
        await $betting
            .loadEventMarkets(marketData.outcomes);

        // В случае успешного запроса на обновление маркета,
        // необходимо немного подождать, прежде чем активировать
        // задание обновления, иначе оно при запуске
        // мгновенно запустит еще одно обновление:
        Helpers
            .sleep(4000)
            .then((): void => {
                if ($SUPERVISOR.isIntervalActive(MarketUpdateTaskName))
                {
                    return
                }

                $SUPERVISOR
                    .resume(MarketUpdateTaskName);
            });

        return {
            event:      targetEvent,
            customerId: customerId,

            isFound:   true,
            isExpired: false,
            isLoaded:  !isMarketDataUpdated,
            isUpdated: isMarketDataUpdated,
        };
    }

    /**
     * Загрузка в интерфейс маркета
     * переданного события.
     */
    public async setEventForMarket(
        event: FeedTypes.Event
    ): Promise<void>
    {
        const {
            $betting,
        } = usePiniaState();

        // Загрузка в интерфейс маркетов события,
        // выбранного в интерфейсе фида:
        await $betting
            .loadEventIntoMarket(event);
    }

    /**
     * Запрос на добавление в купон переданного исхода события.
     *
     * В случае если передан только ключ исхода, то будет
     * произведена попытка собрать исход из данных интерфейса.
     *
     * Взаимодействие с заблокированным исходом события будет прервано.
     *
     * Если существует дубликат события, то событие будет
     * полностью удалено из купона.
     *
     * Если существует связанное событие, то переданное событие
     * будет добавлено взамен существующего.
     *
     * @param payload - Структура исхода события, или его ключ в интерфейсе.
     */
    public async addOutcomeToBetSlip(
        payload: FeedTypes.OutcomeInBetSlip|string
    ): Promise<void>
    {
        const {
            $app,
            $betting,
        } = usePiniaState();

        let outcomeInBetSlip: FeedTypes.OutcomeInBetSlip|null = null;

        // Если вместо готовой к добавлению в купон структуры была передана строка,
        // значит добавление идет непосредственно из интерфейса маркета:
        if ('string' === typeof payload) {
            // Необходимо по ключу найти исход события
            // среди текущих активных маркетов:
            const outcome: FeedTypes.Outcome|null = (null !== $betting.availableMarkets)
                ? $betting.availableMarkets[payload] ?? null
                : null;

            // Если запрос на добавление исхода в купон пришел из маркета, и притом
            // его не существует среди доступных - то интерфейс
            // находится в некорректном состоянии:
            if (!outcome) {
                throw new RuntimeException({
                    isFatal: true,
                    label:   'OUTCOME-SELECT',
                    code:    BettingErrors.MARKETS_ERROR_OUTCOME_SELECT,
                    message: `Logic error. Selected outcome is not exists in available markets.`,
                });
            }

            // Для сборки объекта так же требуются данные события, к которому
            // относится исход, потому его отсутствие, или не совпадение идентификаторов
            // является фатальной ошибкой:
            if (
                null === $betting.eventForRequestedMarket ||
                $betting.eventForRequestedMarket.id !== outcome.eventId
            ) {
                throw new RuntimeException({
                    isFatal: true,
                    label:   'OUTCOME-SELECT',
                    code:    BettingErrors.MARKETS_ERROR_OUTCOME_SELECT,
                    message: `Logic error. Impossible to add outcome [${outcome.id}] '${outcome.name}'` +
                             ` for event key '${outcome.eventKey}' into bet slip` +
                             ` because there are no active event or active event id` +
                             ` and outcome event id is mismatch.`,
                });
            }

            // Создание структуры исхода события, для добавления
            // в купон, на основе данных интерфейса:
            outcomeInBetSlip = cloneDeep(toRaw({
                at:      $app.getWorkspaceTimestamp(),
                key:     `${outcome.eventId}`,
                event:   cloneDeep(toRaw($betting.eventForRequestedMarket)),
                outcome: outcome,
            }));
        }

        else {
            // Структура исхода события, для добавления в купон,
            // была передана напрямую:
            outcomeInBetSlip = cloneDeep(
                toRaw(payload)
            );
        }

        // ВНИМАНИЕ!
        // Для одиночных ставок и экспресса ключом
        // будет идентификатор события.

        if (outcomeInBetSlip.outcome.isLocked) {
            // Запрет на взаимодействие с
            // заблокированным исходом:
            return;
        }

        // Поиск дублирующего или связанного исхода в купоне:
        const alreadyAddedOutcome: FeedTypes.OutcomeInBetSlip|false = await $betting
            .isOutcomeExistsInSlip(outcomeInBetSlip);

        // Если дублирующее или связанное событие существует:
        if (alreadyAddedOutcome) {
            // В любом случае дублирующий исход
            // должен быть удален:
            await $betting.removeOutcomeFromSlip(
                alreadyAddedOutcome
            );

            // Если это клик по тому же событию,
            // что уже находится в купоне:
            if (alreadyAddedOutcome.outcome.key === outcomeInBetSlip.outcome.key) {
                // То необходимо просто удалить его из купона (что было сделано
                // выше по коду), и дальнейших действий не требуется:
                return;
            }

            // Если это связанное событие, или другой исход того же события,
            // то новое добавляемое должно занять порядковое место старого,
            // потому копируется время добавления:
            outcomeInBetSlip.at = alreadyAddedOutcome.at;
        }

        // Очистка изменений коэффициента, которые
        // могли остаться при извлечении из маркета:
        outcomeInBetSlip.outcome.prevOdds = null;

        // Добавление исхода в купон ставки:
        await $betting.addOutcomeToSlip(
            outcomeInBetSlip
        );
    }

    /**
     * Запрос на проведение ставки через кассу.
     *
     * Если сервис поставит ставку на паузу, то будет возвращен GUID ставки,
     * и время, через которое необходимо повторить запрос.
     *
     * Если в ставке будут присутствовать ограничения на выбранные исходы событий,
     * то кассиру необходимо устранить их вручную. Все ограничения, установленные сервисом,
     * будут автоматически применены к купону, набранному в интерфейсе.
     *
     * Для успешно принятой ставки баланс кассы будет автоматически скорректирован.
     *
     * @param betSlip            - Структура исходов, на которые будет осуществлена ставка.
     * @param stake              - Сумма ставки.
     * @param onOddsChangeAction - Действие кассе при изменении коэффициентов.
     * @param guid               - (Опционально) Идентификатор поставленной на паузу ставки.
     */
    public async commitBet(
        betSlip:            {[key: string]: FeedTypes.OutcomeInBetSlip} | FeedTypes.OutcomeInBetSlip[],
        stake:              number,
        onOddsChangeAction: CouponOddsChangeAction,
        guid:               string|null,
    ): Promise<BettingTypes.CommitBetStatus>
    {
        const {
            $shift,
            $workspace
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: 'Logic error. Logged in cashier is required' +
                         ' to make a bet.',
            });
        }

        if (!$shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: 'Logic error. Opened cashier\'s shift is required' +
                         ' to make a bet.',
            });
        }

        if (!$workspace.hasAccessFor(WorkspaceLogicModule.BETTING)) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_MODULE_ACCESS_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: `Logic error. Current EPOS has no access to` +
                         ` module id: '${WorkspaceLogicModule.BETTING}'`,
            });
        }

        const {
            $container,
        } = useAppContainer();

        const payload: CommitBetRoute.Request = {
            betSlip:       Object.values(betSlip),
            stakeAmount:   stake,
            paymentSource: BetPaymentType.Cash,
            onOddsChange:  onOddsChangeAction,
        }

        if ('string' === typeof guid) {
            payload.guid = guid;
        }

        const request = await ($container
            .get<BettingService>(BettingService.SONAR))
            .makeBetRequest(payload);

        // Если сервис не принял ставку:
        if (!request.isSuccess) {
            // Если на купон наложены ограничения,
            // препятствующие его проведению:
            if (request.response?.restrictions) {
                // Автоматический разбор наложенных на купон ограничений, и
                // отображение этой информации в набранном купоне.
                await this.applyCouponRestrictions(
                    request.response?.restrictions ?? []
                );
            }

            return {
                isBetPlaced: false,
                message:     request.message,

                hasRestrictions: 'object' === typeof request.response?.restrictions &&
                    (Object.keys(request.response?.restrictions ?? [])).length > 0,
                hasTestRestriction: true === request.response?.hasTestRestriction,

                isBetOnHold: (request.response?.waitTime ?? 0) >= 1 &&
                    'string' === typeof request.response?.guid,
                guid:        request.response?.guid,
                waitTime:    request.response?.waitTime,
            };
        }

        // Корректировка отображаемого баланса кассы
        // на сумму принятой ставки:
        $shift.tmpAdjustCashBalance(
                request.response?.stakeAmount ?? 0
            );

        // Купон был успешно принят:
        return {
            isBetPlaced: true,

            couponId:    request.response?.couponId,
            stakeAmount: request.response?.stakeAmount,
        };
    }

    /**
     * Запрос на привязку купона к аккаунту клиента.
     *
     * Если в ставке будут присутствовать ограничения на выбранные исходы событий,
     * то на купон или аккаунт клиента наложены ограничения. Если прошло менее 90 секунд
     * с момента принятия ставки, то у кассира будет возможность удалить ставку в случае,
     * если гость не согласен с изменениями. Если время, отведенное на удаление, истекло,
     * то можно будет либо согласиться на изменения купона,
     * либо же оставить купон не привязанным.
     *
     * Все ограничения, установленные сервисом, будут автоматически
     * применены к купону, набранному в интерфейсе.
     *
     * Если по купону есть возврат в связи с его удалением, либо порезкой суммы ставки,
     * то балансы кассы будут автоматически скорректированы.
     *
     * @param couponId   - Идентификатор купона, к которому осуществляется привязка.
     * @param customerId - Идентификатор привязываемого аккаунта клиента.
     */
    public async bindCoupon(
        couponId:   number,
        customerId: number,
    ): Promise<BettingTypes.BindBetStatus>
    {
        const {
            $shift,
            $workspace
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: 'Logic error. Logged in cashier is required' +
                         ' to bind accepted coupon to the customer.',
            });
        }

        if (!$shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: 'Logic error. Opened cashier\'s shift is required' +
                         ' to bind accepted coupon to the customer.',
            });
        }

        if (!$workspace.hasAccessFor(WorkspaceLogicModule.ALLOW_BET_BIND)) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_MODULE_ACCESS_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: `Logic error. Current EPOS has no access to` +
                         ` module id: '${WorkspaceLogicModule.ALLOW_BET_BIND}'`,
            });
        }

        const {
            $container,
        } = useAppContainer();

        const payload: BindCouponRoute.Request = {
            couponId:   couponId,
            customerId: customerId,
        };

        const request = await ($container
            .get<BettingService>(BettingService.SONAR))
            .bindCouponRequest(payload);

        // Если сервис отклонил привязку купона:
        if (!request.isSuccess || !request.response?.isBound) {
            // Данные об идентификаторе купона и клиента
            // должны браться из сервиса, чтобы их
            // можно было использовать в отладке:
            const bindResult: BettingTypes.BindBetStatus = {
                isBound:    false,
                couponId:   request.response?.couponId   ?? 0,
                customerId: request.response?.customerId ?? 0,
            };

            // Если существует какой-либо возврат средств:
            if (true === request.response?.isRefundExists) {
                bindResult.isRefundExists = true;
                bindResult.isDeleted      = true === request.response
                    ?.isDeleted;

                // Сумма возврата:
                bindResult.refundAmount = request.response
                    ?.refundAmount ?? 0;

                // Корректировка баланса кассы
                // на сумму возврата:
                $shift.tmpAdjustCashBalance(
                    (bindResult.refundAmount) * -1
                );
            }

            // Если ставка была отклонена по причине
            // не существующего купона:
            if (true === request.response?.isCouponNotFound) {
                bindResult.isCouponNotFound = true;
            }

            // Ставка принята на устройстве, которое
            // не поддерживается веб кассой:
            if ('number' === typeof request.response?.sourceId) {
                bindResult.sourceId = request.response?.sourceId;
            }

            // Если ставка отклонена по причине того, что
            // она уже была привязана к какому-либо аккаунту:
            if (true === request.response?.alreadyBound) {
                bindResult.alreadyBound = true;
                bindResult.alreadyBoundToSameCustomer = request.response
                    ?.alreadyBoundToSameCustomer;
            }

            // Если кассиру можно показать возможность удалить купон
            // с полным возвратом денег клиенту:
            if (true === request.response?.canBeDeletedByCashier) {
                bindResult.canBeDeletedByCashier = true;
            }

            // Если на купон или аккаунт клиента наложены ограничения,
            // которые изменят содержимое и/или коэффициент купона:
            if (request.response?.restrictions) {
                // Автоматический разбор наложенных на купон ограничений, и
                // отображение этой информации в набранном купоне.
                const restrictionsAmount: number = await this.applyCouponRestrictions(
                    request.response?.restrictions ?? []
                );

                if (restrictionsAmount > 0) {
                    bindResult.hasRestrictions = true;
                }
            }

            return bindResult;
        }

        // Запрос на привязку был принят сервисом.
        // Купон успешно привязан к аккаунту клиента:
        const bindResult: BettingTypes.BindBetStatus = {
            isBound:    true,
            couponId:   request.response?.couponId   ?? 0,
            customerId: request.response?.customerId ?? 0,
        };

        // Если существует какой-либо возврат средств:
        if (true === request.response?.isRefundExists) {

            bindResult.isRefundExists = true;
            // Обновленная сумма ставки по купону,
            // с учетом суммы возврата:
            bindResult.updatedStake = request.response
                ?.updatedStake ?? 0;

            // Сумма возврата:
            bindResult.refundAmount = request.response
                ?.refundAmount ?? 0;

            // Корректировка баланса кассы
            // на сумму возврата:
            $shift.tmpAdjustCashBalance(
                (bindResult.refundAmount) * -1
            );
        }

        return bindResult;
    }

    /**
     * Обновление информации о списке купонов,
     * принятых кассиром за текущую смену.
     */
    public async updateBetsPerShiftList(): Promise<number>
    {
        const {
            $shift,
            $betting,
            $workspace
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: 'Logic error. Logged in cashier is required' +
                         ' to retrieve per shift coupons.',
            });
        }

        if (!$shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: 'Logic error. Opened cashier\'s shift is required' +
                         ' to retrieve per shift coupons.',
            });
        }

        if (!$workspace.hasAccessFor(WorkspaceLogicModule.COUPON_OVERVIEW)) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_MODULE_ACCESS_ERROR,
                isFatal: true,
                label:   'BETTING',
                message: `Logic error. Current EPOS has no access to` +
                         ` module id: '${WorkspaceLogicModule.COUPON_OVERVIEW}'`,
            });
        }

        const {
            $container,
        } = useAppContainer();

        const coupons = await ($container
            .get<BettingService>(BettingService.SONAR))
            .fetchCouponsPerShift();

        await $betting
            .updateCouponsPerShiftList(coupons);

        return coupons.length;
    }

    /**
     * Обработка списка ограничений, наложенных
     * на загруженный в интерфейс купон.
     */
    protected async applyCouponRestrictions(
        restrictions: BettingTypes.RestrictedOutcome[]
    ): Promise<number>
    {
        if ('object' !== typeof restrictions || null === restrictions) {
            throw new RuntimeException({
                code:    BettingErrors.COMMIT_BET_ERROR_INCORRECT_RESTRICTIONS,
                isFatal: true,
                label:   'BET-BIND',
                message: 'Incorrect structure provided for the bet restrictions processing.',
            });
        }

        if (Object.values(restrictions).length < 1) {
            return 0;
        }

        // Маппинг идентификатора события к структуре ограничений:
        const list: {[key: number]: BettingTypes.RestrictedOutcome} = {};

        // Обработка и компоновка списка ограничений:
        for (const restrictionData of Object.values(restrictions)) {

            const eventId: number = Number(
                restrictionData.eventId
            );

            if (isNaN(eventId)) {
                // Пропуск ограничения, у которого не указан идентификатор события,
                // чтобы не ошибиться при отображении ограничений:
                continue;
            }

            if ('object' !== typeof list[eventId]) {
                // Добавление ограничения в список "как есть", если до этого
                // не было добавлено элемента с таким же идентификатором:
                list[eventId] = restrictionData;
                continue;
            }

            if (restrictionData.isLocked) {
                // Смена флага в ранее добавленном элементе только в том случае,
                // если событие заблокировано:
                list[eventId].isLocked = true;
            }

            if (null !== restrictionData.allowedOdds) {
                // Смена значения в ранее добавленном элементе только в том случае,
                // если коэффициенты присутствуют в текущем элементе:
                list[eventId].allowedOdds = restrictionData.allowedOdds;
            }
        }

        if (Object.keys(list).length < 1) {
            return 0;
        }

        const {
            $betting,
        } = usePiniaState();

        await $betting
            .applyRestrictionsToActiveCoupon(list);

        return Object.keys(list).length;
    }
}