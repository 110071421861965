/**
 * #########################################
 * Описание инициализации приложения:
 * (основной десктоп интерфейс)
 * #########################################
 */
import {createPinia}
    from "pinia";
import Render
    from '~lib/render/Render';
import Helpers
    from "~lib/helpers";
import RenderSettings
    from "~lib/render/RenderSettings";
import container
    from "~app/IoC/inversify.config";
import Epos
    from "~tpl-desktop/Epos.vue";
/*import FloatingVue
    from 'floating-vue';*/

import usePiniaState
    from "~interaction/Store/usePiniaState";
import ParseInitConstantProvider
    from "~app/Providers/AppInit/ParseInitConstant.provider";
import CreateTmpLibServiceProvider
    from "~app/Providers/AppInit/CreateTmpLibService.provider";
import DefineInitRoutesProvider
    from "~app/Providers/AppInit/DefineInitRoutes.provider";
import CreateSvgSpriteDataProvider
    from "~app/Providers/AppInit/CreateSvgSpriteData.provider";
import WorkspaceDbProvider
    from "~app/Providers/AppInit/WorkspaceDb.provider";
import HttpClientProvider
    from "~app/Providers/AppInit/HttpClient.provider";
import AuthorizationCheckProvider
    from "~app/Providers/AppInit/AuthorizationCheck.provider";
import DependenciesInjectionProvider
    from "~app/Providers/AppInit/DependenciesInjection.provider";
import RuntimeExceptionHandlerProvider
    from "~app/Providers/AppInit/RuntimeExceptionHandler.provider";
import WindowsManagerProvider
    from "~app/Providers/Windows/WindowsManager.provider";
import SupervisorProvider
    from "~app/Providers/AppInit/Supervisor.provider";
import DefineInitTasksProvider
    from "~app/Providers/AppInit/DefineInitTasks.provider";
import AppEventBusProvider
    from "~app/Providers/AppInit/AppEventBus.provider";


const RENDER_SETTINGS: RenderSettings = {
    id:    'epos-shell',
    class: 'page',
    consoleLogOnFatal: true,
};

(new Render(
    container,
    RENDER_SETTINGS,
    Epos
))
    // Позиция в списке соответствует
    // очередности регистрации:
    .register([
        // Обработчик ошибок должен быть
        // зарегистрирован самым первым:
        RuntimeExceptionHandlerProvider,
        // Список сервисов, доступных
        // для извлечения через IoC:
        DependenciesInjectionProvider,
        // Обработка параметров, приходящих
        // вместе с HTML страницей:
        ParseInitConstantProvider,

        AppEventBusProvider,
        CreateTmpLibServiceProvider,
        CreateSvgSpriteDataProvider,
        DefineInitRoutesProvider,
        WorkspaceDbProvider,
        HttpClientProvider,
        WindowsManagerProvider,

        AuthorizationCheckProvider,
        DefineInitTasksProvider,
        SupervisorProvider,
    ])
    .render(async function(app): Promise<void>
    {
        // Для Pinia нет необходимости помещать какое-либо хранилище
        // непосредственно в контейнер приложения, достаточно
        // инициализации самого функционала:
        app.use(
            createPinia()
        );

        // Подключение плагина FloatingVue:
        //FloatingVue.options.container = '.workspace';
        //app.use(FloatingVue);

    })
    .then(async (shell: Render): Promise<void> =>
    {
        await shell
            .mount();

        const {
            $app,
        } = usePiniaState();

        // Необходим, чтобы убрать
        // мелькание прелоадера:
        await Helpers
            .sleep(350);

        // Приложение закончило
        // предварительную загрузку:
        $app.changeParameter(
            'isAppBootstrapFinished',
            true
        );
    });





