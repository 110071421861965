export const type = Symbol.for('SvgSprite');

export type SpriteFileList = {[index: string]: string};

export default class SvgSprite {

    /**
     * Список загруженных в приложение спрайтов.
     *
     * @private
     */
    private readonly sprites: SpriteFileList;

    /**
     * Список доступных спрайтов загружается разово,
     * при инициализации объекта. Обновление
     * уже существующего списка не допускается.
     *
     * @param sprites
     */
    constructor(sprites: SpriteFileList)
    {
        this.sprites = sprites;
    }

    /**
     * Возвращает путь до запрошенной иконки в спрайте.
     *
     * @param spriteName - спрайт, в котором содержится иконка.
     * @param icoName    - идентификатор иконки в спрайте
     * @param handler    - обработчик, соединяющий путь до спрайта с именем иконки
     */
    public get(
        spriteName: string,
        icoName:    string,
        handler:    null|((filename: string, ico: string) => string) = null
    ): string
    {
        const filename = this.sprites[spriteName]
            ?? '';

        return ('function' === typeof handler)
            ? handler(filename, icoName)
            : `${filename}#sprite-${icoName}`;
    }
}
