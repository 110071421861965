import {defineStore}
    from 'pinia'
import {ref, computed}
    from "@vue/runtime-core";
import {CustomerParameters}
    from "~app/Customer/Contracts/Customer.types";
import Customer
    from "~app/Customer/Models/Customer";
import usePiniaState
    from "~interaction/Store/usePiniaState";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

export const CustomerModuleKey: symbol = Symbol
    .for('PiniaCustomerModule');

const CustomerState = defineStore(CustomerModuleKey.toString(), () =>
{
    /**
     * @see data
     */
    const _data = ref<Customer|null>(null);

    /**
     * Хранилище модели гостя/игрока, с которым
     * в настоящий момент работает интерфейс.
     */
    const data = computed(
        () => _data.value
    );

    /**
     * Флаг, отвечающий за наличие аккаунта гостя,
     * загруженного в интерфейс кассы для дальнейшей работы.
     */
    const isLoaded = computed<boolean>(() => {
        return null !== data.value;
    });

    /**
     * Возвращает идентификатор аккаунта гостя,
     * с которым в настоящий момент работает касса.
     */
    const id = computed<number|null>(() => {
        return (null !== data.value)
            ? data.value.get<number|null>('id')
            : null;
    });

    /**
     * Возвращает временный токен доступа гостя,
     * используемый для совершения операций с его аккаунтом,
     * или от его имени.
     */
    const token = computed<string|null>(() => {
        return (null !== data.value)
            ? data.value.get<string|null>('token')
            : null;
    });

    /**
     * Активен ли аккаунт гостя.
     */
    const isActive = computed<boolean>(() => {
        return null !== data.value && null === (data.value?.get('banDate') ?? null);
    });

    /**
     * Возвращает одно из полей, описывающий
     * информацию об аккаунте гостя.
     *
     * Должно использоваться только для
     * отображения в интерфейсе.
     */
    const info = computed(() => {
        return <T>(field: keyof CustomerParameters): T|null =>
        {
            return (null !== data.value)
                ? data.value?.get<T>(`${field}`) ?? null
                : null
        }
    });

    /**
     * @see customerNameFormatId
     */
    const _customerNameFormatId = ref<1|2|3>(1);

    /**
     * Идентификатор формата расстановки ФИО.
     *
     * Определяется автоматически, на основе языка кассы.
     */
    const customerNameFormatId = computed(
        () => _customerNameFormatId.value
    );

    /**
     * Возвращает ФИО клиента в необходимом формате.
     *
     * По умолчанию будет использован формат,
     * определенный на основе локали кассы.
     *
     * @param format - Формат вывода ФИО.
     * @param short  - Если "TRUE", то вывод будет сокращен.
     */
    function getName(
        format: 1|2|3|null = null,
        short:  boolean    = false
    ): string
    {
        format = (null === format)
            ? customerNameFormatId.value
            : format;

        return (!isLoaded.value)
            ? '—'
            : (data.value?.getFullName(format, short) ?? '—');
    }

    /**
     * Загружает или выгружает из интерфейса модель,
     * содержащую информацию об аккаунте гостя,
     * с которым в настоящий момент ведется работа.
     */
    function setActiveCustomer(
        payload: Customer|null
    ): void
    {
        const {
            $app,
        } = usePiniaState();

        _customerNameFormatId.value = ((): 1|2|3 => {
            switch ($app.locale) {
                default:
                    return 3;
                case 'en_GB':
                case 'fr_FR':
                    return 1;
            }
        })();

        if (null !== payload && !(payload instanceof Customer)) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: 'Logic error. Failed to set customer data.' +
                         ' Payload must be instance of "Customer" model.'
            });
        }

        _data.value = payload;
    }

    /**
     * Возвращает токен загруженного в систему аккаунта гостя,
     * используемый для совершения операций от его имени.
     */
    function getCustomerToken(): string|null
    {
        if (!isLoaded.value) {
            return null;
        }

        return info
            .value('token');
    }

    return {
        data,
        isLoaded,
        id,
        token,
        isActive,
        info,

        getName,
        setActiveCustomer,
        getCustomerToken
    };
});

export default CustomerState;