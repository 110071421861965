<template>
  <div class="ui-button">
    <button
        :type="type"
        :class="shellStyles"
    >
        <span class="button__container">
          <template v-if="!isProcessing">
            <svg-elem
                v-if="ico?.length > 0"
                sprite="cleared"
                :to="ico ?? 'warning--triangle'"
                :size="size === 'size-s' ? 'size-xs' : icoSize"
                :is-mirrored="isMirroredIcon"
                class="button__icon"
            />
            <span
                v-if="text?.length > 0"
                class="button__label no-select"
            >
                {{ text }}
            </span>
          </template>
          <svg-elem
              v-else
              :isLoader="true"
              :size="'size-xs'"
              to="infinity-loader"
              sprite="cleared"
              class="button__icon"
          />
        </span>
    </button>
    <span
        v-if="description?.length"
        v-html="description"
        class="ui-button__description no-select"
    ></span>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'Button';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
  computed,
  defineProps,
} from "@vue/runtime-core";

import {SvgList}
  from "~tpl-global/svg/SvgList";
import {SvgSize}
  from "~tpl-global/svg/SvgSize";
import {StyleExtend}
  from "~tpl-global/StyleExtend";
import {
  ButtonType,
  ButtonTheme,
  ButtonLabel,
  ButtonSize,
  ButtonStyle,
  ButtonBorderWidth
} from "~tpl-global/Buttons/ButtonDefault/ButtonTypes";

/** IMPORTED VUE TEMPLATES: */
import SvgElem from "~tpl-global/svg/SvgElem.vue";

/** COMPONENT LOGIC: */

type ComponentProps = {
  shellStyles?: StyleExtend,
  type?:        ButtonType,
  themeStyle?:  ButtonStyle,
  size?:        ButtonSize,
  theme?:       ButtonTheme,
  labelStyle?:  ButtonLabel,

  isDisabled?:    boolean,
  isProcessing?:  boolean,
  description?:   string,

  ico?:            SvgList | null,
  icoSize?:        SvgSize,
  icoOnly?:        boolean,
  text?:           string,
  isMirroredIcon?: boolean,
  borderWidth?:    ButtonBorderWidth
};

const props = withDefaults(defineProps<ComponentProps>(), {
  shellStyles: () => [],
  type:       'button',
  themeStyle: 'default',
  size:       'size-m',
  theme:      'primary',
  labelStyle: 'default',

  isDisabled:     false,
  isProcessing:   false,
  description:    '',

  ico:         null,
  icoSize:     'size-s',
  icoOnly:     false,
  text:        '',
  borderWidth: 'normal'
});

// стили для общей оболочки кнопки:
const shellStyles = computed<StyleExtend>({
  set: (val) => null,
  get: function () {
    const styles = [
      'ui-button__button',
      'button',
      'button--style-' + props.themeStyle,
      'button--' + props.size,
      'button--theme-' + props.theme
    ];

    if (props.icoOnly) {
      styles.push('button--icon-only');
    }

    if (props.isDisabled) {
      styles.push('button--is-disabled');
    }

    if (props.isProcessing) {
      styles.push('button--is-loading');
    }

    if ('default' !== props.labelStyle) {
      styles.push('button--is-label-' + props.labelStyle);
    }

    if ('outlined' === props.themeStyle) {
      styles.push('button--border-' + props.borderWidth)
    }

    return [
      ...styles,
      ...props.shellStyles
    ];
  },
});
</script>

<style lang="scss" scoped>
  @import '~scss-shared/components/button';
</style>