import {computed, ref}
    from "@vue/runtime-core";
import IMask, {FactoryStaticOpts}
    from "imask";
import {SvgList}
    from "~tpl-global/svg/SvgList";
import {SvgSize}
    from "~tpl-global/svg/SvgSize";
import {DropdownOption}
    from "~tpl-global/Dropdown/DropdownOption";
import {InputType}
    from "~tpl-global/Input/InputType";
import {InputSize}
    from "~tpl-global/Input/InputSize";
import {StyleExtend}
    from "~tpl-global/StyleExtend";
import {AvailableList}
    from "~app/Other/Currency/CurrencyIdToCode";

export type InputIco  = {svg: SvgList, size: SvgSize};
export type IcoButton = {callable: (event: Event) => void} & InputIco;

export type ComponentProps = {
    type?:       InputType,
    isError?:    boolean,
    isDisabled?: boolean,

    iMask?: FactoryStaticOpts,

    size?:              InputSize,
    shellStyles?:       StyleExtend,
    descriptionStyles?: StyleExtend,
    hasBorder?:         boolean,

    prependIco?:            InputIco,
    isMirroredPrependIcon?: boolean,
    appendIcoList?:         IcoButton[],
    currency?:              AvailableList|null,

    id?:              string,
    name?:            string,
    label?:           string,
    placeholder?:     string,
    description?:     string|string[],
    dropdownOptions?: DropdownOption[],

    /**
     * Не изменять имя поля с "modelValue", иначе для двусторонней связи
     * (через v-model:) придется указывать конкретное значение связываемого поля.
     */
    modelValue?: string | number,
};

type EmitterType = (
    event: 'update:modelValue',
    ...args: any[]
) => void;

export const ComponentPropsDefaults = {
    type:       'text',
    isError:    false,
    isDisabled: false,

    size:              'size-m',
    shellStyles:       () => [],
    descriptionStyles: () => [],
    hasBorder:         true,

    prependIco:    undefined,
    appendIcoList: undefined,
    currency:      null,

    id:              '',
    name:            '',
    label:           '',
    placeholder:     '',
    description:     '',
    dropdownOptions: () => [],

    modelValue: () => (''),
};

/**
 * Необходимо добавить параметры для
 * двунаправленного связывания поля ввода:
 *
 * :value="modelValueProxy"
 * @input="inputHandler($event)"
 *
 * Основные параметры поля ввода:
 *
 * :id="inputId"
 * :name="inputName"
 * :type="type"
 * :placeholder="placeholder"
 *
 * Необходимо добавить обработку событий
 * для поля ввода:
 *
 * @focus="isFocused = true"
 * @blur="isFocused = false"
 * @mouseenter="isHovered = true"
 * @mouseleave="isHovered = false"
 *
 * Правка для Google Chrome:
 *
 * :autocomplete="autocomplete"
 *
 * @param props
 * @param emitter
 */
export default function (
    props:   ComponentProps,
    emitter: EmitterType
) {

    /**
     * Флаг нахождение поля ввода в активном фокусе.
     */
    const isFocused= ref<boolean>(false);

    /**
     * Флаг активного наведения курсора манипулятора на поле ввода.
     */
    const isHovered= ref<boolean>(false);

    const isDropdownHidden = computed<boolean>(() => {
        if ((props.dropdownOptions?.length ?? 0) > 0) {
            return true;
        }

        return true !== isFocused.value;
    });

    /**
     * Псевдослучайно генерируемая строка, используемая
     * для генерации имени и/или идентификатора поля ввода,
     * если они не были заданы вручную.
     */
    const randomStr: string = Math.random()
        .toString(36)
        .substring(7);

    /**
     * Идентификатор поля ввода.
     */
    const inputId: string = ('undefined' === typeof props.id || props.id?.length < 4)
        ? `id-${randomStr}`
        : props.id;

    /**
     * Название поля ввода.
     */
    const inputName: string = ('undefined' === typeof props.name || props.name?.length < 4)
        ? `name-${randomStr}`
        : props.name;

    /**
     * Апи функционала iMask.
     */
    const iMaskApi = ('undefined' !== typeof props.iMask)
        ? IMask.createMask(props.iMask)
        : null;

    /**
     * Html параметр, для указания браузеру способа обработки
     * для автозаполнения поля ввода.
     */
    const autocomplete = computed<string>((): string => {
        return props.type === 'password'
            ? 'current-password'
            : '';
    });

    /**
     * Прокси переменная, необходима для разделения
     * значения поля с результатом работы iMask.
     */
    const modelValueProxy = computed<string|number>(() => {
        if (null !== iMaskApi) {
            iMaskApi.resolve(`${props.modelValue}`);

            if (props.modelValue !== iMaskApi.unmaskedValue) {
                emitter('update:modelValue', iMaskApi.unmaskedValue);
            }

            return iMaskApi.value;
        }

        return props.modelValue ?? '';
    });

    /**
     * Обработчик ввода в поле.
     */
    function inputHandler(
        event: Event
    ): void
    {
        const value: string = (event.target as unknown as {value: string})
            .value;

        const elem: EventTarget|null = event.target;

        if (null !== iMaskApi)
        {
            iMaskApi
                .resolve(`${value}`);

            (elem as unknown as {value: string})
                .value = iMaskApi.value;
        }

        emitter('update:modelValue', (null !== iMaskApi)
            ? iMaskApi.unmaskedValue
            : value
        );
    }

    return {
        isFocused,
        isHovered,
        isDropdownHidden,

        inputId,
        inputName,
        autocomplete,

        modelValueProxy,
        inputHandler,
    };
}