import {inject, injectable}
    from 'inversify';
import Md5
    from "crypto-js/md5";

import usePiniaState
    from "~interaction/Store/usePiniaState";
import useAppContainer
    from "~app/IoC/useAppContainer";
import AccountingService
    from "~app/Epos/Accounting.service";
import * as ShiftTypes
    from "~app/Epos/Contracts/Shift.types";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

@injectable()
export default class AccountingModule {

    public static readonly SONAR = Symbol
        .for('AccountingModule');

    /**
     * Внимание! Инициализация сервиса предполагается только
     * через IoC контейнер InversifyJS.
     */
    constructor(
    ){}

    /**
     * Запрос на обновление списка транзакций, проведенной через кассу
     * в рамках текущей и предыдущей смены.
     *
     * ВНИМАНИЕ!
     * Обязательно требуется наличие активной рабочей смены кассира.
     */
    public async updateWorkspaceTransactions(): Promise<void>
    {
        const {
            $workspace,
            $shift
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.LOGGED_IN_CASHIER_REQUIRED,
                isFatal: true,
                message: 'Logic error. Logged in cashier is required' +
                         ' to retrieve workspace transactions list.',
            });
        }

        if (!$shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: 'Logic error. Opened cashier\'s shift is required' +
                         ' to retrieve workspace transactions list.',
            });
        }

        const {
            $container,
        } = useAppContainer();

        const transactions = await ($container
            .get<AccountingService>(AccountingService.SONAR))
            .retrieveTransactionsList();

        await $shift
            .updateTransactionsList(
                transactions
            );
    }

    /**
     * Запрос на обновления списка запросов на вывод со счета клиента,
     * назначенных на текущее рабочее место.
     *
     * ВНИМАНИЕ!
     * Обязательно требуется наличие активной рабочей смены кассира.
     */
    public async updateWorkspaceWithdrawalRequests(): Promise<void>
    {
        const {
            $workspace,
            $shift
        } = usePiniaState();

        if (!$workspace.isCashierLoggedIn) {
            throw new RuntimeException({
                code:    EposCoreErrors.LOGGED_IN_CASHIER_REQUIRED,
                isFatal: true,
                message: 'Logic error. Logged in cashier is required' +
                         ' to retrieve active withdrawals list.',
            });
        }

        if (!$shift.isActive) {
            throw new RuntimeException({
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                isFatal: true,
                message: 'Logic error. Opened cashier\'s shift is required' +
                         ' to retrieve active withdrawals list.',
            });
        }

        const {
            $container,
        } = useAppContainer();

        const withdrawalRequests = await ($container
            .get<AccountingService>(AccountingService.SONAR))
            .retrievePendingWithdrawalRequests();

        $shift
            .updateApprovedWithdrawalsList(
                Object.values(withdrawalRequests ?? []),
            );
    }

    /**
     * Проверяет наличие запросов на вывод со счета указанного клиента,
     * назначенных на текущее рабочее место.
     *
     * @param customerId - Идентификатор аккаунта клиента.
     */
    public async isCustomerHasApprovedWithdrawalRequest(
        customerId: number
    ): Promise<boolean>
    {
        const {
            $shift,
        } = usePiniaState();

        if (!$shift.isWorkspaceHasWithdrawalRequests) {
            return false;
        }

        for (const rq of $shift.approvedWithdrawals.value ?? []) {
            if (customerId !== rq.customerId) {
                continue;
            }

            return true;
        }

        return false;
    }

    /**
     * Ищет, в памяти рабочего места, одобренный запрос на выплату
     * для указанного гостя и, проверяет совпадение хэша кода подтверждения.
     *
     * Если ничего не найдено, либо если указан некорректный код,
     * то возвращается "false".
     *
     * В случае успеха возвращает структуру запроса на выплату.
     *
     * @param customerId - Идентификатор аккаунта клиента.
     * @param code       - Код подтверждения выплаты.
     */
    public checkCustomerWithdrawalRequest(
        customerId: number,
        code:       string
    ): ShiftTypes.ApprovedWithdrawalRequest|false
    {
        const {
            $shift,
        } = usePiniaState();

        if (!$shift.isWorkspaceHasWithdrawalRequests) {
            return false;
        }

        code = Md5(code)
            .toString();

        for (const rq of $shift.approvedWithdrawals.value ?? []) {
            if (customerId !== rq.customerId || code !== rq.withdrawalCode) {
                continue;
            }

            return rq;
        }

        return false;
    }
}