<template>
  <nav class="workspace__menu">
    <ul v-if="null !== leftMenu" class="workspace-menu">
      <template v-for="elem in leftMenu">
        <li
            v-if="elem.data.showInHotMenu"
            :key="elem.window"
            class="workspace-menu__item"
        >
          <a
              @click.prevent="$app.changeActiveWindowId(elem.window)"
              :class="{
                'workspace-menu-link--is-active': $app.windowId === elem.window,
                'workspace-menu-link--is-disabled': !$shift.isActive || $shift.isDurationExceed24Hours
            }"
              class="workspace-menu__link workspace-menu-link"
          >
            <SvgElem
                sprite="cleared"
                :to="($app.windowId === elem.window) ? getActiveMenuIco(elem.data.ico) : elem.data.ico"
                size="size-l"
                class="workspace-menu-link__icon"
            />
            <span class="workspace-menu-link__label">{{ elem.data.name }}</span>
          </a>
        </li>
      </template>
      <li v-if="false" class="workspace-menu__item">
        <button @click="showSideMenu" class="workspace-menu__button workspace-menu-button">
          <SvgElem
              sprite="cleared"
              to="overflow-menu--horizontal"
              size="size-l"
              class="workspace-menu-button__icon"
          />
        </button>
      </li>
<!--      <li class="workspace-menu__item">-->
<!--        <button class="workspace-menu__button workspace-menu-button">-->
<!--          <SvgElem-->
<!--              sprite="cleared"-->
<!--              to="overflow-menu&#45;&#45;horizontal"-->
<!--              size="size-l"-->
<!--              class="workspace-menu-button__icon"-->
<!--          />-->
<!--        </button>-->
<!--      </li>-->
    </ul>
    <transition v-if="false" name="side-menu">
      <left-side-menu v-if="isSideMenuShowed" />
    </transition>
  </nav>
</template>

<script lang="ts">
const CMP_NAME = 'LeftHotMenu';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {ref} from "@vue/runtime-dom";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import {WM_TAG_LEFT}
  from "~app/Providers/Windows/WindowsManager.provider";
import useWindowsManager
  from "~interaction/useWindowsManager";
import {SvgList}
  from "~svg/cleared/menu/SvgList";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
  from "~tpl-global/svg/SvgElem.vue";
import LeftSideMenu
  from "~tpl-desktop/workspace/Navigation/LeftSideMenu.vue";

/** COMPONENT LOGIC: */
const {
    $app,
    $workspace,
    $shift,
} = usePiniaState();

const {
  $WM
} = useWindowsManager();

// список окон левого меню:
const leftMenu = $WM
    .getTagInfo(WM_TAG_LEFT);

/**
 * Фикс отображения иконок для активного состояния меню.
 */
function getActiveMenuIco(payload: string): SvgList
{
  return (('dashboard' !== payload)
      ? `${payload}-filled`
      : `${payload}--filled`) as unknown as SvgList;
}

const isSideMenuShowed = ref<boolean>(false);

function showSideMenu() {
  return isSideMenuShowed.value = !isSideMenuShowed.value;
}
</script>

<style lang="scss">
  @import '~scss-desktop/common/components/navigation/_left-menu';
</style>