<template>
  <div class="workplace-main__inner">
    <guest-window-ico
      ico="server-settings"
      size="size-custom-95"
      sprite="cleared"
      :is-flicker-allowed="$syncData.isProcessingFailed"
    />
    <!-- Общий заголовок функционала: -->
    <h1 class="workplace-main__title">{{ $T.text('interface-init-stage-sync-header') }}</h1>
    <!-- Отображение краткого описание каждого выполняемого задания: -->
    <div class="workplace-main__description">
      <p
        v-if="!$syncData.isQueueEmpty"
        v-for="textPart in $syncData.handledTaskDescription"
        class="workplace-main__text"
      >{{textPart}}</p>
    </div>
    <!-- Кнопки управления на случай, если с каким либо из шагов возникли проблемы: -->
    <div v-if="$syncData.isProcessingFailed" class="workplace-main__buttons">
      <Button
        @click="$workspaceModule.runWorkspaceSyncTasks()"
        size="size-l"
        label-style="uppercase"
        class="workplace-main__button"
        :text="$T.text('interface-init-restart-after-fail')"
      />
      <Button
        size="size-l"
        ico="download--two"
        ico-only
        ico-size="size-m"
        class="workplace-main__button workplace-main__button--small"
      />
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'WorkspaceSynchronization';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useWorkspaceModule
    from "~app/IoC/useWorkspaceModule";

/** IMPORTED VUE TEMPLATES: */
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $syncData,
} = usePiniaState();

const {
    $workspaceModule,
} = useWorkspaceModule();

// При инициализации компонента, автоматически
// запускается логика синхронизации рабочего места:
$workspaceModule
    .runWorkspaceSyncTasks();
</script>