import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";
import AsyncProvider
    from "~ioc/contracts/AsyncProvider.contract";
import IndexedDb
    from "~lib/indexeddb/IndexedDb";
import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

/**
 * Ключ для регистрации соединения с IndexedDB в контейнере:
 */
export const WorkspaceDataDB = Symbol.for('WorkspaceDataDBSonar');
/**
 * Название базы данных, в которой будут находиться таблицы рабочего места:
 */
export const DB_NAME= 'workspace-data';
/**
 * Название таблицы с данными рабочего места:
 */
export const TABLE_WORKSPACE= 'workspace';
/**
 * Название таблицы для хранения локализаций интерфейса:
 */
export const TABLE_LOCALIZATION = 'localization';
/**
 * Название таблицы для долговременного хранения кеша фидов:
 */
export const TABLE_FEEDS = 'feeds';
/**
 * Название таблицы для временного хранения кеша данных,
 * необходимых для работы функционала беттинга:
 */
export const TABLE_BETTING_CACHE = 'betting-cache';

/**
 * Регистрирует подключение к хранилищу IndexedDB, в котором
 * должны храниться авторизационные данные рабочего места.
 *
 * Так же в нем могут храниться данные, необходимые для
 * инициализации приложения, либо для работы его функционала
 * общего назначения.
 */
export default class WorkspaceDbProvider extends ServiceProvider
    implements
        AsyncProvider {

    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol.for('WorkspaceDbProvider');
    }

    /**
     * @inheritDoc
     */
    isParallel(): boolean
    {
        return false;
    }

    /**
     * @inheritDoc
     *
     * @param app
     */
    async register(app: Container): Promise<void>
    {
        const connection: IndexedDb = await (new IndexedDb({
            name:    DB_NAME,
            version: 4,
            tables:  {
                [TABLE_WORKSPACE]: {
                    name:          TABLE_WORKSPACE,
                    primary:       'key',
                    autoIncrement: false
                },
                [TABLE_LOCALIZATION]: {
                    name:          TABLE_LOCALIZATION,
                    primary:       'key',
                    autoIncrement: false
                },
                [TABLE_FEEDS]: {
                    name:          TABLE_FEEDS,
                    primary:       'key',
                    autoIncrement: false
                },
                [TABLE_BETTING_CACHE]: {
                    name:          TABLE_BETTING_CACHE,
                    primary:       'key',
                    autoIncrement: false,
                },
            },
        }))
            .connect()
            .then((db: IndexedDb) => db)
            .catch((error): never => {
                throw new RuntimeException({
                    code:     EposCoreErrors.DATABASE_INIT_ERROR,
                    isFatal:  true,
                    label:    "DATABASE-INIT",
                    message:  `Failed to connect to the database "${DB_NAME}".`,
                    previous: error,
                });
            });

        try
        {
            app
                .bind<IndexedDb>(WorkspaceDataDB)
                .toConstantValue(connection);

        } catch (error) {
            throw new RuntimeException({
                code:     EposCoreErrors.DATABASE_INIT_ERROR,
                isFatal:  true,
                label:    "DATABASE-INIT",
                message:  `Failed store active connection of "${DB_NAME}" database into app container.`,
                previous: error,
            });
        }
    }
}