import {DateTime as Luxon} from "luxon";

export default class Date {

    /**
     * Возвращает UNIX timestamp для
     * системного времени браузера.
     *
     */
    public static clientTimestamp(): number
    {
        return Luxon.now().toUnixInteger();
    }
}