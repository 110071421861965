import {Container}
    from "inversify";
import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import FeedService
    from "~app/Betting/Feed.service";
import {AppInterfaceType}
    from "~app/Epos/Enum/AppInterfaceType";
import {WorkspaceLogicModule}
    from "~app/Epos/WorkspaceLogicModule";
import AVAILABLE_BETTING_LOCALES
    from "~app/Betting/BettingLocales.tech";
import * as LiveFeed
    from "~app/Betting/Routes/Feed/FeedLiveGroups.route";
import useEventsFeedUpdateLogic
    from "~interaction/Betting/useEventsFeedUpdateLogic";
import usePiniaState
    from "~interaction/Store/usePiniaState";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {BettingErrors}
    from "~app/Betting/Betting.errors";

/**
 * Задание на получение данных LIVE фида от апи,
 * его обработку и сохранения в кеше.
 */
const LiveFeedUpdateInterval: IntervalTaskContract = {
    requiredInterface: [
        AppInterfaceType.EPOS_DESKTOP,
    ],

    requiredModules: [
        WorkspaceLogicModule.FETCH_LIVE_FEED,
    ],

    allowedLocales: AVAILABLE_BETTING_LOCALES,

    id:                'live-feed-update',
    intervalMs:        15 * 1000,
    startOnInit:       true,
    authRequired:      true,
    cashierRequired:   true,
    shiftRequired:     true,

    sequence: 'betting-feed',

    payload: async function (ioc: Container): Promise<void>
    {
        const {
            $betting,
        } = usePiniaState();

        if ($betting.isBetSettingsActive) {
            // Запрос к фидам не должен выполняться
            // пока идет настройка ставки:
            return;
        }

        const type: 'live' = 'live';

        let rawFeedData: LiveFeed.Response = null;

        const $feedService = ioc
            .get<FeedService>(FeedService.SONAR);

        try {
            rawFeedData = await $feedService
                .retrieveGroupsOfEvents<LiveFeed.Response>(
                    {
                        type: type,
                    }
                );
        }

        catch (throwable) {
            //TODO: уведомление в глобальную систему нотификаций
            //TODO: проверить если ли уведомление о проблемах при обновлении фида, и если есть - удалить
            throw throwable;
        }

        if (null === rawFeedData) {
            // Ошибка логики, не получен или не обработан ответ от API:
            throw new RuntimeException({
                isFatal:  true,
                code:     BettingErrors.FEED_UNEXPECTED_ERROR,
                message:  `Logic error on live feed update task. API response is not handled.`
            });
        }

        const {
            handleRetrievedFeedData,
        } = useEventsFeedUpdateLogic();

        // Обработка ответа от фида:
        await handleRetrievedFeedData(
            $feedService,
            type,
            rawFeedData
        );
    }
};

export default LiveFeedUpdateInterval;