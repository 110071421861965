import Exception
    from "~lib/Exception/Exception";
import {ComponentPublicInstance}
    from "@vue/runtime-core";

export type AppInterfaceConstructor = {
    code:    number;
    message: string|null;
    trace?:  Error;

    interfaceComponent: ComponentPublicInstance|null;
}

/**
 * Исключение предназначено для обработки ошибок и
 * предупреждений, выбрасываемых ядром Vue.
 *
 * ВНИМАНИЕ!
 * Исключение всегда считается фатальным, и приводит к
 * аварийному завершению работы приложения, вне зависимости от того,
 * является ли возникшее событие ошибкой, или предупреждением.
 *
 * Учитываются:
 * - app.config.errorHandler
 * - app.config.warnHandler
 *
 * Дополнительная информация:
 * https://vuejs.org/api/application.html
 * https://vuejs.org/error-reference/#runtime-errors
 */
export default class AppInterfaceException
    extends Exception
    implements AppInterfaceConstructor {

    public readonly interfaceComponent: ComponentPublicInstance|null;

    /**
     * @inheritDoc
     */
    constructor(
        payload: AppInterfaceConstructor
    )
    {
        super({
            isFatal:  true,
            code:     payload.code,
            type:     'Exception',
            name:     'AppInterfaceException',
            label:    'APP-INTERFACE-EXCEPTION',
            message:  payload.message,
            previous: payload.trace
        });

        this.interfaceComponent = payload.interfaceComponent;
    }

    /**
     * Пытается найти и вернуть название компонента,
     * в котором произошло исключение.
     */
    public getInstanceTemplateName(): string|null
    {
        if (!this.interfaceComponent) {
            return null;
        }

        return this.interfaceComponent
            .$
            .type
            .name ?? null;
    }
}