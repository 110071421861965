import "reflect-metadata";
import {Container}
    from "inversify";

// Описание хранения поля с IoC контейнером приложения
// внутри глобального объекта "window":
export type ContainerInGlobalWindow = {
    $container: Container
};

// Идентификатор для обращения к привязке контейнера:
export const ContainerSonar: symbol = Symbol
    .for('$container');

// Прокси для типизации:
const $window: ContainerInGlobalWindow = (
    (): ContainerInGlobalWindow => window as unknown as ContainerInGlobalWindow
)();

// Создание нового контейнера приложения:
$window.$container = new Container();

// Возможность использовать сам контейнер
// при инъекции зависимостей:
$window.$container
    .bind<Container>(ContainerSonar)
    .toConstantValue($window.$container);

export default $window.$container;
