/**
 * @see https://vuejs.org/error-reference/#runtime-errors
 */
export const VueRuntimeErrorCodeMap: {[key: string]: string} =
    {
        '0':	'setup function',
        '1':	'render function',
        '2':	'watcher getter',
        '3':	'watcher callback',
        '4':	'watcher cleanup function',
        '5':	'native event handler',
        '6':	'component event handler',
        '7':	'vnode hook',
        '8':	'directive hook',
        '9':	'transition hook',
        '10':	'app errorHandler',
        '11':	'app warnHandler',
        '12':	'ref function',
        '13':	'async component loader',
        '14':	'scheduler flush',
        'sp':	'serverPrefetch hook',
        'bc':	'beforeCreate hook',
        'c':	'created hook',
        'bm':	'beforeMount hook',
        'm':	'mounted hook',
        'bu':	'beforeUpdate hook',
        'u':	'updated',
        'bum':	'beforeUnmount hook',
        'um':	'unmounted hook',
        'a':	'activated hook',
        'da':	'deactivated hook',
        'ec':	'errorCaptured hook',
        'rtc':	'renderTracked hook',
        'rtg':	'renderTriggered hook',
    }