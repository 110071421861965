import StackTraceGPS
    from "stacktrace-gps";
import ErrorStackParser, {StackFrame}
    from "error-stack-parser";
import Exception, {ExceptionConstructor}
    from "~lib/Exception/Exception";

export type ExceptionHandlerFactory<
    Throwable extends Exception,
    Arguments extends unknown[] = unknown[],
    Service extends ExceptionHandler<Throwable> = ExceptionHandler<Throwable>
> = (
    ...args: Arguments
) => Service;

export type traceTableType = {
    handler: string,
    line:    string|number,
    source:  string
}[];

export default abstract class ExceptionHandler<Throwable extends Exception> {

    public static readonly SONAR = Symbol
        .for('ExceptionHandlerContract')

    /**
     * Обрабатываемое исключение.
     */
    protected readonly throwable: Throwable;

    protected constructor(
        throwable: Throwable
    )
    {
        this.throwable = throwable;
    }

    /**
     * Метод должен описывать логику обработки переданного исключения.
     *
     * @param data - дополнительная информация, необходимая при обработке
     */
    public abstract handle<Data = unknown>(
        data?: Data
    ): void | Promise<void>;

    /**
     * Метод должен описывать логику логирования переданного исключения.
     *
     * @param data - дополнительная информация, необходимая при обработке
     */
    public abstract log<Data = unknown>(
        data?: Data
    ): void | Promise<void>;

    /**
     * Генерирует таблицу трассировки до места возникновения исключения.
     *
     * @param payload - результат работы "error-stack-parser"
     * @param splice  - количество пропускаемых строк
     */
    protected static async generateTraceTable(
        payload: StackFrame[],
        splice:  number = 0
    ): Promise<traceTableType>
    {
        const adaptSourceName = (
            source: string
        ): string =>
        {
            const group: string[] = source
                .split('/');

            return group[group.length -1];
        };

        const traceTable: traceTableType = [];

        // Пропускаем первые два индекса, т.к.
        // там будет информация о создании исключения:
        for await (const line of (payload).splice(splice)) {
            traceTable.push({
                handler: line.functionName ?? '-',
                line:    line.lineNumber   ?? '-',
                source:  (line.fileName)
                    ? adaptSourceName(line.fileName)
                    : '-',
            });
        }

        return traceTable;
    }

    /**
     * Полная трассировка возникшего исключения, дополняющая вывод
     * используя сгенерированный sourcemap.
     *
     * TODO: 1) Проходиться по карте асинхронно
     * TODO: 2) Использовать кеш
     *
     * @see https://github.com/stacktracejs/error-stack-parser
     * @see https://github.com/stacktracejs/stacktrace-gps
     */
    protected async getTrace(
        throwable?: Error
    ): Promise<StackFrame[]>
    {
        const traceGPS: StackTraceGPS = new StackTraceGPS();

        const trace: ErrorStackParser.StackFrame[] = ErrorStackParser.parse(
            (throwable)
                ? throwable
                : this.throwable.trace
        );

        let index: number = -1;

        for await (const line of trace)
        {
            // TODO: чистить "line"
            index++;
            trace[index] = await traceGPS
                .pinpoint(line)
                .then((processedLine) => processedLine)
                .catch((error) => line);
        }

        return trace;
    }

    /**
     * Преобразование исключения неопределенного типа в экземпляр объекта,
     * определенного дженериком ReturnedException.
     *
     * @param payload          - исключение для обработки
     * @param defaultException - тип исключения, который должен быть на выходе
     * @param data             - дополнительные данные, необходимые конструктору
     */
    protected static processingIncomingException<ReturnedException extends Exception,
        Data extends {[index: string]: any} = ExceptionConstructor>(
        payload:          unknown,
        defaultException: (new(payload: Data) => ReturnedException),
        data:             Data,
    ): ReturnedException
    {
        if (payload instanceof Exception) {
            return payload as ReturnedException;
        }

        if (payload instanceof Error) {
            return new defaultException({
                ...data,
                ...{
                    previous: payload,
                }
            });
        }

        // в остальных ситуациях ошибка была создана некорректно,
        // (например при использовании конструкции throw "some text"),
        // сделать трассировку к такому исключению будет невозможно.
        return new defaultException({
            ...data,
            ...{
                previous: payload,
                message: `Unsupported error thrown. Type: "${typeof payload}". ` +
                         `Context: ${((['string', 'number'].includes(typeof payload))) ? payload : '-'}`,
            }
        });
    }
}
