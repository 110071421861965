import {Container}
    from "inversify";
import {Base64}
    from "js-base64";
import ServiceProvider
    from "~ioc/ServiceProvider";
import MsgpackDecoder
    from "~lib/msgpack/Decoder";
import {SystemSonar}
    from "~app/Epos/Enum/SystemSonar";
import {InterfaceBootstrapResponse}
    from "~app/Epos/Contracts/Epos.types";
import useEposModule
    from "~app/IoC/useEposModule";

/**
 * Поиск и обработка константы, возвращаемой API вместе
 * с HTML страницей.
 *
 * Данные, содержащиеся в ней, влияют на
 * инициализацию приложения.
 */
export default class ParseInitConstantProvider
    extends ServiceProvider
{
    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('ParseInitConstantProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        // Декодирование данных из пакета, поставляемого вместе с HTTP страницей:
        const appInitData: InterfaceBootstrapResponse = MsgpackDecoder
            .decode<InterfaceBootstrapResponse>(
                Base64.toUint8Array(_APP_INIT_DATA)
            );

        // Временное размещение декодированных данных инициализации
        // в контейнер приложения, для использования в последующих провайдерах:
        // (А так же для формирования лога отладки)
        app.bind<InterfaceBootstrapResponse>(SystemSonar.AppInitConstant)
            .toConstantValue(appInitData);

        const {
            $eposModule
        } = useEposModule();

        // Установка параметров, влияющий на способ инициализации интерфейса, и
        // которые будет невозможно переопределить в дальнейшем:
        $eposModule
            .setupCurrentWorkspace(appInitData);
    }
}