import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import useAppContainer
    from "~app/IoC/useAppContainer";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useSupervisor
    from "~app/IoC/useSupervisor";

import Exception
    from "~lib/Exception/Exception";

//TODO: похоже на то, что задания идут не последовательно, разобраться
//TODO: intervalHandler возвращает boolean|void, проверить учитывается ли это. Возможно не учитывается результат из вложенного payload
//В теории:
// true = итерация выполнена; false - итерация не могла быть выполнена и перенесена в конец очереди.

/**
 * Реализация обработчика фоновых заданий кассы.
 *
 * @param task - Обрабатываемое задание.
 */
export default async function IntervalTasksHandler (
    task: IntervalTaskContract
): Promise<boolean|void>
{
    // Необходимо на каждый цикл создавать экземпляры
    // для доступа к АПИ хранилищ, во избежание коллизии:
    const {
        $app,
        $workspace,
        $shift
    } = usePiniaState();

    const {
        $SUPERVISOR
    } = useSupervisor();

    // В случае возникновения фатальной ошибки, или если обнаружен
    // дублирующий экземпляр, приложение - полностью останавливается,
    // т.к. обработка фоновых заданий более не требуется:
    if ($app.isAppCrashed || $app.isAppHasDuplicatedInstance)
    {
        if ($SUPERVISOR.isIntervalActive())
        {
            $SUPERVISOR
                .pause();
        }

        return false;
    }

    // Пропуск задания если интерфейс не готов к работе,
    // либо занят другой задачей:
    if (!$app.isAppBootstrapFinished || $app.isAppBusy)
    {
        return false;
    }

    // Пропуск задания, если оно не должно
    // работать в режиме техобслуживания:
    if ($app.isMaintenanceActive && (true !== task.workOnMaintenance))
    {
        return false;
    }

    // Пропуск задания если оно не должно работать
    // на кассе с некорректными настройками:
    if ($workspace.isWorkspaceSettingsIncorrect && (true !== task.workOnSettingsError))
    {
        return false;
    }

    // Задание будет выполняться только если
    // рабочее место авторизовано:
    if (
        task.authRequired &&
        (!$workspace.isWorkspaceAuthorized || $workspace.isWorkspaceAuthLost)
    )
    {
        return false;
    }

    // Задание будет выполняться только если
    // на рабочем месте есть авторизованный кассир:
    if (task.cashierRequired && !$workspace.isCashierLoggedIn)
    {
        return false;
    }

    // Задание будет выполняться только если
    // у кассира открыта рабочая смена:
    if (task.shiftRequired && (!$shift.isActive || $shift.isDurationExceed24Hours))
    {
        return false;
    }

    // Если задан список модулей,
    // необходимый для выполнения задания:
    if (Array.isArray(task.requiredModules))
    {
        // То рабочее место должно иметь доступ
        // ко всем перечисленным модулям:
        for (const module of task.requiredModules) {
            if ($workspace.hasAccessFor(module)) {
                continue;
            }

            return false;
        }
    }

    // Если задан список локалей, с которыми может работать задание,
    // то проверяется наличие локали интерфейса в указанном в задании списке:
    if (
        Array.isArray(task.allowedLocales) &&
        !task.allowedLocales.includes($app.locale)
    )
    {
        return false;
    }

    try
    {
        const {
            $container
        } = useAppContainer();

        // Запуск обработки задания:
        await task
            .payload($container);
    }

    catch (throwable)
    {
        if (throwable instanceof Exception && throwable.isFatal) {
            // Остановка супервизора, если
            // было перехвачено критическое исключение:
            if ($SUPERVISOR.isIntervalActive())
            {
                $SUPERVISOR
                    .pause();
            }
        }

        throw throwable;
    }
}