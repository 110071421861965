import {Container}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";
import Supervisor
    from "~lib/supervisor/Supervisor";

import DefinedAppIntervalTasksList
    from "~app/Task/AppIntervalTasks.list";
import {IntervalTaskContract}
    from "~app/Task/Contracts/IntervalTask.contract";
import IntervalTasksHandler
    from "~app/Other/Supervisor/IntervalTasksHandler";
import usePiniaState
    from "~interaction/Store/usePiniaState";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

/**
 * Регистрирует в контейнере функционал для обработки
 * и выполнения одиночных или повторяющихся задач.
 */
export default class SupervisorProvider
    extends ServiceProvider {

    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('SupervisorProvider');
    }

    /**
     * @inheritDoc
     */
    register(app: Container): void
    {
        const {
            $app,
        } = usePiniaState();

        if ($app.isAppStartFailed) {
            // Логика супервизора не запускается, если
            // интерфейс инициализировался в режиме http ошибки:
            return;
        }

        // Получение списка всех интервальных задач,
        // актуальных для текущего экземпляра приложения:
        const intervalTasks: IntervalTaskContract[] = this
            .intervalTaskList();

        // Инициализация супервизора,
        // следящего за всеми заданиями кассы:
        app.bind<Supervisor>(Supervisor.SONAR)
            .toConstantValue(new Supervisor({
                interval: {
                    intervalMs:      150,
                    intervalTasks:   intervalTasks,
                    intervalHandler: IntervalTasksHandler
                }
            }));
    }

    /**
     * Список задач, выполняемых через заданный интервал.
     */
    private intervalTaskList(): IntervalTaskContract[]
    {
        const {
            $app
        } = usePiniaState();

        const tasks: IntervalTaskContract[] = [];

        const appInterface = $app
            .parameters('interface');

        if (null === appInterface) {
            throw new RuntimeException({
                isFatal: true,
                code:    EposCoreErrors.WORKSPACE_LOGIC_ERROR,
                message: 'Application interface type must be defined to setup supervisor logic.'
            });
        }

        for (const task of DefinedAppIntervalTasksList)
        {
            // Тип интерфейса не может поменяться после запуска приложения,
            //  потому проверку необходимо выполнить разово:
            if (
                'undefined' !== typeof task.requiredInterface &&
                null !== $app.parameters('interface')    &&
                !task.requiredInterface.includes(appInterface)
            )
            {
                // Пропуск задания, если оно не должно работать
                // с текущим типом интерфейса:
                continue;
            }

            tasks.push(task);
        }

        return tasks;
    }
}