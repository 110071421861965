<template>
  <!--
      -balance-limit--is-normal - дефолтное состояние, зелёная шкала
      -balance-limit--is-caution - второе состояние, жёлтая шкала
      -balance-limit--is-alert - третье состояние, оранжевая шкала

      balance-limit--is-full -- данный модификатор нужен в случае, когда шкала полностью заполнена,
      то есть percentage === 100. Это нужно для соответствия дизайну, когда
      у правого края шкалы скругление появляется только при полном заполнении

      у элемента balance-limit__scale указывается ширина в процентах, которая
      отрисовывает заполнение, показывая "прогресс"
  -->
  <div
      :class="[
          'balance-limit--is-' + status,
          { 'balance-limit--is-full': 100 === percentage }
      ]"
      class="balance-limit">
    <div
        :style="`width: ${percentage}%`"
        class="balance-limit__scale">
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'BalanceLimit';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
  ref,
  defineProps, PropType,
} from "@vue/runtime-core";
import {LimitStatuses}
  from "~interaction/Shift/useBalanceLimit";

/** IMPORTED VUE TEMPLATES: */

/** COMPONENT LOGIC: */

type ComponentProps = {
  status:     LimitStatuses,
  percentage: number,
};

const props = withDefaults(defineProps<ComponentProps>(), {
  status:     'normal',
  percentage: 0,
});
</script>

<style lang="scss" scoped>
  @import "~scss-shared/components/balance-limit";
</style>
