import {defineStore}  from "pinia";
import {
    computed,
    nextTick,
    ref
} from "@vue/runtime-core";

import {Strings}
    from "~lib/helpers";
import ApplicationState
    from "~app/Storage/Application.state";
import {Notification, StatusNotification}
    from "~app/Storage/contracts/Notification";

export const NotificationsStoreKey: symbol = Symbol.for('NotificationsState');

type AddNotificationPayload = Partial<Notification> & Required<Pick<Notification, 'title' | 'description'>>;

const NotificationsState = defineStore(NotificationsStoreKey.toString(), () =>
{
    /**
     * Состояние подключения к БД
     */
    const dbStatus = ref<StatusNotification|null>(null);
    /**
     * Состояние соединения
     */
    const connectionStatus = ref<StatusNotification|null>(null)

    /**
     * "Linux семафор" для отображения одиночных уведомлений.
     */
    const notificationBeacon = ref<boolean>(false);

    /**
     * Список уведомлений для кассира;
     */
    const list = ref<Notification[]>([]);

    /**
     * Проверка наличия доступных к показу уведомлений.
     */
    const isEmpty = computed<boolean>(
        () => list.value.length <= 0
    );

    /**
     * Добавляет новое уведомление в список.
     */
    function add(
        payload: AddNotificationPayload|AddNotificationPayload[]
    ): void
    {
        if (!Array.isArray(payload)) {
            payload = [payload];
        }

        const timestamp = (ApplicationState())
            .getWorkspaceTimestamp();

        for (const notification of payload) {
            list.value.unshift({
                id:       notification.id       ?? `${Strings.random(6)}-${timestamp}`,
                severity: notification.severity ?? 'info',
                //time:     0,//notification.time     ?? timestamp,
                at:       0,

                icon:        notification.icon  ?? 'email',
                title:       notification.title,
                description: notification.description,
            });
        }
    }

    /**
     * Ищет и, в случае успеха, удаляет уведомление,
     * по указанному идентификатору.
     */
    function remove(id: string|string[]): void
    {
        if (!Array.isArray(id)) {
            id = [id];
        }

        for (const idOfNotify of id) {
            list.value.splice(
                list
                    .value
                    .findIndex((notify: Notification) => idOfNotify === notify.id),
                1
            );
        }
    }

    /**
     * Функционал для уведомления всех заинтересованных "слушателей",
     * о том что был активирован один из одиночных блоков уведомлений,
     * и другие необходимо закрыть.
     */
    async function triggerNotificationBeacon(): Promise<void>
    {
        notificationBeacon.value = true;

        await nextTick(() => {
            notificationBeacon.value = false;
        });
    }

    /**
     * Очищает весь список уведомлений.
     */
    function flush(): void
    {
        list.value = [];
    }

    return {
        list,
        isEmpty,
        dbStatus,
        connectionStatus,
        notificationBeacon,

        add,
        remove,
        flush,
        triggerNotificationBeacon,
    };
})

export default NotificationsState;