import {Container, interfaces}
    from "inversify";
import {RuntimeExceptionHandlerFactory}
    from "~lib/Exception/Contracts/RuntimeExceptionHandlerFactory";
import ServiceProvider
    from "~ioc/ServiceProvider";

import {AppExceptionHandler}
    from "~app/Epos/Enum/SystemSonar";
import RuntimeExceptionHandler
    from "~app/Exception/RuntimeExceptionHandler";

/**
 * Регистрация сервиса по обработке ошибок, возникающих
 * во время работы приложения.
 */
export default class RuntimeExceptionHandlerProvider
    extends ServiceProvider {

    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol
            .for('RuntimeExceptionHandlerProvider');
    }

    /**
     * @inheritDoc
     *
     * @param app
     */
    register(app: Container): void
    {
        app.bind<RuntimeExceptionHandlerFactory>(AppExceptionHandler)
            .toDynamicValue((
                context: interfaces.Context
            ) =>
            {
                return (
                    throwable: unknown
                ) => new RuntimeExceptionHandler(throwable);
            });

        return;
    }
}