<template>
  <div>[- PRELOADER -]</div>
</template>

<script lang="ts">
const CMP_NAME = 'Preloader.window';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">

</script>