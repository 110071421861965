import {injectable}
    from "inversify";

import RuntimeException
    from "~app/Exception/Runtime.exception";
import {EposCoreErrors}
    from "~app/Epos/EposCore.errors";

export type PathList = {
    [index: string]: {
        as:     string,
        method: string,
        uri:    string
    }
};

@injectable()
export default class RoutesPool {

    public static SONAR: symbol = Symbol
        .for('RoutesPool');

    /**
     * Список URI маршрутов, доступных приложению.
     */
    protected readonly list: PathList;

    /**
     * @param payload
     */
    constructor(
        payload: PathList
    )
    {
        this.list = payload;
    }

    /**
     * Возвращает URL соответствующий запрошенному
     * именованному маршруту.
     *
     * В случае если запрошенного маршрута не существует - будет
     * выброшено исключение.
     *
     * @param name
     *
     * @return string
     * @throws UndefinedRouteException
     */
    public route(
        name: string
    ): string
    {
        if (!this.list.hasOwnProperty(name)) {
            throw new RuntimeException({
                code:    EposCoreErrors.INCORRECT_ROUTE_NAME,
                isFatal: true,
                message: `Routing error. Path for rout name '${name}' is not exists.`,
            });
        }

        return this.list[name].uri;
    }
}