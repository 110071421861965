import usePiniaState
    from "~interaction/Store/usePiniaState";

export default class Clock {

    /**
     * Метод для итерационной проверки и обновления информации
     * о времени на кассе, и продолжительности смены кассира.
     */
    public static mainTimersHandler(): void
    {
        const {
            $app,
            $workspace,
            $shift,
        } = usePiniaState();

        // ВНИМАНИЕ!
        // Задача: не дергать пере-отрисовку, если реальные
        // отображаемые кассиру данные не изменились.

        const timestamp = $app
            .getWorkspaceTimestamp();

        const [
            day,
            time,
            date
        ] = $workspace
            .timeInWorkspaceTz(timestamp, 'EEE|HH:mm|dd LLL yyyy')
            .split('|');

        // проверка необходимости обновления
        // отображаемых часов:
        if (
            null == $app.clock         ||
            ($app.clock.day  !== day)  ||
            ($app.clock.time !== time) ||
            ($app.clock.date !== date)
        ) {
            $app.updateClock({
                day:  day,
                time: time,
                date: date,
            });
        }

        const isShiftActive = $shift.isActive;

        // отключение счетчика смены, если она неактивна:
        if (!isShiftActive && (null !== $shift.clock)) {
            $shift.updateClock(null);
        }
        // проверка необходимости обновления
        // информации о продолжительности смены:
        if (isShiftActive) {
            const duration = $shift.duration() ?? 0;
            const hours    = Math.floor(duration / 3600);
            const minutes  = Math.floor(duration / 60) % 60;

            // выбор метки продолжительности смены:
            const $chooseDurationLabel = () => {
                if (!isShiftActive) {
                    return 'disabled';
                }
                if (duration >= 72000) {
                    // 20 часов
                    return 'exceed';
                }
                if (duration >= 36000) {
                    // 10 часов
                    return 'warn';
                }
                return 'active';
            };

            const timer = [hours, minutes]
                .map(v => v < 10 ? "0" + v : v)
                .filter((v,i) => v !== "00" || i >= 0)
                .join(":");

            if (timer !== $shift.clock?.duration) {
                $shift.updateClock({
                    label:    $chooseDurationLabel(),
                    duration: timer
                });
            }
        }
    }
}