import {Model}
    from "~lib/eloquent/Model";
import {WorkspaceSystemParameters}
    from "~app/Epos/Contracts/Epos.types";

export const ModelName = Symbol
    .for('WorkspaceSystemParameters');

/**
 * Модель системных параметров, относящихся непосредственно к рабочему месту, и
 * не синхронизирующихся с виртуальной кассой напрямую.
 */
export default class Workspace
    extends Model<WorkspaceSystemParameters> {

    protected isWorkspaceInitFinished: boolean = false;
    protected isWorkspaceAuthLost:    boolean  = false;
    protected isAuthClearRequested:   boolean  = false;

    protected isCashierSessionBroken:  boolean = false;
    protected isCashierSessionExpired: boolean = false;

    protected syncInterval:     number = 15;
    protected serverTimeAtSync: number = 0;
    protected clientTimeAtSync: number = 0;

    /**
     * @see Model.init
     *
     * @param feed
     */
    constructor(
        feed: Partial<WorkspaceSystemParameters> | null
    )
    {
        super();

        this.init(
            feed
        );
    }

    /**
     * @inheritDoc
     */
    public modelName(): symbol
    {
        return ModelName;
    }
}