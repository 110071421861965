/**
 * Список идентификаторов, определяющих
 * тип структуры ставки.
 *
 * 0 - Одиночная ставка
 * 1 - Экспресс ставка (аккумулятор)
 * 2 - Система
 * 3 - Цепочка
 * 4 - Мульти-система
 * 5 - Мульти-цепочка
 * 6 - Антиэкспресс
 * 7 - Lucky
 * 8 - Патент
 * 9 - Конструктор
 */
export enum BetType {
    Single      = 0,
    Express     = 1,
    System      = 2,
    Chain       = 3,
    MultiSystem = 4,
    MultiChain  = 5,
    AntiExpress = 6,
    Lucky       = 7,
    Patent      = 8,
    Builder     = 9,
}