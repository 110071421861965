import Exception
    from "~lib/Exception/Exception";
import {AppCoreErrors}
    from "~lib/Exception/Enum/AppCore.errors";

type ConstructorType = {
    message:   string|null;
    at?:       number;
    previous?: any;
};

/**
 * Исключение, которое может быть выброшено
 * во время работы супервизора.
 *
 * Всегда приводит к аварийному завершению работы приложения.
 */
export default class SupervisorException
    extends Exception

    implements ConstructorType {
    constructor(payload: ConstructorType)
    {
        super({
            isFatal:  true,
            code:     AppCoreErrors.SUPERVISOR_ERROR,
            type:     'Exception',
            name:     'SupervisorException',
            message:  payload.message,

            at:       payload.at,
            label:    'SUPERVISOR-ERROR',
            previous: payload.previous,
        });
    }
}