export default class Binary {

    /**
     * TODO: описание
     *
     * @param payload
     */
    public static uint8ArrayToHex(payload: Uint8Array): string
    {
        const byteToHex = [];

        for (let n = 0; n <= 0xff; ++n) {
            const hexOctet = n.toString(16).padStart(2, "0");
            byteToHex.push(hexOctet);
        }

        // new Array(buff.length) is even faster (preallocates necessary array size),
        // then use hexOctets[i] instead of .push()
        const hexOctets = [];
        for (let i = 0; i < payload.length; ++i)
            hexOctets.push(byteToHex[payload[i]]);

        return hexOctets.join("");
    }

    /**
     * TODO: проверить и описание
     *
     * @param payload
     */
    public static hexToUint8Array(payload: string): Uint8Array
    {
        const data = payload.match(/.{1,2}/g)?.map((byte => parseInt(byte, 16)));
        if (!data || null === data.length) {
            return new Uint8Array();
        }

        return new Uint8Array(data as Iterable<number>);
    }

    /**
     * Преобразует Base64 кодированную строку
     * в "Uint8Array".
     *
     * @param base64
     */
    public static base64ToBinary(base64: string): Uint8Array
    {
        return Uint8Array
            .from(
                atob(base64.replace(/^data[^,]+,/,'')),
                v => v.charCodeAt(0)
            );
    }

    /**
     * Возвращает количество байт в строке.
     *
     * @param payload
     */
    public static strByteSize(payload: string): number
    {
        return new Blob([payload]).size;
    }
}