import {SvgList as FlagIco}
    from "~svg/colorful/flags/SvgList";
import {CountryCodeTypes}
    from "~app/Betting/Contracts/CountryCode.types";
/**
 * Маппинг для соотнесения Alpha-2 кода страны
 * с svg иконкой флага.
 *
 * Ключ     - двухзначный код страны
 * Значение - название иконки в спрайте
 */
const CountryToSvgMap: {[key: string]: FlagIco} = {
        "AD": "AD",
        "AE": "AE",
        "AF": "AF",
        "AG": "AG",
        "AI": "AI",
        "AL": "AL",
        "AM": "AM",
        "AN": "AN",
        "AO": "AO",
        "AR": "AR",
        "AT": "AT",
        "AU": "AU",
        "AW": "AW",
        "AZ": "AZ",
        "BA": "BA",
        "BB": "BB",
        "BD": "BD",
        "BE": "BE",
        "BF": "BF",
        "BG": "BG",
        "BH": "BH",
        "BI": "BI",
        "BJ": "BJ",
        "BM": "BM",
        "BN": "BN",
        "BO": "BO",
        "BR": "BR",
        "BS": "BS",
        "BT": "BT",
        "BW": "BW",
        "BY": "BY",
        "BZ": "BZ",
        "CA": "CA",
        "CD": "CD",
        "CF": "CF",
        "CG": "CG",
        "CH": "CH",
        "CI": "CI",
        "CL": "CL",
        "CM": "CM",
        "CN": "CN",
        "CO": "CO",
        "CR": "CR",
        "CU": "CU",
        "CV": "CV",
        "CY": "CY",
        "CZ": "CZ",
        "DE": "DE",
        "DJ": "DJ",
        "DK": "DK",
        "DM": "DM",
        "DO": "DO",
        "DZ": "DZ",
        "EC": "EC",
        "EE": "EE",
        "EG": "EG",
        "ER": "ER",
        "ES": "ES",
        "ET": "ET",
        "EU": "EU",
        "FI": "FI",
        "FJ": "FJ",
        "FM": "FM",
        "FR": "FR",
        "GA": "GA",
        "GB": "GB",
        "GD": "GD",
        "GE": "GE",
        "GG": "GG",
        "GH": "GH",
        "GI": "GI",
        "GM": "GM",
        "GN": "GN",
        "GQ": "GQ",
        "GR": "GR",
        "GT": "GT",
        "GW": "GW",
        "GY": "GY",
        "HK": "HK",
        "HN": "HN",
        "HR": "HR",
        "HT": "HT",
        "HU": "HU",
        "ID": "ID",
        "IE": "IE",
        "IL": "IL",
        "IM": "IM",
        "IN": "IN",
        "IQ": "IQ",
        "IR": "IR",
        "IS": "IS",
        "IT": "IT",
        "JE": "JE",
        "JM": "JM",
        "JO": "JO",
        "JP": "JP",
        "KE": "KE",
        "KG": "KG",
        "KH": "KH",
        "KM": "KM",
        "KN": "KN",
        "KP": "KP",
        "KR": "KR",
        "KW": "KW",
        "KY": "KY",
        "KZ": "KZ",
        "LA": "LA",
        "LB": "LB",
        "LC": "LC",
        "LI": "LI",
        "LK": "LK",
        "LR": "LR",
        "LS": "LS",
        "LT": "LT",
        "LU": "LU",
        "LV": "LV",
        "LY": "LY",
        "MA": "MA",
        "MC": "MC",
        "MD": "MD",
        "ME": "ME",
        "MG": "MG",
        "MK": "MK",
        "ML": "ML",
        "MM": "MM",
        "MN": "MN",
        "MO": "MO",
        "MR": "MR",
        "MS": "MS",
        "MT": "MT",
        "MU": "MU",
        "MV": "MV",
        "MW": "MW",
        "MX": "MX",
        "MY": "MY",
        "MZ": "MZ",
        "NA": "NA",
        "NE": "NE",
        "NG": "NG",
        "NI": "NI",
        "NL": "NL",
        "NO": "NO",
        "NP": "NP",
        "NZ": "NZ",
        "OM": "OM",
        "PA": "PA",
        "PE": "PE",
        "PF": "PF",
        "PG": "PG",
        "PH": "PH",
        "PK": "PK",
        "PL": "PL",
        "PR": "PR",
        "PT": "PT",
        "PW": "PW",
        "PY": "PY",
        "QA": "QA",
        "RO": "RO",
        "RS": "RS",
        "RU": "RU",
        "RW": "RW",
        "SA": "SA",
        "SB": "SB",
        "SC": "SC",
        "SD": "SD",
        "SE": "SE",
        "SG": "SG",
        "SH": "SH",
        "SI": "SI",
        "SK": "SK",
        "SL": "SL",
        "SM": "SM",
        "SN": "SN",
        "SO": "SO",
        "SR": "SR",
        "ST": "ST",
        "SV": "SV",
        "SY": "SY",
        "SZ": "SZ",
        "TC": "TC",
        "TD": "TD",
        "TG": "TG",
        "TH": "TH",
        "TJ": "TJ",
        "TL": "TL",
        "TM": "TM",
        "TN": "TN",
        "TO": "TO",
        "TR": "TR",
        "TT": "TT",
        "TW": "TW",
        "TZ": "TZ",
        "UA": "UA",
        "UG": "UG",
        "US": "US",
        "UY": "UY",
        "UZ": "UZ",
        "VC": "VC",
        "VE": "VE",
        "VG": "VG",
        "VN": "VN",
        "VU": "VU",
        "WS": "WS",
        "YE": "YE",
        "ZA": "ZA",
        "ZM": "ZM",
        "ZW": "ZW",
        //"AX": "",
        //"CAF: "",
        //"CEU": "",
        //"CNA": "",
        //"COC": "",
        //"CSA": "",
        //"FK": "",
        //"Special": "",
        //"WW": "",
    };

export default CountryToSvgMap;