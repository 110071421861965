<template>
  <div class="workplace-main__inner">
    <guest-window-ico ico="lock" size="size-custom-95" sprite="cleared" />
    <h1
      v-html="$T.composite<string[]>('win-workspace-auth-broken-header').join('<br/>')"
      class="workplace-main__title"
    ></h1>
    <div class="workplace-main__description">
        <p
          v-for="text in $T.composite<string[]>('win-workspace-auth-broken-desc')"
          class="workplace-main__text">{{ text }}</p>
    </div>
    <div class="workplace-main__buttons">
      <Button
          @click="$workspaceModule.submitWorkspaceAuthClear()"
          size="size-l"
          :is-disabled="$app.isAppBusy"
          :is-processing="$app.isAppBusy"
          :text="$T.text('interface-auth-lost-reset-btn')"
          label-style="uppercase"
          class="workplace-main__button"
      />
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'WorkspaceAuthBroken.window';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useWorkspaceModule
    from "~app/IoC/useWorkspaceModule";

/** IMPORTED VUE TEMPLATES: */
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();
const {
    $app
} = usePiniaState();

const {
    $workspaceModule
} = useWorkspaceModule();
</script>