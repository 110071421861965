import VueComponentType
    from "~lib/render/VueComponent";
import Library
    from "~lib/translate/Library";
import {WinListDefinition}
    from "~app/Providers/Windows/WindowsManager.provider";

import WindowSwapPreloader
    from "~tpl-mobile/workspace/Tech/WindowSwapPreloader.vue";
import WindowSwapError
    from "~tpl-mobile/workspace/Tech/WindowSwapError.vue";

export const WM_TAG_SHIFT_OVERVIEW  = 'WM-shift-overview';
export const WM_TAG_TOP_BAR_WINDOWS = 'WM-top-bar-windows';

export default function ($T: Library): WinListDefinition {
    return {
        loadingCmp: WindowSwapPreloader,
        errorCmp:   WindowSwapError,
        list:       {
            shift: {
                tag:  WM_TAG_SHIFT_OVERVIEW,
                data: {
                    ico:  'continue',
                    name: $T.text('shift-control-title'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-mobile/workspace/Shift/Shift.vue') as unknown as Promise<VueComponentType>;
                }
            },
            accounting: {
                tag:  WM_TAG_TOP_BAR_WINDOWS,
                data: {
                    ico: 'dashboard',
                    name: $T.text('main-menu-shift-overview'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-mobile/workspace/Accounting/Accounting.vue') as unknown as Promise<VueComponentType>;
                }
            },
            'customer-deposit': {
                tag:  WM_TAG_TOP_BAR_WINDOWS,
                data: {
                    ico: 'bank--slot-alt',
                    name: $T.text('main-menu-customer-deposit'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-mobile/workspace/Customer/Deposit.vue') as unknown as Promise<VueComponentType>;
                }
            },
            'customer-withdrawal': {
                tag:  WM_TAG_TOP_BAR_WINDOWS,
                data: {
                    ico: 'bank-alt',
                    name: $T.text('main-menu-customer-withdrawal'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-mobile/workspace/Customer/Withdrawal.vue') as unknown as Promise<VueComponentType>;
                }
            },
            'transactions-history': {
                tag:  WM_TAG_TOP_BAR_WINDOWS,
                data: {
                    ico: 'event--schedule',
                    name: $T.text('shift-overview-table-title'),
                    showInHotMenu: true,
                },
                vue: function() {
                    return import('~tpl-mobile/workspace/TransactionHistory/History.vue') as unknown as Promise<VueComponentType>;
                }
            },
        }
    };
};