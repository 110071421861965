<template>
  <svg-elem
    v-on:mousedown="checkEvent($event)"
    v-on:mouseup="checkEvent($event)"
    v-on:mouseleave="checkEvent($event)"
    v-on:touchstart="checkEvent($event)"
    v-on:touchend="checkEvent($event)"
    v-on:touchcancel="checkEvent($event)"
    v-on:touchmove="checkEvent($event)"

    is-mirrored
    :to="ico"
    :sprite="sprite"
    :size="size"

    class="workplace__icon workplace-main__icon no-select"
    :class="{'workplace__icon-flicker workplace-main__icon-flicker': icoFlickering}"
  />
</template>

<script lang="ts">
const CMP_NAME = 'GuestWindowIco';
export default {
    name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
    defineProps,
    withDefaults,
    computed,
    defineEmits,
    watch,
} from "@vue/runtime-core";

import useWorkspaceModule
    from "~app/IoC/useWorkspaceModule";
import useHoldMouseButtons
    from "~interaction/useHoldMouseButtons";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
    from "~tpl-global/svg/SvgElem.vue";
import {SvgList}
    from "~tpl-global/svg/SvgList";
import {SvgSize}
    from "~tpl-global/svg/SvgSize";

/** COMPONENT LOGIC: */
type ComponentProps = {
    sprite: "cleared" | "colorful";
    ico:    SvgList;
    size:   SvgSize;

    isFlickerAllowed?: boolean;
}

const props = withDefaults(
    defineProps<ComponentProps>(),
    {
        isFlickerAllowed: true,
    }
);

/**
 * Список событий, возникающий при проверке "зажима".
 */
const emitter = defineEmits([
    'onHoldButtonAborted',
    'onHoldButtonPassed'
]);

/**
 * Возвращает "true", если необходимо
 * отобразить мигание иконки.
 */
const icoFlickering = computed<boolean>(() => {
    return props.isFlickerAllowed &&
        $workspace.isWorkspaceAuthorized &&
        fillPercentage.value > 1;
});

const {
    $workspace
} = usePiniaState();

const {
    fillPercentage,
    isHoldPassed,

    missClickCheck,
} = useHoldMouseButtons({
    holdMs:       6000,
    transitionMs: 20,
}, emitter);

/**
 * Проверка наличия сброса
 * авторизации рабочего места
 *
 * @param event
 */
const checkEvent = async (event: Event) =>
{
    if (!props.isFlickerAllowed)
    {
        return;
    }

    missClickCheck(event);
}

watch(() => isHoldPassed.value, (actual, old) =>
{
    if (!actual || !props.isFlickerAllowed)
    {
        return;
    }

    const {
        $workspace
    } = usePiniaState();

    if (!$workspace.isWorkspaceAuthorized)
    {
        return;
    }

    const {
        $workspaceModule
    } = useWorkspaceModule();

    $workspaceModule
        .switchInterfaceToAuthClearMode();
});
</script>