<template>
  <div class="page__main main page__main--not-auth">
    <div class="main__workplace workplace">
      <div class="workplace__main workplace-main">
        <div class="workplace-main__header">
          <span class="workplace-main__version">{{ $T.text('version-info-short') }}: {{ $app.parameters('appVersion') }}</span>
        </div>
        <div class="workplace-main__inner">
          <guest-window-ico
              ico="face--dissatisfied"
              sprite="cleared"
              size="size-custom-95"
              :is-flicker-allowed="false"
          />
          <h1 class="workplace-main__title">{{ $T.text('interface-fatal-header') }}</h1>
          <div
            v-for="text in $T.composite('interface-http-fatal-description')"
            class="workplace-main__description"
          >
            <p class="workplace-main__text">{{ text }}</p>
          </div>
          <form class="workplace-main__form workplace-main-form">
            <Button
                size="size-l"
                label-style="uppercase"
                class="workplace-main__button"
                :text="$T.text('interface-fatal-dtn-download-log')"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'ApplicationCrash';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";

/** IMPORTED VUE TEMPLATES: */
import Button
  from "~tpl-global/Buttons/ButtonDefault/Button.vue";
import GuestWindowIco
    from "~tpl-global/Guest/GuestWindowIco.vue";

/** COMPONENT LOGIC: */
const {
    $T
} = useInterfaceTexts();

const {
    $app
} = usePiniaState();
</script>