/**
 * Список локалей, на которых возможна
 * работа функционала беттинга.
 *
 * TODO: локали сделать энумом
 */
const AVAILABLE_BETTING_LOCALES: string[] = [
    'ru_RU', 'ar_AE', 'fr_FR', 'uz_UZ', 'en_GB', 'bn_BD', 'so_SO',
];

export default AVAILABLE_BETTING_LOCALES;