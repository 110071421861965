import {Container, interfaces}
    from "inversify";
import ServiceProvider
    from "~ioc/ServiceProvider";
import WindowsManager, {WindowsSetup}
    from "~lib/windows/WindowsManager";
import VueComponentType
    from "~lib/render/VueComponent";

import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import EposDesktopWindows
    from "~app/Providers/Windows/EposDesktopWindows";
import EposMobileWindows
    from "~app/Providers/Windows/EposMobileWindows";
import RuntimeException
    from "~app/Exception/Runtime.exception";

// описание структуры дополнительных данных
// для каждого из окон приложения:
export type WinSettings = {
    ico:          string,
    name:         string,
    description?: string,
    showInHotMenu: boolean
};

// т.к. приложение разбито на пакеты, под разные
// типы интерфейса, то тип описывает необходимый набор
// настроек используемых окон для каждого из пакетов:
export type WinListDefinition = {
    loadingCmp: VueComponentType;
    errorCmp:   VueComponentType;
    list:       WindowsSetup<WinSettings>;
}

export const WM              = Symbol.for('WindowsManagerInstance');
export const WM_TAG_LEFT     = 'WM-left-menu-tag';
export const WM_TAG_CUSTOMER = 'WM-customer-section';

export default class WindowsManagerProvider extends ServiceProvider {

    /**
     * @inheritDoc
     */
    name(): symbol
    {
        return Symbol.for('WindowsManagerProvider');
    }

    /**
     * @inheritDoc
     *
     * @param app
     */
    register(app: Container): void
    {
        // т.к. менеджер переключения окон использует информацию,
        // недоступную на момент инициализации провайдеров (например: отсутствует
        // полная библиотека текстов), то инициализируем его только при первом запросе,
        // и после сохраняем непосредственно в контейнере:
        app
            .bind<WindowsManager<WinSettings>>(WM)
            .toDynamicValue((context: interfaces.Context) => {

                const {
                    $T,
                } = useInterfaceTexts();

                const resolvedWM: symbol = Symbol
                    .for('resolved-wm');

                if (context.container.isBound(resolvedWM)) {
                    return context
                        .container
                        .get<WindowsManager<WinSettings>>(resolvedWM);
                }

                let bundleSettings: WinListDefinition;

                const {
                    $app
                } = usePiniaState();

                // TODO: асинхронная загрузка
                switch ($app.parameters('interface')) {
                    case 'epos-desktop':
                        bundleSettings = EposDesktopWindows($T);
                        break;
                    case 'epos-mobile':
                        bundleSettings = EposMobileWindows($T);
                        break;
                    default:
                        throw new RuntimeException({
                            code:    0,
                            isFatal: true,
                            message: `Unknown interface type "${$app.parameters('interface')}"`
                        });
                }

                context
                    .container
                    .bind<WindowsManager<WinSettings>>(resolvedWM)
                    .toConstantValue(
                        new WindowsManager<WinSettings>({
                            preloaderWindow: bundleSettings.loadingCmp,
                            errorWindow:     bundleSettings.errorCmp,
                            defaultWindow:   'shift',
                            windows:         bundleSettings.list,
                        })
                    );

                return context
                    .container
                    .get<WindowsManager<WinSettings>>(resolvedWM);
            });
    }
}