/**
 * Список вариантов оплаты проводимой ставки.
 *
 * Доступность вариантов оплаты зависит от
 * некоторых настроек кассы и параметров самой ставки.
 */
export enum BetPaymentType
{
    Cash           = 0,
    AccountBalance = 1,
}