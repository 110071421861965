import {reactive, watch,}
    from "@vue/runtime-core";
import Helpers
    from "~lib/helpers";

import {AppInterfaceType}
    from "~app/Epos/Enum/AppInterfaceType";
import useEposModule
    from "~app/IoC/useEposModule";
import useInterfaceTexts
    from "~app/IoC/useInterfaceTexts";
import usePiniaState
    from "~interaction/Store/usePiniaState";
import useModalMessage
    from "~interaction/useModalMessage";

import {SvgList}
    from "~tpl-global/svg/SvgList";

import Exception
    from "~lib/Exception/Exception";

export default function ()
{
    const {
        $T
    } = useInterfaceTexts();

    const {
        $app,
        $shift,
        $modal,
    } = usePiniaState();

    const {
        getError,
    } = useModalMessage();

    type CashierMenu = {
        id:       string,
        ico:      SvgList,
        name:     string,
        isLocked: boolean,
        handler:  () => void,
    };

    // список пунктов меню кассира:
    const menuMobile = reactive<Record<CashierMenu['id'], CashierMenu>>({
        /*{
          id:       'settings',
          ico:      'settings',
          name:     $T.text('cashier-menu-settings-name'),
          isLocked: false,
          handler:  () => null,
        },
        {
          id:       'afk-mode',
          ico:      'pedestrian',
          name:     $T.text('cashier-menu-afk-mode'),
          isLocked: false,
          handler:  () => null,
        },*/
        'shift-control': {
            id:       'shift',
            ico:      'continue',
            name:     $T.text('shift-control-title'),
            isLocked: false,
            handler:  () => null,
        },
        'log-out': {
            id:       'log-out',
            ico:      'exit',
            name:     $T.text('cashier-menu-log-out'),
            isLocked: $shift.isActive,
            handler:  () => cashierLogOut(),
        },
    });

    // список пунктов меню кассира:
    const menuDesktop = reactive<Record<CashierMenu['id'], CashierMenu>>({
        /*{
          id:       'settings',
          ico:      'settings',
          name:     $T.text('cashier-menu-settings-name'),
          isLocked: false,
          handler:  () => null,
        },
        {
          id:       'afk-mode',
          ico:      'pedestrian',
          name:     $T.text('cashier-menu-afk-mode'),
          isLocked: false,
          handler:  () => null,
        },*/
        /*        'shift-control': {
                    id:       'shift',
                    ico:      'continue',
                    name:     $T.text('shift-control-title'),
                    isLocked: false,
                    handler:  () => null,
                },*/
        'log-out': {
            id:       'log-out',
            ico:      'exit',
            name:     $T.text('cashier-menu-log-out'),
            isLocked: $shift.isActive,
            handler:  () => cashierLogOut(),
        },
    });

    const menu: Record<string, CashierMenu> = (
        $app.parameters('interface') === AppInterfaceType.EPOS_DESKTOP
    )
        ? menuDesktop
        : menuMobile;

    //TODO: временное решение, переделать как в логике информации о смене
    // временное решение, иначе объект не будет обновляться
    // при изменении значения в хранилище:
    watch(() => $shift.isActive, (current, prev) => {
        menu['log-out'].isLocked = current;
    });

    // TODO: необходим прелоадер или модал с прелоадером пока идет обработка запроса
    // TODO: ошибки выводить в модале
    async function cashierLogOut(): Promise<void>
    {
        $modal.show({
            ico:                 'warning-round',
            title:               $T.text('cashier-log-out-processing-header'),
            message:             $T.composite<string[]>('cashier-log-out-processing-message'),
            mainBtn:             $T.text('btn-close'),
            mainBtnIsProcessing: true,
            mainBtnOnPress:      () => {},
        });

        await Helpers
            .sleep(1000);

        const {
            $eposModule
        } = useEposModule();

        await $eposModule
            .cashierLogOut().then(() =>
            {
                $modal.close();
            })
            .catch((throwable) =>
            {
                if (!(throwable instanceof Exception) || throwable.isFatal) {
                    throw throwable;
                }

                $modal.close();
                $modal.show({
                    ico:            'do-not',
                    title:          $T.text('label-rejected-action'),
                    message:        $T.composite('modal-error-message-default'),
                    mainBtn:        $T.text('btn-close'),
                    mainBtnOnPress: (): void => { $modal.close(); },
                });
            });
    }

    return {
        menu,

        cashierLogOut,
    }
}