import {SvgList as SportsSvg}
    from "~svg/cleared/sports/SvgList";
import {SvgList as FlagSvg}
    from "~svg/colorful/flags/SvgList";

export const LIVE_FEED_CACHE_KEY = Symbol
    .for('live-feed-storage-key');

export const PRE_MATCH_FEED_CACHE_KEY = Symbol
    .for('pre-match-feed-storage-key');

/**
 * Определение типа фида.
 *
 * @param live      - События, происходящие в данный момент.
 * @param pre-match - События, дата начала которых находится в будущем.
 */
export type FeedType = 'live' | 'pre-match';

/**
 * Структура, используемая для кеширования данных фида на стороне браузера,
 * содержащего список обработанных групп событий.
 *
 * @param at   - Время генерации группы.
 * @param data - Содержимое группы событий.
 */
export type EventsGroupIDBCache = {
    at:   number;
    data: EventsGroup[];
};

/**
 * Структура, используемая для кеширования данных фида, содержащего
 * список событий, принадлежащих определенной группе.
 */
export type EventsGroupContentIDBCache = {
    at:       number;
    groupKey: string;
    payload:  Event[];
};

/**
 * Структура спорта, к которому относится событие.
 *
 * @param id   - Идентификатор спорта.
 * @param name - Название спорта.
 * @param ico  - Свг иконка спорта.
 */
export type Sport = {
    id:   number;
    name: string;
    ico:  SportsSvg;
};

/**
 * Структура страны, в которой находится событие.
 *
 * @param id   - Идентификатор спорта.
 * @param name - Название спорта.
 * @param code - ISO код страны.
 */
export type Country = {
    id:   number;
    name: string;
    code: string;
};

/**
 * Структура, содержащая группу событий,
 * объединенных под одним типом фида, страной
 * и спортом.
 *
 * @param feed      - Тип фида.
 * @param key       - Ключ группы событий, представляющий собой строку, созданную из параметров группы.
 * @param id        - Глобальный идентификатор группы.
 * @param isVirtual - Содержит ли группа (только) виртуальные события.
 * @param sportId   - Идентификатор спорта, к которому относятся события группы.
 * @param countryId - Идентификатор страны, к которой относятся события группы.
 * @param sportIco  - Свг иконка спорта.
 * @param eventIco  - Свг иконка группы событий.
 * @param sportName - Название спорта, события которого представлены в группе.
 * @param name      - Название группы событий.
 * @param amount    - Количество событий в группе.
 * @param events    - Список событий группы.
 */
export type EventsGroup = {
    feed:      FeedType;
    key:       string;
    id:        number,
    isVirtual: boolean;
    sportId:   number;
    countryId: number;
    sportIco:  SportsSvg|null;
    eventIco:  FlagSvg|null;
    sportName: string;
    name:      string;
    amount:    number;
    events:    Event[];
};

/**
 * Структура конкретного события, для которого
 * можно запросить список маркетов.
 *
 * @param feed           - Фид, к которому относится событие.
 * @param groupKey       - Ключ группы, к которой относится событие.
 * @param groupName      - Название группы, к которой относится событие.
 * @param id             - Идентификатор события.
 * @param number         - Номер для быстрого поиска события.
 * @param startAt        - Дата начала события.
 * @param isSpecial      - Имеет ли событие пометку "специальное".
 * @param isEventOfDay   - Имеет ли событие пометку "событие дня".
 * @param firstOpponent  - Первый оппонент события.
 * @param secondOpponent - Второй оппонент события.
 * @param period         - Названия периода события (актуально для вложенного события).
 * @param condition      - Название условия события (актуально для вложенного события).
 * @param sportId        - Идентификатор спорта, к которому относится событие.
 * @param sportName      - Название спорта, к которому относится событие.
 * @param sportIco       - Название SVG иконки, для спорта, к которому относится событие.
 * @param isSubEvent     - Является ли событие вложенной частью другого события.
 * @param mainEventId    - Идентификатор основного события (актуально только для вложенного события).
 * @param subEvents      - Список вложенных событий (актуально только для основного события).
 */
export type Event = {
    feed:           FeedType;
    groupKey:       string;
    groupName:      string;
    id:             number;
    number:         number;
    startAt:        number;
    isSpecial:      boolean;
    isEventOfDay:   boolean;
    firstOpponent:  string|null;
    secondOpponent: string|null;
    period:         string|null;
    condition:      string|null;
    sportId:        number;
    sportName:      string;
    sportIco:       SportsSvg;
    isSubEvent:     boolean;
    mainEventId:    number|null;
    subEvents:      Event[]|null;
}

/**
 * Структура запроса на смену типа события в интерфейсе.
 *
 * @param id        - Идентификатор основного события.
 * @param number    - Номер для быстрого поиска основного события.
 * @param subId     - Идентификатор вложенного события.
 * @param subNumber - Номер для быстрого поиска вложенного события.
 */
export type SubEventChangeRequest = {
    id:        number;
    number:    number;
    subId:     number;
    subNumber: number;
};

/**
 * Структура исхода события, на которое есть
 * возможность сделать ставку.
 *
 * @param eventKey   - Ключ группы, к которой относится событие.
 * @param eventId    - Идентификатор события, к которому относится исход.
 * @param key        - Ключ исхода события.
 * @param id         - Идентификатор маркета.
 * @param groupId    - Идентификатор группы маркета.
 * @param templateId - Идентификатор шаблона для разбора маркета.
 * @param odds       - Коэффициент исхода события.
 * @param prevOdds   - Коэффициент исхода события, который был известен ранее, до обновления.
 * @param name       - Название исхода события. Отображается в маркетах или в купоне.
 * @param groupName  - Название группы, к которой относится маркет.
 * @param isLocked   - Заблокировано ли событие в настоящий момент.
 * @param parameter  - Параметр для подстановки в событие.
 * @param playerId   - Идентификатор игрока, участвующего в событии. (не путать с гостем).
 * @param playerName - Имя игрока, участвующего в событии. (не путать с гостем).
 */
export type Outcome = {
    eventKey:   string;
    eventId:    number;
    key:        string;
    id:         number;
    groupId:    number;
    templateId: number;
    odds:       number;
    prevOdds:   number|null;
    name:       string;
    groupName:  string;
    isLocked:   boolean;
    parameter:  number|null;
    playerId:   number|null;
    playerName: string|null;
};

/**
 * Структура исхода события, добавленного в проводимую ставку.
 *
 * @param at      - Время добавления в купон.
 * @param key     - Уникальный ключ выбора исхода внутри ставки.
 * @param event   - Событие, на которое делается ставка.
 * @param outcome - Исход события.
 */
export type OutcomeInBetSlip = {
    at:      number;
    key:     string;
    event:   Event;
    outcome: Outcome;
};