/**
 * Список возможных типов транзакций.
 *
 * 9  - Депозит на счет гостя.
 * 10 - Вывод со счета гостя.
 * 19 - Инкассация (пополнение).
 */
export enum TransactionType {
    BET_LIVE_ACCEPTED          = 1,
    BET_LIVE_PREMATCH_ACCEPTED = 2,
    BET_PREMATCH_ACCEPTED      = 3,
    BET_LIVE_PAID              = 4,
    BET_LIVE_PREMATCH_PAID     = 5,
    BET_PREMATCH_PAID          = 6,
    DEPOSIT                    = 9,
    WITHDRAWAL                 = 10,
    ENCASHMENT_IN              = 19,
    BET_SELL                   = 35,
}