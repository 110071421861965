import FieldMap
    from "~lib/eloquent/FieldMap";
import Arr
    from "~lib/helpers/Arr";
import ModelException
    from "~lib/eloquent/exceptions/Model.exception";
import Arrayable, {isArrayable}
    from "~lib/contracts/support/Arrayable.contract";

/**
 * Список типов данных, которые могут содержать поля модели.
 */
export type AllowedDataForModelFields = string|string[]|number|number[]|null|object|boolean|typeof Model;

/**
 * Результат преобразования содержимого модели в ассоциативный массив.
 */
export type ToArrayConvolution = {[index: string]:
        string|string[]|number|number[]|null|object|boolean};

/**
 * Каркас структуры, которую моет иметь класс, наследующий данную модель.
 */
export type ModelShapeBlueprint = {[key: string]: AllowedDataForModelFields};

/**
 * Элемент настроек автоматического преобразования
 * поля модели во вложенную модель.
 */
export type FieldAutoCastElem<T extends ModelShapeBlueprint> = {
    [key in keyof Extract<keyof T, string>]: (
        new({}?: ModelShapeBlueprint
        ) => typeof Model)
}

/**
 * Список настроек работы модели.
 */
export type ModelSettings<ModelShape extends ModelShapeBlueprint> = {
    /**
     * Настройки автоматического преобразования значения поля в модель.
     */
    fieldToModelAutoCast?: FieldAutoCastElem<ModelShape>;
};

/**
 * Модель, ограничивающая прямой доступ к своим полям для чтения и записи,
 * а так же позволяющая использовать подсветку существующих полей и их значений.
 *
 * Пример создания модели:
 *
 * type ModelShapeExample = {
 *     field1: number;
 *     field2: string|null;
 * }
 *
 * export const ModelName = Symbol
 *     .for('ExampleModel');
 *
 * export default class ExampleModel
 *     extends Model<ModelShapeExample> {
 *
 *     protected field1: number      = 0;
 *     protected field2: string|null = null;
 *
 *     constructor(
 *         feed: Partial<ModelShapeExample> | null
 *     )
 *     {
 *         super();
 *
 *         this.init(
 *             feed
 *         );
 *     }
 *
 *     public modelName(): symbol
 *     {
 *         return ModelName;
 *     }
 * }
 */
export abstract class Model<ModelShape extends ModelShapeBlueprint>
    implements
        Arrayable
{
    /**
     * Кэш списка полей, заданных для
     * текущей модели.
     *
     * ВНИМАНИЕ!
     * Количество и тип полей не может меняться
     * после создания модели.
     */
    private modelFields: Extract<keyof ModelShape, string>[] = [];

    /**
     * Список корневых моделей, которые будут автоматически преобразованы
     * в соответствующие маппингу модели, если в настоящий момент
     * поле не является указанной моделью.
     */
    private readonly fieldToModelAutoCast: FieldAutoCastElem<ModelShape>|{};

    /**
     * @param setup
     */
    protected constructor(
        setup?: ModelSettings<ModelShape>
    )
    {
        this.fieldToModelAutoCast = setup
            ?.fieldToModelAutoCast ?? {};
    }

    /**
     * Т.к. в случае минификации, либо еще каких-либо преобразований,
     * название модели может измениться, то нельзя полагаться
     * на вызов "this.constructor.name" или "static::name".
     *
     * Во избежание подобных ситуаций каждая модель, расширяющая абстрактный класс,
     * должна определить метод "name", возвращающий элемент "Symbol",
     * содержащий названия модели.
     *
     * Пример:
     * Symbol.for('ModelName')
     */
    public abstract modelName(): symbol;

    /**
     * Заполняет существующие поля модели
     * из массива-источника.
     *
     * @param feed
     */
    public fillFromArrayFeed(
        feed: Partial<ModelShape>
    ): void
    {
        if (
            'object' !== typeof feed ||
            Object.keys(feed).length < 1
        ) {
            return;
        }

        for (const field of this.fields()) {
            if ('undefined' === typeof feed[field]) {
                continue;
            }

            this.set(
                field,
                feed[field] as unknown as Extract<keyof ModelShape, string>
            );
        }
    }

    /**
     * Заполнение модели данными на основе
     * карты маппинга.
     *
     * @param map  - массив ["ключ объекта $feed" => "искомое поле модели"]
     * @param feed - данные для заполнения
     */
    public fillFromMapping(
        map:  {[key: string]: keyof ModelShapeBlueprint},
        feed: ModelShapeBlueprint
    ): void
    {
        for (const origin of Object.keys(map)) {

            const mapped: Extract<keyof ModelShape, string> = `${map[origin]}` as
                unknown as Extract<keyof ModelShape, string>;

            if ('undefined' === typeof feed[origin] || !this.hasField(mapped)) {
                continue;
            }

            this.set(
                mapped,
                feed[origin]
            );
        }
    }

    /**
     * Задает для модели значение переданного поля
     * (или вложенных полей).
     *
     * Пример:
     * .set('a', 0)
     * .set('a.b.c', 1)
     *
     * @param field   - Поле (или вложенное поле), в которое ведется запись.
     * @param payload - Содержимое, записываемое в поле модели.
     */
    public set(
        field:   Extract<keyof ModelShape, string>,
        payload: AllowedDataForModelFields
    ): void
    {
        if ('undefined' === typeof payload) {
            throw new ModelException({
                model:   this.modelName().toString(),
                code:    ModelException.UNDEFINED_PAYLOAD_TYPE,
                field:   field,
                message: `Payload type 'undefined' is forbidden for field '${field}'` +
                         ` in Model: {${this.modelName().toString()}}`,
            });
        }

        const map: FieldMap = new FieldMap(field);

        if ('undefined' === typeof (this as {[index: string]: any})[map.rootField]) {
            throw new ModelException({
                model:   this.modelName().toString(),
                code:    ModelException.MODEL_FIELD_NOT_EXISTS,
                field:   field,
                payload: payload,
                message: `Root field '${map.rootField}' (${field} requested) does not exists` +
                         ` in Model: {${this.modelName().toString()}}`,
            });
        }

        // если идет запись в корневое поле модели:
        if (!map.hasTail()) {
            (this as { [index: string]: any })[map.rootField] = (
                !this.fieldToModelAutoCast.hasOwnProperty(map.rootField) ||
                null === payload
            )
                ? payload
                : this.createSubModel(
                    map.rootField as unknown as Extract<keyof ModelShape, string>,
                    payload
                );
            return;
        }

        // иначе запись идет еще в какое-либо поле,
        // внутри корневого поля модели:
        let
            index  = 0,
            search = (this as {[index: string]: any})[map.rootField];

        // если корневое поле является саб моделью, либо должно ей быть
        // согласно маппингу:
        if (
            search instanceof Model ||
            this.fieldToModelAutoCast.hasOwnProperty(map.rootField)
        ) {
            if (null === search) {
                // создаем пустую саб модель, для
                // последующего заполнения ее конкретного поля:
                search = this.createSubModel(
                    map.rootField as unknown as Extract<keyof ModelShape, string>
                );
            }

            search.set(
                map.tailString,
                payload
            );

            return;
        }

        // пошагово проверяем каждый сегмент хвоста запроса,
        // в попытке записать значение в его конец
        // (по больше части необходимо для корректной обработки, если
        // где-то на пути хвоста встретится другая модель):
        for (const nextSegment of map.tail) {
            // если текущей сегмент число или строка:
            if ('object' !== typeof search) {
                // если это не последний сегмент, то продолжить цепочку
                // физически невозможно:
                if (index !== map.tailLength) {
                    throw new ModelException({
                        model:   this.modelName().toString(),
                        code:    ModelException.MODEL_FIELD_NOT_EXISTS,
                        field:   field,
                        payload: payload,
                        message: `Field ${field} does not exists in Model: {${this.modelName().toString()}}.` +
                                 ` Failed at segment '${nextSegment}'.`,
                    });
                }

                // записываем значение в модель:
                search = payload;
                return;
            }

            if (null === search) {
                // если текущий сегмент 'null', то создаем вложенные ассоциативные массивы,
                // пока не дойдем до конца хвоста:
                Arr.set(search, map.tail.splice(index).join('.'), payload);
                return;
            }

            if (search instanceof Model) {
                search.set(
                    map.tail.splice(index).join('.'),
                    payload
                );

                return;
            }

            // если текущей сегмент это массив:
            if ('object' === typeof search) {
                if ('undefined' === typeof search[nextSegment]) {
                    throw new ModelException({
                        model:   this.modelName().toString(),
                        code:    ModelException.MODEL_FIELD_NOT_EXISTS,
                        field:   field,
                        payload: payload,
                        message: `Field ${field} does not exists in Model: {${this.modelName().toString()}}.` +
                                 ` Failed at segment '${nextSegment}'.`,
                    });
                }

                if (index === map.tailLength) {
                    search = payload;
                    return;
                }

                index++;
                search = search[nextSegment];
                continue;
            }

            // на случай если нет логики для обработки такого типа:
            throw new ModelException({
                model:   this.modelName().toString(),
                field:   field,
                payload: payload,
                message: `Unable to set value with type of '${typeof payload}'` +
                         ` to the nested model field '${field}'` +
                         ` for the Model: {${this.modelName().toString()}}`,
            });
        }
    }

    /**
     * Возвращает запрошенное корневое / вложенное поле модели,
     * либо 'Default' значение, если запрошенное поле не существует,
     * либо не задано.
     *
     * Пример:
     * .get('a', 'default')
     * .get('a.b.c', 44)
     *
     * @param field - Поле (или вложенное поле), в котором ищется значение.
     * @param def   - Значение, которое необходимо вернуть если поле не найдено.
     */
    public get<Return = ModelShape[Extract<keyof ModelShape, string>], Default = null>(
        field: Extract<keyof ModelShape, string>|string,
        def: Return|Default|null = null
    ): Return|Default|null
    {
        const map = new FieldMap(field);
        if ('undefined' === typeof (this as {[index: string]: any})[map.rootField]) {
            return def;
        }

        if (!map.hasTail()) {
            return (this as {[index: string]: any})[map.rootField];
        }

        let
            index  = 0,
            search = (this as {[index: string]: any})[map.rootField];

        // пошагово проверяем каждый сегмент хвоста запроса,
        // в попытке получить итоговое запрошенное значение:
        for (const nextSegment of map.tail) {
            // если текущей сегмент число или строка:
            if ('object' !== typeof search) {
                if (index !== map.tailLength) {
                    return def;
                }

                return search;
            }

            if (null === search) {
                // если рассматривается не последний сегмент хвоста и текущее его значение "null",
                // то дальше продолжать искать смысла нет, возвращаем значение по умолчанию:
                return (index === map.tailLength)
                    ? null
                    : def;
            }

            // логика обработки если текущий сегмент является моделью:
            if (search instanceof Model) {
                return search.get<Return, Default>(map.tail.splice(index).join('.'), def);
            }

            // если текущей сегмент это объект:
            // (подразумевается обычный/ассоциативный массив)
            if ('object' === typeof search) {
                if ('undefined' === typeof search[nextSegment]) {
                    return def;
                }

                if (index === map.tailLength) {
                    return search;
                }

                index++;
                search = search[nextSegment];
                continue;
            }

            // на случай если нет логики для обработки такого типа:
            // Исключение будет выброшено в случае несоответствия
            throw new ModelException({
                model:   this.modelName().toString(),
                message: `Unable to retrieve requested field '${field}'` +
                         ` of the Model: {${this.modelName().toString()}}`,
                field:   field,
            });
        }

        // на случай если нет логики для обработки такого типа:
        throw new ModelException({
            model:   this.modelName().toString(),
            message: `Unable to retrieve requested field '${field}'` +
                     ` of the Model: {${this.modelName().toString()}}`,
            field:   field,
        });
    }

    /**
     * Проверяет существование запрошенного поля модели.
     *
     * @param field
     */
    public hasField(
        field: string
    ): boolean
    {
        try {
            const marker: symbol = Symbol
                .for('::not-exists::');

            return this
                .get<any, Symbol>(field, marker) !== marker;

        } catch (error) {

            if (error instanceof ModelException) {
                throw error;
            }

            let errorMsg: string = `Unexpected error occurred while trying to check "${field}" field` +
                                   `  in Model: "${this.modelName().toString()}"`;

            if ('string' === typeof error) {
                errorMsg += `  Threw with message:  ${error}`;
            }

            if ('object' === typeof error && null !== error && error.hasOwnProperty('message')) {
                errorMsg += ` Threw with message: ${(error as {message: string}).message}`;
            }

            throw new ModelException({
                model:   this.modelName().toString(),
                message: errorMsg,
            });
        }
    }

    /**
     * Возвращает список корневых полей модели.
     */
    public fields(): Extract<keyof ModelShape, string>[]
    {
        return this.modelFields;
    }

    /**
     * Преобразует модель в ассоциативный массив.
     *
     * Так же будет произведена попытка преобразовать в массив
     * значения всех полей, которые являются экземпляром 'Arrayable'.
     *
     * @param except
     */
    public toArray(except: string[]|null = null): ToArrayConvolution
    {
        type ModelTypeMock        = {[index: string]: any};
        type FieldConvolutionType = string|number|null|object|boolean;

        const castVariableType = (item: any): FieldConvolutionType => {

            const fieldType = typeof item;

            if (['undefined', 'function'].includes(fieldType) || null === item) {
                return null;
            }

            if ('symbol' === fieldType) {
                return item.toString();
            }

            if ('object' === fieldType) {
                return (isArrayable(item))
                    ? item.toArray()
                    : item;
            }

            return item;
        };

        const
            data: ToArrayConvolution = {},
            exceptList = Arr.flip(except ?? []);

        for (const field of this.fields()) {
            if (exceptList.hasOwnProperty(field)) {
                continue;
            }

            if (
                null !== (this as ModelTypeMock)[field] &&
                'object' === typeof (this as ModelTypeMock)[field] &&
                !((this as ModelTypeMock)[field] instanceof Model)
            ) {
                type RootDataType =
                    {[index: string]: {[index: string|number]: FieldConvolutionType} | FieldConvolutionType};

                data[field] = {};
                for (const subKey of Object.keys((this as ModelTypeMock)[field])) {
                    if (null === data[field] || !(data[field]?.hasOwnProperty(subKey))) {
                        (data[field] as RootDataType)[subKey] = {};
                    }

                    (data[field] as RootDataType)[subKey] = castVariableType((
                        this as ModelTypeMock)[field][subKey]
                    );
                }

                continue;
            }

            data[field] = castVariableType((this as ModelTypeMock)[field]);
        }

        return data;
    }

    /**
     * ВНИМАНИЕ!
     * Инициализация модели из наследующего класса необходима
     * так как не все действия можно реализовать в области видимости
     * родительского конструктора.
     */
    protected init(
        feed: Partial<ModelShape>|null = null
    ): void
    {
        this.detectModelFields();

        if (feed) {
            this.fillFromArrayFeed(feed);
        }
    }

    /**
     * Создание модели для поля, описанного в "fieldToModelAutoCast".
     *
     * @param field - поле, для которого будет создаваться модель
     * @param data  - (опционально) фид данных, из которых может быть создана модель
     *
     * @return Model
     * @throws ModelException
     */
    private createSubModel(
        field: Extract<keyof ModelShape, string>,
        data?: AllowedDataForModelFields
    ): typeof Model
    {
        if (!this.fieldToModelAutoCast.hasOwnProperty(field)) {
            throw new ModelException({
                code:    ModelException.SUB_MODEL_CREATION_FAILED,
                model:   this.modelName().toString(),
                field:   field,
                message: `Trying to create sub model for field "${field}",` +
                         ` but that field does not exists in mapping list.` +
                         ` Model: {${this.modelName().toString()}}.`,
            });
        }

        const modelCreator = (
            field: Extract<keyof ModelShape, string>,
            data?: AllowedDataForModelFields
        ): Model<ModelShape> => {

            try {
                // @ts-ignore TODO: исправить ошибку
                return new this.fieldToModelAutoCast[field](data);

            } catch (exception) {
                throw new ModelException({
                    code:    ModelException.SUB_MODEL_CREATION_FAILED,
                    model:   this.modelName().toString(),
                    message: `Failed to create sub model for field "${field}". ` +
                             ` Model: {${this.modelName().toString()}}.`,
                });
            }
        };

        if (!data) {
            return (modelCreator(field) as unknown as typeof Model);
        }

        // Создание временного скелета вложенной модели
        // для использования ее имени в проверках:
        const subModelCheck = modelCreator(field);

        if ('object' !== typeof data) {
            throw new ModelException({
                model:   this.modelName().toString(),
                code:    ModelException.INCORRECT_FIELD_TYPE,
                field:   field,
                payload: data,
                message: `Trying to pass payload type ${typeof data} to the field "${field}"` +
                         ` that mapped as sub model "${subModelCheck.modelName().toString()}"` +
                         ` in Model: {${this.modelName().toString()}}`,
            });
        }

        if (
            data instanceof Model &&
            data.modelName() !== subModelCheck.modelName()
        ) {
            throw new ModelException({
                model:   this.modelName().toString(),
                code:    ModelException.INCORRECT_FIELD_TYPE,
                field:   field,
                payload: data,
                message: `Incorrect type for field "{${field}".` +
                         ` Trying to pass "{${data.modelName().toString()}", but` +
                         ` available types "${subModelCheck.modelName().toString()}" or "null"` +
                         ` in Model: {${this.modelName().toString()}}.`
            });
        }

        return (modelCreator(
            field,
            data
        ) as unknown as typeof Model);
    }

    /**
     * Поиск и сохранение в переменную всех полей модели,
     * описанных в наследуемом классе.
     *
     * Если наследуемый класс не имеет ни одного поля,
     * то будет выброшено исключение.
     *
     * @return void
     * @throws ModelException
     */
    private detectModelFields(): void
    {
        this.modelFields = [];

        const systemFields: {[index: string]: boolean} = {
            modelFields:          true,
            fieldToModelAutoCast: true,
        };

        for (const field of Object.getOwnPropertyNames(this)) {
            if (systemFields.hasOwnProperty(field)) {
                continue;
            }

            this.modelFields
                .push(field as unknown as Extract<keyof ModelShape, string>);
        }

        if (this.modelFields.length < 1) {
            throw new ModelException({
                model:   this.modelName().toString(),
                message: `Model: "${this.modelName().toString()}" has empty fields list.` +
                         ` Make sure you call 'init' method from inherited class constructor.`,
            });
        }
    }
}