import {
    MaskedNumberOptions,
    MaskedRegExpOptions,
} from "imask";

export default function () {
    const transactionIdMask: MaskedNumberOptions = {
        mask:  Number,
        min:   1,
        max:   99999999999,
        scale: 0,
    };
    
    const transactionAmountMask: MaskedNumberOptions = {
        mask:  Number,
        min:   1,
        max:   999999999999999,
        scale: 0,
    };

    const couponIdMask: MaskedNumberOptions = {
        mask:  Number,
        min:   1,
        max:   99999999999,
        scale: 0,
    };

    const couponAmountMask: MaskedNumberOptions = {
        mask:  Number,
        min:   1,
        max:   999999999999999,
        scale: 0,
    };
    
    const tokenMask: MaskedRegExpOptions = {
        mask: /^[a-zA-Z0-9]{0,30}$/,
    };
    
    const loginMask: MaskedRegExpOptions = {
        mask: /^[a-zA-Z0-9]{0,20}$/,
    };

    const passwordMask: MaskedRegExpOptions = {
        mask: /^[a-zA-Z0-9!@#$%^&*()\-_=+;:,<.>№`~?']{0,35}$/,
    };

    const depositMask: MaskedNumberOptions = {
        mask:  Number,
        max:   999999999999999,
        min:   1,
        scale: 0,
    };

    const betStakeMask: MaskedNumberOptions = {
        mask:  Number,
        max:   999999999999999,
        min:   1,
        scale: 0,
    };

    const customerId: MaskedNumberOptions = {
        mask: Number,
        max:   999999999999999,
        min:   1,
        scale: 0,
    }
    
    return {
        transactionIdMask,
        transactionAmountMask,
        couponIdMask,
        couponAmountMask,
        tokenMask,
        loginMask,
        passwordMask,
        depositMask,
        betStakeMask,
        customerId,
    };
}