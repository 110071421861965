export enum EposCoreErrors {
    /** @deprecated */
    GLOBAL_UNEXPECTED_ERROR                 = 100100,
    /** @deprecated */
    NOT_ALLOWED_ON_AUTHORIZED_WORKSPACE     = 100101,
    /** @deprecated */
    NOT_ALLOWED_ON_GUEST_WORKSPACE          = 100102,

    WORKSPACE_LOGIC_ERROR                   = 100103,
    FAILED_TO_STORE_WORKSPACE_DATA_TO_DB    = 100104,
    DATABASE_INIT_ERROR                     = 100105,
    CASHIER_ALREADY_LOGGED_IN               = 100106,
    LOGGED_IN_CASHIER_REQUIRED              = 100108,
    CASHIER_LOG_OUT_ATTEMPT_ON_ACTIVE_SHIFT = 100108,
    CASHIER_LOG_IN_ERROR                    = 100109,
    CASHIER_LOG_OUT_ERROR                   = 100110,
    CASHIER_WORKING_SHIFT_OPEN_ERROR        = 100111,
    CASHIER_WORKING_SHIFT_CLOSE_ERROR       = 100112,
    INCORRECT_ROUTE_NAME                    = 100113,
    TEXTS_LIBRARY_CACHE_ERROR               = 100114,
    TEXTS_LIBRARY_SYNC_ERROR                = 100115,
    WORKSPACE_CACHE_EXTRACTION_ERROR        = 100116,
    WORKSPACE_INIT_CONSTANT_CORRUPTED       = 100117,
    UNKNOWN_INTERFACE_TYPE_DETECTED         = 100118,
    UNKNOWN_APP_LOCALE_DETECTED             = 100119,
    WORKSPACE_INIT_TASK_FAILED_MAX_ATTEMPTS = 100120,
    WORKSPACE_MODULE_ACCESS_ERROR           = 100121,
    UNEXPECTED_API_ERROR                    = 100122,
    WORKSPACE_AUTH_REJECTED                 = 100123,
    CASHIER_TOKEN_EXPIRED                   = 100124,
}