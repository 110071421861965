<template>
  <h1>Window preloader</h1>
</template>

<script lang="ts">
import VueComponentType
    from "~lib/render/VueComponent";

const CMP_NAME = 'WindowLoad';
export default {
  name: CMP_NAME,
} as VueComponentType
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
// -

/** IMPORTED VUE TEMPLATES: */
// -

/** COMPONENT LOGIC: */
// -
</script>