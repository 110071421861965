<template>
  <div class="workspace-side-menu">
    <ul class="workspace-side-menu__list">
      <li
          v-if="null !== leftMenu"
          v-for="(elem, index) in leftMenu"
          :key="elem.window"
          class="workspace-side-menu__item"
      >
        <button
            @click.prevent="$store.changeActiveWindowId(elem.window)"
            :class="{
                'workspace-side-menu-link--is-active': $store.windowId === elem.window,
                'workspace-side-menu-link--is-disabled': !$shift.isActive || $shift.isDurationExceed24Hours
            }"
            class="workspace-side-menu__link workspace-side-menu-link"
        >
          <SvgElem
              sprite="cleared"
              :to="($store.windowId === elem.window) ? getActiveMenuIco(elem.data.ico) : elem.data.ico"
              size="size-s"
              class="workspace-side-menu-link__icon"
          />
          <span class="workspace-side-menu-link__label">{{ elem.data.name }}</span>
        </button>
      </li>
    </ul>
    <Button
      theme-style="outlined"
      theme="outlined-primary"
      ico="arrow--left"
      ico-only
      class="workspace-side-menu__button"
    />
  </div>
</template>

<script lang="ts">
const CMP_NAME = 'LeftSideMenu';
export default {
  name: CMP_NAME,
}
</script>

<script setup lang="ts">
/** IMPORTED SCRIPTS: */
import {
  ref,
  defineProps
} from "@vue/runtime-core";

import ApplicationState
  from "~app/Storage/Application.state";
import ShiftState
  from "~app/Storage/Shift.state";
import {WM_TAG_LEFT}
  from "~app/Providers/Windows/WindowsManager.provider";
import useWindowsManager
  from "~interaction/useWindowsManager";
import {SvgList}
  from "~svg/cleared/menu/SvgList";

/** IMPORTED VUE TEMPLATES: */
import SvgElem
  from "~tpl-global/svg/SvgElem.vue";
import Button from "~tpl-global/Buttons/ButtonDefault/Button.vue";
// -

/** COMPONENT LOGIC: */
const $store = ApplicationState();
const $shift = ShiftState();
const {
  $WM
} = useWindowsManager();

// список окон левого меню:
const leftMenu = $WM
    .getTagInfo(WM_TAG_LEFT);

/**
 * Фикс отображения иконок для активного состояния меню.
 */
function getActiveMenuIco(payload: string): SvgList
{
  return (('dashboard' !== payload)
      ? `${payload}-filled`
      : `${payload}--filled`) as unknown as SvgList;
}

type ComponentProps = {

};

const props = withDefaults(defineProps<ComponentProps>(), {
});

const emitter = defineEmits(['showSideMenu']);
</script>

<style lang="scss" scoped>
  @import '~scss-desktop/common/components/navigation/left-side-menu';
</style>