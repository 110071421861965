import {defineStore}
    from 'pinia'
import {ref, computed}
    from "@vue/runtime-core";

export const ThrottlingStateKey: symbol = Symbol
    .for('PiniaThrottlingState');

const ThrottlingState = defineStore(ThrottlingStateKey.toString(), () => {

    /**
     * Публичный токен, предназначенный для взаимодействия
     * с Google ReCaptcha от имени проекта WEB касс.
     */
    const _reCaptchaKey = ref<string|null>(null);

    /**
     * Публичный токен, предназначенный
     * для взаимодействия с Google ReCaptcha.
     */
    const reCaptchaKey = computed(
        () => _reCaptchaKey.value
    );

    /**
     * Возвращает "TRUE" если для защищенных HTTP форм
     * требуется проверка "captcha".
     */
    const isHttpThrottlingActive = computed(
        (): boolean => null !== _reCaptchaKey.value
    );

    /**
     * Обновление, или удаление, токена,
     * необходимого для доступа к API ReCaptcha.
     */
    function setOrRemoveReCaptchaKey(
        payload: string|null
    ): void
    {
        _reCaptchaKey.value = payload;
    }

    return {
        reCaptchaKey,
        isHttpThrottlingActive,

        setOrRemoveReCaptchaKey,
    };
});

export default ThrottlingState;